import React, { Component } from 'react';
import { Link } from 'react-router-dom';
require('./style.scss');

class ReflectionSkillCard extends Component {
  render(){
    return (
      <Link to={this.props.linkUrl} className="reflection-skill-card" onClick={this.props.onClick} aria-role="button" aria-label={this.props.title}>
        <img src={this.props.imageUrl} alt="" />
        <h3 aria-hidden="true">{this.props.title}</h3>
      </Link>
    );
  }
}

export default ReflectionSkillCard;
