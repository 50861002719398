import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import MyContext from '../../state/MyContext';
import PageHeader from '../PageHeader';
import MotivationSetter from '../MotivationSetter';
import FooterUpdator from '../FooterUpdator';
require('./style.scss');

class Motivations extends Component {

  componentDidMount() {
    //if(!this.context.user_logged_in) this.props.history.push('/');
  }

  _onSortEnd(list){
    this.context.updateMotivations(list).then(() => {

      let motivations_id_list = '';
      list.forEach((motivation, index)=>{
        motivations_id_list += motivation.id;
        if(index < list.length-1) motivations_id_list += ',';
      });

      //KeenIO
      this.context.sendKeenEvent('motivations', {
        skip: false,
        motivation_order: motivations_id_list,
        motivation_changed: true,
        onboarding: this.context.user_onboarded ? false : true,
        path: '/myself/motivations'
      });

      //MixPanel
      this.context.sendMixPanelEvent('motivations', {
        skip: false,
        motivation_order: motivations_id_list,
        motivation_changed: true,
        onboarding: this.context.user_onboarded ? false : true,
        path: '/myself/motivations'
      });

      this.context.getGuides().then(() => {
        this.context.sortFeaturedChallenges();
      });
    });
  }

  render(){
    return (
      <div id="motivations">
        <Helmet>
          <title>Nod - Motivations</title>
        </Helmet>
        <PageHeader bgColor="tan" />
        <h1>Priorities changed? Re-rank your goals here.</h1>
        <MotivationSetter onSortEnd={this._onSortEnd.bind(this)} />
        <FooterUpdator normal />
      </div>
    );
  }
}

Motivations.contextType = MyContext;
export default Motivations;
