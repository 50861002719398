import React, { Component } from 'react';
import { Link } from 'react-router-dom';
require('./style.scss');

class ChallengeTeaser extends Component {
  render(){
    let animated_gifs = window.localStorage.getItem('@nod:animated_gifs');
    if(animated_gifs === 'false') animated_gifs = false;
    if(animated_gifs === null) animated_gifs = true;

    let students = null;
    if(this.props.partipants >= 100) students = <div className="students">{this.props.partipants} students</div>;
    return (
      <Link to={this.props.link} className={'challenge-teaser ' + this.props.theme}>
        <div className="imageWrap">
          <div className={'animation-wrap' + (animated_gifs ? ' animated' : '')}>
            <img src={this.props.image_url} alt="" />
          </div>
          {students}
        </div>
        <div className="titleWrap">
          <div dangerouslySetInnerHTML={{__html: this.props.introduction}}></div>
          <h1>{this.props.title}</h1>
          <div className="tag-wrap">
            {
              this.props.tags.map( (tag, index) => {
                return (
                  <div className="tag" key={'tag-'+index}>
                    <i className={'icon ' + tag.className}/>{tag.title}
                  </div>
                );
              })
            }
          </div>
        </div>
        <div className="button">See Tips <i className="icon arrow"/></div>
      </Link>
    );
  }
}

export default ChallengeTeaser;
