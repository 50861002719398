import React, { Component } from 'react';
import { Link } from 'react-router-dom';
require('./style.scss');

class SignUpForm extends Component {

  constructor (props) {
    super();
    this.state = {
      first_name_value: '',
      last_name_value: '',
      email_value: '',
      password_value: '',
      validation_message: '',
      submit_disabled: false,
      terms_selected: true,
    }

    //Bindings & References
    this._handleFirstNameChange = this._handleFirstNameChange.bind(this);
    //this._handleLastNameChange = this._handleLastNameChange.bind(this);
    this._handleEmailChange = this._handleEmailChange.bind(this);
    this._handlePasswordChange = this._handlePasswordChange.bind(this);
    this._handleClickTerms = this._handleClickTerms.bind(this);
    this._handleSubmit = this._handleSubmit.bind(this);
  }

  _handleFirstNameChange(event) {
    this.setState({first_name_value: event.target.value});
  }

  // _handleLastNameChange(event) {
  //   this.setState({last_name_value: event.target.value});
  // }

  _handleEmailChange(event) {
    this.setState({email_value: event.target.value});
  }

  _handlePasswordChange(event) {
    this.setState({password_value: event.target.value});
  }

  _handleClickTerms(e) {
    this.setState({terms_selected: e.target.checked, terms_warning:!e.target.checked})
  }

  _handleSubmit(e) {
    e.preventDefault();

    let valid = true;
    let valid_message = '';
    this.setState({validation_message: valid_message});

    //Check first_name
    if(!this._isValidName(this.state.first_name_value)){
      valid = false;
      valid_message += 'Please enter a first name. ';
    }

    //Check last_name
    // if(!this._isValidName(this.state.last_name_value)){
    //   valid = false;
    //   valid_message += 'Please enter a last name. ';
    // }

    //Check email
    if(!this._isValidEmail(this.state.email_value)){
      valid = false;
      valid_message += 'Please use a valid email address. ';
    }

    //Check password
    if(!this._isValidPassword(this.state.password_value)){
      valid = false;
      valid_message += 'Please use a password that is 8 characters or longer. ';
    }

    //Check terms
    // if(!this.state.terms_selected){
    //   valid = false;
    //   valid_message += 'You must agree to get started. ';
    // }

    if(valid){
      this.setState({submit_disabled: true});
      this.props.onSubmit(this.state.first_name_value, this.state.last_name_value, this.state.email_value, this.state.password_value, this.state.terms_selected, (error, message) => {
        if(error) this.setState({validation_message: message, submit_disabled: false});
      });
    }else{
      this.setState({validation_message: valid_message, submit_disabled: false});
    }
  }

  _isValidName(name){
    return (name.length < 1) ? false : true;
  }

  _isValidEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape
    return re.test(email);
  }

  _isValidPassword(password) {
    return (password.length < 8) ? false : true;
  }

  render(){
    return(
      <form onSubmit={this._handleSubmit}>
        <label htmlFor="first_name">What’s your first name?</label>
        <input id="first_name" type="text" value={this.state.first_name_value} onChange={this._handleFirstNameChange} placeholder="Your Name" />
        {/*<label htmlFor="last_name">your last name</label>
        <input id="last_name" type="text" value={this.state.last_name_value} onChange={this._handleLastNameChange} placeholder="last_name" />*/}
        <label htmlFor="email">What’s your school email?</label>
        <input id="email" type="text" value={this.state.email_value} onChange={this._handleEmailChange} placeholder="you@school.edu" />
        <label htmlFor="password">
          Choose a password
          <div className="small">Must include 8 characters.</div>
        </label>
        <input id="password" type="password" value={this.state.password_value} onChange={this._handlePasswordChange} />
        {/*<div className="terms-consent">
          <input type="checkbox" checked={this.state.terms_selected} name="terms-consent" id="terms-consent" data-testid="terms-consent" onChange={this._handleClickTerms}/>
          <label className={this.state.terms_selected ? 'active' : ''} htmlFor="terms-consent">I agree to <Link to='/terms-and-conditions'>Terms of Use</Link> and <Link to='/privacy-policy'>Privacy Policy</Link>.</label>
        </div>*/}
        <br />
        {(this.state.validation_message !== '') ? <p className="validationText">{this.state.validation_message}</p> : null}
        <input type="submit" value="Create Account" disabled={ this.state.submit_disabled ? 'disabled' : false } />
        {/*<p className="legal">The NOD portal is 100% confidential. No personal data will ever be associated with your institution or shared with any third parties for commercial purposes. By logging in, you agree to the <Link to='/terms-and-conditions'>Terms of Use</Link> and <Link to='/privacy-policy'>Privacy Policy</Link>.</p>*/}
        <div className="textLink">
          <Link to="/login"> Already Have an Account? </Link>
        </div>
      </form>
    );
  }
}

export default SignUpForm;
