import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { withRouter } from 'react-router-dom';
import posed, { PoseGroup } from 'react-pose';
import moment from 'moment';
import FooterModal from '../FooterModal';
import MyContext from '../../state/MyContext';
import Background from '../Background';
import Header from '../Header';
import Footer from '../Footer';
import Home from '../Home';
import HomeAnonymous from '../HomeAnonymous';
import Welcome from '../Welcome';
import DemoAccess from '../DemoAccess';
import FindYourSchool from '../FindYourSchool';
import Onboarding from '../Onboarding';
import PasswordReset from '../PasswordReset';
import SignUp from '../SignUp';
import SignUpSocial from '../SignUpSocial';
import SignIn from '../SignIn';
import SignInSocial from '../SignInSocial';
import SignOut from '../SignOut';
import EmailVerification from '../EmailVerification';
import Guides from '../Guides';
import Guide from '../Guide';
import Challenge from '../Challenge';
import Reflect from '../Reflect';
import Reflection from '../Reflection';
import Motivations from '../Motivations';
import Checkbacks from '../Checkbacks';
import Journey from '../Journey';
import Settings from '../Settings';
import Notifications from '../Notifications';
import TermsAndConditions from '../TermsAndConditions';
import PrivacyPolicy from '../PrivacyPolicy';
import Feedback from '../Feedback';
import FourOhFour from '../FourOhFour';
import Blank from '../Blank';
import './_global.scss';
import './style.scss';
var pjson = require('../../../package.json');

//Default Metadata
const default_helmet = (
  <Helmet>
    <meta charSet="utf-8" />
    <title>Nod</title>
    <link rel="canonical" href="" />
  </Helmet>
);

const RoutesContainer = posed.div({
  enter: {
    opacity: 1,
    delay: 300,
    scale: 1
  },
  exit: {
    opacity: 0,
    scale: 1
  }
});

const ProtectedRoute = ({ context: context, component: Component, ...rest }) => (
   <Route {...rest} render={(props) => (
      context.user_logged_in === true ?
         <Component {...props} /> : <Redirect to={{ pathname: '/', state: { from: props.location }}} />
   )} />
);

class MainWrapper extends Component {

  /*
    DEV NOTE: I'm sorry this component has become a bit of a tangled mess.
    Lots of dev under tight deadlines with no refactor time.
    Would be good to break out some of this initialization code into a more organized/understandable fashion.
    Redirects from Notifications and SSO complicated things a lot and there's probably a much better approach to take.
    See UrlRedirects.jsx - Very frustrating that this is window event driven.
    Would be better if we could somehow see if there is an incoming event before actually loading the React app.
  */

  constructor() {
    super();

    this.state = {
      update_modal_show: false,
      update_modal_force: false,
      update_modal_message: ''
    }

    this.page_start_time = new Date();
    this.scroll_gate = false;
    this.scrolled = false;
    this.time_on_page = 0;
    this.did_scroll = false;

    //Pages to track scrolling - Wildcards may only be used once at the end of the string like /challenges/* NOT /challenges/*/id
    this.scrolled_paths = ['/', '/guides', '/guides/*', '/skills', '/skills/*', '/reflect', '/myself', '/myself/settings', '/myself/notifications', '/challenges/*'];

    //Bindings & References
    this.footerModal = React.createRef();
    this._resume = this._resume.bind(this);
    this._beforeUnload = this._beforeUnload.bind(this);
    this.mainAppDiv = React.createRef();
  }

  //This is the very start of the app
  componentDidMount() {

    //Setup some listeners
    window.addEventListener('offline', () => { this.context.setState({online:false}); }, false);
    window.addEventListener('online', () => {this.context.setState({online:true}); this._begin(); }, false);
    document.addEventListener('offline', () => { this.context.setState({online:false}); }, false);
    document.addEventListener('online', () => {this.context.setState({online:true}); this._begin(); }, false);
    this.props.history.listen((location, action) => this._historyChangeHandler(location.pathname));

    //Before Unload and Resume
    document.addEventListener('beforeunload', this._beforeUnload);
    document.addEventListener('pause', this._beforeUnload);
    document.addEventListener('resume', this._resume);

    //Detect page scroll
    window.onscroll = (e) => {
      if(this.scroll_gate) this.scrolled = true;
    };

    //Start
    if(!navigator.onLine){
      this.context.setState({online:false});
    }else{

      //Let's run the historyChangeHandler to see if we need to respond to the path (ie sso login)
      //the problem here is it ends up firing off an appflow event without loaded user data
      //we want to fire this event after _begin or _beginSSO
      this._historyChangeHandler(this.props.location.pathname);

      //This is delayed so we can see context state changes from _historyChangeHandler
      setTimeout(() => {
        if(!this.context.sso_logging_in) this._begin(); //If not an SSO Login, we begin the app
      }, 500);
    }
  }

  _resume(){
    this._timeOnPageHandling();
    if(this.context.user_logged_in) this.context.sessionStarted(true); //fires in _begin otherwise
  }

  _beforeUnload(e) {
    let params = { duration: new Date().getTime() - this.context.session_start.getTime() };
    this.context.sendKeenEvent('session_duration', params);
    this.context.sendMixPanelEvent('session_duration', params);

    this._scrollHandling();
    this._timeOnPageHandling();
    this.context.sendKeenEvent('app_flow', {
      path: this.context.last_path,
      prior_path: this.context.last_last_path,
      time_on_page: this.time_on_page,
      scrolled: this.did_scroll,
      exit_app: true,
      marketing_channel: this.context.marketing_channel,
      push_notification_title: this.context.push_notification_title,
      page_view_count: this.context.page_view_count
    });

    this.context.sendMixPanelEvent('app_flow', {
      path: this.context.last_path,
      prior_path: this.context.last_last_path,
      time_on_page: this.time_on_page,
      scrolled: this.did_scroll,
      exit_app: true,
      marketing_channel: this.context.marketing_channel,
      push_notification_title: this.context.push_notification_title,
      page_view_count: this.context.page_view_count
    });

    if(this.context.last_path.includes('/reflect')){
      let guide_title = null;
      let challenge_id = null;
      let challenge_title = null;
      if(this.context.user_challenge_just_completed){
        const challenge = this.context.challenges.find((thechallenge)  => { return thechallenge.id === this.context.reflect_came_from_challenge_id; });
        challenge_id = challenge.id;
        challenge_title = challenge.title;
        const guide = this.context.guides_all.find((theguide) => { return theguide.id === challenge.guide_id; });
        guide_title = guide.title;
      }
      this.context.sendKeenEvent('reflection', {
        skip: false,
        guide_title: guide_title,
        challenge_id: challenge_id,
        challenge_title: challenge_title,
        reflection_id: this.context.reflect_current ? this.context.reflect_current.id : null,
        reflection_title: this.context.reflect_current ? this.context.reflect_current.title : null,
        reflection_type: this.context.reflect_current ? this.context.reflect_current.type : null,
        back_navigation: false,
        home_navigation: false,
        finish: false,
        modal_page: 'finished',
        option_selected_now: null,
        option_selected: this.context.reflect_option ? this.context.reflect_option.button_text : null,
        mood_changed: this.context.reflect_mood_changed,
        mood_balance: this.context.reflect_mood_balance,
        mood_label: this.context.reflect_mood_coord_label,
        skill: this.context.reflect_skill_choice,
        audio_played: null,
        exit_app: true,
      });

      this.context.sendMixPanelEvent('reflection', {
        skip: false,
        guide_title: guide_title,
        challenge_id: challenge_id,
        challenge_title: challenge_title,
        reflection_id: this.context.reflect_current ? this.context.reflect_current.id : null,
        reflection_title: this.context.reflect_current ? this.context.reflect_current.title : null,
        reflection_type: this.context.reflect_current ? this.context.reflect_current.type : null,
        back_navigation: false,
        home_navigation: false,
        finish: false,
        modal_page: 'finished',
        option_selected_now: null,
        option_selected: this.context.reflect_option ? this.context.reflect_option.button_text : null,
        mood_changed: this.context.reflect_mood_changed,
        mood_balance: this.context.reflect_mood_balance,
        mood_label: this.context.reflect_mood_coord_label,
        skill: this.context.reflect_skill_choice,
        audio_played: null,
        exit_app: true,
      });
    }
  }

  _begin() {
    this.context.getInitData().then(() => {
      this._checkForUpdates();
      this.context.sessionStarted(false);
    });
  }

  _beginSSO(sso_token, sso_account_was_created) {
    this.context.signInUserSSO(sso_token).then((result) => {
      if(result.success){
        this.context.getInitData().then(() => {
          const welcomeScreenText = this.context.welcome_slide_set['slide'+(this.context.welcome_slide_index+1)];
          if(sso_account_was_created === 'yes'){
            //keen
            this.context.sendKeenEvent('registration', {
              sso: true,
              sso_facebook: false,
              sso_google: false,
              sso_apple: false,
              welcome_screen: welcomeScreenText,
              channel: this.context.marketing_channel
            });
            //mixpanel
            this.context.sendMixPanelEvent('registration', {
              sso: true,
              sso_facebook: false,
              sso_google: false,
              sso_apple: false,
              welcome_screen: welcomeScreenText,
              channel: this.context.marketing_channel
            });
          };

          //keen
          this.context.sendKeenEvent('login', {
            sso: true,
            welcome_screen: welcomeScreenText
          });

          //mixpanel
          this.context.sendMixPanelEvent('login', {
            sso: true,
            welcome_screen: welcomeScreenText
          });

          this.context.sessionStarted(false);
          this.props.history.push('/');
        });
      }
    });
  }

  _scrollHandling(){
    //Scrolling
    this.did_scroll = null;
    this.scroll_gate = false;
    this.scrolled_paths.forEach((scroll_path) => {
      if(scroll_path === this.context.last_path) this.did_scroll = this.scrolled;
      if(scroll_path.includes('*')){ //Wildcards
        if(this.context.last_path.includes(scroll_path.replace('*', ''))) this.did_scroll = this.scrolled;
      }
    });
    this.scrolled = false;
    setTimeout(() => { this.scroll_gate = true; }, 500); //So auto-scroll to the top doesn't count
  }

  _timeOnPageHandling(){
    this.time_on_page = moment(new Date()).diff(this.page_start_time, 'seconds');
    this.page_start_time = new Date();
  }

  _destinationParamCheck() {
    if(this.props.location.search !== ''){
      const split = this.props.location.search.substr(1).split('=');
      if(split[0] === 'destination'){
        const dest = split[1];
        if(dest !== ''){
          window.localStorage.setItem('@nod:destination', dest);
        }
      }
    }
  }

  _historyChangeHandler(path) {

    if(path !== '/') document.querySelector('html').classList.remove('noscroll'); //remove freeze scrolling from welcome page
    this._scrollHandling();
    this._timeOnPageHandling();
    this._destinationParamCheck();

    //Analytics
    this.context.sendGAPageLoad(this.context.last_path);
    //keen
    this.context.sendKeenEvent('app_flow', {
      path: this.context.last_path,
      prior_path: this.context.last_last_path,
      time_on_page: this.time_on_page,
      scrolled: this.did_scroll,
      exit_app: false,
      marketing_channel: this.context.marketing_channel,
      push_notification_title: this.context.push_notification_title,
      page_view_count: this.context.page_view_count,
    });
    //mixpanel
    this.context.sendMixPanelEvent('app_flow', {
      path: this.context.last_path,
      prior_path: this.context.last_last_path,
      time_on_page: this.time_on_page,
      scrolled: this.did_scroll,
      exit_app: false,
      marketing_channel: this.context.marketing_channel,
      push_notification_title: this.context.push_notification_title,
      page_view_count: this.context.page_view_count,
    });

    this.context.setState({
      last_path: path,
      last_last_path: this.context.last_path,
      page_view_count: this.context.page_view_count + 1
    });

    if(this.mainAppDiv.current && !['/reflect/interact'].includes(path)){
      this.mainAppDiv.current.focus();
    }

    //Check if landed on sso route
    if(path.includes('/sso/login/')){
      if(!this.context.sso_session && !this.context.user_logged_in){ //Hack for when branchio fires another sso session apon resume
        const path_split = path.split('/');
        if(path_split[1] === 'sso' && path_split[2] === 'login' && path_split.length === 5){
          const sso_token = path_split[3];
          const sso_account_was_created = path_split[4];
          this.context.setState({init_data_loaded:false, sso_session:true, sso_logging_in:true});
          setTimeout(() => this._beginSSO(sso_token, sso_account_was_created), 100);
        }
      }else{
        this.props.history.push('/');
      }
      return null;
    }

    //If logged in (and not sso logging in)
    if(this.context.user_logged_in && !this.context.sso_logging_in){

      //Send to destination
      const destination = window.localStorage.getItem('@nod:destination');
      if(destination){
        window.localStorage.removeItem('@nod:destination');
        return setTimeout(() => this.props.history.push(destination), 100);
      }

      //If a user not onboarded and on a different page than challenges or welcome, send them to onboarding
      if(!this.context.user_onboarded && !path.includes('/challenges/') && !path.includes('/onboarding')){
        return this.props.history.push('/onboarding/onboard');
      }

      //If welcome path but logged in, redirect to homepage
      if(path.includes('/login') || path.includes('/signup') || path.includes('/hello')){
        return this.props.history.push('/');
      }
    }
  }

  _checkForUpdates() {
    const current_version_number = pjson.version;
    const latest_version_number = this.context.app_versions.length > 0 ? this.context.app_versions[0].version : pjson.version;
    const app_version_local_storage = JSON.parse(window.localStorage.getItem('@nod:appVersion'));

    //If the version is out of date
    if(current_version_number !== latest_version_number){
      const current_version = this.context.app_versions.find(version => version.version === current_version_number);
      let force_upgrade = false;
      let show_modal = false;

      //If current version is found, loop through the versions to see if any of them force upgrade
      if(current_version){
        const current_version_index = this.context.app_versions.findIndex(version => version.version === current_version_number);
        this.context.app_versions.forEach((app_version, index) => {
          if(index < current_version_index && app_version.force_upgrade) force_upgrade = true;
        });
      }else{ //Otherwise, force upgrade
        //force_upgrade = false; //Commented out so we can still access it before we've added the new version in the cms.
      }

      //If local storage doesn't show the current version number, show the modal (so it shows it once)
      if(app_version_local_storage){
        if(app_version_local_storage.version !== latest_version_number){
          show_modal = true;
        }
      }else{
        show_modal = true;
      }

      if(show_modal || force_upgrade){
        this.setState({
          update_modal_show: true,
          update_modal_force: force_upgrade,
          update_modal_message: this.context.app_versions[0].message
        });
      }
    }

    //Set Local Storage
    if(app_version_local_storage){
      if(current_version_number !== app_version_local_storage.version) window.localStorage.setItem('@nod:appVersion', '{"version":"'+current_version_number+'"}');
    }else{
      window.localStorage.setItem('@nod:appVersion', '{"version":"'+current_version_number+'"}');
    }
  }

  render() {

    const handleOpenAppStore = (e) => {
      e.preventDefault();
      const isAndroid = navigator.userAgent.match(/android/i) ? true : false;
      const isIOS = navigator.userAgent.match(/(ipod|ipad|iphone)/i) ? true : false;
      let link = '';
      if(isIOS){
        link = 'https://itunes.apple.com/app/apple-store/id1474882971';
      }else if(isAndroid){
        link = 'https://play.google.com/store/apps/details?id=com.gritdigitalhealth.nodfrontend';
      }
      //console.log(link);
      if(window.cordova){
        window.cordova.InAppBrowser.open(link, '_system');
      }else{
        window.open(link, "_system");
      }
    }

    let classes = this.context.background_color;
    if(this.context.init_data_loaded && this.context.online && !this.context.sso_logging_in){
      return (
        <div tabIndex="-1" ref={this.mainAppDiv} id="app" className={classes}>
          {default_helmet}
          <Background />
          <Header />
          <div id="main" aria-label="main landmark" className={ this.context.footer_show ? 'has-footer' : '' }>
            <Route render={ ({location}) => (
              <PoseGroup flipMove={false} preEnterPose="exit">
                <RoutesContainer key={location.pathname}>
                  { this.context.disable_web_access ?
                    <Switch location={location}>
                      <Route exact path="/" component={HomeAnonymous} />
                      <Route exact path="/challenges/:challenge" component={Challenge} />
                      <Route exact path="/terms-and-conditions" component={TermsAndConditions} />
                      <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                      <Route component={FourOhFour} />
                    </Switch>
                  :
                    <Switch location={location}>

                      {/* Welcome/Onboarding */}
                      { !this.context.user_logged_in ? <Route exact path="/" component={Welcome} /> : null }
                      { this.context.user_logged_in && !this.context.user_onboarded ? <Route exact path="/" component={Onboarding} /> : null}
                      { this.context.user_logged_in && this.context.user_onboarded ? <Route exact path="/" component={Home} /> : null}
                      <Route exact path="/welcome" component={Welcome} />
                      <Route exact path="/welcome/select-school" component={FindYourSchool} />
                      <Route exact path="/demo-access" component={DemoAccess} />
                      {/*<Route exact path="/welcome/:screen" component={Welcome} />*/}
                      { this.context.user_logged_in ? <Redirect from="/hello/:instance_id" to='/' /> : <Route exact path="/hello/:instance_id" component={Welcome} /> }
                      { this.context.user_logged_in ? <Redirect from="/hi/:research_id" to='/' /> : <Route exact path="/hi/:research_id" component={Welcome} /> }
                      <Route exact path="/onboarding" component={Onboarding} />
                      <Route exact path="/onboarding/:screen" component={Onboarding} />

                      {/* Auth */}
                      <Route exact path="/signup-social" component={SignUpSocial} />
                      <Route exact path="/signup" component={SignUp} />
                      <Route exact path="/login" component={SignIn} />
                      <Route exact path="/login-social" component={SignInSocial} />
                      <Route exact path="/logout" component={SignOut} />
                      <Route exact path="/verify/:token" component={EmailVerification} />
                      <Route exact path="/password-reset" component={PasswordReset} />
                      <Route exact path="/password-reset/:token" component={PasswordReset} />
                      <Route exact path="/sso/login/:x_session_id/:account_created" component={Blank} />{/* _historyChangeHandler() above responds to this */}

                      {/* Guides/Skills/Challenges/Reflections */}
                      <ProtectedRoute context={this.context} exact path="/guides" component={Guides} />
                      <ProtectedRoute context={this.context} exact path="/guides/:guide" component={Guide} />
                      <ProtectedRoute context={this.context} exact path="/skills" component={Guide} />
                      <ProtectedRoute context={this.context} exact path="/skills/:skill" component={Guide} />
                      <Route exact path="/challenges/:challenge" component={Challenge} />
                      <ProtectedRoute context={this.context} exact path="/challenges/:challenge/:screen" component={Challenge} />
                      <ProtectedRoute context={this.context} exact path="/reflect" component={Reflect} />
                      <ProtectedRoute context={this.context} exact path="/reflect/:screen" component={Reflection} />
                      <ProtectedRoute context={this.context} exact path="/reflect/:screen/:skill" component={Reflection} />

                      {/* Myself */}
                      <ProtectedRoute context={this.context} exact path="/myself" component={Journey} />
                      <ProtectedRoute context={this.context} exact path="/myself/motivations" component={Motivations} />
                      <Redirect from="/myself/loneliness" to="/myself/mystatus" />
                      <ProtectedRoute context={this.context} exact path="/myself/mystatus" component={Checkbacks} />
                      <ProtectedRoute context={this.context} exact path="/myself/settings" component={Settings} />
                      <ProtectedRoute context={this.context} exact path="/myself/notifications" component={Notifications} />

                      {/* Misc */}
                      <Route exact path="/terms-and-conditions" component={TermsAndConditions} />
                      <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                      <Route exact path="/feedback" component={Feedback} />
                      <Route component={FourOhFour} />

                    </Switch>
                  }
                </RoutesContainer>
              </PoseGroup>
            )} />
          </div>
          { !this.context.disable_web_access ? <Footer /> : null }

          { this.state.update_modal_show ?
            <FooterModal disableBackgroundClick={this.state.update_modal_force} disableCloseButton={this.state.update_modal_force} parentId="main">
              <h3>Oops, looks like you’re behind the times...</h3>
              <div dangerouslySetInnerHTML={{__html:this.state.update_modal_message}}></div>
              <div className="textLink">
                <a href="/" onClick={handleOpenAppStore}>Open App Store</a>
              </div>
              <br /><br />
            </FooterModal>
          :null
          }

        </div>
      );
    }else{
      if(!this.context.online){
        return (
          <div id="app" className="gradient">
            {default_helmet}
            <div className="main">
              <div role="alert" className="offline">Nod requires an internet connection.</div>
              <div className="intro"/>
            </div>
          </div>
        );
      }else{
        return (
          <div id="app" className="gradient">
            {default_helmet}
            <div className="main">
              <div className="intro"/>
            </div>
          </div>
        );
      }

    }
  }
}

MainWrapper.contextType = MyContext;
export default withRouter(MainWrapper);
