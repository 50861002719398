import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ChallengeDots, {STATE_UNTRIED, STATE_ACTIVE, STATE_COMPLETED} from '../ChallengeDots';
require('./style.scss');

class GuideOverviewCard extends Component {
  render(){
    const untried_count = this.props.dotValues.filter(dot => dot === STATE_UNTRIED).length;
    const active_count = this.props.dotValues.filter(dot => dot === STATE_ACTIVE).length;
    const completed_count = this.props.dotValues.filter(dot => dot === STATE_COMPLETED).length;
    let aria_label = this.props.title + ' has ';
    aria_label += (untried_count + ' untried, ');
    aria_label += (active_count + ' active, and ');
    aria_label += (completed_count + ' completed ideas');
    return (
      <Link to={this.props.link} className={'guide-overview-card ' + this.props.theme} aria-label={aria_label}>
        <div className="activeImage"><img src={this.props.image_url} alt="" /></div>
        <div className="textWrap">
          <h1 aria-hidden="true">{this.props.title}</h1>
          <ChallengeDots dotValues={this.props.dotValues} theme={this.props.theme} />
        </div>
      </Link>
    );
  }
}

export default GuideOverviewCard;
