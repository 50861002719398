import React, { Component } from 'react';
import { Link } from 'react-router-dom';
require('./style.scss');

class ChallengeMiniCard extends Component {
  render(){
    let animated_gifs = window.localStorage.getItem('@nod:animated_gifs');
    if(animated_gifs === 'false') animated_gifs = false;
    if(animated_gifs === null) animated_gifs = true;

    const title_shortened = this.props.title.substring(0, 13);
    const title_shortened_at_word_split = title_shortened.split(' ');
    const last_word = title_shortened_at_word_split[title_shortened_at_word_split.length-1];
    const last_word_length = last_word.length+1;
    const title_shortened_at_word = title_shortened.substring(0, title_shortened.length - last_word_length);
    return (
      <Link to={this.props.link} className={'challenge-mini-card ' + this.props.theme} aria-label={title_shortened_at_word}>
        <div className={'activeImage' + (animated_gifs ? ' animated' : '')}><img src={this.props.image_url} alt="" /></div>
        <div aria-hidden="true" className="title">{title_shortened_at_word}...</div>
      </Link>
    );
  }
}

export default ChallengeMiniCard;
