import React, { Component } from 'react';
import MyContext from '../../state/MyContext';
import {Helmet} from "react-helmet";
import { withRouter } from "react-router";
import FooterUpdator from '../FooterUpdator';
import FooterModal from '../FooterModal';
import PageHeader from '../PageHeader';
import SignUpForm from './SignUpForm';
import loading_gif from '../../assets/images/loading.gif';
require('./style.scss');

class SignUp extends Component {

  constructor (props) {
    super();
    this.state = {
      authenticating: false
    }

    //Bindings & References
    this._formSubmit = this._formSubmit.bind(this);
    this.footerModal = React.createRef();
    this._beforeUnload = this._beforeUnload.bind(this);
  }

  componentDidMount() {
    document.addEventListener('beforeunload', this._beforeUnload);
    document.addEventListener('pause', this._beforeUnload);
    if(this.context.user_logged_in) this.props.history.push('/');
  }

  _beforeUnload(e){
    //if the modal is up, the footer will be inert
    this.footerModal.current.hide();
    this.context.footerModalNotPresent();
  }

  _formSubmit(first_name, last_name, email, password, agree_to_terms, callback){
    this.setState({authenticating:true});
    this.context.registerUser(first_name, last_name, email, password, agree_to_terms).then(result => {
      this.setState({authenticating:false});
      if(result.success){
        this.footerModal.current.show();
      }else{
        callback(true, result.message);
      }
    }).catch(err => {
      this.setState({authenticating:false});
      callback(true, 'Something went wrong. Please try again.');
    })
  }

  _handleBackToLogin(e){
    e.preventDefault();
    this.footerModal.current.hide();
    this.props.history.push('/login');
  }

  render(){
    return(
      <MyContext.Consumer>
        {(context) => (
          <div id="sign-up">
            <Helmet>
              <title>Nod - Sign Up</title>
            </Helmet>
            <div className={this.state.authenticating ? 'loading show' : 'loading'}><img src={loading_gif} alt="loading" /></div>
            <PageHeader bgColor="tan-accent" bgHeaderColor="tan">
              <h1 className="adieu">Create your account.</h1>
            </PageHeader>
            <SignUpForm onSubmit={this._formSubmit} />
            <FooterModal ref={this.footerModal} visible={false} parentId="sign-up">
              <h3>Check your email on this device!</h3>
              <p>From this device, follow the link in your email to confirm and activate your account.</p>
              <div className="textLink">
                <a href="/" onClick={(e) => this._handleBackToLogin(e)}>Back to sign in</a>
              </div>
              <br /><br />
            </FooterModal>
            <FooterUpdator
              showHome={false}
              backHandler={() => {this.props.history.goBack()}} />
          </div>
        )}
      </MyContext.Consumer>
    );
  }
}

SignUp.contextType = MyContext;
export default withRouter(SignUp);
