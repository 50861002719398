import React, { Component } from 'react';
import { Link } from 'react-router-dom';
require('./style.scss');

class NotificationCard extends Component {

  componentDidMount() {

  }

  render(){
    return (
      <Link to={this.props.link} className={"notification-card " + this.props.class}>
        {this.props.viewed !== true ? <div className="new-dot"/> : null }
        <h3>{this.props.title}</h3>
        <p>{this.props.text}</p>
        {this.props.link ? <p>{this.props.date}</p> : null }
        <p>{this.props.viewed}</p>
        <p>{this.props.actionText}</p>
      </Link>
    );
  }
}

export default NotificationCard;
