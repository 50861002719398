import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import PageHeader from '../PageHeader';
import FindYourSchoolPicklist from '../FindYourSchoolPicklist';
import FooterModal from '../FooterModal';
import FooterUpdator from '../FooterUpdator';

require('./style.scss');

class FindYourSchool extends Component {

  constructor(props){
    super(props);
    this.footerModal = React.createRef();
  }

  componentDidMount() {
    if(this.context.user_logged_in) this.props.history.push('/');
  }

  render() {

    const handleCantFindSchool = () => {
      this.footerModal.current.show();
    }

    const handleCantFindSchoolLearnMore = (e) => {
      e.preventDefault();
      if(window.cordova){
        window.cordova.InAppBrowser.open('https://heynod.com/find-my-nod-campus/not-there-yet/');
      }else{
        window.open('https://heynod.com/find-my-nod-campus/not-there-yet/', '_system');
      }
    }

    return (
      <div id="find-your-school">
        <Helmet>
          <title>Nod - Select School</title>
        </Helmet>
        <PageHeader bgColor="tan-accent" bgHeaderColor="tan">
          <h1 className="adieu">Hey there!<br />Let's get started.</h1>
        </PageHeader>
        <p htmlFor="find-your-school-select-input">Search for your school to sign in or sign up.</p>
        <FindYourSchoolPicklist ssoAssertOnConfirm={true} />
        <br />
        <div>
          <button className="help" onClick={handleCantFindSchool} aria-label="Help! My school isn’t listed, or I’m not affiliated with a school."></button>
          <div aria-hidden="true" onClick={handleCantFindSchool}>Help! My school isn’t listed, or I’m not affiliated with a school.</div>
        </div>
        <FooterModal ref={this.footerModal} visible={false} parentId="find-your-school">
          <h3>Your access may have changed.</h3>
          <p>Nod is now available only at participating schools. You can still access free Nod tips, request a demo account, or bring Nod to your school through heynod.com.</p>
          <div className="textLink">
            <a className="skip" href="/" onClick={handleCantFindSchoolLearnMore}>Find out more</a><br /><br />
          </div>
        </FooterModal>
        <FooterUpdator
          showHome={false}
          backHandler={() => {this.props.history.goBack()}} />
      </div>
    );

  }
}

export default FindYourSchool;
