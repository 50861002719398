import * as PIXI from 'pixi.js';
import * as Matter from 'matter-js';

import Calc from '../../common/Calc';
import SvgSprite from '../../common/SvgSprite';

class Candy extends SvgSprite {
  constructor(
    x,
    y,
    width,
    asset,
    xAnchor = 0.5,
    yAnchor = 0.5,
    collisionCategories
  ) {
    super(x, y, width, asset, xAnchor, yAnchor);

    this.collisionCategories = collisionCategories;

    this.sprite.alpha = 0;

    this.container = new PIXI.Container();
    this.container.addChild(this.sprite);

    this.body = Matter.Bodies.rectangle(
      this.x,
      this.y,
      this.sprite.width - 2,
      this.sprite.height - 2,
      {
        restitution: 0,
        friction: 1,
        label: 'candy',
        collisionFilter: {
          category: this.collisionCategories.candy,
          mask: this.collisionCategories.wall | this.collisionCategories.candy
        }
      }
    );
  }

  activate(world, x, y) {
    Matter.Body.setPosition(this.body, { x: x, y: y });
    Matter.World.add(world, this.body);
    Matter.Body.applyForce(
      this.body,
      { x: this.body.position.x, y: this.body.position.y },
      {
        x: Calc.rand(-0.0075, 0.0075),
        y: Calc.rand(-0.015, -0.005)
      }
    );

    this.sprite.alpha = 1;
  }

  update() {
    this.container.x = this.body.position.x;
    this.container.y = this.body.position.y;
    this.container.rotation = this.body.angle;
  }
}

export default Candy;
