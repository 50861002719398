import React, { Component } from 'react';
import MyContext from '../../state/MyContext';
import {Helmet} from "react-helmet";
import { withRouter } from "react-router";
import FooterUpdator from '../FooterUpdator';
import PageHeader from '../PageHeader';
import SocialLoginButtons from '../SocialLoginButtons';
//import FindYourSchoolPicklist from '../FindYourSchoolPicklist';
import login_button_email from '../../assets/images/login-buttons/email.png';
import loading_gif from '../../assets/images/loading.gif';
require('./style.scss');

class SignInSocial extends Component {

  constructor (props) {
    super();
    this.state = {
      authenticating: false
    }
  }

  componentDidMount() {
    if(this.context.user_logged_in) this.props.history.push('/');
  }

  render(){
    return(
      <MyContext.Consumer>
        {(context) => (
          <div id="sign-in-social">
            <Helmet>
              <title>Nod - Sign Up</title>
            </Helmet>
            <div className={this.state.authenticating ? 'loading show' : 'loading'}><img src={loading_gif} alt="loading" /></div>
            <PageHeader bgColor="tan-accent" bgHeaderColor="tan">
              <h1 className="adieu">Welcome Back!</h1>
            </PageHeader>
            <SocialLoginButtons
              active={true}
              onLoading={(loading) => { this.setState({authenticating:loading}); }} />
            <button className="login-email" onClick={() => { this.props.history.push('/login'); }}><img src={login_button_email} alt="Continue with Email" /></button>
            {/*<h3>Or use your university id</h3>
            <FindYourSchoolPicklist ssoAssertOnConfirm={true} />*/}
            <FooterUpdator
              showHome={false}
              backHandler={() => {this.props.history.goBack()}} />
          </div>
        )}
      </MyContext.Consumer>
    );
  }
}

SignInSocial.contextType = MyContext;
export default withRouter(SignInSocial);
