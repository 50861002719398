import React, { Component } from 'react';
require('./style.scss');

class PasswordResetForm extends Component {

  constructor (props) {
    super(props);

    this.state = {
      password_value: '',
      password_again_value: '',
      validation_message: '',
      submit_disabled: false,
      token: this.props.token,
      token_email: this.props.tokenEmail,
    }

    //Bindings & References
    this._handlePasswordChange = this._handlePasswordChange.bind(this);
    this._handlePasswordAgainChange = this._handlePasswordAgainChange.bind(this);
    this._handleSubmit = this._handleSubmit.bind(this);
  }

  _handlePasswordChange(event) {
    this.setState({password_value: event.target.value});
  }

  _handlePasswordAgainChange(event) {
    this.setState({password_again_value: event.target.value});
  }

  _handleSubmit(e) {
    e.preventDefault();

    let valid = true;
    let valid_message = '';
    this.setState({validation_message: valid_message});

    //Check password
    if(!this._isValidPassword(this.state.password_value)){
      valid = false;
      valid_message += 'Please use a password that is at least 8 characters. ';
    }

    //Check passwords match
    if(this.state.password_value !== this.state.password_again_value){
      valid = false;
      valid_message += 'Your passwords don\'t match. ';
    }

    if(valid){
      this.setState({submit_disabled: true});
      this.props.onSubmit(this.state.token_email, this.state.password_value, this.state.token, (error, message) => {
        if(error) this.setState({validation_message: message, submit_disabled: false});
      });
    }else{
      this.setState({validation_message: valid_message, submit_disabled: false});
    }
  }

  _isValidEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape
    return re.test(email);
  }

  _isValidPassword(password) {
    return (password.length < 8) ? false : true;
  }

  render(){
    return(
      <form onSubmit={this._handleSubmit}>
        <p>{this.state.token_email}</p>
        <label htmlFor="password">New Password</label><br />
        <input id="password" type="password" value={this.state.password_value} onChange={this._handlePasswordChange} placeholder="password" /><br />
        <label htmlFor="password-again">Confirm Password</label><br />
        <input id="password-again" type="password" value={this.state.password_again_value} onChange={this._handlePasswordAgainChange} placeholder="password" /><br />
        {(this.state.validation_message !== '') ? <p className="validationText">{this.state.validation_message}</p> : null}
        <input type="submit" value="Submit" disabled={ this.state.submit_disabled ? 'disabled' : false } />
      </form>
    );
  }
}

export default PasswordResetForm;
