import React from 'react';

class BaseComponent extends React.Component {
  constructor(props) {
    super(props);
    this.canvasWrapRef = React.createRef();
  }

  componentDidMount() {
    this.reflection = new this.Reflection(
      this.canvasWrapRef.current,
      this.props
    );

    this.reflection.init(this.onComplete.bind(this));

    this.time = {
      start: Date.now(),
      end: null,
      duration: null
    };

    this.props.onStart();
  }

  onComplete() {
    this.time.end = Date.now();
    this.time.duration = this.time.end - this.time.start;
    this.props.onComplete(this.time.duration);
  }

  componentDidUpdate(prevProps) {
    if (this.props.width !== prevProps.width) {
      this.reflection.reset(this.props.width, this.props.height);
    }

    if (this.props.height !== prevProps.height) {
      this.reflection.reset(this.props.width, this.props.height);
    }
  }

  componentWillUnmount() {
    this.reflection.destroy();
  }

  render() {
    return <div ref={this.canvasWrapRef} />;
  }
}

export default BaseComponent;
