import React, { Component } from 'react';
import MyContext from '../../state/MyContext';
import { withRouter } from "react-router";
require('./style.scss');

const searchableFields = ['name', 'title', 'acronym', 'zip_code'];
class FindYourSchoolPicklist extends Component {
  constructor (props) {
    super();
    this.state = {
      searchText: '',
      filteredInstances: [],
      selectedInstanceId: 'none',
      instanceValidated: false,
      showHiddenInstances: false,
      hiddenButtonClickCount: 0,
      showWarning: false,
    };

    //Bindings & References
    this.handleSubmit = this.handleSubmit.bind(this);
    this.clearSearchText = this.clearSearchText.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInstanceClick = this.handleInstanceClick.bind(this);
    this.handleHiddenButtonClick = this.handleHiddenButtonClick.bind(this);
  }

  formatSchoolText (school) {
    const { title, acronym, city, state, zip_code: zipCode } = school;
  
    const schoolName = acronym ? `${title} (${acronym})` : title;

    const locationParts = [];
    if (city && state) {
      locationParts.push(`${city}, ${state}`);
    } else if (city) {
      locationParts.push(city);
    } else if (state) {
      locationParts.push(state)
    }

    if (zipCode) {
      locationParts.push(zipCode);
    }

    return {
      schoolName,
      schoolLocation: locationParts.join(' ')
    };
  }

  handleInputChange (event) {
    const searchText = event.target.value;
    const upperText = searchText.toUpperCase();

    let filteredInstances = this.context.instances.filter(instance => searchableFields.reduce((accumulator, field) => accumulator || (instance[field] && instance[field].toUpperCase().includes(upperText)), false));

    if (filteredInstances.length === 0) filteredInstances = [{ title: 'No schools found' }];

    this.setState({
      searchText,
      filteredInstances
    });
  }

  clearSearchText () {
    this.setState({
      searchText: '',
      filteredInstances: []
    });
  }

  handleInstanceClick (instance) {
    if (!instance.id) {
      this.setState({
        selectedInstanceId: null,
        instanceValidated:false,
        showWarning:false
      });
    } else {
      const { schoolName, schoolLocation } = this.formatSchoolText(instance);

      this.setState({
        searchText: (schoolLocation && `${schoolName}, ${schoolLocation}`) || schoolName,
        filteredInstances: [],
        selectedInstanceId: instance.id,
        instanceValidated:true,
        showWarning:false
      });
    }
  }

  handleSubmit (event){
    event.preventDefault();

    if (this.state.instanceValidated) {
      const instance = this.context.findInstanceById(parseInt(this.state.selectedInstanceId));
      this.context.welcomeSelectInstance(parseInt(this.state.selectedInstanceId)).then(() => {
        if(
          (this.state.selectedInstanceId === parseInt(process.env.REACT_APP_DEMO_SCHOOL_ID))
          ||
          (this.state.selectedInstanceId === parseInt(process.env.REACT_APP_DEMO_HIGH_SCHOOL_ID))
        ) return this.props.history.push('/demo-access');
        if(this.props.ssoAssertOnConfirm){
          if(instance.sso !== null){
            this.context.setState({sso_session:false}); //Hack relating to the branchio hack in MainWrapper.jsx
            this.context.ssoAssert(instance.sso).then((result) =>{
              switch(result.type){
                default:
                case 'history':
                  this.props.history.push(result.url);
                  break;
                case 'location':
                  window.location.replace(result.url);
                  break;
              }
            });
          } else {
            this.props.history.push('/signup');
          }
        } else {
          this.props.history.push('/welcome/get-started');
        }
      });
    } else {
      this.setState({showWarning:true});
    }
  }

  handleHiddenButtonClick(e){
    e.preventDefault();
    if (this.state.hiddenButtonClickCount < 1) {
      this.setState({hiddenButtonClickCount: this.state.hiddenButtonClickCount+1});
    } else {
      this.setState({showHiddenInstances: true});
    }
  }

  renderSchools(schools) {
    if (this.state.searchText.length < 2) return;

    return schools.map((instance, index) => {
      const { schoolName, schoolLocation } = this.formatSchoolText(instance);

      const school = (
        <li key={index} value={instance.id} tabIndex={0} onClick={() => this.handleInstanceClick(instance)}>
          {schoolName && <span>{schoolName}</span>}
          {schoolLocation && <span>{schoolLocation}</span>}
        </li>
      );

      if (this.state.showHiddenInstances) {
        return school;
      } else {
        //if(!instance.hidden || instance.id === parseInt(process.env.REACT_APP_DEMO_SCHOOL_ID)){
        return instance.hidden ? null : school;
      }
    })
  }

  render(){
    document.querySelector('html').classList.remove('noscroll'); //Undo the welcome override
    
    const { searchText, filteredInstances, showWarning, instanceValidated } = this.state;
    
    return(
      <MyContext.Consumer>
        {(context) => (
          <div id="find-your-school-picklist">
            <form onSubmit={this.handleSubmit}>
              <a aria-hidden="true" tabIndex="-1" href="/" className="hidden-button" onClick={this.handleHiddenButtonClick}>&nbsp;</a>
              <div className="dropdown-wrap">
                <input
                  value={searchText}
                  onChange={this.handleInputChange}
                  id="find-your-school-select-input"
                  placeholder={"School or Zip Code"}
                  className={(searchText.length >= 2 && filteredInstances.length > 0) ? 'list-active' : undefined}
                />
                <a className={`find-your-school-clear ${searchText.length === 0 && 'hidden'}`} onClick={this.clearSearchText} />
                <ul id="find-your-school-list">
                  {this.renderSchools(filteredInstances)}
                </ul>
              </div>
              {showWarning && <div role="alert" className="validationText">Please choose a school above.</div>}
              <input type="submit" className="button" value="Continue" disabled={!instanceValidated} />
            </form>
          </div>
        )}
      </MyContext.Consumer>
    );
  }
}

FindYourSchoolPicklist.contextType = MyContext;
export default withRouter(FindYourSchoolPicklist);
