import { Component } from 'react';
import MyContext from '../../state/MyContext';
require('./style.scss');

class SignOut extends Component {

  constructor (props) {
    super();
  }

  componentDidMount () {
    //setTimeout( () => {
      this.context.signOutUser().then(() => {
        //this.props.history.push('/');
      });
    //},250);
  }

  render(){
    return null;
  }
}

SignOut.contextType = MyContext;
export default SignOut;
