import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { NavLink, Link } from 'react-router-dom';
import MyContext from '../../state/MyContext';
require('./style.scss');

class Footer extends Component {

  constructor(props) {
    super(props);
    this.state = {

    }
  }

  _getFooterNormal() {
    let classes = 'footer_inner footer_inner-normal';
    let tabIndex = '-1';
    let ariaHidden = true;
    if(this.context.footer_type === 'normal'){
      classes += ' visible';
      tabIndex = '0';
      ariaHidden = false;
    }
    return(
      <ul className={classes}>
        <li><NavLink aria-hidden={ariaHidden} tabIndex={tabIndex} role="tab" exact to='/'>Home</NavLink></li>
        <li><NavLink aria-hidden={ariaHidden} tabIndex={tabIndex} role="tab" to='/guides'>Ideas</NavLink></li>
        <li><NavLink aria-hidden={ariaHidden} tabIndex={tabIndex} role="tab" to='/reflect'>Reflect</NavLink></li>
        <li><NavLink aria-hidden={ariaHidden} tabIndex={tabIndex} role="tab" to='/myself'>{this.context.new_notifications > 0 ? <span className="number">{this.context.new_notifications}</span> : null}My Nod</NavLink></li>
      </ul>
    );
  }

  _getFooterSubNav() {
    const homeHandler = () => {
      document.querySelector('html').classList.remove('locked');
      if(this.context.footer_subnav_home_handler){
        this.context.footer_subnav_home_handler();
      }
      setTimeout(() => this.props.history.push('/'), 100);
    }

    let classes = 'footer_inner footer_inner-subnav';
    let classval = '';
    let tabIndex = '-1';
    let ariaHidden = true;
    if(this.context.footer_type === 'subnav'){
      classes += ' visible';
      tabIndex = '0';
      ariaHidden = false;
    }
    if(this.context.footer_subnav_next_text) {
      classval = this.context.footer_subnav_next_text.replace(/\s+/g, '-').toLowerCase();
    }
    return(
      <div className={classes}>
        <div className="footer_inner-subnav_left">
          {this.context.footer_subnav_show_home ? <button aria-hidden={ariaHidden} tabIndex={tabIndex} aria-label="home" className="home" onClick={homeHandler}></button> : null }
          {this.context.footer_subnav_back_handler !== null ? <button aria-hidden={ariaHidden} tabIndex={tabIndex} aria-label="go back" className="prev" onClick={this.context.footer_subnav_back_handler} /> : null}
        </div>
        <div className="footer_inner-subnav_right">
          {this.context.footer_subnav_show_signin ? <Link aria-hidden={ariaHidden} tabIndex={tabIndex} className="login" to="/login">Sign-In</Link> : null }
          {this.context.footer_subnav_skip_handler !== null ? <button aria-hidden={ariaHidden} tabIndex={tabIndex} className="skip" onClick={this.context.footer_subnav_skip_handler}>{this.context.footer_subnav_skip_text}</button> : null}
          {this.context.footer_subnav_next_handler !== null && this.context.footer_subnav_next_text !== '' ? <button aria-hidden={ariaHidden} tabIndex={tabIndex} className={ classval + " next button"} onClick={this.context.footer_subnav_next_handler}>{this.context.footer_subnav_next_text}</button> : null}
        </div>
      </div>
    );
  }

  render(){
    if(this.context.footer_show){
      let classes = '';
      if(this.context.footer_modal_present) classes = 'hide';
      return(
        <MyContext.Consumer>
          {(context) => (
            <footer id="footer" className={classes}>
              {this._getFooterNormal()}
              {this._getFooterSubNav()}
            </footer>
          )}
        </MyContext.Consumer>
      );
    }else{
      return null;
    }
  }
}

Footer.contextType = MyContext;
export default withRouter(Footer);
