import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import KeenTracking from 'keen-tracking';
import mixpanel from 'mixpanel-browser';

let keen_client = new KeenTracking({
  projectId: process.env.REACT_APP_KEEN_PROJECT_ID,
  writeKey: process.env.REACT_APP_KEEN_WRITE_KEY
});

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);

const startApp = () => {
  ReactDOM.render(<App />, document.getElementById('root'));
  if(window.cordova) window.screen.orientation.lock('portrait');
  //window.facebookConnectPlugin.activateApp();
  //window.facebookConnectPlugin.logEvent('Test');
  handleBranch();
  serviceWorker.unregister();
};

const handleBranch = () => {

  // return if branch.io not available
  if(!window.Branch) {return}

  window.localStorage['branchData'] = "{}";

  // for development and debugging only
  window.Branch.setDebug(false);

  // for GDPR compliance (can be called at anytime)
  window.Branch.disableTracking(false);

  // Branch initialization
  //setTimeout(() => { //this was a hack to fix a branchio bug
    window.Branch.initSession().then(data => {
      if (data['+clicked_branch_link']) {
        console.log('Branchio initSession', data);

        keen_client.recordEvent('branchio_init_session', data).then((response) => {
          console.log('branchio_init_session response',  response);
        }).catch(error => {
          if(this.state.debug) console.log('KeenIO Error:',  'branchio_init_session', error);
        });

        mixpanel.track('branchio_init_session', data)

        // read deep link data on click
        // save to model to be used later
        window.localStorage['branchData'] = JSON.stringify(data)
        // Redirect if given redirectPath
        window.dispatchEvent(new CustomEvent("branchio_redirects"));
      }
    }).catch((err) => {
        console.log('Branchio initSession Error', err);

        keen_client.recordEvent('branchio_init_session_error', {err: JSON.stringify(err)}).then((response) => {
          console.log('branchio_init_session_error response',  response);
        }).catch(error => {
          if(this.state.debug) console.log('KeenIO Error:',  'branchio_init_session_error', error);
        });

        mixpanel.track('branchio_init_session_error', {err: JSON.stringify(err)})
    });
  //}, 1000);

}

const resumeApp  = () => {
  handleBranch();
}

if (!window.cordova) {
  // This is for development, when Cordova isn't available
  startApp();
} else {
  // This is inside the app, where Cordova is available
  document.addEventListener("deviceready", startApp, false);
  document.addEventListener("resume", resumeApp, false);
}

//ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
