import * as PIXI from 'pixi.js';
import { Howl } from 'howler';
import { TweenMax, Back } from 'gsap';

import BaseReflection from '../../common/BaseReflection';
import Calc from '../../common/Calc';
import RectSprite from '../../common/RectSprite';
import SvgSprite from '../../common/SvgSprite';

import heart0Src from '../images/heart-0.png';
import heart1Src from '../images/heart-1.png';
import heart2Src from '../images/heart-2.png';
import heartMask0Src from '../images/heart-mask-0.png';
import heartMask1Src from '../images/heart-mask-1.png';
import heartMask2Src from '../images/heart-mask-2.png';

import completeWebmSrc from '../sounds/complete.webm';
import completeOggSrc from '../sounds/complete.ogg';
import completeMp3Src from '../sounds/complete.mp3';
import heartbeatWebmSrc from '../sounds/heartbeat.webm';
import heartbeatOggSrc from '../sounds/heartbeat.ogg';
import heartbeatMp3Src from '../sounds/heartbeat.mp3';

class HeartFillingReflection extends BaseReflection {
  init(onComplete) {
    super.init(onComplete);

    this.heartShapeAssets = [heart0Src, heart1Src, heart2Src];

    this.heartMaskAssets = [heartMask0Src, heartMask1Src, heartMask2Src];

    this.assetPaths = [
      heart0Src,
      heart1Src,
      heart2Src,
      heartMask0Src,
      heartMask1Src,
      heartMask2Src,
      completeWebmSrc,
      completeOggSrc,
      completeMp3Src,
      heartbeatWebmSrc,
      heartbeatOggSrc,
      heartbeatMp3Src
    ];

    this.heartWraps = [];
    this.heartFills = [];

    this.heartWidth = 300;
    this.heartHeight = 300;

    this.heartAnimFrameCurrent = 0;
    this.heartAnimFrameTotal = 3;
    this.heartAnimTick = 0;
    this.heartAnimTickTotal = 10;

    this.heartTapCurrent = 0;
    this.heartTapTotal = 5;

    this.heartCanInteract = true;
    this.heartInteractionBreak = 0;

    this.heartFillOffset = 0;

    this.progressObj = { value: 0 };
    this.completeTimeout = null;

    this.addEventListeners();
    this.loadAssets();
  }

  reset(width = this.width, height = this.height) {
    super.reset(width, height);

    window.clearTimeout(this.completeTimeout);

    this.heartTapCurrent = 0;
    this.progressObj.value = 0;
    this.heartWraps.length = 0;
    this.heartFills.length = 0;
    this.heartContainer = null;

    this.create();
  }

  addEventListeners() {
    super.addEventListeners();

    this.onPointerDown = this.onPointerDown.bind(this);
    this.canvasWrap.addEventListener('mousedown', this.onPointerDown);
    this.canvasWrap.addEventListener('touchstart', this.onPointerDown);
  }

  removeEventListeners() {
    super.removeEventListeners();

    this.canvasWrap.removeEventListener('mousedown', this.onPointerDown);
    this.canvasWrap.removeEventListener('touchstart', this.onPointerDown);
  }

  onPointerDown(e) {
    if (this.heartCanInteract) {
      this.heartCanInteract = false;
      setTimeout(() => {
        this.heartCanInteract = true;
      }, this.heartInteractionBreak);
    } else {
      return false;
    }
    if (this.heartTapCurrent < this.heartTapTotal) {
      this.sounds.heartbeat[this.heartTapCurrent].play();
      this.heartTapCurrent += 1;
      TweenMax.to(this.progressObj, 0.2, {
        value: this.heartTapCurrent / this.heartTapTotal,
        ease: Back.easeOut.config(3)
      });
      if (this.heartTapCurrent === this.heartTapTotal) {
        this.sounds.complete.play();
        this.completeTimeout = window.setTimeout(() => {
          this.onComplete();
        }, 3000);
      }
    }

    this.progressTarget = this.heartTapCurrent / this.heartTapTotal;
  }

  initPhysics() {
    super.initPhysics();
  }

  initSounds() {
    super.initSounds();

    this.sounds.complete = new Howl({
      src: [completeWebmSrc, completeOggSrc, completeMp3Src],
      volume: 1
    });

    this.sounds.heartbeat = [];
    for (let i = 0; i < this.heartTapTotal; i++) {
      this.sounds.heartbeat.push(
        new Howl({
          src: [heartbeatWebmSrc, heartbeatOggSrc, heartbeatMp3Src],
          volume: 1,
          rate: Calc.map(i, 0, this.heartTapTotal - 1, 1.25, 2.25)
        })
      );
    }
  }

  create() {
    super.create();

    this.heartContainer = new PIXI.Container();
    this.heartContainer.x = this.width / 2;
    this.heartContainer.y = this.height / 2;
    this.pixiApp.stage.addChild(this.heartContainer);

    for (let i = 0; i < 3; i++) {
      let wrap = new PIXI.Container();
      wrap.alpha = 0;
      this.heartWraps.push(wrap);
      this.heartContainer.addChild(wrap);

      let shape = new SvgSprite(
        0,
        0,
        this.heartWidth,
        this.assets[this.heartShapeAssets[i]]
      );
      wrap.addChild(shape.sprite);

      let mask = new SvgSprite(
        0,
        0,
        this.heartWidth,
        this.assets[this.heartMaskAssets[i]]
      );
      wrap.addChild(mask.sprite);

      let fill = new RectSprite(
        0,
        this.heartHeight / 2,
        this.heartWidth,
        this.heartHeight,
        'hsl(0, 90%, 60%)',
        0.5,
        1
      );
      fill.sprite.mask = mask.sprite;
      wrap.addChild(fill.sprite);
      this.heartFills.push(fill);
    }
  }

  onTick() {
    super.onTick();

    if (this.heartAnimTick < this.heartAnimTickTotal - 1) {
      this.heartAnimTick++;
    } else {
      this.heartAnimTick = 0;
      this.heartAnimFrameLast = this.heartAnimFrameCurrent;
      if (this.heartAnimFrameCurrent < this.heartAnimFrameTotal - 1) {
        this.heartAnimFrameCurrent++;
      } else {
        this.heartAnimFrameCurrent = 0;
      }
      this.heartFillOffset = Calc.rand(-5, 5);

      this.heartWraps[this.heartAnimFrameLast].alpha = 0;
      this.heartWraps[this.heartAnimFrameCurrent].alpha = 1;
    }

    this.heartContainer.scale.set(
      Calc.map(this.progressObj.value, 0, 1, 0.5, 1)
    );

    for (let i = 0, len = this.heartFills.length; i < len; i++) {
      let heartFill = this.heartFills[i];
      heartFill.sprite.height = Calc.clamp(
        this.heartHeight * this.progressObj.value + this.heartFillOffset,
        0,
        this.heartHeight
      );
    }
  }

  destroy() {
    super.destroy();

    window.clearTimeout(this.completeTimeout);

    this.heartShapeAssets.length = 0;
    this.heartMaskAssets.length = 0;

    this.heartWraps.length = 0;
    this.heartWraps = null;
    this.heartFills.length = 0;
    this.heartFills = null;
    this.heartContainer = null;
  }
}

export default HeartFillingReflection;
