import React, { Component } from 'react';
import {Helmet} from "react-helmet";

import FooterUpdator from '../FooterUpdator';
import FooterModal from '../FooterModal';
import PageHeader from '../PageHeader';
//import LonelinessMeter from '../LonelinessMeter';
import MotivationSetter from '../MotivationSetter';
import GradeOptions from './GradeOptions';
import GenderOptions from './GenderOptions';

import MyContext from '../../state/MyContext';

import wild_thing_mole_img from '../../assets/images/illustrations/wild-thing-mole.png';
import pizza_and_pineapple from '../../assets/images/illustrations/pizza-and-pineapple.png';
import flamingos from '../../assets/images/illustrations/flamingos.png';
//import page_home from  '../../assets/images/illustrations/page-home.png';
//import page_reflection from  '../../assets/images/illustrations/page-reflection.png';
//import page_interactions from  '../../assets/images/illustrations/page-interactions.png';

import onboarding_challenge from '../../assets/images/illustrations/onboarding-challenge.png';
import onboarding_tips from '../../assets/images/illustrations/onboarding-tips.png';
import onboarding_moodmeter from '../../assets/images/illustrations/onboarding-moodmeter.png';

import 'rc-slider/assets/index.css';

require('./style.scss');

class Onboarding extends Component {
  constructor(props) {
    super(props);

    this.state = {

      //Slide - Some more info
      // more_info_age_value: 'none',
      // more_info_grade_level_value: 'none',
      // more_info_gender_value: 'none',
      // more_info_ethnicity_value: 'none',

      //Slide - Tune In
      loneliness_value: null,

      //Slide - Whats important
      //motivations: null,

      animated_gifs: true,
      touch_gestures: true,
      subtitles: true,

    }

    this.footerModal = React.createRef();
  }

  componentDidMount() {

      //Send to homepage if not logged in
      if(!this.context.user_logged_in) this.props.history.push('/');

      let animated_gifs = window.localStorage.getItem('@nod:animated_gifs');
      if(animated_gifs === 'false') animated_gifs = false;
      if(animated_gifs === null){ animated_gifs = true; };

      let touch_gestures = window.localStorage.getItem('@nod:touch_gestures');
      if(touch_gestures === 'false') touch_gestures = false;
      if(touch_gestures === null){ touch_gestures = true; };

      let subtitles = window.localStorage.getItem('@nod:subtitles');
      if(subtitles === 'false') subtitles = false;
      if(subtitles === null){ subtitles = true; };

      let init_state = {};
      init_state.animated_gifs = animated_gifs;
      init_state.touch_gestures = touch_gestures;
      init_state.subtitles = subtitles;
      this.setState(init_state);
  }

  //Render//////////////////////////////////////////////////////////////////////
  _getOnboardSlide() {

    const handleSubmit = () => {
      console.log('Continue')
      this.props.history.push('/onboarding/ideas')
    }

    return(
      <div className="slide-inner" >
        <Helmet>
          <title>Nod - Onboard</title>
        </Helmet>
        <PageHeader bgColor="gradient" bgHeaderColor="tan">
          <h1>Here’s how Nod works...</h1>
          <p>A little effort goes a long way to help you feel more connected.</p>
        </PageHeader>
        <img width="250px" src={pizza_and_pineapple} alt=""/>
        <FooterUpdator
          showHome={false}
          nextText="Continue"
          nextHandler={handleSubmit} />
      </div>
    );
  }

  _getIdeasSlide() {

    const handleSkip = () => {
      this.props.history.push('/onboarding/whats-important');

      //KeenIO
      this.context.sendKeenEvent('onboarding', {
        skip: true,
        screen: 'ideas'
      });

      //MixPanel
      this.context.sendMixPanelEvent('onboarding', {
        skip: true,
        screen: 'ideas'
      });
    }

    const handleSubmit = () => {
      this.props.history.push('/onboarding/grounded');

      //KeenIO
      this.context.sendKeenEvent('onboarding', {
        skip: false,
        screen: 'ideas'
      });

      //MixPanel
      this.context.sendMixPanelEvent('onboarding', {
        skip: false,
        screen: 'ideas'
      });
    }

    return(
      <div className="slide-inner">
        <Helmet>
          <title>Nod - Onboard Ideas</title>
        </Helmet>
        <PageHeader bgColor="gradient" bgHeaderColor="tan">
          <h1>We’ll give you new ideas based on your goals</h1>
          <p>Read tips and student perspectives, then save the ones you want to try.</p>
        </PageHeader>
        <img width="275px" src={onboarding_challenge} alt=""/>
        <FooterUpdator
          showHome={false}
          backHandler={() => this.props.history.goBack() }
          skipHandler={handleSkip}
          nextText="Next"
          nextHandler={handleSubmit} />
      </div>
    );
  }

  _getGroundedSlide() {

    const handleSkip = () => {
      this.props.history.push('/onboarding/whats-important');

      //KeenIO
      this.context.sendKeenEvent('onboarding', {
        skip: true,
        screen: 'grounded'
      });

      //MixPanel
      this.context.sendMixPanelEvent('onboarding', {
        skip: true,
        screen: 'grounded'
      });
    }

    const handleSubmit = () => {
      this.props.history.push('/onboarding/reflect');

      //KeenIO
      this.context.sendKeenEvent('onboarding', {
        skip: false,
        screen: 'grounded'
      });

      //MixPanel
      this.context.sendMixPanelEvent('onboarding', {
        skip: false,
        screen: 'grounded'
      });
    }

    return(
      <div className="slide-inner">
        <Helmet>
          <title>Nod - Onboard Grounded</title>
        </Helmet>
        <PageHeader bgColor="gradient" bgHeaderColor="tan">
          <h1>Then give it a go! Try an idea out IRL or virtually...</h1>
          <p>We’ll make it easier with reminders that help you work ideas into your life.</p>
        </PageHeader>
        <img width="300px" src={onboarding_tips} alt=""/>
        <FooterUpdator
          showHome={false}
          backHandler={() => this.props.history.goBack() }
          skipHandler={handleSkip}
          nextText="Next"
          nextHandler={handleSubmit} />
      </div>
    );
  }

  _getReflectSlide() {

    const handleSkip = () => {
      this.props.history.push('/onboarding/whats-important');

      //KeenIO
      this.context.sendKeenEvent('onboarding', {
        skip: true,
        screen: 'reflect'
      });

      //MixPanel
      this.context.sendMixPanelEvent('onboarding', {
        skip: true,
        screen: 'reflect'
      });
    }

    const handleSubmit = () => {
      this.props.history.push('/onboarding/whats-important');

      //KeenIO
      this.context.sendKeenEvent('onboarding', {
        skip: false,
        screen: 'reflect'
      });

      //MixPanel
      this.context.sendMixPanelEvent('onboarding', {
        skip: false,
        screen: 'reflect'
      });
    }

    return(
      <div className="slide-inner">
        <Helmet>
          <title>Nod - Onboard Reflect</title>
        </Helmet>
        <PageHeader bgColor="gradient" bgHeaderColor="tan">
          <h1>Then come back and reflect on how you feel.</h1>
          <p>Rate your mood, choose a way you want to reflect and we’ll offer short guided exercises.</p>
        </PageHeader>
        <img width="300px" src={onboarding_moodmeter} alt=""/>
        <FooterUpdator
          showHome={false}
          backHandler={() => this.props.history.goBack() }
          skipHandler={handleSkip}
          nextText="Next"
          nextHandler={handleSubmit} />
      </div>
    );
  }

  _getWhatsImportantSlide() {

    const onMotivationInit = (list) => {
      //this.setState({motivations:list});
      this.context.setState({onboarding_motivations:list});
    }

    const onSortEnd = (list) => {
      //this.setState({motivations:list});
      this.context.setState({
        onboarding_motivations:list,
        onboarding_motivations_changed: true
      });
    }

    const handleSubmit = () => {
      this.context.updateMotivations(this.context.onboarding_motivations).then(() => { //
        this.props.history.push('/onboarding/allow-notifications');

        let motivations_id_list = '';
        this.context.onboarding_motivations.forEach((motivation, index)=>{
          motivations_id_list += motivation.id;
          if(index < this.context.onboarding_motivations.length-1) motivations_id_list += ',';
        });

        //KeenIO
        this.context.sendKeenEvent('motivations', {
          skip: false,
          motivation_order: motivations_id_list,
          motivation_changed: this.context.onboarding_motivations_changed,
          onboarding: true,
          path: '/onboarding/whats-important'
        });

        //MixPanel
        this.context.sendMixPanelEvent('motivations', {
          skip: false,
          motivation_order: motivations_id_list,
          motivation_changed: this.context.onboarding_motivations_changed,
          onboarding: true,
          path: '/onboarding/whats-important'
        });
      });
    }

    const handleSkip = () => {
      this.props.history.push('/onboarding/allow-notifications');

      let motivations_id_list = '';
      this.context.onboarding_motivations.forEach((motivation, index)=>{
        motivations_id_list += motivation.id;
        if(index < this.context.onboarding_motivations.length-1) motivations_id_list += ',';
      });

      //KeenIO
      this.context.sendKeenEvent('motivations', {
        skip: true,
        motivation_order: motivations_id_list,
        motivation_changed: this.context.onboarding_motivations_changed,
        onboarding: true,
        path: '/onboarding/whats-important'
      });

      //MixPanel
      this.context.sendMixPanelEvent('motivations', {
        skip: true,
        motivation_order: motivations_id_list,
        motivation_changed: this.context.onboarding_motivations_changed,
        onboarding: true,
        path: '/onboarding/whats-important'
      });
    }

    return(
      <div className="slide-inner">
        <Helmet>
          <title>Nod - What's Important</title>
        </Helmet>
        <PageHeader bgColor="tan" bgHeaderColor="tan"></PageHeader>
        <h4>Ok, let's personalize your experience</h4>
        <h1>Rank your goals to customize the ideas you see.</h1>
        <MotivationSetter randomize={ this.context.onboarding_motivations === null } onInit={onMotivationInit} onSortEnd={onSortEnd} />
        <FooterUpdator
          showHome={false}
          backHandler={() => this.props.history.goBack() }
          skipHandler={handleSkip}
          nextText="Next"
          nextHandler={handleSubmit} />
      </div>
    );
  }

  _getAllowNotificationsSlide(){

    const handleSubmit = () => {
      this.context.areOsNotificationsEnabled().then(granted => {
        if(granted){
          this.context.setNotificationSetting('notifications', true);

          //KeenIO Event
          this.context.sendKeenEvent('notification', {
            page_title: 'onboarding_allow_notifications',
            skipped: false,
            os_declined: false,
            snoozed: false,
            reminder_selection: null,
            notification_title: null,
            marked_complete: false,
            push_notification: false
          });

          //MixPanel Event
          this.context.sendMixPanelEvent('notification', {
            page_title: 'onboarding_allow_notifications',
            skipped: false,
            os_declined: false,
            snoozed: false,
            reminder_selection: null,
            notification_title: null,
            marked_complete: false,
            push_notification: false
          });

          //Set 5 Day Checkback Notification
          const debug = (process.env.REACT_APP_DEBUG === 'true') ? true : false;
          this.context.createNotification(
            debug ? 5000 : (5 * 24 * 60 * 60 * 1000), //trigger
            'We want to make Nod better for you!', //title
            'Take a moment to answer three quick questions.', //text
            'suggested_notification', //category
            'Give feedback', //actionText
            '/myself/mystatus', //redirectRoute
          );

          this.props.history.push('/onboarding/some-more-info');

        }else{
          this.context.requestEnableOsNotifications().then(granted => {
            if(granted){
              this.context.setNotificationSetting('notifications', true);

              //KeenIO Event
              this.context.sendKeenEvent('notification', {
                page_title: 'onboarding_allow_notifications',
                skipped: false,
                os_declined: false,
                snoozed: false,
                reminder_selection: null,
                notification_title: null,
                marked_complete: false,
                push_notification: false
              });

              //MixPanel Event
              this.context.sendMixPanelEvent('notification', {
                page_title: 'onboarding_allow_notifications',
                skipped: false,
                os_declined: false,
                snoozed: false,
                reminder_selection: null,
                notification_title: null,
                marked_complete: false,
                push_notification: false
              });

              //Set 5 Day Checkback Notification
              this.context.createNotification(
                5000, //(5 * 24 * 60 * 60 * 1000), //trigger
                'We want to make Nod better for you!', //title
                'Take a moment to answer three quick questions.', //text
                'suggested_notification', //category
                'Give feedback', //actionText
                '/myself/mystatus', //redirectRoute
              );

              this.props.history.push('/onboarding/some-more-info');

            }else{
              this.footerModal.current.show();

              //KeenIO Event
              this.context.sendKeenEvent('notification', {
                page_title: 'onboarding_allow_notifications',
                skipped: false,
                os_declined: true,
                snoozed: false,
                reminder_selection: null,
                notification_title: null,
                marked_complete: false,
                push_notification: false
              });

              //MixPanel Event
              this.context.sendMixPanelEvent('notification', {
                page_title: 'onboarding_allow_notifications',
                skipped: false,
                os_declined: true,
                snoozed: false,
                reminder_selection: null,
                notification_title: null,
                marked_complete: false,
                push_notification: false
              });
            }
          });
        }
      });
    }

    const handleSkip = () => {
      this.context.setNotificationSetting('notificationAskSkips', 1);

      //KeenIO Event
      this.context.sendKeenEvent('notification', {
        page_title: 'onboarding_allow_notifications',
        skipped: true,
        os_declined: false,
        snoozed: false,
        reminder_selection: null,
        notification_title: null,
        marked_complete: false,
        push_notification: false
      });

      //MixPanel Event
      this.context.sendMixPanelEvent('notification', {
        page_title: 'onboarding_allow_notifications',
        skipped: true,
        os_declined: false,
        snoozed: false,
        reminder_selection: null,
        notification_title: null,
        marked_complete: false,
        push_notification: false
      });

      this.props.history.push('/onboarding/some-more-info');
    }

    return(
      <div className="slide-inner">
        <Helmet>
          <title>Nod - Allow Notifications</title>
        </Helmet>
        <PageHeader bgColor="tan-accent" bgHeaderColor="tan">
          <h1>Can we check in along the way?</h1>
        </PageHeader>
        <img className="notification-image" src={wild_thing_mole_img} alt=""/>
        <div className="subhead">What kinda check-ins?</div>
        <p className="checkmark-paragraph">Tips for working towards your goal</p>
        <p className="checkmark-paragraph">Reminders to try new ideas</p>
        <FooterModal ref={this.footerModal} visible={false} parentId="onboarding">
          <h3>First, allow Nod to send reminders</h3>
          <p>Open Settings on your phone to allow Nod to send you notifications.</p>
          <br /><br />
        </FooterModal>
        <FooterUpdator
          showHome={false}
          backHandler={() => this.props.history.goBack() }
          skipHandler={handleSkip}
          nextText="Allow Notifications"
          nextHandler={handleSubmit} />
      </div>
    );
  }

  _getSomeMoreInfoSlide() {

    // const handleAgeChange = (event) => {
    //   this.context.setState({
    //     more_info_age: event.target.value,
    //     onboarding_more_info_changed: true
    //   });
    // }

    const handleYearChange = (event) => {
      this.context.setState({
        more_info_grade_level: event.target.value,
        onboarding_more_info_changed: true
      });
    }

    const handleGenderChange = (event) => {
      this.context.setState({
        more_info_gender: event.target.value,
        onboarding_more_info_changed: true
      });
    }

    const handleSelfDescribeChange = (event) => {
      this.context.setState({
        more_info_ethnicity: event.target.value,
        onboarding_more_info_changed: true
      });
    }

    const handleSubmit = () => {
      this.context.welcomeSetMoreInfo(
        //No longer need to pass properties since it's tied directly to context state

        // this.state.more_info_age_value,
        // this.state.more_info_grade_level_value,
        // this.state.more_info_gender_value,
        // this.state.more_info_ethnicity_value

        // this.context.more_info_age,
        // this.context.more_info_grade_level,
        // this.context.more_info_gender,
        // this.context.more_info_ethnicity
      ).then(() =>{

        //KeenIO
        this.context.sendKeenEvent('demographics', {
          skip: false,
          onboarding: true
        });

        //MixPanel
        this.context.sendMixPanelEvent('demographics', {
          skip: false,
          onboarding: true
        });

        this.props.history.push('/onboarding/accessibility');
      });
    }

    const handleSkipSubmit = () => {
      this.context.welcomeSetMoreInfo(null, null, null, null).then(() => {
        this.props.history.push('/onboarding/accessibility');

        //KeenIO
        this.context.sendKeenEvent('demographics', {
          skip: true,
          onboarding: true
        });

        //MixPanel
        this.context.sendMixPanelEvent('demographics', {
          skip: true,
          onboarding: true
        });
      });
    }

    return(
      <div className="slide-inner">
        <Helmet>
          <title>Nod - More Info</title>
        </Helmet>
        <PageHeader bgColor="tan-accent" bgHeaderColor="tan">
          <h4>Share some info to make Nod better</h4>
          <h1>Tell us about yourself.</h1>
        </PageHeader>
        <label htmlFor="more-info-grade-level-select">{`Year in ${this.context.user.app_type.id === 1 ? 'College' : 'High School'}`}</label>
        <div className="dropdownWrap">
          <select id="more-info-grade-level-select" value={this.context.more_info_grade_level} onChange={handleYearChange}>
            <option value='none'>Select</option>
            <GradeOptions typeId={this.context.user.app_type.id} />
          </select>
        </div>
        {/*<label>age</label>
        <div className="dropdownWrap">
          <select value={this.context.more_info_age} onChange={handleAgeChange}>
            <option value='none'>- Age -</option>
            <option value='13-17'>13-17</option>
            <option value='18-24'>18-24</option>
            <option value='25-34'>25-34</option>
            <option value='35-44'>35-44</option>
            <option value='45-54'>45-54</option>
            <option value='55-64'>55-64</option>
            <option value='65 and over'>65 and over</option>
          </select>
        </div>*/}
        <label htmlFor="more-info-gender-select">Gender</label>
        <div className="dropdownWrap">
          <select id="more-info-gender-select" value={this.context.more_info_gender} onChange={handleGenderChange}>
            <option value='none'>Select</option>
            <GenderOptions />
          </select>
        </div>
        <label htmlFor="more-info-ethnicity-select">How do you describe yourself?</label>
        <div className="dropdownWrap">
          <select id="more-info-ethnicity-select" value={this.context.more_info_ethnicity} onChange={handleSelfDescribeChange}>
            <option value='none'>Select</option>
            <option value='American Indian or Alaska Native'>American Indian or Alaska Native</option>
            <option value='Asian'>Asian</option>
            <option value='Black or African American'>Black</option>
            <option value='Hispanic or Latino/a/x'>Hispanic or Latino/a/x</option>
            <option value='Middle Eastern or North African'>Middle Eastern or North African</option>
            <option value='Native Hawaiian or Other Pacific Islander'>Native Hawaiian or Other Pacific Islander</option>
            <option value='White'>White</option>
            <option value='Multiracial'>Multiracial</option>
            <option value='Other race, ethnicity or origin'>Other race ethnicity, or origin</option>
            <option value="Don't Know">Don't Know</option>
            <option value='I prefer not to respond'>I prefer not to respond</option>
          </select>
        </div>
        <FooterUpdator
          showHome={false}
          backHandler={() => this.props.history.goBack() }
          skipHandler={handleSkipSubmit}
          nextText="Next"
          nextHandler={handleSubmit} />
      </div>
    );
  }

  _getAccessibilitySlide(){

    const handleSkip = () => {
      this.props.history.push('/onboarding/tailor-a');
    }

    const handleSubmit = () => {
      if(this.state.animated_gifs){ window.localStorage.removeItem('@nod:animated_gifs'); }else{ window.localStorage.setItem('@nod:animated_gifs', false); }
      if(this.state.touch_gestures){ window.localStorage.removeItem('@nod:touch_gestures'); }else{ window.localStorage.setItem('@nod:touch_gestures', false); }
      if(this.state.subtitles){ window.localStorage.removeItem('@nod:subtitles'); }else{ window.localStorage.setItem('@nod:subtitles', false); }
      this.props.history.push('/onboarding/tailor-a');
    }

    const updateSelect = (name, event) => {
      const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
      this.setState({[name]:value});
    }

    return(
      <div className="slide-inner">
        <Helmet>
          <title>Nod - Accessibility</title>
        </Helmet>
        <PageHeader bgColor="tan-accent" bgHeaderColor="tan">
          <h4>Customize your experience</h4>
          <h1>Let’s make sure this app is accessible for you</h1>
        </PageHeader>
        <img width="250px" alt="" src={flamingos} />
        <div id="settings">
          <div className="settingWrap">
            <div className="checkbox">
              <div className="label" aria-hidden="true">Allow GIF Animations</div>
              <label htmlFor="animated-gifs">
                <input aria-label="Allow GIF Animations" type="checkbox" id="animated-gifs" checked={this.state.animated_gifs} onChange={(e) => updateSelect('animated_gifs', e)} />
                <div className="custom-checkbox" />
              </label>
            </div>
          </div>
          <div className="settingWrap">
            <div className="checkbox">
              <div className="label" aria-hidden="true">Allow touch gestures</div>
              <label htmlFor="touch-gestures">
                <input aria-label="Allow touch gestures" type="checkbox" id="touch-gestures" checked={this.state.touch_gestures} onChange={(e) => updateSelect('touch_gestures', e)} />
                <div className="custom-checkbox" />
              </label>
            </div>
          </div>
          <div className="settingWrap">
            <div className="checkbox">
              <div className="label" aria-hidden="true">Turn on subtitles for audio or video when available</div>
              <label htmlFor="subtitles">
                <input aria-label="Turn on subtitles for audio or video when available" type="checkbox" id="subtitles" checked={this.state.subtitles} onChange={(e) => updateSelect('subtitles', e)} />
                <div className="custom-checkbox" />
              </label>
            </div>
          </div>
        </div>
        <FooterUpdator
          showHome={false}
          backHandler={() => this.props.history.goBack() }
          skipHandler={handleSkip}
          nextText="Next"
          nextHandler={handleSubmit} />
      </div>
    );
  }

  _getTailorQuestionsASlide(){

    const handleSkip = () => {
      // this.props.history.push('/onboarding/tailor-b');
      this.context.welcomeFinish().then(() => this.props.history.push('/'));

      //KeenIO
      this.context.sendKeenEvent('loneliness_2', {
        skip: true,
        loneliness: null,
        onboarding: true,
        how_often_do_you_feel_that_you_lack_companionship: this.context.tailor_question_lack_companionship,
        how_often_do_you_feel_leftout: this.context.tailor_question_left_out,
        how_often_do_you_feel_isolated_from_others: this.context.tailor_question_isolated_from_others,
      });

      //MixPanel
      this.context.sendMixPanelEvent('loneliness_2', {
        skip: true,
        loneliness: null,
        onboarding: true,
        how_often_do_you_feel_that_you_lack_companionship: this.context.tailor_question_lack_companionship,
        how_often_do_you_feel_leftout: this.context.tailor_question_left_out,
        how_often_do_you_feel_isolated_from_others: this.context.tailor_question_isolated_from_others,
      });
    }

    const handleSubmit = () => {

      // const settings = {
      //   tailor_question_lack_companionship: this.context.tailor_question_lack_companionship,
      //   tailor_question_left_out: this.context.tailor_question_left_out,
      //   tailor_question_isolated_from_others: this.context.tailor_question_isolated_from_others,
      // };
      //this.context.updateProfile(settings).then(() => {

        // this.props.history.push('/onboarding/tailor-b');
        this.context.welcomeFinish().then(() => this.props.history.push('/'));

        //KeenIO
        this.context.sendKeenEvent('loneliness_2', {
          skip: false,
          loneliness: null,
          onboarding: true,
          how_often_do_you_feel_that_you_lack_companionship: this.context.tailor_question_lack_companionship,
          how_often_do_you_feel_leftout: this.context.tailor_question_left_out,
          how_often_do_you_feel_isolated_from_others: this.context.tailor_question_isolated_from_others,
        });

        //MixPanel
        this.context.sendMixPanelEvent('loneliness_2', {
          skip: false,
          loneliness: null,
          onboarding: true,
          how_often_do_you_feel_that_you_lack_companionship: this.context.tailor_question_lack_companionship,
          how_often_do_you_feel_leftout: this.context.tailor_question_left_out,
          how_often_do_you_feel_isolated_from_others: this.context.tailor_question_isolated_from_others,
        });

      //});
    }

    const updateSelect = (name, event) => {
      this.context.setState({
        [name]: event.target.value
      });
    }

    return(
      <div className="slide-inner">
        <Helmet>
          <title>Nod - Tailor</title>
        </Helmet>
        <PageHeader bgColor="tan-accent" bgHeaderColor="tan">
          <h4>Let’s check in</h4>
          <h1>How are you feeling these days?</h1>
        </PageHeader>

        <div className="subhead">1 of 3</div>
        <label htmlFor="tailor-question-lack-companionship-select" className="subhead-p">How often do you feel that you lack companionship?</label>
        <div className="dropdownWrap">
          <select id="tailor-question-lack-companionship-select" value={this.context.tailor_question_lack_companionship} onChange={(e) => updateSelect('tailor_question_lack_companionship', e)}>
            <option value='none'>Select</option>
            <option value='Never'>Never</option>
            <option value='Rarely'>Rarely</option>
            <option value='Sometimes'>Sometimes</option>
            <option value='Often'>Often</option>
            <option value='Always'>Always</option>
          </select>
        </div>

        <div className="subhead">2 of 3</div>
        <label htmlFor="tailor-question-left-out-select" className="subhead-p">How often do you feel left out?</label>
        <div className="dropdownWrap">
          <select id="tailor-question-left-out-select" value={this.context.tailor_question_left_out} onChange={(e) => updateSelect('tailor_question_left_out', e)}>
            <option value='none'>Select</option>
            <option value='Never'>Never</option>
            <option value='Rarely'>Rarely</option>
            <option value='Sometimes'>Sometimes</option>
            <option value='Often'>Often</option>
            <option value='Always'>Always</option>
          </select>
        </div>

        <div className="subhead">3 of 3</div>
        <label htmlFor="tailor-question-isolated-from-others-select" className="subhead-p">How often do you feel isolated from others?</label>
        <div className="dropdownWrap">
          <select id="tailor-question-isolated-from-others-select" value={this.context.tailor_question_isolated_from_others} onChange={(e) => updateSelect('tailor_question_isolated_from_others', e)}>
            <option value='none'>Select</option>
            <option value='Never'>Never</option>
            <option value='Rarely'>Rarely</option>
            <option value='Sometimes'>Sometimes</option>
            <option value='Often'>Often</option>
            <option value='Always'>Always</option>
          </select>
        </div>

        <FooterUpdator
          showHome={false}
          backHandler={() => this.props.history.goBack() }
          skipHandler={handleSkip}
          nextText="Next"
          nextHandler={handleSubmit} />
      </div>
    );
  }

  // _getTailorQuestionsBSlide(){

  //   const handleSkip = () => {
  //     //this.props.history.push('/onboarding/whats-important');
  //     this.context.welcomeFinish().then(() => this.props.history.push('/'));

  //     //KeenIO
  //     this.context.sendKeenEvent('circumstances', {
  //       where_do_you_expect_to_be_living: this.context.tailor_question_expect_to_be_living,
  //       where_will_your_classes_be_held: this.context.tailor_question_classes_be_held,
  //       skip:true,
  //     });
  //   }

  //   const handleSubmit = () => {

  //     // const settings = {
  //     //   tailor_question_expect_to_be_living: this.context.tailor_question_expect_to_be_living,
  //     //   tailor_question_classes_be_held: this.context.tailor_question_classes_be_held,
  //     // };
  //     //this.context.updateProfile(settings).then(() => {

  //       //this.props.history.push('/onboarding/whats-important');
  //       this.context.welcomeFinish().then(() => this.props.history.push('/'));

  //       //KeenIO
  //       this.context.sendKeenEvent('circumstances', {
  //         where_do_you_expect_to_be_living: this.context.tailor_question_expect_to_be_living,
  //         where_will_your_classes_be_held: this.context.tailor_question_classes_be_held,
  //         skip:false,
  //       });

  //     // });
  //   }

  //   const updateSelect = (name, event) => {
  //     this.context.setState({
  //       [name]: event.target.value
  //     });
  //   }

  //   return(
  //     <div className="slide-inner">
  //       <Helmet>
  //         <title>Nod - Tailor</title>
  //       </Helmet>
  //       <PageHeader bgColor="tan-accent" bgHeaderColor="tan">
  //         <h4>Finally...</h4>
  //         <h1>We know things are unusual right now.</h1>
  //       </PageHeader>

  //       <div className="subhead">1 of 2</div>
  //       <label htmlFor="tailor-question-expect-to-be-living-select" className="subhead-p">Where are you living this term?</label>
  //       <div className="dropdownWrap">
  //         <select id="tailor-question-expect-to-be-living-select" value={this.context.tailor_question_expect_to_be_living} onChange={(e) => updateSelect('tailor_question_expect_to_be_living', e)}>
  //           <option value='none'>Select</option>
  //           <option value='Solely on or near campus'>Solely on or near campus</option>
  //           <option value='Mainly on or near campus'>Mainly on or near campus</option>
  //           <option value='About half and half'>About half and half</option>
  //           <option value='Mainly remote/at home'>Mainly remote/at home</option>
  //           <option value='Solely remote/at home'>Solely remote/at home</option>
  //           <option value="Unsure/Don't know">Unsure/Don’t know</option>
  //         </select>
  //       </div>

  //       <div className="subhead">2 of 2</div>
  //       <label htmlFor="tailor-question-classes-be-held-select" className="subhead-p">Where are your classes being held?</label>
  //       <div className="dropdownWrap">
  //         <select id="tailor-question-classes-be-held-select" value={this.context.tailor_question_classes_be_held} onChange={(e) => updateSelect('tailor_question_classes_be_held', e)}>
  //           <option value='none'>Select</option>
  //           <option value='Solely in-person'>Solely in-person</option>
  //           <option value='Mainly in-person'>Mainly in-person</option>
  //           <option value='About half and half'>About half and half</option>
  //           <option value='Mainly online'>Mainly online</option>
  //           <option value='Solely online'>Solely online</option>
  //           <option value="Unsure/Don't know">Unsure/Don’t know</option>
  //         </select>
  //       </div>

  //       <FooterUpdator
  //         showHome={false}
  //         backHandler={() => this.props.history.goBack() }
  //         skipHandler={handleSkip}
  //         nextText="Next"
  //         nextHandler={handleSubmit} />
  //     </div>
  //   );
  // }

  // _getInTuneSlide() {
  //
  //   const handleOnChange = (value) => {
  //     this.setState({loneliness_value: value});
  //     this.context.setState({onboarding_loneliness_changed: true});
  //   }
  //
  //   const handleSkip = () => {
  //     this.props.history.push('/onboarding/whats-important');
  //
  //     //KeenIO
  //     this.context.sendKeenEvent('loneliness_2', {
  //       skip: true,
  //       loneliness: this.state.loneliness_value,
  //       onboarding: true
  //     });
  //   }
  //
  //   const handleSubmit = () => {
  //     this.context.welcomeSetLoneliness(this.state.loneliness_value).then(() => {
  //       this.props.history.push('/onboarding/whats-important');
  //
  //       //KeenIO
  //       this.context.sendKeenEvent('loneliness_2', {
  //         skip: false,
  //         loneliness: this.state.loneliness_value,
  //         onboarding: true
  //       });
  //
  //     });
  //   }
  //
  //   return(
  //     <div className="slide-inner">
  //       <Helmet>
  //         <title>Nod - In Tune</title>
  //       </Helmet>
  //       <PageHeader bgColor="tan-accent" bgHeaderColor="tan">
  //         <h4>Let’s tailor your experience</h4>
  //         <h1>How often have you felt lonely in the past week?</h1>
  //       </PageHeader>
  //       <LonelinessMeter onChange={ (value) => handleOnChange(value) } />
  //       <FooterUpdator
  //         showHome={false}
  //         backHandler={() => this.props.history.goBack() }
  //         skipHandler={handleSkip}
  //         nextText="Next"
  //         nextHandler={handleSubmit} />
  //     </div>
  //   );
  // }

  render() {

    //Pull in the correct content by looking at the location
    let the_content = null;
    switch(this.props.match.params.screen) {

      default:
      case 'onboard':
        the_content = this._getOnboardSlide();
        break;

      case 'ideas':
        the_content = this._getIdeasSlide();
        break;

      case 'reflect':
        the_content = this._getReflectSlide();
        break;

      case 'grounded':
        the_content = this._getGroundedSlide();
        break;

      case 'whats-important':
        the_content = this._getWhatsImportantSlide();
        break;

      case 'allow-notifications':
        the_content = this._getAllowNotificationsSlide();
        break;

      case 'some-more-info':
        the_content = this._getSomeMoreInfoSlide();
        break;

      case 'tailor-a':
        the_content = this._getTailorQuestionsASlide();
        break;

      // case 'tailor-b':
      //   the_content = this._getTailorQuestionsBSlide();
      //   break;

      case 'accessibility':
        the_content = this._getAccessibilitySlide();
        break;

      // case 'in-tune': //Skipped currently
      //   the_content = this._getInTuneSlide();
      //   break;

    }

    //Classes and Render
    let slide_classes = 'slide';
    (this.props.match.params.screen !== undefined) ? slide_classes += ' slide_'+this.props.match.params.screen : slide_classes += ' slide_select-school';
    return (
      <MyContext.Consumer>
        {(context) => (
          <div id="onboarding">
            <div className={slide_classes}>
              {the_content}
            </div>
          </div>
        )}
      </MyContext.Consumer>
    );
  }
}

Onboarding.contextType = MyContext;
export default Onboarding;
