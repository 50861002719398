import * as PIXI from 'pixi.js';

class Fish {
  constructor(x, y, width, assetClosed, assetOpen) {
    this.x = x;
    this.y = y;
    this.width = width;
    this.assetClosed = assetClosed;
    this.assetOpen = assetOpen;

    this.assetSizer = this.assetClosed;

    this.dpr = window.devicePixelRatio;
    this.srcWidth = this.assetSizer.texture.width;
    this.srcHeight = this.assetSizer.texture.height;
    this.srcRatio = this.srcWidth / this.srcHeight;
    this.destWidth = this.width;
    this.destHeight = this.width / this.srcRatio;

    this.canvas1 = document.createElement('canvas');
    this.ctx1 = this.canvas1.getContext('2d');
    this.canvas1.width = this.destWidth * this.dpr;
    this.canvas1.height = this.destHeight * this.dpr;
    this.ctx1.scale(this.dpr, this.dpr);

    this.ctx1.drawImage(
      this.assetClosed.data,
      0,
      0,
      this.destWidth,
      this.destHeight
    );
    this.fishClosedTexture = PIXI.Texture.from(this.canvas1);

    this.canvas2 = document.createElement('canvas');
    this.ctx2 = this.canvas2.getContext('2d');
    this.canvas2.width = this.destWidth * this.dpr;
    this.canvas2.height = this.destHeight * this.dpr;
    this.ctx2.scale(this.dpr, this.dpr);
    this.ctx2.drawImage(
      this.assetOpen.data,
      0,
      0,
      this.destWidth,
      this.destHeight
    );
    this.fishOpenTexture = PIXI.Texture.from(this.canvas2);

    this.sprite = new PIXI.Sprite(this.fishClosedTexture);
    this.sprite.x = this.x;
    this.sprite.y = this.y;
    this.sprite.width = this.destWidth;
    this.sprite.height = this.destHeight;
    this.sprite.anchor.set(0.5);
    this.sprite.interactive = true;

    this.canvas1 = null;
    this.ctx1 = null;
    this.canvas2 = null;
    this.ctx2 = null;
  }
}

export default Fish;
