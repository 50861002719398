import * as PIXI from 'pixi.js';

import Calc from '../../common/Calc';
import RectSprite from '../../common/RectSprite';

class Particle extends RectSprite {
  constructor(
    x,
    y,
    width,
    height,
    color,
    xAnchor = 0.5,
    yAnchor = 0.5,
    angle,
    amplitude
  ) {
    super(x, y, width, height, color, xAnchor, yAnchor);

    this.container = new PIXI.Container();
    this.container.x = x;
    this.container.y = y;
    this.container.addChild(this.sprite);

    this.sprite.x = 0;
    this.sprite.y = 0;

    this.active = false;
    this.gravity = Calc.rand(0.1, 0.25);
    this.timeOffset = Calc.rand(10000);
    this.rotationVelocity = Calc.rand(-0.1, 0.1);
    this.waveMag = Calc.rand(0.01, 0.2);
    this.waveTimeMult = Calc.rand(0.001, 0.005);
    this.scale = Calc.rand(2);
    this.scaleOffset = Calc.rand(10000);
    this.scaleTimeMult = Calc.rand(0.001, 0.0075);

    this.velocity = {
      x: Math.cos(angle) * amplitude,
      y: Math.sin(angle) * amplitude
    };
  }

  activate(x, y) {
    this.container.x = x;
    this.container.y = y;
    this.active = true;
  }

  update(time) {
    if (!this.active) {
      return;
    }

    this.velocity.x +=
      Math.sin((time + this.timeOffset) * this.waveTimeMult) * this.waveMag;

    this.velocity.y += this.gravity;

    this.velocity.x *= 0.9;
    this.velocity.y *= 0.95;

    this.scale = Math.sin((time + this.scaleOffset) * this.scaleTimeMult);

    this.container.x += this.velocity.x;
    this.container.y += this.velocity.y;
    this.container.scale.set(this.scale, 1);
    this.container.rotation += this.rotationVelocity;
  }
}

export default Particle;
