import * as PIXI from 'pixi.js';
import * as Matter from 'matter-js';
import { Howl } from 'howler';

import BaseReflection from '../../common/BaseReflection';
import Calc from '../../common/Calc';
import SvgSprite from '../../common/SvgSprite';

import Boom from './Boom';
import Candy from './Candy';
import Particle from './Particle';
import Pinata from './Pinata';
import Rope from './Rope';

import boomSrc from '../images/boom.png';
import candy0Src from '../images/candy-0.png';
import candy1Src from '../images/candy-1.png';
import candy2Src from '../images/candy-2.png';
import candy3Src from '../images/candy-3.png';
import ceilingSrc from '../images/ceiling.png';
import pinata0Src from '../images/pinata-0.png';
import pinata1Src from '../images/pinata-1.png';
import pinata2Src from '../images/pinata-2.png';

import drop0WebmSrc from '../sounds/drop-0.webm';
import drop0OggSrc from '../sounds/drop-0.ogg';
import drop0Mp3Src from '../sounds/drop-0.mp3';
import drop1WebmSrc from '../sounds/drop-1.webm';
import drop1OggSrc from '../sounds/drop-1.ogg';
import drop1Mp3Src from '../sounds/drop-1.mp3';
import drop2WebmSrc from '../sounds/drop-2.webm';
import drop2OggSrc from '../sounds/drop-2.ogg';
import drop2Mp3Src from '../sounds/drop-2.mp3';
import drop3WebmSrc from '../sounds/drop-3.webm';
import drop3OggSrc from '../sounds/drop-3.ogg';
import drop3Mp3Src from '../sounds/drop-3.mp3';
import hornWebmSrc from '../sounds/horn.webm';
import hornOggSrc from '../sounds/horn.ogg';
import hornMp3Src from '../sounds/horn.mp3';
import punch0WebmSrc from '../sounds/punch-0.webm';
import punch0OggSrc from '../sounds/punch-0.ogg';
import punch0Mp3Src from '../sounds/punch-0.mp3';
import punch1WebmSrc from '../sounds/punch-1.webm';
import punch1OggSrc from '../sounds/punch-1.ogg';
import punch1Mp3Src from '../sounds/punch-1.mp3';
import punch2WebmSrc from '../sounds/punch-2.webm';
import punch2OggSrc from '../sounds/punch-2.ogg';
import punch2Mp3Src from '../sounds/punch-2.mp3';

class PinataReflection extends BaseReflection {
  init(onComplete) {
    super.init(onComplete);

    this.assetPaths = [
      boomSrc,
      candy0Src,
      candy1Src,
      candy2Src,
      candy3Src,
      ceilingSrc,
      pinata0Src,
      pinata1Src,
      pinata2Src,
      drop0WebmSrc,
      drop0OggSrc,
      drop0Mp3Src,
      drop1WebmSrc,
      drop1OggSrc,
      drop1Mp3Src,
      drop2WebmSrc,
      drop2OggSrc,
      drop2Mp3Src,
      drop3WebmSrc,
      drop3OggSrc,
      drop3Mp3Src,
      hornWebmSrc,
      hornOggSrc,
      hornMp3Src,
      punch0WebmSrc,
      punch0OggSrc,
      punch0Mp3Src,
      punch1WebmSrc,
      punch1OggSrc,
      punch1Mp3Src,
      punch2WebmSrc,
      punch2OggSrc,
      punch2Mp3Src
    ];

    window.completeTimeout = null;

    this.hitCount = 10;

    this.candies = [];
    this.candyCurrent = 0;
    this.candyPerHit = 1;
    this.candyCount = this.candyPerHit * this.hitCount + 20;

    this.particles = [];
    this.particleCount = 150;

    this.collisionCategories = {
      wall: Matter.Body.nextCategory(),
      pinata: Matter.Body.nextCategory(),
      candy: Matter.Body.nextCategory()
    };

    this.addEventListeners();
    this.loadAssets();
  }

  reset(width = this.width, height = this.height) {
    super.reset(width, height);

    window.clearTimeout(this.completeTimeout);

    this.candies.length = 0;
    this.particles.length = 0;
    this.pinata.destroy();
    this.rope.destroy();

    this.create();
  }

  addEventListeners() {
    super.addEventListeners();
  }

  removeEventListeners() {
    super.removeEventListeners();
  }

  initPhysics() {
    super.initPhysics();

    this.engine = Matter.Engine.create({
      positionIterations: 6,
      velocityIterations: 6,
      constraintIterations: 10,
      enableSleeping: true
    });
    this.engine.world.gravity.y = 4;
    Matter.Engine.run(this.engine);

    Matter.Events.on(this.engine, 'collisionStart', e => {
      let pair = e.pairs[0];
      let bodyA = pair.bodyA;
      let bodyB = pair.bodyB;

      let compareBody = null;
      if (bodyA.label === 'candy' && !bodyA.hasLanded) {
        if (
          bodyB.label === 'wallBottom' ||
          (bodyB.label === 'candy' && bodyB.hasLanded)
        ) {
          compareBody = bodyA;
        }
      }
      if (bodyB.label === 'candy' && !bodyB.hasLanded) {
        if (
          bodyA.label === 'wallBottom' ||
          (bodyA.label === 'candy' && bodyA.hasLanded)
        ) {
          compareBody = bodyB;
        }
      }

      if (compareBody) {
        compareBody.hasLanded = true;
      }

      if (compareBody && pair.collision.depth > 0.4) {
        let sound = Calc.randArr(this.sounds.drop);
        let minVol = 0.1;
        let maxVol = 0.4;
        let volume = Calc.map(pair.collision.depth, 0, 10, minVol, maxVol);
        volume = Calc.clamp(volume, minVol, maxVol);
        let rate = Calc.rand(0.8, 1.2);
        sound.volume(volume);
        sound.rate(rate);
        sound.play();
      }
    });
  }

  initSounds() {
    super.initSounds();

    this.sounds.drop = [];
    [
      { src: [drop0WebmSrc, drop0OggSrc, drop0Mp3Src], vol: 0.5 },
      { src: [drop1WebmSrc, drop1OggSrc, drop1Mp3Src], vol: 0.5 },
      { src: [drop2WebmSrc, drop2OggSrc, drop2Mp3Src], vol: 0.5 },
      { src: [drop3WebmSrc, drop3OggSrc, drop3Mp3Src], vol: 0.5 }
    ].forEach(obj => {
      this.sounds.drop.push(
        new Howl({
          src: obj.src,
          volume: obj.vol
        })
      );
    });

    this.sounds.punch = [];
    [
      { src: [punch0WebmSrc, punch0OggSrc, punch0Mp3Src], vol: 0.5 },
      { src: [punch1WebmSrc, punch1OggSrc, punch1Mp3Src], vol: 0.3 },
      { src: [punch2WebmSrc, punch2OggSrc, punch2Mp3Src], vol: 0.5 }
    ].forEach(obj => {
      this.sounds.punch.push(
        new Howl({
          src: obj.src,
          volume: obj.vol
        })
      );
    });

    this.sounds.horn = new Howl({
      src: [hornWebmSrc, hornOggSrc, hornMp3Src],
      volume: 0.5,
      rate: 0.9
    });
  }

  create() {
    super.create();

    this.createWalls();
    this.createCeiling();
    this.createBoom();
    this.createCandy();
    this.createParticles();
    this.createPinata();
    this.createRope();
  }

  createWalls() {
    const buffer = 1000;
    const wallOptions = {
      isStatic: true,
      restitution: 0,
      friction: 1,
      label: 'wall',
      collisionFilter: {
        category: this.collisionCategories.wall
      }
    };

    this.wallTop = Matter.Bodies.rectangle(
      this.width / 2,
      -buffer / 2,
      this.width + buffer,
      buffer,
      wallOptions
    );
    Matter.World.add(this.engine.world, this.wallTop);

    this.wallBottom = Matter.Bodies.rectangle(
      this.width / 2,
      this.height + buffer / 2,
      this.width + buffer,
      buffer,
      Object.assign({}, wallOptions, { label: 'wallBottom' })
    );
    Matter.World.add(this.engine.world, this.wallBottom);

    this.wallLeft = Matter.Bodies.rectangle(
      -buffer / 2,
      this.height / 2,
      buffer,
      this.height + buffer,
      wallOptions
    );
    Matter.World.add(this.engine.world, this.wallLeft);

    this.wallRight = Matter.Bodies.rectangle(
      this.width + buffer / 2,
      this.height / 2,
      buffer,
      this.height + buffer,
      wallOptions
    );
    Matter.World.add(this.engine.world, this.wallRight);
  }

  createCeiling() {
    this.ceilingContainer = new PIXI.Container();
    this.pixiApp.stage.addChild(this.ceilingContainer);
    this.ceilingWidth = 614;
    this.ceilingContainer.x = 0;
    this.ceilingContainer.y = 0;
    let ceilingSpritesNeeded = Math.ceil(this.width / this.ceilingWidth) + 1;
    for (let i = 0; i < ceilingSpritesNeeded; i++) {
      let ceiling = new SvgSprite(
        i * this.ceilingWidth - i * 16 - 16,
        0,
        this.ceilingWidth,
        this.assets[ceilingSrc],
        0,
        0
      );
      this.ceilingContainer.addChild(ceiling.sprite);
    }
  }

  createBoom() {
    this.boom = new Boom(0, 0, 180, this.assets[boomSrc]);
    this.pixiApp.stage.addChild(this.boom.container);
  }

  createCandy() {
    let candyMap = [
      {
        src: candy0Src,
        scale: 1
      },
      {
        src: candy1Src,
        scale: 1
      },
      {
        src: candy2Src,
        scale: 0.8
      },
      { src: candy3Src, scale: 1.5 }
    ];

    for (let i = 0; i < this.candyCount; i++) {
      let candyConfig = candyMap[i % candyMap.length];
      let width = 25 * candyConfig.scale;
      let candy = new Candy(
        0,
        0,
        width,
        this.assets[candyConfig.src],
        0.5,
        0.5,
        this.collisionCategories
      );
      this.pixiApp.stage.addChild(candy.container);
      this.candies.push(candy);
    }
  }

  createParticles() {
    for (let i = 0; i < this.particleCount; i++) {
      let width = Calc.rand(3, 12);
      let height = Calc.rand(3, 12);
      let particle = new Particle(
        -100,
        -100,
        width,
        height,
        `hsla(${Calc.rand(0, 360)}, 95%, 60%, 1)`,
        0.5,
        0.5,
        Calc.rand(-Math.PI + 0.5, -0.5),
        Calc.rand(0, 20)
      );
      this.pixiApp.stage.addChild(particle.container);
      this.particles.push(particle);
    }
  }

  createPinata() {
    let width = 140;
    this.pinata = new Pinata(
      this.width / 2,
      this.height / 2,
      width,
      this.assets[pinata0Src],
      this.assets[pinata1Src],
      this.assets[pinata2Src],
      this.sounds.punch,
      Calc.generateVerticesFromPolygonString(
        '465.44,325.52 555.46,412.56 593.82,501.49 479.86,733.51 128.06,733.51 62.12,684.46 0.26,243.91 0,183.47 47.11,25.57 120.36,0',
        width / this.assets[pinata0Src].texture.width
      ),
      0.43,
      0.58,
      this.collisionCategories,
      this.onHit.bind(this),
      this.hitCount
    );
    this.pixiApp.stage.addChild(this.pinata.sprite);
    Matter.World.add(this.engine.world, this.pinata.body);
  }

  createRope() {
    this.rope = new Rope(
      this.width,
      this.height,
      this.collisionCategories,
      this.pinata
    );
    Matter.World.add(this.engine.world, this.rope.parts);
    Matter.World.add(this.engine.world, this.rope.constraint);
    this.pixiApp.stage.addChild(this.rope.sprite);
  }

  onTick() {
    super.onTick();

    for (let i = 0, len = this.candies.length; i < len; i++) {
      this.candies[i].update();
    }

    let time = Date.now();
    for (let i = 0, len = this.particles.length; i < len; i++) {
      this.particles[i].update(time);
    }

    this.pinata.sprite.x = this.pinata.body.position.x;
    this.pinata.sprite.y = this.pinata.body.position.y;
    this.pinata.sprite.rotation = this.pinata.body.angle;

    this.rope.render();
  }

  onHit(done, hits) {
    if (done) {
      this.activateCandy(true);
      this.explode();
      this.finish();
    } else {
      this.activateCandy();
    }

    this.progressTarget = hits / this.hitCount;
  }

  activateCandy(large = false) {
    let amount = this.candyPerHit;
    if (large) {
      amount = this.candyCount - (this.hitCount - 1) * this.candyPerHit;
    }
    for (let i = 0; i < amount; i++) {
      let candy = this.candies[this.candyCurrent];
      if (candy) {
        candy.activate(
          this.engine.world,
          this.pinata.sprite.x + Calc.rand(-50, 50),
          this.pinata.sprite.y + Calc.rand(-50, 50)
        );
        this.candyCurrent++;
      }
    }
  }

  explode() {
    this.sounds.horn.play();

    for (let i = 0, len = this.particles.length; i < len; i++) {
      this.particles[i].activate(this.pinata.sprite.x, this.pinata.sprite.y);
    }

    this.boom.activate(this.pinata.sprite.x, this.pinata.sprite.y);

    this.pinata.sprite.alpha = 0;

    Matter.World.remove(this.engine.world, [
      this.rope.parts,
      this.rope.constraint,
      this.pinata.body
    ]);
    this.pixiApp.stage.removeChild(this.rope.sprite, this.pinata.sprite);
  }

  finish() {
    this.completeTimeout = window.setTimeout(() => {
      this.onComplete();
    }, 3000);
  }

  destroy() {
    super.destroy();

    window.clearTimeout(this.completeTimeout);

    this.candies.length = 0;
    this.candies = null;

    this.particles.length = 0;
    this.particles = null;

    this.pinata.destroy();
    this.pinata = null;

    this.rope.destroy();
    this.rope = null;
  }
}

export default PinataReflection;
