import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import MyContext from '../../state/MyContext';
import {Helmet} from "react-helmet";
import SlickSlider from "react-slick";
import FooterUpdator from '../FooterUpdator';
import FooterModal from '../FooterModal';
import PageHeader from '../PageHeader';
import MoodMeter from '../MoodMeter';
import FeaturedChallenges from '../FeaturedChallenges';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import appleIcon from '../../assets/images/icons/Download-iOS.png';
import androidIcon from '../../assets/images/icons/Download-GooglePlay.png';
import wild_thing_mole_img from '../../assets/images/illustrations/wild-thing-mole.png';
import virtual_img from '../../assets/images/illustrations/virtual.png';
import irl_img from '../../assets/images/illustrations/irl.png';
import both_img from '../../assets/images/illustrations/both.png';

import completion_dog from '../../assets/images/illustrations/completion_dog.png';
import completion_duck from '../../assets/images/illustrations/completion_duck.png';
import completion_quokka from '../../assets/images/illustrations/completion_quokka.png';
import completion_toucan from '../../assets/images/illustrations/completion_toucan.png';

const waytogo_images = [
  completion_dog,
  completion_duck,
  completion_quokka,
  completion_toucan
];

const approaches = {
  0: 'virtual',
  1: 'in-real-life',
  2: 'both'
};

require('./style.scss');

class Challenge extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ready:false,

      //Challenge Slide
      accept_button_disabled: false,
      feedback_choice: null,
      approach_choice: null,
      sharing_open: false,

      //Date Slide
      //challenge_date_value: new Date().getTime(), //moment.utc().add(1, 'days').format('YYYY-MM-DD'),
      //date_button_disabled: false,

      //Notify Slide
      notify_button_disabled: false,

      //Check It Off Modal Screen
      check_it_off_screen: 0,

      notification: null,

      appDownloadLink: '#',

      tips_position_virtual: 0,
      tips_swipe_virtual: null,
      tips_position_irl: 0,
      tips_swipe_irl: null,

    };
    this.challenge_id = null;
    this.challenge = null;
    this.guide = null;
    this.user_challenge = false;

    //Bindings & References
    this.footerModal = React.createRef();
    this.footerModalComplete = React.createRef();

    this._beforeUnload = this._beforeUnload.bind(this);

    let animated_gifs = window.localStorage.getItem('@nod:animated_gifs');
    if(animated_gifs === 'false') animated_gifs = false;
    if(animated_gifs === null) animated_gifs = true;
    this.animated_gifs = animated_gifs;
  }

  componentDidMount() {
    // if(
    //   this.props.match.params.screen !== undefined
    //   && this.props.match.params.screen !== 'date'
    //   && this.props.match.params.screen !== 'notify'
    //   && this.props.match.params.screen !== 'active'
    //   && this.props.match.params.screen !== 'share'
    // ){
    //   this.props.history.push('/');
    // }

    document.addEventListener('beforeunload', this._beforeUnload);
    document.addEventListener('pause', this._beforeUnload);

    //Preload content
    if(!this.context.guides_loaded && this.context.user_logged_in) this.context.getGuides();
    if(!this.context.challenges_loaded) this.context.getChallenges();
    if(!this.context.user_challenges_loaded && this.context.user_logged_in) this.context.getUserChallenges();
    if((this.context.guides_loaded || !this.context.user_logged_in) && this.context.challenges_loaded && (this.context.user_challenges_loaded || !this.context.user_logged_in)){
      if(this.context.user_logged_in) this.context.sortFeaturedChallenges();
      this._getChallengeFromID();
    }

  }

  componentDidUpdate() {
    if((this.context.guides_loaded || !this.context.user_logged_in) && this.context.challenges_loaded && (this.context.user_challenges_loaded || !this.context.user_logged_in) && !this.state.ready){
      if(this.context.user_logged_in) this.context.sortFeaturedChallenges();
      this._getChallengeFromID();
    }
  }

  _beforeUnload(e) {
    if(this.context.last_path.includes('/challenges')){
      let status = 'inactive';
      if(this.user_challenge){
        if(this.user_challenge.completed){
          status = 'completed';
        }else{
          status = 'active';
        }
      }
      this.context.sendKeenEvent('challenge', {
        guide_title: this.guide.title,
        challenge_id: this.challenge.id,
        challenge_title: this.challenge.title,
        status: status,
        rating: null,
        approach: null,
        skill_title: this.challenge.cbt_skill ? this.challenge.cbt_skill.title : null,
        prior_path: this.context.last_last_path,
        back:false,
        home:false,
        push_notification_title: this.context.push_notification_title,
        exit_app: true
      });

      this.context.sendMixPanelEvent('challenge', {
        guide_title: this.guide.title,
        challenge_id: this.challenge.id,
        challenge_title: this.challenge.title,
        status: status,
        rating: null,
        approach: null,
        skill_title: this.challenge.cbt_skill ? this.challenge.cbt_skill.title : null,
        prior_path: this.context.last_last_path,
        back:false,
        home:false,
        push_notification_title: this.context.push_notification_title,
        exit_app: true
      });
    }
  }

  _getChallengeFromID() {
    this.challenge_id = this.props.match.params.challenge ? parseInt(this.props.match.params.challenge) : null;
    if(this.challenge_id !== null){
      this.challenge = this.context.getChallengeByID(parseInt(this.challenge_id));
      this.guide = this.context.guides_all && this.context.guides_all.find((theguide) => { return theguide.id === this.challenge.guide_id; });
      this.user_challenge = this.context.getUserChallengeByChallengeID(this.challenge.id);

      if(this.context.user_challenge_just_completed) this.context.setState({user_challenge_just_completed:false});
      this.setState({ready:true, feedback_choice: parseInt(this.user_challenge.rating)});
      this._getExpiredChallengeNotifactionFromId();
      this._downloadAppLink();
    }else{
      this.props.history.push('/guides');
    }
  }

  _getExpiredChallengeNotifactionFromId() {
    if(this.user_challenge !== false && !this.user_challenge.completed){
      this.context.getExpiredChallengeNotifactionFromId(this.challenge_id).then(notification => {
        if(notification !== null && notification !== undefined){
          this.setState({notification: notification});
        }
      });
    }
  }

  // _downloadApp() {
  //   this.context.makeBranchLink({redirectPath: "?destination=/challenges/" + this.challenge_id}).then(link => {
  //     window.location.href = link;
  //   })
  // }

  _downloadAppLink() {
    this.context.makeBranchLink({
      redirectPath: "?destination=/challenges/" + this.challenge_id,
      '$desktop_url': 'https://nod-pro.herokuapp.com/#/challenges/' + this.challenge_id,
    }).then(link => {
      this.setState({appDownloadLink:link});
    })
  }

  //Render//////////////////////////////////////////////////////////////////////
  _getAppDownloadSection() {
    return(
      <div className="shareWrap">
        <svg viewBox="0 0 1000 31.39">
          <path className="pathItem" d="M0,0V29.86c21.61,2.24,42.07-5.11,53.07-6.72,36.48-5.34,61-3.46,98.63,3.15S206.18,27.62,253,11.53s63.77,6.58,91.48,13.9,43.63-6.72,66-1.95C425.35,26.63,452,30.39,471.75,25c7.09-1.93,56.62-5,67.8-5.67C588,16.39,589,36.39,651.56,30.2,680.08,27.37,710,15.39,758,23.3c48.81,8,54.62,2.37,92.26-4.25s60.15-1.49,96.63,3.85c11,1.61,31.46,9,53.07,6.72V0Z"/>
        </svg>
        <h1>Download Nod to get Started</h1>
        {/*<a href="/" onClick={(e) => {e.preventDefault(); this._downloadApp();}} className="app-store-link apple"><img src={appleIcon}/></a>
        <a href="/" onClick={(e) => {e.preventDefault(); this._downloadApp();}} className="app-store-link android"><img src={androidIcon}/></a>*/}
        <a href={this.state.appDownloadLink} className="app-store-link apple"><img alt="Get the App" src={appleIcon}/></a>
        <a href={this.state.appDownloadLink} className="app-store-link android"><img alt="Get the App" src={androidIcon}/></a>
      </div>
    );
  }

  _getShareThisChallenge(text, top_vector = false) {
    var onSuccess = function(result) {
      console.log("Share completed? " + result.completed); // On Android apps mostly return false even while it's true
      console.log("Shared to app: " + result.app); // On Android result.app since plugin version 5.4.0 this is no longer empty. On iOS it's empty when sharing is cancelled (result.completed=false)
    };

    var onError = function(msg) {
      console.log("Sharing failed with message: " + msg);
    };

    var onShare  = (e) => {
      e.preventDefault();
      //this.context.makeBranchLink({redirectPath: "?destination=/challenges/" + this.challenge_id}).then(link => {
        var options = {
          message: 'Hey! Check this out. Want to do it with me?', // not supported on some apps (Facebook, Instagram)
          subject: 'Hey! Check this out. Want to do it with me?', // fi. for email
          url: this.state.appDownloadLink,
        };
        if(window.cordova) window.plugins.socialsharing.shareWithOptions(options, onSuccess, onError);
      //})
    }

    return(
      <div className="shareWrap">
        {top_vector ?
            <svg viewBox="0 0 1000 31.39">
              <path className="pathItem" d="M0,0V29.86c21.61,2.24,42.07-5.11,53.07-6.72,36.48-5.34,61-3.46,98.63,3.15S206.18,27.62,253,11.53s63.77,6.58,91.48,13.9,43.63-6.72,66-1.95C425.35,26.63,452,30.39,471.75,25c7.09-1.93,56.62-5,67.8-5.67C588,16.39,589,36.39,651.56,30.2,680.08,27.37,710,15.39,758,23.3c48.81,8,54.62,2.37,92.26-4.25s60.15-1.49,96.63,3.85c11,1.61,31.46,9,53.07,6.72V0Z"/>
            </svg>
          :
            null
        }

        {/*<a href="/" onClick={onShare}>ShareNative</a>*/}

        <div className={'shareBlock ' + (this.state.sharing_open ? 'active' : '') }>
          {/*<a href="/" onClick={(e)=>{e.preventDefault(); this.setState({sharing_open:true});}} className={'share'} >&nbsp;</a>*/}
          <a href="/" onClick={onShare} className={'share'} >&nbsp;</a>
          {/*<div className="share-popup">
            <ul>
              <li className="close"><a href="/" onClick={(e)=>{e.preventDefault(); this.setState({sharing_open:false});}}><img src={img_icon_close} alt="Close"/></a></li>
              <li><a href="whatsapp://send?text=The text to share!" data-action="share/whatsapp/share"><img src={img_icon_whatsapp} alt="Whats App"/></a></li>
              <li><a href="https://www.facebook.com/sharer/sharer.php?u=https://cactusinc.com"><img src={img_icon_facebook} alt="Facebook"/></a></li>
              <li><a href="http://twitter.com/share?text=text goes here&url=https://cactusinc.com"><img src={img_icon_twitter} alt="Twitter"/></a></li>
              <li><a href="mailto:?body=body text&subject=subject"><img src={img_icon_email} alt="Email"/></a></li>
            </ul>
          </div>*/}
        </div><br />
        <div className="textWrap">
          <a href="/" onClick={onShare} className="shareText">{text}</a>
        </div>
      </div>
    );
  }

  _getMoodTracker() {
    let mood_after_position  =  this.user_challenge.mood_after;
    if(mood_after_position !== null && mood_after_position !== undefined){
      mood_after_position =  mood_after_position.split(',');
      mood_after_position[0] = parseFloat(mood_after_position[0]);
      mood_after_position[1] = parseFloat(mood_after_position[1]);
    }else{
      mood_after_position = [0.5,0.5];
    }
    return(
      <div className="mood-meter-wrap">
        <div className="squiggle">
          <svg viewBox="0 0 1000 31.39">
            <path className="pathItem" d="M0,0V29.86c21.61,2.24,42.07-5.11,53.07-6.72,36.48-5.34,61-3.46,98.63,3.15S206.18,27.62,253,11.53s63.77,6.58,91.48,13.9,43.63-6.72,66-1.95C425.35,26.63,452,30.39,471.75,25c7.09-1.93,56.62-5,67.8-5.67C588,16.39,589,36.39,651.56,30.2,680.08,27.37,710,15.39,758,23.3c48.81,8,54.62,2.37,92.26-4.25s60.15-1.49,96.63,3.85c11,1.61,31.46,9,53.07,6.72V0Z"/>
          </svg>
        </div>
        <h3>How I felt afterward.</h3>
        <MoodMeter initialPosition={mood_after_position} disabled={true} />
          <div className="squiggle bottom">
            <svg viewBox="0 0 1000 31.39">
              <path className="pathItem" d="M0,0V29.86c21.61,2.24,42.07-5.11,53.07-6.72,36.48-5.34,61-3.46,98.63,3.15S206.18,27.62,253,11.53s63.77,6.58,91.48,13.9,43.63-6.72,66-1.95C425.35,26.63,452,30.39,471.75,25c7.09-1.93,56.62-5,67.8-5.67C588,16.39,589,36.39,651.56,30.2,680.08,27.37,710,15.39,758,23.3c48.81,8,54.62,2.37,92.26-4.25s60.15-1.49,96.63,3.85c11,1.61,31.46,9,53.07,6.72V0Z"/>
            </svg>
          </div>
      </div>
    );
  }

  _getTestimonial() {
    return(
      <div className="testimonial wavy-section tan">
        <svg viewBox="0 0 1000 31.39">
          <path className="pathItem" d="M0,0V29.86c21.61,2.24,42.07-5.11,53.07-6.72,36.48-5.34,61-3.46,98.63,3.15S206.18,27.62,253,11.53s63.77,6.58,91.48,13.9,43.63-6.72,66-1.95C425.35,26.63,452,30.39,471.75,25c7.09-1.93,56.62-5,67.8-5.67C588,16.39,589,36.39,651.56,30.2,680.08,27.37,710,15.39,758,23.3c48.81,8,54.62,2.37,92.26-4.25s60.15-1.49,96.63,3.85c11,1.61,31.46,9,53.07,6.72V0Z"/>
        </svg>
        {(this.challenge.testimonial !== null) ? <div className="secContent" dangerouslySetInnerHTML={{__html: this.challenge.testimonial}}></div> : null}
      </div>
    );
  }

  // _getRating() {
  //
  //   const onChange = (which) => {
  //     this.setState({feedback_choice:which});
  //     this.context.updateUserChallenge(this.challenge.id, {rating:which, completed:true}).then(() => {
  //       this.context.cancelChallengeNotification(this.challenge.id);
  //       this.context.setState({user_challenge_just_completed: true}).then(() =>{
  //         this.props.history.push('/reflect/mood');
  //       });
  //     });
  //   }
  //
  //   return (
  //     <React.Fragment>
  //       <div className="">
  //         <h3>Did you like this idea?</h3>
  //         <div className="feedback-buttons">
  //           <a href="/" onClick={(e)=> {e.preventDefault(); onChange(3);}} className={'love' + (this.state.feedback_choice === 3 ? ' active' : '')}>&nbsp;</a>
  //           <a href="/" onClick={(e)=> {e.preventDefault(); onChange(2);}} className={'like' + (this.state.feedback_choice === 2 ? ' active' : '')}>&nbsp;</a>
  //           <a href="/" onClick={(e)=> {e.preventDefault(); onChange(1);}} className={'meh' + (this.state.feedback_choice === 1 ? ' active' : '')}>&nbsp;</a>
  //           <a href="/" onClick={(e)=> {e.preventDefault(); onChange(0);}} className={'dislike' + (this.state.feedback_choice === 0 ? ' active' : '')}>&nbsp;</a>
  //         </div>
  //       </div>
  //     </React.Fragment>
  //   );
  // }

  // _getCompletionApproach(){
  //
  //   const onChange = (which) => {
  //     this.setState({approach_choice:which});
  //     console.log(which);
  //     // this.context.updateUserChallenge(this.challenge.id, {rating:which, completed:true}).then(() => {
  //     //   this.context.cancelChallengeNotification(this.challenge.id);
  //     //   this.context.setState({user_challenge_just_completed: true}).then(() =>{
  //     //     this.props.history.push('/reflect/mood');
  //     //   });
  //     // });
  //   }
  //
  //   return (
  //     <React.Fragment>
  //       <div className="">
  //         <h3>Which way did you try it?</h3>
  //         <div className="feedback-buttons">
  //           <a href="/" onClick={(e)=> {e.preventDefault(); onChange(0);}} className={'virtual' + (this.state.approach_choice === 0 ? ' active' : '')}>&nbsp;</a>
  //           <a href="/" onClick={(e)=> {e.preventDefault(); onChange(1);}} className={'in-real-life' + (this.state.approach_choice === 1 ? ' active' : '')}>&nbsp;</a>
  //           <a href="/" onClick={(e)=> {e.preventDefault(); onChange(2);}} className={'both' + (this.state.approach_choice === 2 ? ' active' : '')}>&nbsp;</a>
  //         </div>
  //       </div>
  //     </React.Fragment>
  //   );
  // }

  _getRatingComplete() {

    const onChange = (which) => {
      this.setState({feedback_choice:which});
      this.context.updateUserChallenge(this.challenge_id, {rating:which});

      if(this.context.came_from_push_notification){

        //KeenIO Event
        this.context.sendKeenEvent('notification', {
          page_title: 'challenge',
          skipped:false,
          os_declined: false,
          snoozed:false,
          reminder_selection: null,
          notification_title: null,
          marked_complete: true,
          push_notification: true,
          notification_action_button_clicked: null,
          code_location: 'Challenge.jsx',
          push_notification_title: null,
        });

        //MixPanel Event
        this.context.sendMixPanelEvent('notification', {
          page_title: 'challenge',
          skipped:false,
          os_declined: false,
          snoozed:false,
          reminder_selection: null,
          notification_title: null,
          marked_complete: true,
          push_notification: true,
          notification_action_button_clicked: null,
          code_location: 'Challenge.jsx',
          push_notification_title: null,
        });

        this.context.setState({came_from_push_notification:false});
      }
    }

    return (
      <div className="ratingComplete">
        <h3>What I thought of the idea.</h3>
        <div className="feedback-buttons">
          <a href="/" onClick={(e)=> {e.preventDefault(); onChange(3);}} className={'love' + (this.state.feedback_choice === 3 ? ' active' : '')}>&nbsp;</a>
          <a href="/" onClick={(e)=> {e.preventDefault(); onChange(2);}} className={'like' + (this.state.feedback_choice === 2 ? ' active' : '')}>&nbsp;</a>
          <a href="/" onClick={(e)=> {e.preventDefault(); onChange(1);}} className={'meh' + (this.state.feedback_choice === 1 ? ' active' : '')}>&nbsp;</a>
          <a href="/" onClick={(e)=> {e.preventDefault(); onChange(0);}} className={'dislike' + (this.state.feedback_choice === 0 ? ' active' : '')}>&nbsp;</a>
        </div>
        <br /><br />
      </div>
    );
  }

  _getTags(){
    const guide = this.context.guides_all && this.context.guides_all.find(guide => guide.id === this.challenge.guide_id);
    const skill = this.challenge.cbt_skill ? this.challenge.cbt_skill : null;

    const goal_element = (
      <div className="tag">
        <i className={'icon goal'}/> {guide.title}
      </div>
    );

    const skill_element = skill ? (
      <div className="tag">
        <i className={'icon skill'}/> {skill.title}
      </div>
    ) : null;

    return(
      <div className="tag-wrap">
        {goal_element}
        {skill_element}
      </div>
    );
  }

  //////////////////////////////////////////////////////////////////////////////
  //Slides//////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////

  _getChallengeSlideAnonymous(){

    let students = null;
    if(this.challenge.participants >= 100) students = <div className="students">{this.challenge.participants} students</div>;

    return(
      <React.Fragment>
        <div className="challenge-header">
          <div className={'challengeImg' + (this.animated_gifs ? ' animated' : '')}>
            <img src={this.challenge.image_url} alt=""  />
          </div>
          {students}
        </div>
        <div className="challengeDescription">
          <h2>Here's the idea:</h2>
          <h1>{this.challenge.title}</h1>
          <div dangerouslySetInnerHTML={{__html: this.challenge.introduction}}></div>
          <div dangerouslySetInnerHTML={{__html: this.challenge.main_content}}></div>
        </div>
        {this._getAppDownloadSection()}
        {/*this._getTestimonial()*/}
      </React.Fragment>
    );
  }

  _getChallengeHeader(){
    let students = null;
    if(this.challenge.participants >= 100) students = <div className="students">{this.challenge.participants} students</div>;
    return(
      <div className="challenge-header">
        <div className={'challengeImg' + (this.animated_gifs ? ' animated' : '')}>
          <img src={this.challenge.image_url} alt=""  />
        </div>
        {students}
      </div>
    );
  }

  _getChallengeDescription(){
    return(
      <div className="challengeDescription">
        <h2>Here's the idea:</h2>
        <h1>{this.challenge.title}</h1>
        <div className="description" dangerouslySetInnerHTML={{__html: this.challenge.main_content}}></div>
      </div>
    );
  }

  _getChallengeWhy(){
    return(
      <div className="testimonial wavy-section tan">
        <svg viewBox="0 0 1000 31.39">
          <path className="pathItem" d="M0,0V29.86c21.61,2.24,42.07-5.11,53.07-6.72,36.48-5.34,61-3.46,98.63,3.15S206.18,27.62,253,11.53s63.77,6.58,91.48,13.9,43.63-6.72,66-1.95C425.35,26.63,452,30.39,471.75,25c7.09-1.93,56.62-5,67.8-5.67C588,16.39,589,36.39,651.56,30.2,680.08,27.37,710,15.39,758,23.3c48.81,8,54.62,2.37,92.26-4.25s60.15-1.49,96.63,3.85c11,1.61,31.46,9,53.07,6.72V0Z"/>
        </svg>
        <h3>Why?</h3>
        <div className="secContent" dangerouslySetInnerHTML={{__html: this.challenge.testimonial}}></div>
        {this._getTags()}
        <br/><br/>
      </div>
    );
  }

  _getChallengeHow(){

    let tips_virtual = this.challenge.tips.filter(tip => tip.type === '1')
    if(tips_virtual.length > 0) tips_virtual.sort((a, b) => (a.sort_order > b.sort_order) ? 1 : -1);

    let tips_irl = this.challenge.tips.filter(tip => tip.type === '0');
    if(tips_irl.length > 0) tips_irl.sort((a, b) => (a.sort_order > b.sort_order) ? 1 : -1);

    const handleAccordionChange = (e) => {
      const accordion_title = e[0];

      //KeenIO Event
      this.context.sendKeenEvent('tips_accordion', {
        accordion_action: accordion_title ? 'open' : 'close',
        accordion_title: accordion_title ? accordion_title : '',
        accordion_position: accordion_title === 'virtual' ? this.state.tips_position_virtual : this.state.tips_position_irl,
        challenge_id: this.challenge.id,
        challenge_title: this.challenge.title,
        guide_title: this.guide.title,
        swipe: accordion_title === 'virtual' ? this.state.tips_swipe_virtual : this.state.tips_swipe_irl,
        tip_id: tips_virtual[0].id,
        tip_title: tips_virtual[0].title,
        skill_title: this.challenge.cbt_skill ? this.challenge.cbt_skill.title : null,
      });

      //MixPanel Event
      this.context.sendMixPanelEvent('tips_accordion', {
        accordion_action: accordion_title ? 'open' : 'close',
        accordion_title: accordion_title ? accordion_title : '',
        accordion_position: accordion_title === 'virtual' ? this.state.tips_position_virtual : this.state.tips_position_irl,
        challenge_id: this.challenge.id,
        challenge_title: this.challenge.title,
        guide_title: this.guide.title,
        swipe: accordion_title === 'virtual' ? this.state.tips_swipe_virtual : this.state.tips_swipe_irl,
        tip_id: tips_virtual[0].id,
        tip_title: tips_virtual[0].title,
        skill_title: this.challenge.cbt_skill ? this.challenge.cbt_skill.title : null,
      });
    }

    const slider_settings_virtual = {
      dots: true,
      infinite: false,
      centerMode: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      centerPadding: "20px",
      focusOnSelect: true,
      adaptiveHeight: true,
      beforeChange: (oldIndex, newIndex) => {
        if(newIndex !== oldIndex){

          const swipe = newIndex > oldIndex ? 'left' : 'right';

          this.setState({
            tips_position_virtual: newIndex,
            tips_swipe_virtual: swipe
          });

          //KeenIO Event
          this.context.sendKeenEvent('tips_accordion', {
            accordion_action:'swipe',
            accordion_title:'virtual',
            accordion_position: newIndex,
            challenge_id: this.challenge.id,
            challenge_title: this.challenge.title,
            guide_title: this.guide.title,
            swipe: swipe,
            tip_id: tips_virtual[newIndex].id,
            tip_title: tips_virtual[newIndex].title,
            skill_title: this.challenge.cbt_skill ? this.challenge.cbt_skill.title : null,
          });

          //MixPanel Event
          this.context.sendMixPanelEvent('tips_accordion', {
            accordion_action:'swipe',
            accordion_title:'virtual',
            accordion_position: newIndex,
            challenge_id: this.challenge.id,
            challenge_title: this.challenge.title,
            guide_title: this.guide.title,
            swipe: swipe,
            tip_id: tips_virtual[newIndex].id,
            tip_title: tips_virtual[newIndex].title,
            skill_title: this.challenge.cbt_skill ? this.challenge.cbt_skill.title : null,
          });

        }
      },
    };

    const slider_settings_irl = {
      dots: true,
      infinite: false,
      centerMode: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      centerPadding: "20px",
      focusOnSelect: true,
      adaptiveHeight: true,
      beforeChange: (oldIndex, newIndex) => {
        if(newIndex !== oldIndex){

          const swipe = newIndex > oldIndex ? 'left' : 'right';

          this.setState({
            tips_position_irl: newIndex,
            tips_swipe_irl: swipe
          });

          //KeenIO Event
          this.context.sendKeenEvent('tips_accordion', {
            accordion_action:'swipe',
            accordion_title:'irl',
            accordion_position: newIndex,
            challenge_id: this.challenge.id,
            challenge_title: this.challenge.title,
            guide_title: this.guide.title,
            swipe: swipe,
            tip_id: tips_irl[newIndex].id,
            tip_title: tips_irl[newIndex].title,
            skill_title: this.challenge.cbt_skill ? this.challenge.cbt_skill.title : null,
          });

          //MixPanel Event
          this.context.sendMixPanelEvent('tips_accordion', {
            accordion_action:'swipe',
            accordion_title:'irl',
            accordion_position: newIndex,
            challenge_id: this.challenge.id,
            challenge_title: this.challenge.title,
            guide_title: this.guide.title,
            swipe: swipe,
            tip_id: tips_irl[newIndex].id,
            tip_title: tips_irl[newIndex].title,
            skill_title: this.challenge.cbt_skill ? this.challenge.cbt_skill.title : null,
          });

        }
      },
    };

    return(
      <div className="tips-wrap">
        <div className="squiggle">
          <svg viewBox="0 0 1000 31.39">
            <path className="pathItem" d="M0,0V29.86c21.61,2.24,42.07-5.11,53.07-6.72,36.48-5.34,61-3.46,98.63,3.15S206.18,27.62,253,11.53s63.77,6.58,91.48,13.9,43.63-6.72,66-1.95C425.35,26.63,452,30.39,471.75,25c7.09-1.93,56.62-5,67.8-5.67C588,16.39,589,36.39,651.56,30.2,680.08,27.37,710,15.39,758,23.3c48.81,8,54.62,2.37,92.26-4.25s60.15-1.49,96.63,3.85c11,1.61,31.46,9,53.07,6.72V0Z"/>
          </svg>
        </div>
        <h3>How?</h3>
        <div className="tip-description">Socialize safely! Follow the COVID-19 guidelines for your region.</div>
        <Accordion onChange={handleAccordionChange} allowZeroExpanded={true}>

          {tips_virtual.length > 0 ?
            <AccordionItem uuid="virtual">
              <AccordionItemHeading>
                <AccordionItemButton>
                  <img src={virtual_img} alt="Virtual Tips" />
                  Virtual Tips
                </AccordionItemButton>
                </AccordionItemHeading>
              <AccordionItemPanel>
                <SlickSlider {...slider_settings_virtual}>
                  {
                    tips_virtual.map((tip, index) => {
                      return <div key={'tip-virtual-'+index}>
                        <p className="tip-title">{tip.title}</p>
                        <div dangerouslySetInnerHTML={{__html:tip.body}}></div>
                      </div>
                    })
                  }
                </SlickSlider>
                <br/><br/>
              </AccordionItemPanel>
            </AccordionItem>
          :null
          }

          {tips_irl.length > 0 ?
            <AccordionItem uuid="irl">
              <AccordionItemHeading>
                <AccordionItemButton>
                  <img src={irl_img} alt="IRL Tips"/>
                  IRL Tips
                </AccordionItemButton>
                </AccordionItemHeading>
              <AccordionItemPanel>
                <SlickSlider {...slider_settings_irl}>
                  {
                    tips_irl.map((tip, index) => {
                      return <div key={'tip-irl-'+index}>
                        <p className="tip-title">{tip.title}</p>
                        <div dangerouslySetInnerHTML={{__html:tip.body}}></div>
                      </div>
                    })
                  }
                </SlickSlider>
                <br/><br/>
              </AccordionItemPanel>
            </AccordionItem>
          :null
          }

        </Accordion>
      </div>
    );
  }

  _getChallengeSlideInactive(){

    const handleHome = () => {
      this.context.sendKeenEvent('challenge', {
        guide_title: this.guide.title,
        challenge_id: this.challenge.id,
        challenge_title: this.challenge.title,
        status: 'inactive',
        rating: null,
        approach: null,
        skill_title: this.challenge.cbt_skill ? this.challenge.cbt_skill.title : null,
        prior_path: this.context.last_last_path,
        back:false,
        home:true,
        push_notification_title: this.context.push_notification_title,
        exit_app: false
      });

      this.context.sendMixPanelEvent('challenge', {
        guide_title: this.guide.title,
        challenge_id: this.challenge.id,
        challenge_title: this.challenge.title,
        status: 'inactive',
        rating: null,
        approach: null,
        skill_title: this.challenge.cbt_skill ? this.challenge.cbt_skill.title : null,
        prior_path: this.context.last_last_path,
        back:false,
        home:true,
        push_notification_title: this.context.push_notification_title,
        exit_app: false
      });
    }

    const handleBack = () => {

      this.context.sendKeenEvent('challenge', {
        guide_title: this.guide.title,
        challenge_id: this.challenge.id,
        challenge_title: this.challenge.title,
        status: 'inactive',
        rating: null,
        approach: null,
        skill_title: this.challenge.cbt_skill ? this.challenge.cbt_skill.title : null,
        prior_path: this.context.last_last_path,
        back:true,
        home:false,
        push_notification_title: this.context.push_notification_title,
        exit_app: false
      });

      this.context.sendMixPanelEvent('challenge', {
        guide_title: this.guide.title,
        challenge_id: this.challenge.id,
        challenge_title: this.challenge.title,
        status: 'inactive',
        rating: null,
        approach: null,
        skill_title: this.challenge.cbt_skill ? this.challenge.cbt_skill.title : null,
        prior_path: this.context.last_last_path,
        back:true,
        home:false,
        push_notification_title: this.context.push_notification_title,
        exit_app: false
      });

      setTimeout(() => this.props.history.goBack(), 100);
    }

    const handleSubmit = () => {
      if(!this.state.accept_button_disabled){
        this.setState({accept_button_disabled: true});

        //if(this.context.getNumberOfActiveUserChallenges() === 3){
        //  this.footerModal.current.show();
        //}else{
          this.context.acceptChallenge(this.challenge.id).then(() => {

            //Set featured challenge history back so it doesn't try and go to this card that's gone now.
            // let featured_challenge_history_index = window.localStorage.getItem('@nod:featuredChallengeHistoryIndex');
            // featured_challenge_history_index = parseInt(featured_challenge_history_index) - 3;
            // if(featured_challenge_history_index <= 0) featured_challenge_history_index = 0;
            // window.localStorage.setItem('@nod:featuredChallengeHistoryIndex', featured_challenge_history_index);

            this.setState({accept_button_disabled: false});
            this.footerModal.current.show();
            this.context.sortFeaturedChallenges();
          });
        //}

      }
    }

    const handleSetCheckIn = () => {
      this.footerModal.current.hide();

      //Check if notifications are enabled
      this.context.areOsNotificationsEnabled().then(granted => {
        if(granted){
          this.props.history.push('/challenges/'+this.challenge.id+'/date');
        }else{
          //const notificationAskSkips = parseInt(this.context.getNotificationSetting('notificationAskSkips'));
          //if(notificationAskSkips < 3){
            this.props.history.push('/challenges/'+this.challenge.id+'/notify');
          //}else{
          //  this.props.history.push('/challenges/'+this.challenge.id+'/active');
          //}
        }
      });

      //KeenIO Event
      this.context.sendKeenEvent('set_reminder', {
        accept_check_in: true,
        challenge_id: this.challenge.id,
        challenge_title: this.challenge.title,
        guide_title: this.guide.title
      });

      //MixPanel Event
      this.context.sendMixPanelEvent('set_reminder', {
        accept_check_in: true,
        challenge_id: this.challenge.id,
        challenge_title: this.challenge.title,
        guide_title: this.guide.title
      });
    }

    const handleSkip = () => {
      this.footerModal.current.hide();
      this.props.history.push('/');

      //KeenIO Event
      this.context.sendKeenEvent('set_reminder', {
        accept_check_in: false,
        challenge_id: this.challenge.id,
        challenge_title: this.challenge.title,
        guide_title: this.guide.title
      });

      //MixPanel Event
      this.context.sendMixPanelEvent('set_reminder', {
        accept_check_in: false,
        challenge_id: this.challenge.id,
        challenge_title: this.challenge.title,
        guide_title: this.guide.title
      });
    }

    const handleModalClose = () => {
      this.footerModal.current.hide();
      this.user_challenge = true;
      this.forceUpdate();

      //KeenIO Event
      this.context.sendKeenEvent('set_reminder', {
        accept_check_in: false,
        challenge_id: this.challenge.id,
        challenge_title: this.challenge.title,
        guide_title: this.guide.title
      });

      //MixPanel Event
      this.context.sendMixPanelEvent('set_reminder', {
        accept_check_in: false,
        challenge_id: this.challenge.id,
        challenge_title: this.challenge.title,
        guide_title: this.guide.title
      });
    }

    return(
      <React.Fragment>
        {this._getChallengeHeader('inactive')}
        {this._getChallengeDescription()}
        {this._getChallengeWhy()}
        {this._getChallengeHow()}
        <FooterModal ref={this.footerModal} visible={false} onClose={handleModalClose} parentId="challenge">
          <div className="status">
            <div className={this.challenge.theme_name + " iconWrap"}>
              <i className="icon white banner"/>
            </div>
            Saved
          </div>
          <h3>Nice! Let's make a plan.</h3>
          <p>Life gets busy. Keep yourself on track by planning a check in.</p>
          <button className="button" onClick={handleSetCheckIn}>Set a check in</button>
          <div className="textLink">
            <a className="skip" href="/" onClick={handleSkip} aria-label="skip">Skip</a><br /><br />
          </div>
        </FooterModal>
        {/*this._getTestimonial()*/}
        {/*this._getShareThisChallenge('Share this idea', false)*/}
        {/*<FooterModal ref={this.footerModal} visible={false}>
          <h3>Heads Up</h3>
          <p>You can only have 3 ideas in progress at a time. Try them out or cancel them to add more.</p>
        </FooterModal>*/}
        <FooterUpdator
          showHome={true}
          homeHandler={handleHome}
          backHandler={handleBack}
          nextText="Save"
          nextHandler={handleSubmit} />
      </React.Fragment>
    );
  }

  _getChallengeSlideActive() {

    const handleHome = () => {

      this.context.sendKeenEvent('challenge', {
        guide_title: this.guide.title,
        challenge_id: this.challenge.id,
        challenge_title: this.challenge.title,
        status: 'active',
        rating: null,
        approach: null,
        skill_title: this.challenge.cbt_skill ? this.challenge.cbt_skill.title : null,
        prior_path: this.context.last_last_path,
        back:false,
        home:true,
        push_notification_title: this.context.push_notification_title,
        exit_app: false
      });

      this.context.sendMixPanelEvent('challenge', {
        guide_title: this.guide.title,
        challenge_id: this.challenge.id,
        challenge_title: this.challenge.title,
        status: 'active',
        rating: null,
        approach: null,
        skill_title: this.challenge.cbt_skill ? this.challenge.cbt_skill.title : null,
        prior_path: this.context.last_last_path,
        back:false,
        home:true,
        push_notification_title: this.context.push_notification_title,
        exit_app: false
      });

      setTimeout(() => this.props.history.push('/'), 100);
    }

    const handleBack = () => {
      if(this.context.came_from_push_notification) this.context.setState({came_from_push_notification:false});
      this.context.sendKeenEvent('challenge', {
        guide_title: this.guide.title,
        challenge_id: this.challenge.id,
        challenge_title: this.challenge.title,
        status: 'active',
        rating: null,
        approach: null,
        skill_title: this.challenge.cbt_skill ? this.challenge.cbt_skill.title : null,
        prior_path: this.context.last_last_path,
        back:true,
        home:false,
        push_notification_title: this.context.push_notification_title,
        exit_app: false
      });

      this.context.sendMixPanelEvent('challenge', {
        guide_title: this.guide.title,
        challenge_id: this.challenge.id,
        challenge_title: this.challenge.title,
        status: 'active',
        rating: null,
        approach: null,
        skill_title: this.challenge.cbt_skill ? this.challenge.cbt_skill.title : null,
        prior_path: this.context.last_last_path,
        back:true,
        home:false,
        push_notification_title: this.context.push_notification_title,
        exit_app: false
      });

      setTimeout(() => this.props.history.goBack(), 100);
    }

    const handleSkip = (e) => {
      e.preventDefault();
      this.footerModal.current.show();
    }

    const handleSubmit = () => {
      this.footerModalComplete.current.show();
    }

    const handleCancel = () => {
      this.footerModal.current.hide();
      this.context.cancelChallengeNotification(this.challenge.id);
      if(this.context.came_from_push_notification) this.context.setState({came_from_push_notification:false});
      this.context.cancelUserChallenge(this.challenge.id).then(() => {
        this.props.history.push('/');
      });
    }

    const handleChangeTimeline = (e) => {
      e.preventDefault();
      this.props.history.push('/challenges/'+this.challenge.id+'/date');
    }

    // const extendTime = () => {
    //
    //   if(this.state.notification !== null){
    //     const extendChallenge = (e) => {
    //       e.preventDefault();
    //
    //       //SEND TO TIMELINE PAGE INSTEAD OF EXTENDING
    //       //Check if notifications are enabled
    //       this.context.areOsNotificationsEnabled().then(granted => {
    //         if(granted){
    //           this.props.history.push('/challenges/'+this.challenge.id+'/date');
    //         }else{
    //           this.props.history.push('/challenges/'+this.challenge.id+'/active');
    //         }
    //       });
    //
    //       //User Created Notification Creation
    //       // const user_pref_time = this.context.getNotificationSetting('notificationTime').split(':');
    //       // let time = new Date();
    //       // time.setHours(parseInt(user_pref_time[0]), parseInt(user_pref_time[1]));
    //       // time = time.getTime() + (3 * 24 * 60 * 60 * 1000);
    //       // const notificationOptions = {
    //       //   title: 'How’s it going?',
    //       //   text: 'You were planning to ' + this.challenge.title.toLowerCase(),
    //       //   trigger: {at: time}
    //       // }
    //       // this.context.createChallengeNotification(this.challenge.id, notificationOptions).then(() => {
    //       //   this.setState({notification: null});
    //       // });
    //       //
    //       // if(this.context.came_from_push_notification){
    //       //
    //       //   //KeenIO Event
    //       //   this.context.sendKeenEvent('notification', {
    //       //     page_title: 'challenge',
    //       //     skipped: false,
    //       //     os_declined: false,
    //       //     snoozed: true,
    //       //     reminder_selection: null,
    //       //     notification_title: null,
    //       //     marked_complete: false,
    //       //     push_notification: true
    //       //   });
    //       //
    //       //   this.context.setState({came_from_push_notification:false});
    //       // }
    //
    //     };
    //
    //     return(
    //       <div className="extend-time shareWrap">
    //         <div className="squiggle">
    //           <svg viewBox="0 0 1000 31.39">
    //             <path className="pathItem" d="M0,0V29.86c21.61,2.24,42.07-5.11,53.07-6.72,36.48-5.34,61-3.46,98.63,3.15S206.18,27.62,253,11.53s63.77,6.58,91.48,13.9,43.63-6.72,66-1.95C425.35,26.63,452,30.39,471.75,25c7.09-1.93,56.62-5,67.8-5.67C588,16.39,589,36.39,651.56,30.2,680.08,27.37,710,15.39,758,23.3c48.81,8,54.62,2.37,92.26-4.25s60.15-1.49,96.63,3.85c11,1.61,31.46,9,53.07,6.72V0Z"/>
    //           </svg>
    //         </div>
    //         <h2>Not finished yet?</h2>
    //         <p>Extend the idea deadline below,<br />or cancel the idea.</p>
    //       </div>
    //     );
    //   }else{
    //     return null;
    //   }
    // }

    const handleComplete = (navToHome = false) => {
      this.context.updateUserChallenge(
        this.challenge.id, {
          rating:this.state.feedback_choice,
          approach: approaches[this.state.approach_choice],
          completed:true
      }).then(() => {
        this.context.cancelChallengeNotification(this.challenge.id);
        this.setState({check_it_off_screen: 0});
        this.context.setState({user_challenge_just_completed: true}).then(() =>{
          this.footerModalComplete.current.hide();
          if(navToHome){
            this.props.history.push('/');
          }else{
            this.props.history.push('/reflect/mood');
          }
        });
      });
    }

    const handleCheckIn = () => {
      this.setState({check_it_off_screen: 1});
    }

    const handleSkipCheckIn = (e) => {
      e.preventDefault();
      handleComplete(true);
    }

    const handleRatingChange = (which) => {
      this.setState({feedback_choice:which, check_it_off_screen: 2});
    }

    const handleApproachChange = (which) => {
      this.setState({approach_choice: which});
      setTimeout(() => handleComplete(), 100);
    }

    const waytogo_image_choice = Math.floor(Math.random() * 4);
    const waytogo_image = waytogo_images[waytogo_image_choice];

    return(
      <React.Fragment>
        <div className="active-challenge-header">
          <div className="challengeState">Saved</div>
          <div className="change-wrap">
            <a className="change" href="/" onClick={handleChangeTimeline}>Change my timeline</a>
          </div>

          {/*<button className="button submit" onClick={handleSubmit}>I tried this idea</button>
          <a className="cancel" href="/" onClick={handleSkip}>Cancel for now</a>*/}
          {/*<div className="headerBody">
            <div className="challengeImg animated">
              <img src={this.challenge.image_url} alt="challenge"  />
            </div>
            <div className="description">
              <h1>{this.challenge.title}</h1>
              <div dangerouslySetInnerHTML={{__html: this.challenge.main_content}}></div>
            </div>
          </div>*/}
          {/*extendTime()*/}
        </div>
        {this._getChallengeHeader()}
        {this._getChallengeDescription()}
        {this._getChallengeWhy()}
        {this._getChallengeHow()}
        <FooterModal ref={this.footerModalComplete} visible={this.context.came_from_push_notification_yes_action} ignoreInit={true} parentId="challenge">

          {this.state.check_it_off_screen === 0 ?
            <React.Fragment>
              <img src={waytogo_image} alt=""/>
              <h3>Way to go!</h3>
              <p>You’re making progress and building skills.</p>
              {this._getTags()}
              <button className="button" onClick={handleCheckIn}>Check In</button>
              <div className="textLink">
                <a className="skip" role="button" href="/" onClick={handleSkipCheckIn} aria-label="skip and return home">Skip Check In</a>
              </div>
              <br /><br />
            </React.Fragment>
          :null
          }

          {this.state.check_it_off_screen === 1 ?
            <div className="feedback">
              <h3 tabIndex="0">Did you like this idea?</h3>
              <div className="feedback-buttons">
                <a href="/" onClick={(e)=> {e.preventDefault(); handleRatingChange(3);}} className={'love' + (this.state.feedback_choice === 3 ? ' active' : '')} aria-label="love">&nbsp;</a>
                <a href="/" onClick={(e)=> {e.preventDefault(); handleRatingChange(2);}} className={'like' + (this.state.feedback_choice === 2 ? ' active' : '')} aria-label="like">&nbsp;</a>
                <a href="/" onClick={(e)=> {e.preventDefault(); handleRatingChange(1);}} className={'meh' + (this.state.feedback_choice === 1 ? ' active' : '')} aria-label="meh">&nbsp;</a>
                <a href="/" onClick={(e)=> {e.preventDefault(); handleRatingChange(0);}} className={'dislike' + (this.state.feedback_choice === 0 ? ' active' : '')} aria-label="dislike">&nbsp;</a>
              </div>
            </div>
          :null
          }

          {this.state.check_it_off_screen === 2 ?
            <div className="feedback">
              <h3 tabIndex="0">Which way did you try it?</h3>
              <div className="feedback-buttons which">
                <a href="/" onClick={(e)=> {e.preventDefault(); handleApproachChange(0);}} className={'virtual ' + approaches[0] + (this.state.approach_choice === 0 ? ' active' : '')} aria-label="virtual"><img alt="" src={virtual_img}/></a>
                <a href="/" onClick={(e)=> {e.preventDefault(); handleApproachChange(1);}} className={'in-real-life ' + approaches[1] + (this.state.approach_choice === 1 ? ' active' : '')} aria-label="in real life"><img alt="" src={irl_img}/></a>
                <a href="/" onClick={(e)=> {e.preventDefault(); handleApproachChange(2);}} className={'both ' + approaches[2] + (this.state.approach_choice === 2 ? ' active' : '')} aria-label="both"><img alt="" src={both_img}/></a>
              </div>
            </div>
          :null
          }

        </FooterModal>
        <FooterModal ref={this.footerModal} visible={false} parentId="challenge">
          <h4>Change your mind?</h4>
          <p>You sure you want to remove this idea from your list?</p>
          <button className="button" onClick={() => handleCancel()}>Yes, remove the idea</button>
        </FooterModal>
        <FooterUpdator
          showHome={true}
          homeHandler={handleHome}
          backHandler={handleBack}
          skipHandler={handleSkip}
          skipText="Cancel"
          nextText="Check It Off"
          nextHandler={handleSubmit}/>
      </React.Fragment>
    );
  }

  _getChallengeSlideCompleted() {

    const handleHome = () => {

      this.context.sendKeenEvent('challenge', {
        guide_title: this.guide.title,
        challenge_id: this.challenge.id,
        challenge_title: this.challenge.title,
        status: 'completed',
        rating: null,
        approach: null,
        skill_title: this.challenge.cbt_skill ? this.challenge.cbt_skill.title : null,
        prior_path: this.context.last_last_path,
        back:false,
        home:true,
        push_notification_title: this.context.push_notification_title,
        exit_app: false
      });

      this.context.sendMixPanelEvent('challenge', {
        guide_title: this.guide.title,
        challenge_id: this.challenge.id,
        challenge_title: this.challenge.title,
        status: 'completed',
        rating: null,
        approach: null,
        skill_title: this.challenge.cbt_skill ? this.challenge.cbt_skill.title : null,
        prior_path: this.context.last_last_path,
        back:false,
        home:true,
        push_notification_title: this.context.push_notification_title,
        exit_app: false
      });
    }

    const handleBack = () => {

      this.context.sendKeenEvent('challenge', {
        guide_title: this.guide.title,
        challenge_id: this.challenge.id,
        challenge_title: this.challenge.title,
        status: 'completed',
        rating: null,
        approach: null,
        skill_title: this.challenge.cbt_skill ? this.challenge.cbt_skill.title : null,
        prior_path: this.context.last_last_path,
        back:true,
        home:false,
        push_notification_title: this.context.push_notification_title,
        exit_app: false
      });

      this.context.sendMixPanelEvent('challenge', {
        guide_title: this.guide.title,
        challenge_id: this.challenge.id,
        challenge_title: this.challenge.title,
        status: 'completed',
        rating: null,
        approach: null,
        skill_title: this.challenge.cbt_skill ? this.challenge.cbt_skill.title : null,
        prior_path: this.context.last_last_path,
        back:true,
        home:false,
        push_notification_title: this.context.push_notification_title,
        exit_app: false
      });

      setTimeout(() => this.props.history.goBack(), 100);
    }

    const handleSubmit = () => {
      this.setState({accept_button_disabled: true});
      // if(this.context.getNumberOfActiveUserChallenges() === 3){
      //   this.footerModal.current.show();
      // }else{
        this.context.updateUserChallenge(this.challenge.id, {completed: false}).then(() => {
          this.context.sortFeaturedChallenges();
          this.context.setState({user_challenge_just_completed:false}).then(() =>{

            this.context.sendKeenEvent('challenge', {
              guide_title: this.guide.title,
              challenge_id: this.challenge.id,
              challenge_title: this.challenge.title,
              status: 'accepted',
              rating: null,
              approach: null,
              skill_title: this.challenge.cbt_skill ? this.challenge.cbt_skill.title : null,
              prior_path: this.context.last_last_path,
              back:false,
              home:true,
              push_notification_title: this.context.push_notification_title,
              exit_app: false
            });

            this.context.sendMixPanelEvent('challenge', {
              guide_title: this.guide.title,
              challenge_id: this.challenge.id,
              challenge_title: this.challenge.title,
              status: 'accepted',
              rating: null,
              approach: null,
              skill_title: this.challenge.cbt_skill ? this.challenge.cbt_skill.title : null,
              prior_path: this.context.last_last_path,
              back:false,
              home:true,
              push_notification_title: this.context.push_notification_title,
              exit_app: false
            });

            setTimeout(() => this.props.history.push('/challenges/'+this.challenge.id+'/date'), 100);
          });
        });
      //}
    }

    //let students = null;
    //if(this.challenge.participants >= 100) students = <div className="students">{this.challenge.participants} students</div>;

    return(
      <React.Fragment>
        <div className="active-challenge-header">
          <div className="challengeState">You tried this!</div>
        </div>
        {this._getChallengeHeader()}
        {this._getChallengeDescription()}
        {this._getTags()}
        {/*this._getShareThisChallenge('Share this idea',  true)*/}
        {this._getMoodTracker()}
        {this._getRatingComplete()}
        <FooterModal ref={this.footerModal} visible={false} parentId="challenge">
          <h3>Heads Up</h3>
          <p>You can only have 3 ideas in progress at a time. Try them out or cancel them to add more.</p>
        </FooterModal>
        { !this.challenge.hidden ?
          <FooterUpdator
            showHome={true}
            homeHandler={handleHome}
            backHandler={handleBack}
            nextText="Save Again"
            nextHandler={handleSubmit}/>
          :
          <FooterUpdator
            showHome={true}
            homeHandler={handleHome}
            backHandler={handleBack} />
        }
      </React.Fragment>
    );
  }

  //////////////////////////////////////////////////////////////////////////////
  //Screens/////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////

  _getChallengeSlide() {
    return(
      <div id="challenge" className={this.challenge.theme_name}>
        <Helmet>
          <title>Nod - Challenge - {this.challenge.title}</title>
        </Helmet>
        <PageHeader bgColor={this.challenge.theme_name} />
        { !this.context.user_logged_in ?
            this._getChallengeSlideAnonymous()
          :
            <React.Fragment>
              { this.user_challenge ?
                  <React.Fragment>
                    { this.user_challenge.completed ?
                        <React.Fragment>{this._getChallengeSlideCompleted()}</React.Fragment>
                      :
                        <React.Fragment>{this._getChallengeSlideActive()}</React.Fragment>
                    }
                  </React.Fragment>
                :
                  <React.Fragment>{this._getChallengeSlideInactive()}</React.Fragment>
              }
            </React.Fragment>
        }

      </div>
    );
  }

  _getChallengeDateSlide() {

    const DATE_REMINDER_CHOISE_TODAY = 'I\'m doing it today!';
    const DATE_REMINDER_CHOISE_THREEDAYS = 'In the next 3 days';
    const DATE_REMINDER_CHOISE_FIVEDAYS = 'In the next 5 days';
    const DATE_REMINDER_CHOISE_WEEK = 'In the next week';

    const handleSubmit = (timeframe) => {

      let time = new Date();
      const user_pref_time = this.context.getNotificationSetting('notificationTime').split(':');
      time.setHours(parseInt(user_pref_time[0]), parseInt(user_pref_time[1]));
      switch(timeframe){
        default:
        case DATE_REMINDER_CHOISE_TODAY:
          if(time.getTime() <= new Date().getTime()){
            time.setDate(time.getDate() + 1);
          }else{
            time.setDate(time.getDate());
          }
          break;
        case DATE_REMINDER_CHOISE_THREEDAYS:
          time.setDate(time.getDate() + 3);
          break;
        case DATE_REMINDER_CHOISE_FIVEDAYS:
          time.setDate(time.getDate() + 5);
          break;
        case DATE_REMINDER_CHOISE_WEEK:
          time.setDate(time.getDate() + 7);
          break;
      }

      // REMOVE SINCE WE MOVED THIS SCREEN AFTER DATE SCREEN
      // this.context.setState({
      //   challenge_date: timeframe,
      //   challenge_date_value: time.getTime(),
      //   challenge_date_skipped: false
      // }).then(()=>{
      //   this.props.history.push('/challenges/'+this.challenge.id+'/notify');
      // });

      //User Created Notification Creation
      const notificationOptions = {
        title: 'How’s it going?',
        text: 'You were planning to ' + this.challenge.title.toLowerCase(),
        trigger: { at: process.env.REACT_APP_DEBUG === 'true' ? new Date().getTime() + 10000 : time.getTime() },
      }

      //Create the notification
      this.context.createChallengeNotification(this.challenge.id, notificationOptions).then(() => {
        this.props.history.push('/challenges/'+this.challenge.id+'/active');
      }).catch((err) => {
        this.props.history.push('/challenges/'+this.challenge.id+'/active');
      });
      //this.context.setNotificationSetting('notificationLastAsked', new Date().getTime());

      //KeenIO Event
      this.context.sendKeenEvent('notification', {
        page_title: 'challenge',
        skipped: false,
        os_declined: false,
        snoozed: false,
        reminder_selection: timeframe,
        notification_title: null,
        marked_complete: false,
        push_notification: false,
        notification_action_button_clicked: null,
        code_location: 'Challenge.jsx',
        push_notification_title: null,
      });

      //MixPanel Event
      this.context.sendMixPanelEvent('notification', {
        page_title: 'challenge',
        skipped: false,
        os_declined: false,
        snoozed: false,
        reminder_selection: timeframe,
        notification_title: null,
        marked_complete: false,
        push_notification: false,
        notification_action_button_clicked: null,
        code_location: 'Challenge.jsx',
        push_notification_title: null,
      });

    }

    const handleSkip = () => {
      //this.context.setState({challenge_date_skipped: true}).then(() => {
        //this.props.history.push('/challenges/'+this.challenge.id+'/notify');

        //HIDING FOR Nod19
        //Automated Challenge Due Notification Creation
        // const user_pref_time = this.context.getNotificationSetting('notificationTime').split(':');
        // let time = new Date();
        // time.setHours(parseInt(user_pref_time[0]), parseInt(user_pref_time[1]));
        // time = time.getTime() + (3 * 24 * 60 * 60 * 1000);
        // const notificationOptions = {
        //   title: 'How’s your idea going?',
        //   text: this.challenge.title,
        //   trigger: { at: time },
        // }

        //Create the notification - MOVED FROM NOTIFY SCREEN
        // this.context.createChallengeNotification(this.challenge.id, notificationOptions).then(() => {
        //   this.props.history.push('/challenges/'+this.challenge.id+'/active');
        // }).catch((err) => {
          this.props.history.push('/challenges/'+this.challenge.id+'/active');
        //});
        //this.context.setNotificationSetting('notificationLastAsked', new Date().getTime());

      //});
    }

    return(
      <div id="challenge">
        <PageHeader bgColor={this.challenge.theme_name} bgHeaderColor="tan">
          <h1>What’s your timeline?</h1>
          <p>We’ll send a tip and remind you to check off your progress and debrief once you’re done.</p>
        </PageHeader>
        {/*<h2>Pick a Date</h2>
        <div className="dateWrap">
          <input type="date" value={this.state.challenge_date_value} min={moment.utc().add(1, 'days').format('YYYY-MM-DD')} onChange={updateDate} />
        </div>
        <button className="button" onClick={handleSubmit} disabled={ this.state.date_button_disabled ? 'disabled' : false}>Confirm</button>
        <div className="textLink">
          <Link to={'/challenges/'+this.challenge_id+'/notify'}>Skip</Link>
        </div>*/}
        <ul className="multipleChoice-Wrap">
          <li><button className="multipleChoice" onClick={() => handleSubmit(DATE_REMINDER_CHOISE_TODAY)}>{DATE_REMINDER_CHOISE_TODAY}</button></li>
          <li><button className="multipleChoice" onClick={() => handleSubmit(DATE_REMINDER_CHOISE_THREEDAYS)}>{DATE_REMINDER_CHOISE_THREEDAYS}</button></li>
          <li><button className="multipleChoice" onClick={() => handleSubmit(DATE_REMINDER_CHOISE_FIVEDAYS)}>{DATE_REMINDER_CHOISE_FIVEDAYS}</button></li>
          <li><button className="multipleChoice" onClick={() => handleSubmit(DATE_REMINDER_CHOISE_WEEK)}>{DATE_REMINDER_CHOISE_WEEK}</button></li>
        </ul>
        <FooterUpdator
          showHome={true}
          backHandler={() => this.props.history.goBack() }
          skipHandler={handleSkip}
          nextText={null}
          nextHandler={null}/>
      </div>
    );
  }

  _getChallengeNotifySlide() {

    const handleSubmit = () => {
      this.context.areOsNotificationsEnabled().then(granted => {
        if(granted){
          this.props.history.push('/challenges/'+this.challenge.id+'/date');

          //KeenIO Event
          this.context.sendKeenEvent('notification', {
            page_title: 'challenge',
            skipped: false,
            os_declined: false,
            snoozed: false,
            reminder_selection: null,
            notification_title: null,
            marked_complete: false,
            push_notification: false,
            notification_action_button_clicked: null,
            code_location: 'Challenge.jsx',
            push_notification_title: null,
          });

          //MixPanel Event
          this.context.sendMixPanelEvent('notification', {
            page_title: 'challenge',
            skipped: false,
            os_declined: false,
            snoozed: false,
            reminder_selection: null,
            notification_title: null,
            marked_complete: false,
            push_notification: false,
            notification_action_button_clicked: null,
            code_location: 'Challenge.jsx',
            push_notification_title: null,
          });

        }else{
          this.context.requestEnableOsNotifications().then(granted => {
            if(granted){
              this.props.history.push('/challenges/'+this.challenge.id+'/date');

              //KeenIO Event
              this.context.sendKeenEvent('notification', {
                page_title: 'challenge',
                skipped: false,
                os_declined: false,
                snoozed: false,
                reminder_selection: null,
                notification_title: null,
                marked_complete: false,
                push_notification: false,
                notification_action_button_clicked: null,
                code_location: 'Challenge.jsx',
                push_notification_title: null,
              });

              //MixPanel Event
              this.context.sendMixPanelEvent('notification', {
                page_title: 'challenge',
                skipped: false,
                os_declined: false,
                snoozed: false,
                reminder_selection: null,
                notification_title: null,
                marked_complete: false,
                push_notification: false,
                notification_action_button_clicked: null,
                code_location: 'Challenge.jsx',
                push_notification_title: null,
              });

            }else{
              this.footerModal.current.show();

              //KeenIO Event - Not moved to date screen because it's the os_declined=true event
              this.context.sendKeenEvent('notification', {
                page_title: 'challenge',
                skipped: false,
                os_declined: true,
                snoozed: false,
                reminder_selection: null,
                notification_title: null,
                marked_complete: false,
                push_notification: false,
                notification_action_button_clicked: null,
                code_location: 'Challenge.jsx',
                push_notification_title: null,
              });

              //MixPanel Event - Not moved to date screen because it's the os_declined=true event
              this.context.sendMixPanelEvent('notification', {
                page_title: 'challenge',
                skipped: false,
                os_declined: true,
                snoozed: false,
                reminder_selection: null,
                notification_title: null,
                marked_complete: false,
                push_notification: false,
                notification_action_button_clicked: null,
                code_location: 'Challenge.jsx',
                push_notification_title: null,
              });
            }
          });
        }
      });
    }

    const handleSkip = () => {
      const notificationAskSkips = parseInt(this.context.getNotificationSetting('notificationAskSkips'));
      this.context.setNotificationSetting('notificationAskSkips', notificationAskSkips+1);
      this.props.history.push('/challenges/'+this.challenge.id+'/active');

      //KeenIO Event
      this.context.sendKeenEvent('notification', {
        page_title: 'challenge',
        skipped: true,
        os_declined: false,
        snoozed: false,
        reminder_selection: null,
        notification_title: null,
        marked_complete: false,
        push_notification: false,
        notification_action_button_clicked: null,
        code_location: 'Challenge.jsx',
        push_notification_title: null,
      });

      //MixPanel Event
      this.context.sendMixPanelEvent('notification', {
        page_title: 'challenge',
        skipped: true,
        os_declined: false,
        snoozed: false,
        reminder_selection: null,
        notification_title: null,
        marked_complete: false,
        push_notification: false,
        notification_action_button_clicked: null,
        code_location: 'Challenge.jsx',
        push_notification_title: null,
      });
    }

    return(
      <div id="challenge">
        <PageHeader bgColor={this.challenge.theme_name} bgHeaderColor="tan">
          { this.context.challenge_date_skipped ?
            <React.Fragment>
              <p>In order for us to check in...</p>
              <h1>Will you allow notifications?</h1>
            </React.Fragment>
          :
            <React.Fragment>
              <p>In order for us to check in...</p>
              <h1>Will you allow notifications?</h1>
            </React.Fragment>
          }
        </PageHeader>
        <img className="notification-image" alt="" src={wild_thing_mole_img} />
        { this.context.challenge_date_skipped ? <p>Get the occasional reminder to reflect, plus suggestions for new ideas. No spam, just useful stuff.</p> : null }
        {/*<button className="button" onClick={handleSubmit} disabled={ this.state.notify_button_disabled ? 'disabled' : false}>Allow check-ins</button>
        <div className="textLink">
          <Link to={'/challenges/'+this.challenge_id+'/active'}>No Thanks</Link>
        </div>*/}
        <FooterModal ref={this.footerModal} visible={false} parentId="challenge">
          <h3>First, allow Nod to send reminders</h3>
          <p>Open Settings on your phone to allow Nod to send you notifications.</p>
        </FooterModal>
        <FooterUpdator
          showHome={true}
          backHandler={() => this.props.history.goBack() }
          skipHandler={handleSkip}
          nextText="Allow check-ins"
          nextHandler={handleSubmit}/>
      </div>
    );
  }

  _getChallengeActivatedSlide() {
    return(
      <div id="challenge">
        <PageHeader bgColor={this.challenge.theme_name} bgHeaderColor="tan">
          <h1>Let's do this!</h1>
          <p>Sometimes doubt creeps in and that's normal. Don't let it stop you trying.</p>
        </PageHeader>
        <div className={'challengeImg' + (this.animated_gifs ? ' animated' : '')}>
          <img src={this.challenge.image_url} alt=""  />
        </div>
        <FooterUpdator
          showHome={true}
          backHandler={() => this.props.history.goBack() }
          nextText="Back to Idea"
          nextHandler={() => this.props.history.push('/challenges/'+this.challenge.id)}/>
      </div>
    );
  }

  _getChallengeShareSlide() {
    return(
      <div id="challenge">
        <PageHeader bgColor={this.challenge.theme_name} bgHeaderColor="tan">
          <h1>*Headline specific to the idea completed*</h1>
          <p>Try one of the ideas below and get back into the game</p>
        </PageHeader>
        {this._getShareThisChallenge('Share the idea you just finished with a friend')}
        <FeaturedChallenges />
        <FooterUpdator
          showHome={true}
          backHandler={() => this.props.history.goBack() }
          nextText="Go to Dashboard"
          nextHandler={() => this.props.history.push('/')}/>
      </div>
    );
  }

  _getSlide() {
    if(this.props.match.params.screen === undefined){
      return this._getChallengeSlide();
    }else{
      switch(this.props.match.params.screen){
        case 'date':
          return this._getChallengeDateSlide();
        case 'notify':
          return this._getChallengeNotifySlide();
        case 'active':
          return this._getChallengeActivatedSlide();
        case 'share':
          return this._getChallengeShareSlide();
        default:
          return null;
      }
    }
  }

  render(){
    return(
      <div className="challenge-wrapper">
        {this.state.ready ?
            <React.Fragment>{this._getSlide()}</React.Fragment>
          :
            <div></div>
        }
      </div>
    );
  }
}

Challenge.contextType = MyContext;
export default Challenge;
