import React, { Fragment, Component } from 'react';
import {Helmet} from "react-helmet";
import FooterUpdator from '../FooterUpdator';
import FooterModal from '../FooterModal';
import MyContext from '../../state/MyContext';
require('./style.scss');

class Checkbacks extends Component {

  constructor(props) {
    super(props);

    this.state = {
      checkback_feel_less_lonely_value: 'none',
      checkback_help_improve_mood_value: 'none',
      checkback_helpful_tips_value: 'none',
    }

    //Bindings & References
    this._handleSubmit = this._handleSubmit.bind(this);
    this._handleBackToLogin = this._handleBackToLogin.bind(this);
    this.footerModal = React.createRef();
  }

  componentDidMount() {
    let init_state = {};
    init_state.checkback_feel_less_lonely_value = this.context.user.public_user.checkback_feel_less_lonely;
    init_state.checkback_help_improve_mood_value = this.context.user.public_user.checkback_help_improve_mood;
    init_state.checkback_helpful_tips_value = this.context.user.public_user.checkback_helpful_tips;
    init_state.ready = true;
    this.setState(init_state);
  }

  _handleSubmit() {

    const settings = {
      checkback_feel_less_lonely: this.state.checkback_feel_less_lonely_value,
      checkback_help_improve_mood: this.state.checkback_help_improve_mood_value,
      checkback_helpful_tips: this.state.checkback_helpful_tips_value,
    };
    this.context.updateProfile(settings).then(() => {

      this.footerModal.current.show();

      //KeenIO
      this.context.sendKeenEvent('checkbacks', {
        checkback_feel_less_lonely: this.state.checkback_feel_less_lonely_value,
        checkback_help_improve_mood: this.state.checkback_help_improve_mood_value,
        checkback_helpful_tips: this.state.checkback_helpful_tips_value,
        push_notification_title: this.context.push_notification_title
      });

      //MixPanel
      this.context.sendMixPanelEvent('checkbacks', {
        checkback_feel_less_lonely: this.state.checkback_feel_less_lonely_value,
        checkback_help_improve_mood: this.state.checkback_help_improve_mood_value,
        checkback_helpful_tips: this.state.checkback_helpful_tips_value,
        push_notification_title: this.context.push_notification_title
      });

    });
  }

  _handleBackToLogin(e){
    e.preventDefault();
    this.footerModal.current.hide();
    this.props.history.push('/');
  }

  _updateSelect(name, event) {
    this.setState({[name]:event.target.value});
  }

  _getSelectOptions(){
    return(
      <Fragment>
        <option value='none'>- Agree or disagree? -</option>
        <option value='Strongly Agree'>Strongly Agree</option>
        <option value='Agree'>Agree</option>
        <option value='Slightly Agree'>Slightly Agree</option>
        <option value='Neither Agree nor Disagree'>Neither Agree nor Disagree</option>
        <option value='Slightly Disagree'>Slightly Disagree</option>
        <option value='Disagree'>Disagree</option>
        <option value='Strongly Disagree'>Strongly Disagree</option>
      </Fragment>
    );
  }

  render(){
    return (
      <div id="checkbacks">
        <Helmet>
          <title>Nod - Feedback</title>
        </Helmet>
        {/*<PageHeader subhead={true} bgColor="tan-accent" bgHeaderColor="tan">*/}
          <h1>Help us make Nod better.</h1>

          <br />

          <div className="subhead">1 of 3</div>
          <label htmlFor="feel-less-lonely">Nod has helped me feel closer to others.</label>
          <div className="dropdownWrap">
            <select id="feel-less-lonely" value={this.state.checkback_feel_less_lonely_value} onChange={(e) => this._updateSelect('checkback_feel_less_lonely_value', e)}>
              {this._getSelectOptions()}
            </select>
          </div>
        {/*</PageHeader>*/}

        <br />

        <div className="subhead">2 of 3</div>
        <label htmlFor="help-improve-mood">Using Nod has helped improve my mood.</label>
        <div className="dropdownWrap">
          <select id="help-improve-mood" value={this.state.checkback_help_improve_mood_value} onChange={(e) => this._updateSelect('checkback_help_improve_mood_value', e)}>
            {this._getSelectOptions()}
          </select>
        </div>

        <br />

        {/*<div className="wavy-section tan">
          <svg viewBox="0 0 1000 31.39">
            <path className="pathItem" d="M0,0V29.86c21.61,2.24,42.07-5.11,53.07-6.72,36.48-5.34,61-3.46,98.63,3.15S206.18,27.62,253,11.53s63.77,6.58,91.48,13.9,43.63-6.72,66-1.95C425.35,26.63,452,30.39,471.75,25c7.09-1.93,56.62-5,67.8-5.67C588,16.39,589,36.39,651.56,30.2,680.08,27.37,710,15.39,758,23.3c48.81,8,54.62,2.37,92.26-4.25s60.15-1.49,96.63,3.85c11,1.61,31.46,9,53.07,6.72V0Z"/>
          </svg>*/}

          <div className="subhead">3 of 3</div>
          <label htmlFor="helpful-tips">Nod has given me helpful tips for connecting with other people.</label>
          <div className="dropdownWrap">
            <select id="helpful-tips" value={this.state.checkback_helpful_tips_value} onChange={(e) => this._updateSelect('checkback_helpful_tips_value', e)}>
              {this._getSelectOptions()}
            </select>
          </div>
        {/*</div>*/}

        <FooterModal ref={this.footerModal} visible={false} parentId="checkbacks">
          <h2>Thanks for your feedback!</h2>
          <p>Your input will help us improve Nod for other people looking for connections.</p>
          <button className="button" onClick={(e) => this._handleBackToLogin(e)}>Back to Dashboard</button>
          <br /><br />
        </FooterModal>
        <FooterUpdator
          showHome={false}
          backHandler={() => this.props.history.goBack() }
          nextText="Submit"
          nextHandler={this._handleSubmit} />
      </div>
    );
  }
}

Checkbacks.contextType = MyContext;
export default Checkbacks;
