import * as PIXI from 'pixi.js';

class ProgressMeter {
  constructor(x, y, radius, lineWidth, backColor, frontColor) {
    this.x = x;
    this.y = y;
    this.radius = radius;
    this.lineWidth = lineWidth;
    this.backColor = backColor;
    this.frontColor = frontColor;

    this.width = this.radius * 2 + this.lineWidth * 2;
    this.height = this.radius * 2 + this.lineWidth * 2;

    this.createRenderer();
  }

  createRenderer() {
    this.dpr = window.devicePixelRatio;
    this.canvas = document.createElement('canvas');
    this.ctx = this.canvas.getContext('2d');
    this.canvas.width = this.width * this.dpr;
    this.canvas.height = this.height * this.dpr;
    this.ctx.scale(this.dpr, this.dpr);

    this.sprite = new PIXI.Sprite.from(this.canvas);
    this.sprite.x = this.x;
    this.sprite.y = this.y;
    this.sprite.width = this.width;
    this.sprite.height = this.height;
    this.sprite.anchor.set(0.5, 0.5);
  }

  render(progress) {
    this.ctx.clearRect(0, 0, this.width, this.height);

    this.ctx.beginPath();
    this.ctx.arc(this.width / 2, this.height / 2, this.radius, 0, Math.PI * 2);
    this.ctx.lineWidth = this.lineWidth;
    this.ctx.strokeStyle = this.backColor;
    this.ctx.stroke();

    this.ctx.beginPath();
    this.ctx.arc(
      this.width / 2,
      this.height / 2,
      this.radius,
      -Math.PI / 2,
      -Math.PI / 2 + Math.PI * 2 * progress
    );
    this.ctx.lineWidth = this.lineWidth;
    this.ctx.strokeStyle = this.frontColor;
    this.ctx.stroke();

    const progress_percentage = Math.round(progress * 100);
    this.ctx.textAlign = "center";
    this.ctx.fillText(progress_percentage + '%', this.width / 2, this.height / 2 + 3);

    this.sprite.texture.update();
  }

  destroy() {
    this.canvas = null;
    this.ctx = null;
    this.sprite = null;
  }
}

export default ProgressMeter;
