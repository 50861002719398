import * as PIXI from 'pixi.js';

class SvgSprite {
  constructor(x, y, width, asset, xAnchor = 0.5, yAnchor = 0.5) {
    this.x = x;
    this.y = y;
    this.width = width;
    this.asset = asset;
    this.xAnchor = xAnchor;
    this.yAnchor = yAnchor;

    this.dpr = window.devicePixelRatio;
    this.srcWidth = this.asset.texture.width;
    this.srcHeight = this.asset.texture.height;
    this.srcRatio = this.srcWidth / this.srcHeight;
    this.destWidth = this.width;
    this.destHeight = this.width / this.srcRatio;

    this.canvas = document.createElement('canvas');
    this.ctx = this.canvas.getContext('2d');
    this.canvas.width = this.destWidth * this.dpr;
    this.canvas.height = this.destHeight * this.dpr;
    this.ctx.scale(this.dpr, this.dpr);
    this.ctx.drawImage(this.asset.data, 0, 0, this.destWidth, this.destHeight);

    this.sprite = new PIXI.Sprite(PIXI.Texture.from(this.canvas));
    this.sprite.x = this.x;
    this.sprite.y = this.y;
    this.sprite.width = this.destWidth;
    this.sprite.height = this.destHeight;
    this.sprite.anchor.set(this.xAnchor, this.yAnchor);

    this.canvas = null;
    this.ctx = null;
  }
}

export default SvgSprite;
