import React, { Component } from 'react';
require('./style.scss');

class LevelUpCard extends Component {

  componentDidMount() {

  }

  render(){
    return (
      <div className="level-up-card">
        <div className="number"><span>{this.props.level}</span></div>
        <h2>Level Up:</h2>
        <p>You've tried {this.props.level} ideas.</p>
      </div>
    );
  }
}

export default LevelUpCard;
