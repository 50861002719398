import { Component } from 'react';
import MyContext from '../../state/MyContext';

class UrlRedirects extends Component {

  constructor (props) {
    super(props);

    //Actions Setup
    let mythis = this;
    if(window.cordova){
      window.cordova.plugins.notification.local.addActions('yes-no', [
        {id: 'yes', title: 'Yes', launch: true},
        {id: 'no', title: 'No', launch: false}
      ]);
      window.cordova.plugins.notification.local.on('yes', function (notification, eopts) {

        mythis.context.setState({
          came_from_push_notification_yes_action: true,
          marketing_channel: 'notification',
          came_from_push_notification: true,
          push_notification_action_text: notification.data.actionText,
          push_notification_title: notification.title,
          notification_action_button_clicked: 'yes',
        });

        setTimeout(() => {
          if(mythis.context.user_logged_in){
            props.redirectScope.props.history.push(notification.data.redirectRoute);
            mythis.context.sendKeenEvent('notification', {
              page_title: 'push',
              skipped: false,
              os_declined: false,
              snoozed: false,
              reminder_selection: null,
              notification_title: notification.data.actionText,
              marked_complete: true,
              push_notification: true,
              notification_action_clicked: 'yes',
              code_location: 'UrlRedirects',
              push_notification_title: mythis.context.push_notification_title
            });

            mythis.context.sendMixPanelEvent('notification', {
              page_title: 'push',
              skipped: false,
              os_declined: false,
              snoozed: false,
              reminder_selection: null,
              notification_title: notification.data.actionText,
              marked_complete: true,
              push_notification: true,
              notification_action_clicked: 'yes',
              code_location: 'UrlRedirects',
              push_notification_title: mythis.context.push_notification_title
            });
          }else{
            props.redirectScope.props.history.push('/?destination='+notification.data.redirectRoute);
            //KeenIO event will happen in MainWrapper _sessionStarted() after user has logged in
          }
        }, 4000); //Delayed because the whole app hasn't finished loading at this time. One of the pitfalls of this notification system.

      });
      window.cordova.plugins.notification.local.on('no', function (notification, eopts) {

        mythis.context.setState({
          marketing_channel: 'notification',
          came_from_push_notification: true,
          push_notification_action_text: notification.data.actionText,
          push_notification_title: notification.title,
          notification_action_button_clicked: 'no'
        });

        setTimeout(() => {
          if(mythis.context.user_logged_in){
            props.redirectScope.props.history.push(notification.data.redirectRoute);
            mythis.context.sendKeenEvent('notification', {
              page_title: 'push',
              skipped: false,
              os_declined: false,
              snoozed: false,
              reminder_selection: null,
              notification_title: notification.data.actionText,
              marked_complete: true,
              push_notification: true,
              notification_action_button_clicked: 'no',
              code_location: 'UrlRedirects',
              push_notification_title: mythis.context.push_notification_title
            });

            mythis.context.sendMixPanelEvent('notification', {
              page_title: 'push',
              skipped: false,
              os_declined: false,
              snoozed: false,
              reminder_selection: null,
              notification_title: notification.data.actionText,
              marked_complete: true,
              push_notification: true,
              notification_action_button_clicked: 'no',
              code_location: 'UrlRedirects',
              push_notification_title: mythis.context.push_notification_title
            });
          }else{
            props.redirectScope.props.history.push('/?destination='+notification.data.redirectRoute);
            //KeenIO event will happen in MainWrapper _sessionStarted() after user has logged in
          }
        }, 4000); //Delayed because the whole app hasn't finished loading at this time. One of the pitfalls of this notification system.

      });
    }
  }

  componentDidMount() {

    // Listen for notification clicks
    if(window.cordova) window.cordova.plugins.notification.local.on("click", (e) => this.notificationClickRedirect(e));

    // Listen for branchio_redirects event (dispatched in /src/index.js)
    window.addEventListener("branchio_redirects", () => {
      this.context.followBranchRedirect((redirectPath, channel) => {
        console.log('followBranchRedirect', redirectPath, channel);
        this.context.setState({marketing_channel: channel});
        this.props.redirectScope.props.history.push(redirectPath);
      });
    }, false);
  }

  notificationClickRedirect(e) {
    this.context.notificationsRefresh();
    if(e.data.redirectRoute !== undefined) {
      console.log('redirectRoute', e.data.redirectRoute);

      //if(e.data.category === 'challenge_notification') this.context.setState({came_from_push_notification:true});
      this.context.setState({
        marketing_channel: 'notification',
        came_from_push_notification: true,
        push_notification_action_text: e.data.actionText,
        push_notification_title: e.title
      });

      //If not logged in, set destination parameter
      setTimeout(() => {
        if(this.context.user_logged_in){
          this.props.redirectScope.props.history.push(e.data.redirectRoute);
          this.context.sendKeenEvent('notification', {
            page_title: 'push',
            skipped:false,
            os_declined: false,
            snoozed:false,
            reminder_selection: null,
            notification_title: e.data.actionText,
            marked_complete: false,
            push_notification: true,
            code_location: 'UrlRedirects',
            push_notification_title: this.context.push_notification_title
          });

          this.context.sendMixPanelEvent('notification', {
            page_title: 'push',
            skipped:false,
            os_declined: false,
            snoozed:false,
            reminder_selection: null,
            notification_title: e.data.actionText,
            marked_complete: false,
            push_notification: true,
            code_location: 'UrlRedirects',
            push_notification_title: this.context.push_notification_title
          });
        }else{
          this.props.redirectScope.props.history.push('/?destination='+e.data.redirectRoute);
          //KeenIO event will happen in MainWrapper _sessionStarted() after user has logged in
        }
      }, 4000); //Delayed because the whole app hasn't finished loading at this time. One of the pitfalls of this notification system.
    }
  }

  render(){
    return null;
  }
}

UrlRedirects.contextType = MyContext;
export default UrlRedirects;
