import * as Matter from 'matter-js';

import SvgSprite from '../../common/SvgSprite';

class Monkey extends SvgSprite {
  constructor(x, y, width, asset, xAnchor = 0.5, yAnchor = 0.5) {
    super(x, y, width, asset, xAnchor, yAnchor);

    this.body = Matter.Bodies.rectangle(
      this.x,
      this.y,
      this.sprite.width,
      this.sprite.height,
      {
        restitution: 0,
        friction: 1,
        label: 'monkey'
      }
    );
  }

  update() {
    this.sprite.x = this.body.position.x;
    this.sprite.y = this.body.position.y;
    this.sprite.rotation = this.body.angle;
  }
}

export default Monkey;
