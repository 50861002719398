import React, { Component } from 'react';
require('./style.scss');

class HomeAnonymous extends Component {
  render(){
    return (
      <div id="home-anonymous">
        <h1>Hello</h1>
      </div>
    );
  }
}

export default HomeAnonymous;
