import * as PIXI from 'pixi.js';

import Calc from '../../common/Calc';
import SvgSprite from '../../common/SvgSprite';

class Star extends SvgSprite {
  constructor(x, y, width, asset, xAnchor = 0.5, yAnchor = 0.5) {
    super(x, y, width, asset, xAnchor, yAnchor);

    this.sprite.x = 0;
    this.sprite.y = 0;

    this.container = new PIXI.Container();
    this.container.x = x;
    this.container.y = y;
    this.container.addChild(this.sprite);

    this.twinkling = false;
    this.twinkleOffset = Calc.rand(10000);
    this.twinkleMult = Calc.rand(0.002, 0.003);
  }

  update(time) {
    if (this.twinkling) {
      let alpha = Calc.map(
        Math.sin((time + this.twinkleOffset) * this.twinkleMult),
        -1,
        1,
        0.25,
        30
      );
      alpha = Calc.clamp(alpha, 0, 1);
      this.sprite.alpha = alpha;

      let scale = Calc.map(
        Math.sin((time + this.twinkleOffset) * this.twinkleMult),
        -1,
        1,
        0.75,
        30
      );
      scale = Calc.clamp(scale, 0, 1);
      this.container.scale.set(scale);
    }
  }
}

export default Star;
