import React, { Component } from 'react';
import MyContext from '../../state/MyContext';
import BubbleCard from '../BubbleCard';
require('./style.scss');

class GuidesSlider extends Component {
  render(){
    const skills = this.props.skills ? this.props.skills : false;
    let content = skills ? this.context.cbt_skills : this.context.guides.filter(guide => guide.hidden === false);
    if(this.props.yourSkills && skills){
      content = [];
      this.context.cbt_skills.forEach((skill, index) => {
        const user_challenge = this.context.user_challenges.find((challenge) => {
          const user_challenge_skill = challenge.challenge.cbt_skill;
          if(user_challenge_skill){
            return user_challenge_skill.id === skill.id;
          };
          return null;
        });
        if(user_challenge !== undefined) content.push(skill);
      });
      if(content.length === 0) content = this.context.cbt_skills;
    }
    return (
      <div className="guides-slider">
        <div className="guides-slider_horizonal-scroll">
          { skills ?
            <React.Fragment>
              {
                content.map((skill, index) => {
                  return <BubbleCard
                            key={index}
                            skill={true}
                            link={'/skills/'+skill.slug}
                            theme={'tan'}
                            image_url={skill.image_url}
                            animated={false}
                            title={skill.title} />;
                })
              }
            </React.Fragment>
          :
            <React.Fragment>
              {
                content.map((guide, index) => {
                  return <BubbleCard
                            key={index}
                            link={'/guides/'+guide.slug}
                            theme={guide.theme_name}
                            image_url={guide.image_url}
                            animated={false}
                            title={guide.title} />;
                })
              }
            </React.Fragment>
          }
        </div>
      </div>
    );
  }
}

GuidesSlider.contextType = MyContext;
export default GuidesSlider;
