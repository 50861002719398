import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import MyContext from '../../state/MyContext';
import PageHeader from '../PageHeader';
import FooterUpdator from '../FooterUpdator';
import GuidesSlider from '../GuidesSlider';
import UserChallengesSlider from '../UserChallengesSlider';
require('./style.scss');

class Guides extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ready: false,
    };
  }

  componentDidMount() {
    //if(!this.context.user_logged_in) this.props.history.push('/');

    //Preload content
    if(!this.context.guides_loaded) this.context.getGuides();
    if(!this.context.challenges_loaded) this.context.getChallenges();
    if(!this.context.user_challenges_loaded) this.context.getUserChallenges();
    if(this.context.guides_loaded && this.context.challenges_loaded && this.context.user_challenges_loaded) this.setState({ready:true});
  }

  componentDidUpdate() {
    if(this.context.guides_loaded && this.context.challenges_loaded && this.context.user_challenges_loaded && !this.state.ready) this.setState({ready:true});
  }

  render(){
    return (
      <div id="guides">
        <Helmet>
          <title>Nod - Ideas</title>
        </Helmet>
        <PageHeader bgHeaderColor="blue" bgColor="tan">
          <h1>Ideas to Try</h1>
        </PageHeader>
        {
          this.state.ready ?
            <React.Fragment>

              <div id="guides_goals-section">
                <h3>Work toward <strong><i className="icon star"/>goals</strong></h3>
                <GuidesSlider />
                  <div className="squiggle bottom">
                    <svg viewBox="0 0 1000 31.39">
                      <path className="pathItem" d="M0,0V29.86c21.61,2.24,42.07-5.11,53.07-6.72,36.48-5.34,61-3.46,98.63,3.15S206.18,27.62,253,11.53s63.77,6.58,91.48,13.9,43.63-6.72,66-1.95C425.35,26.63,452,30.39,471.75,25c7.09-1.93,56.62-5,67.8-5.67C588,16.39,589,36.39,651.56,30.2,680.08,27.37,710,15.39,758,23.3c48.81,8,54.62,2.37,92.26-4.25s60.15-1.49,96.63,3.85c11,1.61,31.46,9,53.07,6.72V0Z"/>
                    </svg>
                  </div>
              </div>

              <div id="guides_skills-section">
                <h3>Practice <strong><i className="icon growth"/>skills</strong></h3>
                <GuidesSlider skills={true} />
                  <div className="squiggle bottom">
                    <svg viewBox="0 0 1000 31.39">
                      <path className="pathItem" d="M0,0V29.86c21.61,2.24,42.07-5.11,53.07-6.72,36.48-5.34,61-3.46,98.63,3.15S206.18,27.62,253,11.53s63.77,6.58,91.48,13.9,43.63-6.72,66-1.95C425.35,26.63,452,30.39,471.75,25c7.09-1.93,56.62-5,67.8-5.67C588,16.39,589,36.39,651.56,30.2,680.08,27.37,710,15.39,758,23.3c48.81,8,54.62,2.37,92.26-4.25s60.15-1.49,96.63,3.85c11,1.61,31.46,9,53.07,6.72V0Z"/>
                    </svg>
                  </div>
              </div>

              <UserChallengesSlider />

            </React.Fragment>
          :
            <div></div>
          }
        <FooterUpdator normal />
      </div>
    );
  }
}

Guides.contextType = MyContext;
export default Guides;
