import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';

import MyContext from '../../state/MyContext';
import PageHeader from '../PageHeader';
import FooterUpdator from '../FooterUpdator';
import FooterModal from '../FooterModal';
import GradeOptions from '../Onboarding/GradeOptions';
import GenderOptions from '../Onboarding/GenderOptions';

var pjson = require('../../../package.json');

require('./style.scss');

class Settings extends Component {

  constructor(props) {
    super();
    this.state = {
      ready: false,

      notifications:false,
      notification_time: '12:00',

      animated_gifs: true,
      touch_gestures: true,
      subtitles: true,

      //age_value: 'none',
      grade_level_value: 'none',
      gender_value: 'none',
      ethnicity_value: 'none',

      onboarded_value: true,
    }

    this.footerModal = React.createRef();
    this.notificationTime = React.createRef();
  }

  componentDidMount() {

    if(this.context.came_from_push_notification) this.context.setState({came_from_push_notification:false});

    let notifications = this.context.getNotificationSetting('notifications');
    if(notifications === null){ notifications = false; }else{ notifications = (notifications === 'true') ? true : false; };

    let notification_time = this.context.getNotificationSetting('notificationTime');
    if(notification_time === null){ notification_time = '12:00'; };

    let animated_gifs = window.localStorage.getItem('@nod:animated_gifs');
    if(animated_gifs === 'false') animated_gifs = false;
    if(animated_gifs === null){ animated_gifs = true; };

    let touch_gestures = window.localStorage.getItem('@nod:touch_gestures');
    if(touch_gestures === 'false') touch_gestures = false;
    if(touch_gestures === null){ touch_gestures = true; };

    let subtitles = window.localStorage.getItem('@nod:subtitles');
    if(subtitles === 'false') subtitles = false;
    if(subtitles === null){ subtitles = true; };

    let init_state = {};
    init_state.notifications = notifications;
    init_state.notification_time = notification_time;
    init_state.animated_gifs = animated_gifs;
    init_state.touch_gestures = touch_gestures;
    init_state.subtitles = subtitles;
    //init_state.age_value = this.context.user.public_user.age;
    init_state.grade_level_value = this.context.user.public_user.grade_level;
    init_state.gender_value = this.context.user.public_user.gender;
    init_state.ethnicity_value = this.context.user.public_user.ethnicity;
    init_state.ready = true;
    this.setState(init_state);
  }

  _updateSelect(name, event) {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    if(['notifications', 'notification_time'].includes(name)){
      switch(name){
        default:
        case 'notifications':
          if(value){
            this.context.requestEnableOsNotifications().then(granted => {
              if(granted){
                this.setState({[name]:value});
                this.context.setNotificationSetting('notifications', value);

                //KeenIO Event
                this.context.sendKeenEvent('notification', {
                  page_title: 'settings',
                  skipped:false,
                  os_declined: false,
                  snoozed:false,
                  reminder_selection: null,
                  notification_title: null,
                  marked_complete: false,
                  push_notification: false,
                  notification_action_button_clicked: null,
                  code_location: 'Settings.jsx',
                  push_notification_title: null
                });

                //MixPanel Event
                this.context.sendMixPanelEvent('notification', {
                  page_title: 'settings',
                  skipped:false,
                  os_declined: false,
                  snoozed:false,
                  reminder_selection: null,
                  notification_title: null,
                  marked_complete: false,
                  push_notification: false,
                  notification_action_button_clicked: null,
                  code_location: 'Settings.jsx',
                  push_notification_title: null
                });

              }else{
                this.footerModal.current.show();

                //KeenIO Event
                this.context.sendKeenEvent('notification', {
                  page_title: 'settings',
                  skipped:true,
                  os_declined: true,
                  snoozed:false,
                  reminder_selection: null,
                  notification_title: null,
                  marked_complete: false,
                  push_notification: false,
                  notification_action_button_clicked: null,
                  code_location: 'Settings.jsx',
                  push_notification_title: null
                });

                //MixPanel Event
                this.context.sendMixPanelEvent('notification', {
                  page_title: 'settings',
                  skipped:true,
                  os_declined: true,
                  snoozed:false,
                  reminder_selection: null,
                  notification_title: null,
                  marked_complete: false,
                  push_notification: false,
                  notification_action_button_clicked: null,
                  code_location: 'Settings.jsx',
                  push_notification_title: null
                });
              }
            });
          }else{
            this.setState({[name]:value});
            this.context.setNotificationSetting('notifications', value);

            //KeenIO Event
            this.context.sendKeenEvent('notification', {
              page_title: 'settings',
              skipped:false,
              os_declined: true,
              snoozed: false,
              reminder_selection: null,
              notification_title: null,
              marked_complete: false,
              push_notification: false,
              notification_action_button_clicked: null,
              code_location: 'Settings.jsx',
              push_notification_title:  null
            });

            //MixPanel Event
            this.context.sendMixPanelEvent('notification', {
              page_title: 'settings',
              skipped:false,
              os_declined: true,
              snoozed: false,
              reminder_selection: null,
              notification_title: null,
              marked_complete: false,
              push_notification: false,
              notification_action_button_clicked: null,
              code_location: 'Settings.jsx',
              push_notification_title:  null
            });
          }
          break;

        case 'notification_time':
          this.setState({[name]:value});
          this.context.updateAllNotificationTimes(value);
          this.context.setNotificationSetting('notificationTime', value);
          break;
      }
    }else if(['animated_gifs', 'touch_gestures', 'subtitles'].includes(name)){
      this.setState({[name]:value});
      switch(name){
        default:
        case 'animated_gifs':
          if(value){ window.localStorage.removeItem('@nod:animated_gifs'); }else{ window.localStorage.setItem('@nod:animated_gifs', false); }
          break;

        case 'touch_gestures':
          if(value){ window.localStorage.removeItem('@nod:touch_gestures'); }else{ window.localStorage.setItem('@nod:touch_gestures', false); }
          break;

        case 'subtitles':
          if(value){ window.localStorage.removeItem('@nod:subtitles'); }else{ window.localStorage.setItem('@nod:subtitles', false); }
          break;
      }
    }else{
      this.setState({[name]:value});
      setTimeout(() => {
        const settings = {
          //age: this.state.age_value,
          gender: this.state.gender_value,
          grade_level: this.state.grade_level_value,
          ethnicity: this.state.ethnicity_value,
          onboarded: this.state.onboarded_value
        };
        this.context.updateProfile(settings).then(() => {

          //KeenIO
          this.context.sendKeenEvent('demographics', {
            skip: false,
            onboarding: false
          });

          //MixPanel
          this.context.sendMixPanelEvent('demographics', {
            skip: false,
            onboarding: false
          });

        });
      }, 100);
    }
  }

  _handlePrivacyPolicyRedirect(e) {
    e.preventDefault();
    const link = 'https://heynod.com/privacy-policy';
    if(window.cordova){
      window.cordova.InAppBrowser.open(link, '_system');
    }else{
      window.open(link, "_system");
    }
  }

  _handleTermsRedirect(e) {
    e.preventDefault();
    const link = 'https://heynod.com/terms-and-conditions';
    if(window.cordova){
      window.cordova.InAppBrowser.open(link, '_system');
    }else{
      window.open(link, "_system");
    }
  }

  render(){
    return (
      <div id="settings">
        <Helmet>
          <title>Nod - Settings</title>
        </Helmet>
        { !this.state.ready ?
            <div></div>
          :
            <React.Fragment>
              <PageHeader subhead={true} bgColor="tan-accent" bgHeaderColor="tan">
                <h2>Manage notifications</h2>
                <div className="settingWrap">
                  <div className="checkbox">
                    <div className="label" aria-hidden="true">Allow Nod to send me reminders and suggestions</div>
                    <label htmlFor="nudges">
                      <input aria-label="Allow Nod to send me reminders and suggestions" type="checkbox" id="nudges" checked={this.state.notifications} onChange={(e) => this._updateSelect('notifications', e)} />
                      <div className="custom-checkbox" />
                    </label>
                  </div>
                </div>
                <div className="settingWrap">
                  <div className="label" aria-hidden="true">Preferred time for notifications</div>
                  <label for="notification-time">
                    <input aria-label="Preferred time for notifications" ref={this.notificationTime} id="notification-time" type="time" value={this.state.notification_time} onChange={(e) => this._updateSelect('notification_time', e)} />
                  </label>
                </div>
                <h2>Manage accessibility settings</h2>
                <div className="settingWrap">
                  <div className="checkbox">
                    <div className="label" aria-hidden="true">Allow GIF Animations</div>
                    <label htmlFor="animated-gifs">
                      <input aria-label="Allow GIF Animations" type="checkbox" id="animated-gifs" checked={this.state.animated_gifs} onChange={(e) => this._updateSelect('animated_gifs', e)} />
                      <div className="custom-checkbox" />
                    </label>
                  </div>
                </div>
                <div className="settingWrap">
                  <div className="checkbox">
                    <div className="label" aria-hidden="true">Allow touch gestures</div>
                    <label htmlFor="touch-gestures">
                      <input aria-label="Allow touch gestures" type="checkbox" id="touch-gestures" checked={this.state.touch_gestures} onChange={(e) => this._updateSelect('touch_gestures', e)} />
                      <div className="custom-checkbox" />
                    </label>
                  </div>
                </div>
                <div className="settingWrap">
                  <div className="checkbox">
                    <div className="label" aria-hidden="true">Turn on subtitles for audio or video when available</div>
                    <label htmlFor="subtitles">
                      <input aria-label="Turn on subtitles for audio or video when available" type="checkbox" id="subtitles" checked={this.state.subtitles} onChange={(e) => this._updateSelect('subtitles', e)} />
                      <div className="custom-checkbox" />
                    </label>
                  </div>
                </div>
                <h2>Change your information</h2>
                {/*<div className="dropdownWrap">
                  <select value={this.state.age_value} onChange={(e) => this._updateSelect('age_value', e)}>
                  <option value='none'>- Age -</option>
                  <option value='13-17'>13-17</option>
                  <option value='18-24'>18-24</option>
                  <option value='25-34'>25-34</option>
                  <option value='35-44'>35-44</option>
                  <option value='45-54'>45-54</option>
                  <option value='55-64'>55-64</option>
                  <option value='65 and over'>65 and over</option>
                  </select>
                </div>*/}
                <div className="dropdownWrap">
                  <label htmlFor="grade-level">
                    Choose your grade:
                    <select id="grade-level-value" value={this.state.grade_level_value} onChange={(e) => this._updateSelect('grade_level_value', e)}>
                      <option value='none'>- I Am -</option>
                      <GradeOptions typeId={this.context.user && this.context.user.app_type && this.context.user.app_type.id} />
                    </select>
                  </label>
                </div>
                <div className="dropdownWrap">
                  <label htmlFor="gender-value">
                    Choose your gender:
                    <select id="gender-value" value={this.state.gender_value} onChange={(e) => this._updateSelect('gender_value', e)}>
                      <option value='none'>- Gender -</option>
                      <GenderOptions />
                    </select>
                  </label>
                </div>
                <div className="dropdownWrap">
                  <label htmlFor="ethnicity-value">
                    Choose your ethnicity
                    <select id="ethnicity-value" value={this.state.ethnicity_value} onChange={(e) => this._updateSelect('ethnicity_value', e)}>
                      <option value='none'>- Ethnicity -</option>
                      <option value='American Indian or Alaska Native'>American Indian or Alaska Native</option>
                      <option value='Asian'>Asian</option>
                      <option value='Black or African American'>Black</option>
                      <option value='Hispanic or Latino/a/x'>Hispanic or Latino/a/x</option>
                      <option value='Middle Eastern or North African'>Middle Eastern or North African</option>
                      <option value='Native Hawaiian or Other Pacific Islander'>Native Hawaiian or Other Pacific Islander</option>
                      <option value='White'>White</option>
                      <option value='Multiracial'>Multiracial</option>
                      <option value='Other race, ethnicity or origin'>Other race ethnicity, or origin</option>
                      <option value="Don't Know">Don't Know</option>
                      <option value='I prefer not to respond'>I prefer not to respond</option>
                    </select>
                  </label>
                </div>
                <h2>Policies & Feedback</h2>
                <a href="/" className="whiteButton" onClick={this._handlePrivacyPolicyRedirect}>Privacy Policy</a>
                <a href="/" className="whiteButton" onClick={this._handleTermsRedirect}>Terms & Conditions</a>
                <Link to="/feedback" className="whiteButton">Feedback</Link>
              </PageHeader>
              <Link to="/logout" className="button">Log Out</Link>
              <input className="onboarded-checkbox" type="checkbox" checked={this.state.onboarded_value} onChange={(e) => this._updateSelect('onboarded_value', e)} />
              <div aria-hidden="true" className="secret">
                {this.context.user.id}<br/>
                {pjson.version}<br/>
                {this.context.app_versions.length > 0 ? this.context.app_versions[0].version : pjson.version}
              </div>
              <FooterUpdator normal />
              <FooterModal ref={this.footerModal} visible={false} parentId="settings">
                <h3>First, allow Nod to send reminders</h3>
                <p>Open Settings on your phone to allow Nod to send you notifications.</p>
                <br /><br />
              </FooterModal>
            </React.Fragment>
        }
      </div>
    );
  }
}

Settings.contextType = MyContext;
export default Settings;
