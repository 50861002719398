import BaseComponent from '../common/BaseComponent';
import Reflection from './scripts/WhackAMoleReflection';

class WhackAMoleComponent extends BaseComponent {
  constructor(props) {
    super(props);
    this.Reflection = Reflection;
  }
}

export default WhackAMoleComponent;
