import React, { Component } from 'react';
import FooterUpdator from '../FooterUpdator';
require('./style.scss');

class PrivacyPolicy extends Component {
  render(){
    return (
      <div id="privacy-policy">

        <p><b>Grit Privacy Policy</b></p>
        <p><span style={{fontWeight: 400}}>Last Updated: August 27, 2019</span></p>
        <p><br /><br /></p>
        <p><span style={{fontWeight: 400}}>Grit Digital Health (“Grit”) (on behalf of itself and its affiliates and Development Partners, as defined below, collectively “We” or “Us”) understands that visitors to our Product, as defined below, may have questions about whether and how we collect and use information, and we are committed to protecting your privacy. In support of this, we only use the personally identifiable information (“Personal Information”) that you provide in accordance with the privacy practices explained in this policy (“Privacy Policy”).</span></p>
        <p> </p>
        <p><span style={{fontWeight: 400}}>This Privacy Policy applies to mobile applications and websites operated or owned, in whole or in part, by Grit, including but not limited to the Nod application, and Grit’s other products </span><a href="https://.you.xxx.edu"><span style={{fontWeight: 400}}>https://.you.XXX.edu</span></a><span style={{fontWeight: 400}}>, </span><a href="https://gritdigitalhealth.com"><span style={{fontWeight: 400}}>https://gritdigitalhealth.com</span></a><span style={{fontWeight: 400}}>, </span><a href="https://youatcollege.com"><span style={{fontWeight: 400}}>https://youatcollege.com</span></a><span style={{fontWeight: 400}}>, and </span><a href="http://mantherapy.org"><span style={{fontWeight: 400}}>http://mantherapy.org</span></a><span style={{fontWeight: 400}}> (collectively, the “Product”).</span></p>
        <p> </p>
        <p><span style={{fontWeight: 400}}>Grit develops, operates, and owns portions of the Product with affiliates or development partners (collectively, “Development Partners”).  For example, one of Grit’s Development Partners is Hopelab Foundation, Inc. (“Hopelab”). Grit has created the Nod application in collaboration with Hopelab (Hopelab together with Grit, the “Nod Entities”) and the Nod application is jointly owned by the Nod Entities. The Nod application is designed for students of participating higher education institutions experiencing loneliness and interested in completing challenges that help build skills to address loneliness.   The entities that operate or own any portion(s) of the Product, including Grit and the Development Partners, are collectively referred to as the “Product Entities” or “We” or “Us,” as noted above. This Privacy Policy covers any Personal Information we obtain when you visit us, use our Product or services, or access the features on the Product and/or websites or applications where this Privacy Policy is made available. You can access the Products in many ways, including from a computer or mobile phone, and this Privacy Policy will apply regardless of the means of access. By accessing the Product, you are also consenting to our Terms of Use, and acknowledge that you are over 18 years of age and a resident of the United States. By accessing or otherwise using the Product, you are agreeing to the Privacy Policy and the Terms of Use.  If you do not agree to the Privacy Policy or the Terms of Use, you are not authorized to use the Product in any way.</span></p>
        <p> </p>
        <p><span style={{fontWeight: 400}}>This Privacy Policy does not apply to other websites which are linked to the Products. We are not responsible for any actions or policies of such third parties. You should check the applicable privacy policies of those third parties when providing Personal Information to them.</span></p>
        <p> </p>
        <ol>
        <li><b> Information We Collect</b></li>
        </ol>
        <p> </p>
        <p><span style={{fontWeight: 400}}>Personal Information</span></p>
        <p> </p>
        <p><span style={{fontWeight: 400}}>Personal Information is generally defined as information that may be able to identify you or that may be used to get access to your account including: name, email address, username and password.</span></p>
        <p> </p>
        <p><b>With the exception of email addresses, we only transmit or store Personal Information in an encrypted form.</b><span style={{fontWeight: 400}}> This means that your personal information can only be viewed if decrypted in order to present you with your data within the Product, or if shared for allowed research under FERPA regulations.</span></p>
        <p> </p>
        <p><b>We collect your email address in an unencrypted form in order to communicate with you</b><span style={{fontWeight: 400}}>. However, the email address you provide us will not be associated with any usage or other data. Data created by your usage on the Products is viewable by our developers in a secured environment but is not associated with your email or password and is only used for development purposes.</span></p>
        <p> </p>
        <p><b>You may also provide your real name.</b><span style={{fontWeight: 400}}> Our Product may allow you to associate your real name with your account. You are under no obligation to provide this information.</span></p>
        <p> </p>
        <p><span style={{fontWeight: 400}}>Except for data that may be viewable only in a secure development environment, the only Personal Information that we collect in an unencrypted or decipherable format are email addresses.</span></p>
        <p> </p>
        <p><span style={{fontWeight: 400}}>Non-Personal Information</span></p>
        <p> </p>
        <p><span style={{fontWeight: 400}}>We may collect other non-Personal Information about users whenever they interact with our Products. Non-Personal Information may include the device type, login session durations, task or activity completion, activity rating, and responses to product assessments of mood/loneliness/motivation. These data points are not stored or connected to your personal information and are viewed in aggregate for product analysis or research.</span></p>
        <p> </p>
        <ol start="2">
        <li><b> How We Collect and Use Information</b></li>
        </ol>
        <p> </p>
        <p><span style={{fontWeight: 400}}>There are areas on the Products where you may be asked to provide us with Personal Information, or through the Single Sign On interface whereby such Personal Information or other information may be collected from you in an automated manner.</span></p>
        <p> </p>
        <p><span style={{fontWeight: 400}}>Personal Information you provide through forms on the Products is immediately encrypted and stored in a secure database.  </span></p>
        <p> </p>
        <p><span style={{fontWeight: 400}}>We also may collect statistics about how you use and engage with the Product using cookies and other passive collection mechanisms. This information does not contain personal details about you and is not tied to any Personal Information.</span></p>
        <p> </p>
        <p><span style={{fontWeight: 400}}>In accordance with FERPA authorization, we may collect user self-reported personal information through your use of the Product. This data collection is authorized via partnership with your school and personal information may only be used in order to evaluate the product if approved by your school. A school may be defined as a participating higher education entity that has authorized the use of the Product for its student body. We only release personal information for approved research, authorized by your school, to your school or partners your school authorizes. </span></p>
        <p> </p>
        <p><span style={{fontWeight: 400}}>Non individually identifiable information may be used for Product utilization and behavior evaluation by us.  Personal information, in accordance with FERPA, may only use used for approved research and evaluation in partnership with the providing school.  In the event your institution elects to conduct a research and evaluation study, you will be invited to participate.</span></p>
        <p> </p>
        <p><span style={{fontWeight: 400}}>Mobile Application Events and Cookies</span></p>
        <p> </p>
        <p><span style={{fontWeight: 400}}>We collect information about how you use our Products by setting and accessing cookies on your device and tagging events in product code. A cookie is a small piece of information sent by our Products that is saved on your local storage by your device. The cookie holds information our Products may need to personalize or enhance your experience and to gather statistical data, such as which pages are visited, which actions are completed, and overall usage metrics. You may disable cookies on your browser. Please review your browser’s instructions for doing so. Note that certain features of the Products may not be available if you delete or reject cookies.</span></p>
        <p> </p>
        <p><span style={{fontWeight: 400}}>Google Analytics</span></p>
        <p> </p>
        <p><span style={{fontWeight: 400}}>The Product uses Google Analytics (and in the future may use other similar sites or services), a web analytics service provided by Google, Inc. (“Google”), to better assist us in understanding how the Products is used. Google Analytics will place cookies on your computer that will generate information that we select about your use of the Products, including your computer’s IP address. That information will be transmitted to and stored by Google. The information will be used for the purpose of evaluating consumer use of the Product, compiling reports on activity at the Products for our use, and providing other services relating to activity and usage of the Product. Google may also transfer this information to third parties where required to do so by law, or where such third parties process the information on Google’s behalf. You may refuse the use of cookies by selecting the appropriate settings on your browser. Please note that by doing so, you may not be able to use the full functionality of the Products. The use of cookies by Google Analytics is covered by Google’s privacy policy.</span></p>
        <p> </p>
        <p><span style={{fontWeight: 400}}>You can learn more about Google’s practices at </span><a href="http://www.google.com/policies/privacy/partners"><span style={{fontWeight: 400}}>http://www.google.com/policies/privacy/partners</span></a><span style={{fontWeight: 400}}> and view its currently available opt-out options at </span><a href="https://tools.google.com/dlpage/gaoptout"><span style={{fontWeight: 400}}>https://tools.google.com/dlpage/gaoptout</span></a><span style={{fontWeight: 400}}>.  </span></p>
        <p> </p>
        <ol start="3">
        <li><b> How We Use Collected Information</b></li>
        </ol>
        <p> </p>
        <p><span style={{fontWeight: 400}}>We use secure encrypted information to facilitate access to our Product and services. If you provide us with your email address, we may also send you updates, tips, and reminders with your consent.  We may also use any information we collect in a de-identified, aggregate form (“Usage Information”) to help us understand usage and demographic patterns and improve the functionality of our Products </span></p>
        <p> </p>
        <p><span style={{fontWeight: 400}}>If you choose to provide your real name and your photograph, it may be associated with your account number and relevant statistical and demographic information.</span></p>
        <p> </p>
        <p><span style={{fontWeight: 400}}>We may also share aggregated demographic and statistical information that is not personally identifiable with third parties as described below.</span></p>
        <p> </p>
        <p><span style={{fontWeight: 400}}>By providing to us the Personal Information and other information referenced above, you agree that we may use the Personal Information and other information in accordance with the terms of this Privacy Policy.</span></p>
        <p> </p>
        <ol start="4">
        <li><b> Sharing Your Personal Information</b></li>
        </ol>
        <p> </p>
        <p><span style={{fontWeight: 400}}>We may share certain portions of Personal Information and other information to make the Products and services related to the Products function properly. This may include sharing portions of Personal Information with development, hosting and email service providers to encrypt your information and receive, transfer, use, and host your encrypted Personal Information. </span></p>
        <p> </p>
        <p><span style={{fontWeight: 400}}>We may share Personal Information or Usage Information with your educational institution. However, we also may share aggregated demographic and statistical information that is not personally identifiable: (1) with other educational institutions for informational purposes; and (2) for research purposes. We or educational institutions may view and use your Personal Information or Usage Information with your consent for research purposes. You agree that we may use Usage Information for development purposes, including to improve the Product and to develop future applications and products. </span></p>
        <p> </p>
        <p><span style={{fontWeight: 400}}>We may be required to use or disclose your information in connection with a legal action or other proceeding, including without limitation, in response to a court order or a subpoena. We also may disclose such information in response to a law enforcement agency's request. We may also disclose information if, in the reasonable judgment of Grit, it is necessary to enforce compliance with our Terms of Use or to protect our Products, customers, or others from imminent physical harm or damage to property.</span></p>
        <p> </p>
        <p><span style={{fontWeight: 400}}>If permitted under an agreement with your educational institution, we may share your Personal Information and other information with a third party if our ownership status changes, such as if any Product Entity is acquired or acquires another entity.</span></p>
        <p> </p>
        <p><span style={{fontWeight: 400}}>Other than what is referenced above, the Personal Information and other information collected from you is not shared with nor sold to any person or entity outside of us.</span></p>
        <p> </p>
        <ol start="5">
        <li><b> Third Party Websites</b></li>
        </ol>
        <p> </p>
        <p><span style={{fontWeight: 400}}>This Privacy Policy applies only to the Products, and not to websites owned by third parties. We may provide links to other websites which we believe may be of interest to our visitors. However, due to the nature of the internet, we cannot guarantee the privacy standards of websites to which we link or be responsible for the contents of sites other than this one, and this Privacy Policy is not intended to be applicable to any linked, non-Product Entity websites.</span></p>
        <p> </p>
        <p><span style={{fontWeight: 400}}>Some users access our Product via a webpage that is hosted by the user’s individual school, college or university (“School Product”). We do not control the content or links that appear on these School Products and are not responsible for the practices employed by School Products. In addition, School Products and services each have their own privacy policies and customer service policies. Browsing and interaction on any other School Product is subject to that School Product’s own terms and policies.</span></p>
        <p> </p>
        <ol start="6">
        <li><b> Security</b></li>
        </ol>
        <p> </p>
        <p><span style={{fontWeight: 400}}>In addition to encryption, we take reasonable precautions and follow industry best practices to make sure information is not lost, misused, accessed, disclosed, altered or destroyed.</span></p>
        <p> </p>
        <ol start="7">
        <li><b> Children’s Information</b></li>
        </ol>
        <p> </p>
        <p><span style={{fontWeight: 400}}>The Product is intended for individuals over 18 years of age and older located in the United States.</span></p>
        <p> </p>
        <p><span style={{fontWeight: 400}}>The Products is not directed at, marketed to, nor intended for children under the age of 13 and we do not intentionally collect any information from or about children under the age of 13. If you believe a child under 13 years of age has provided us with information, contact us at </span><a href="mailto:inquiries@gritdigitalhealth.com"><span style={{fontWeight: 400}}>inquiries@gritdigitalhealth.com</span></a><span style={{fontWeight: 400}}>. If we learn that any information was provided through the Product by a person younger than 13 years of age, we will delete the information immediately.</span></p>
        <p> </p>
        <ol start="8">
        <li><b> Email Opt Out</b></li>
        </ol>
        <p> </p>
        <p><span style={{fontWeight: 400}}>We will only add you to our email list with your consent.</span></p>
        <p> </p>
        <p><span style={{fontWeight: 400}}>If you have consented to receive emails from us, we may send you updates, news, and information about our services. If at any time you wish to stop receiving emails or mailings from us please send us an email to admin@gritdigitalhealth.com with the phrase “Privacy Opt-out: Grit Mailings” in the subject line, or write to us at the address provided below, and we will remove you from our mailing list. Alternatively, for email communications, you may opt out of receiving such communications by following the unsubscribe instructions set forth at the bottom of most e-mail messages from us.</span></p>
        <p> </p>
        <p><span style={{fontWeight: 400}}>Please note that even if you do not sign up to receive email from us, we may send you important service announcements.</span></p>
        <p> </p>
        <p><span style={{fontWeight: 400}}>Also, please note that we have not yet developed a response to browser “Do Not Track” signals, and do not change any of our data collection practices when we receive such signals. We will continue to evaluate potential responses to “Do Not Track” signals in light of industry developments or legal changes.</span></p>
        <p> </p>
        <ol start="9">
        <li><b> California Privacy Rights </b></li>
        </ol>
        <p> </p>
        <p><span style={{fontWeight: 400}}>We do not share Personal Information as defined by California Civil Code Section 1798.83 (“Shine the Light Law”) with third parties for their direct marketing purposes absent your consent. If you are a California resident, you may request information about our compliance with the Shine the Light law by contacting us by email to </span><a href="mailto:inquiries@gritdigitalhealth.com"><span style={{fontWeight: 400}}>inquiries@gritdigitalhealth.com</span></a><span style={{fontWeight: 400}}> or by sending a letter to:</span></p>
        <p> </p>
        <p><span style={{fontWeight: 400}}>Grit Digital Health LLC</span></p>
        <p><span style={{fontWeight: 400}}>2128 15</span><span style={{fontWeight: 400}}>th</span><span style={{fontWeight: 400}}> Street</span></p>
        <p><span style={{fontWeight: 400}}>Denver, CO 80202</span></p>
        <p> </p>
        <p><span style={{fontWeight: 400}}>Any such request must include “California Privacy Rights Request” in the first line of the description and include your name, street address, city, state, and ZIP code. Please note that we are only required to respond to one request per customer each year, and we are not required to respond to requests made by means other than through this email address or mail address.</span></p>
        <p> </p>
        <ol start="10">
        <li><b> Updates to this Policy and Contact Information</b></li>
        </ol>
        <p> </p>
        <p><span style={{fontWeight: 400}}>We reserve the right to change this Privacy Policy at any time. If we decide to change our Privacy Policy, we will post those changes on this page so that you are always aware of what information we collect, how we use it and under what circumstances we disclose it. As we may make minor changes from time to time without notifying you, we suggest that you periodically consult this Privacy Policy. Your continued use of the Products after the effective date of any modification to the Privacy Policy will be deemed to be your agreement to the changed terms.</span></p>
        <p> </p>
        <p><span style={{fontWeight: 400}}>If you have any questions about your privacy or security on our Products, please contact us using the following information: </span></p>
        <p> </p>
        <p><span style={{fontWeight: 400}}>Grit Digital Health LLC</span></p>
        <p><span style={{fontWeight: 400}}>2128 15</span><span style={{fontWeight: 400}}>th</span><span style={{fontWeight: 400}}> Street</span></p>
        <p><span style={{fontWeight: 400}}>Denver, CO 80202</span></p>
        <p> </p>

        <FooterUpdator
          showHome={true}
          backHandler={() => this.props.history.goBack() } />
      </div>
    );
  }
}

export default PrivacyPolicy;
