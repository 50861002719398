import React, {useState, useEffect, useRef} from 'react';
require('./style.scss');

function A11yVideoAudioPlayer (props) {
  const [paused, setPaused] = useState(true);
  const [canPlay, setCanPlay] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [timelineHovering, setTimelineHovering] = useState(false);
  const [timelinePlayheadLeft, setTimelinePlayheadLeft] = useState('0px');
  const [timelineBarWidth, setTimelineBarWidth] = useState('0%');
  const video = useRef(null);
  const timeline = useRef(null);

  const onloadedMetaBefore = () => {
    setDuration(video.current.duration);
    setCanPlay(true);
  }

  const onloadedMetaAfter = () => {
    setCanPlay(true);
  }

  useEffect(() => {
    onloadedMetaBefore();
    onloadedMetaAfter();

    video.current.addEventListener('loadedmetadata', onloadedMetaBefore);

    video.current.onpause = (event) => {
      if(!paused) setPaused(true);
    }

    video.current.onended = (event) => {
      video.current.currentTime = 0;
      setPaused(true);
      setTimelinePlayheadLeft('0px');
      setTimelineBarWidth('0px');
    }

    video.current.ontimeupdate = (event) => {
      setCurrentTime(event.target.currentTime);
    };

    timeline.current.onmouseenter = (event) => {
      setTimelineHovering(true);
    }
    timeline.current.onmouseleave = (event) => {
      setTimelineHovering(false);
      setTimelinePlayheadLeft(video.current.currentTime);
    }
    timeline.current.onmousemove = (event) => {
      const left = event.offsetX < 0 ? 0 : event.offsetX;
      setTimelinePlayheadLeft(left + 'px');
    }
    timeline.current.onmousedown = (event) => {
      let time = event.offsetX / event.target.offsetWidth * video.current.duration;
      video.current.currentTime = time;
      play();
    }
  }, []);

  useEffect(() => {
    const percentage = currentTime / duration * 100;
    const width = percentage + '%';
    let left = timelinePlayheadLeft;
    if(!timelineHovering) left = percentage + '%';
    setTimelineBarWidth(width);
    setTimelinePlayheadLeft(left);
  }, [currentTime]);

  const play = () => {
    if(canPlay){
      video.current.play();
      setPaused(false);
    }
  }

  const pause = () => {
    video.current.pause();
    setPaused(true);
  }

  const handlePlayPause = (e) => {
    e.preventDefault();
    if(paused){
      play();
    }else{
      pause();
    }
  }

  const secondsToString = (seconds) => {
    const numminutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60);
    const numseconds = (((seconds % 31536000) % 86400) % 3600) % 60;
    return Math.floor(numminutes)  + ':' + ("0" + Math.floor(numseconds)).slice(-2)
  }

  const total_time = secondsToString(duration);
  const current_time = secondsToString(currentTime);
  const timeline_bar_style = {width: timelineBarWidth};
  const timeline_playhead_style = {left: timelinePlayheadLeft};
  const audioplaypauseclasses = paused ? 'player_play-pause paused' : 'player_play-pause';
  const playpauseclasses = paused ? 'player-controls_play-pause paused' : 'player-controls_play-pause';
  return (
    <div className="a11y-video-audio-player">
      <video ref={video} style={{height: props.height}} playsInline webkit-playsinline="true" crossOrigin="anonymous">
        {props.isAudioPlayer ? <source src={props.src} type="audio/mp3" /> : <source src={props.src} type="video/mp4" />}
        {props.captionsEnabled && <track label="English" kind="subtitles" srcLang="en" src={props.captionsSrc} default />}
      </video>
      {props.isAudioPlayer && <button className={audioplaypauseclasses} onClick={handlePlayPause} />}
      <div className="player-controls">
        <div aria-hidden="true" className="player-controls_timeline">
          <div className="player-controls_timeline_bar" style={timeline_bar_style}></div>
          <div className="player-controls_timeline_playhead" style={timeline_playhead_style}></div>
          <div ref={timeline} className="player-controls_timeline_overlay"></div>
        </div>
        <button aria-label="play and pause video" className={playpauseclasses} onClick={handlePlayPause} />
        <div aria-label={current_time + 'seconds out of ' + total_time + 'seconds'} className="player_time">{current_time + ' / ' + total_time}</div>
      </div>
    </div>
  );
}

export default A11yVideoAudioPlayer;
