import React, { Component } from 'react';
import FooterUpdator from '../FooterUpdator';
import PageHeader from '../PageHeader';
require('./style.scss');

class ForOhFor extends Component {
  render(){
    return (
      <div id="four-oh-four">
        <PageHeader bgColor="tan-accent" bgHeaderColor="tan">
          <h1>404</h1>
        </PageHeader>
        <p>Sorry, this page doesn't exist</p>
        <FooterUpdator
          showHome={true}
          backHandler={() => this.props.history.goBack() } />
      </div>
    );
  }
}

export default ForOhFor;
