import * as PIXI from 'pixi.js';
import * as Matter from 'matter-js';
import { Howl, Howler } from 'howler';
import { TweenMax } from 'gsap';

import ProgressMeter from './ProgressMeter';

import blankWebmSrc from './blank.webm';
import blankOggSrc from './blank.ogg';
import blankMp3Src from './blank.mp3';

class BaseReflection {
  constructor(canvasWrap, props) {
    this.canvasWrap = canvasWrap;
    this.props = props;
    this.assetPaths = [];
  }

  init(onComplete) {
    this.onComplete = onComplete;
    this.tick = 0;
    this.progress = 0;
    this.progressTarget = 0;
    this.setDimensions(this.props.width, this.props.height);
  }

  reset(width = this.width, height = this.height) {
    this.setDimensions(width, height);

    TweenMax.killAll();

    if (this.pixiApp) {
      this.pixiApp.stage.removeChildren();
      this.pixiApp.renderer.resize(width, height);
    }

    if (this.engine) {
      Matter.World.clear(this.engine.world);
    }

    this.tick = 0;
    this.progress = 0;
    this.progressTarget = 0;

    this.createProgressMeter();
  }

  addEventListeners() {
    this.onPointerDownGlobal = this.onPointerDownGlobal.bind(this);

    this.canvasWrap.addEventListener('mousedown', this.onPointerDownGlobal);
    this.canvasWrap.addEventListener('touchstart', this.onPointerDownGlobal);
  }

  removeEventListeners() {
    this.canvasWrap.removeEventListener('mousedown', this.onPointerDownGlobal);
    this.canvasWrap.removeEventListener('touchstart', this.onPointerDownGlobal);
  }

  onPointerDownGlobal() {
    if (!this.sounds.unlocked) {
      this.sounds.blank.play();
      this.sounds.unlocked = true;
    }
  }

  setDimensions(width, height) {
    this.width = width;
    this.height = height;
    this.xOffset = this.canvasWrap.offsetLeft;
    this.yOffset = this.canvasWrap.offsetTop;
    this.dpr = window.devicePixelRatio;
  }

  loadAssets() {
    PIXI.LoaderResource.setExtensionLoadType(
      'mp3',
      PIXI.LoaderResource.LOAD_TYPE.XHR
    );
    PIXI.LoaderResource.setExtensionLoadType(
      'ogg',
      PIXI.LoaderResource.LOAD_TYPE.XHR
    );
    PIXI.LoaderResource.setExtensionLoadType(
      'webm',
      PIXI.LoaderResource.LOAD_TYPE.XHR
    );

    this.assets = null;

    PIXI.utils.destroyTextureCache();
    PIXI.Loader.shared.reset();

    PIXI.Loader.shared.add(this.assetPaths).load((loader, resources) => {
      this.assets = resources;
      this.initRenderer();
      this.initPhysics();
      this.initSounds();
      this.create();
      this.createProgressMeter();

      setTimeout(() => {
        this.canvasWrap.classList.add('ready');
      }, 0);
    });
    PIXI.Loader.shared.onError.add((e) => {
      console.log('PIXI.Loader onError', e);
    });
  }

  initRenderer() {
    this.pixiApp = new PIXI.Application({
      antialias: true,
      autoDensity: true,
      height: this.height,
      resolution: this.dpr,
      transparent: true,
      width: this.width
    });

    if(this.canvasWrap) {
      this.canvasWrap.appendChild(this.pixiApp.view);
    }

    this.pixiApp.ticker.add(this.onTick.bind(this));
  }

  initPhysics() {}

  initSounds() {
    this.sounds = {};
    this.sounds.unlocked = false;

    this.sounds.blank = new Howl({
      src: [blankWebmSrc, blankOggSrc, blankMp3Src],
      volume: 0
    });
  }

  create() {}

  createProgressMeter() {
    let x = 35;
    let y = this.height - 20;
    let radius = 16;
    let lineWidth = 4;
    let backColor = 'hsla(0, 0%, 0%, 0.1)';
    let frontColor = 'hsla(0, 0%, 0%, 1)';
    this.progressMeter = new ProgressMeter(
      x,
      y,
      radius,
      lineWidth,
      backColor,
      frontColor
    );

    if (this.pixiApp) {
      this.pixiApp.stage.addChild(this.progressMeter.sprite);
    }
  }

  onTick() {
    this.tick++;

    this.progress += (this.progressTarget - this.progress) * 0.2;
    this.progressMeter.render(this.progress);
  }

  destroy() {
    this.removeEventListeners();

    TweenMax.killAll();

    if (this.pixiApp) {
      this.pixiApp.renderer.context = null;
      this.pixiApp.destroy(false, true);
      this.pixiApp = null;
    }

    this.canvasWrap = null;

    if(this.assets) {
      this.assets.length = 0;
      this.assets = null;
    }

    Howler.unload();
    this.sounds = null;

    if (this.engine) {
      Matter.World.clear(this.engine.world);
      Matter.Engine.clear(this.engine);
    }
  }
}

export default BaseReflection;
