import * as PIXI from 'pixi.js';
import * as Matter from 'matter-js';

import FloatBuffer from '../../common/FloatBuffer';
import SvgSprite from '../../common/SvgSprite';

class Duck extends SvgSprite {
  constructor(x, y, width, asset, xAnchor = 0.5, yAnchor = 0.5) {
    super(x, y, width, asset, xAnchor, yAnchor);

    this.container = new PIXI.Container();
    this.container.addChild(this.sprite);

    this.sprite.x = 0;
    this.sprite.y = 0;

    this.body = Matter.Bodies.rectangle(
      this.x,
      this.y,
      this.sprite.width,
      this.sprite.height,
      {
        restitution: 0.999,
        friction: 0.001,
        frictionAir: 0.01,
        frictionStatic: 0,
        inertia: Infinity
      }
    );

    this.speedFloatBuffer = new FloatBuffer(50);
    this.speedFloatBuffer.reset(Infinity);

    this.xVelocityFloatBuffer = new FloatBuffer(40);
  }

  update(width, height, ripples) {
    this.speedFloatBuffer.update(this.body.speed);
    this.xVelocityFloatBuffer.update(this.body.velocity.x);

    if (this.speedFloatBuffer.average() < 0.1) {
      this.speedFloatBuffer.reset(Infinity);

      let force = 0.001 * this.body.mass;
      let dx = this.body.position.x - width / 2;
      let dy = this.body.position.y - height / 2;
      let angle = Math.atan2(dy, dx);
      Matter.Body.applyForce(
        this.body,
        { x: this.body.position.x, y: this.body.position.y },
        {
          x: Math.cos(angle) * -force,
          y: Math.sin(angle) * -force
        }
      );
    }

    let i = ripples.length;
    while (i--) {
      let ripple = ripples[i];
      if (!ripple.hasHit) {
        let dx = this.body.position.x - ripple.x;
        let dy = this.body.position.y - ripple.y;
        let radii = this.sprite.width / 2 + ripple.radius;
        if (dx * dx + dy * dy <= radii * radii) {
          ripple.hasHit = true;

          let force = 0.005 * this.body.mass * ripple.strength;
          let angle = Math.atan2(dy, dx);
          Matter.Body.applyForce(
            this.body,
            { x: this.body.position.x, y: this.body.position.y },
            {
              x: Math.cos(angle) * force,
              y: Math.sin(angle) * force
            }
          );
        }
      }
    }

    this.container.x = this.body.position.x;
    this.container.y = this.body.position.y + Math.cos(Date.now() * 0.005) * 2;
    this.container.rotation = Math.sin(Date.now() * 0.005) * 0.05;
    this.container.scale.set(
      this.xVelocityFloatBuffer.average() > 0 ? 1 : -1,
      1
    );
  }
}

export default Duck;
