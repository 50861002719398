import React, { Component } from 'react';
import MyContext from '../../state/MyContext';
import ChallengeMiniCard from '../ChallengeMiniCard';
import image_url from '../../assets/images/illustrations/ideas_empty.png';

//import ChallengeCard from '../ChallengeCard';
//import SlickSlider from 'react-slick';
require('./style.scss');

// const slider_settings = {
//   dots: true,
//   infinite: false,
//   centerMode: true,
//   speed: 500,
//   slidesToShow: 1,
//   slidesToScroll: 1,
//   arrows: false,
//   centerPadding: "20px",
//   focusOnSelect: true,
// };

class UserChallengesSlider extends Component {

  // _getChallengeCardsSlider() { //Old version
  //   return(
  //     <SlickSlider {...slider_settings}>
  //     {
  //       this.context.user_challenges.map((challenge, index) => {
  //         if(!challenge.completed){
  //           return <ChallengeCard
  //                     key={index}
  //                     link={'/challenges/'+challenge.id}
  //                     theme={challenge.challenge.theme_name}
  //                     image_url={challenge.challenge.image_url}
  //                     title={challenge.challenge.title}
  //                     status={challenge.completed ? 'completed' : 'active'} />;
  //         }else{
  //           return null;
  //         }
  //       })
  //     }
  //     </SlickSlider>
  //   )
  // }

  render(){
    const user_has_challenges = this.context.user_challenges.length > 0;
    const active_challenges = this.context.user_challenges.filter(challenge => challenge.completed === false);
    const user_has_active_challenges = user_has_challenges && active_challenges.length > 0;
    return (
      <div className="user-challenges-slider">
        <h2 className="sliderLabel"><i className="icon banner" />Saved Ideas</h2>
        { user_has_active_challenges ?
          <div className="user-challenges-slider_horizonal-scroll">
            {
              this.context.user_challenges.map((challenge, index) => {
                if(!challenge.completed){
                  return <ChallengeMiniCard
                            key={index}
                            link={'/challenges/'+challenge.id}
                            theme={challenge.challenge.theme_name}
                            image_url={challenge.challenge.image_url}
                            title={challenge.challenge.title}
                            status={challenge.completed ? 'completed' : 'active'} />;
                }else{
                  return null;
                }
              })
            }
          </div>
        :
          <React.Fragment>
            { !user_has_challenges ?
              <div className="noChallenge">
                <img src={image_url} alt="" />
                  <div className="textWrap">
                    <h2>Nothing saved yet...</h2>
                    <p>Browse ideas and save one that you want to try!</p>
                </div>
              </div>
            :
            <div className="noChallenge">
                <img src={image_url} alt="" />
                <div className="textWrap">
                  <h2>Nothing to see here...</h2>
                  <p>You tried all your saved ideas. Find another to try!</p>
                </div>
              </div>
            }
          </React.Fragment>
        }
      </div>
    );
  }
}

UserChallengesSlider.contextType = MyContext;
export default UserChallengesSlider;
