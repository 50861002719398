import BaseComponent from '../common/BaseComponent';
import Reflection from './scripts/MonkeyInABarrelReflection';

class MonkeyInABarrelComponent extends BaseComponent {
  constructor(props) {
    super(props);
    this.Reflection = Reflection;
  }
}

export default MonkeyInABarrelComponent;
