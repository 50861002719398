import * as PIXI from 'pixi.js';
import { TweenMax, Back } from 'gsap';

import SvgSprite from '../../common/SvgSprite';

class Boom extends SvgSprite {
  constructor(x, y, width, asset, xAnchor = 0.5, yAnchor = 0.5) {
    super(x, y, width, asset, xAnchor, yAnchor);

    this.sprite.alpha = 0;

    this.container = new PIXI.Container();
    this.container.addChild(this.sprite);
  }

  activate(x, y) {
    this.container.x = x;
    this.container.y = y;
    this.sprite.alpha = 1;

    let obj = { value: 1 };
    TweenMax.to(obj, 0.3, {
      value: 0,
      ease: Back.easeIn.config(3),
      onUpdate: () => {
        this.container.scale.set(obj.value);
      }
    });
  }
}

export default Boom;
