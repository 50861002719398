import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import MyContext from '../../state/MyContext';
import PageHeader from '../PageHeader';
import ChallengeCard from '../ChallengeCard';
//import GuideCard from '../GuideCard';
import ReflectionCard from '../ReflectionCard';
import LevelUpCard from '../LevelUpCard';
import FooterUpdator from '../FooterUpdator';
import GuidesOverviewSlider from '../GuidesOverviewSlider';
import GuidesSlider from '../GuidesSlider';
import moment from 'moment';
require('./style.scss');

class Journey extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      journey_visible: false
    };

    this._onWindowScroll = this._onWindowScroll.bind(this);
  }

  componentDidMount() {
    if(!this.context.user_logged_in) this.props.history.push('/');
    this.context.setState({journey_loaded:false});
    if(!this.context.guides_loaded) this.context.getGuides();
    if(!this.context.user_challenges_loaded) this.context.getUserChallenges();
    if(!this.context.standalone_reflections_loaded) this.context.getStandaloneReflections();
    this.context.getJourney();
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this._onWindowScroll);
  }

  componentDidUpdate() {
    if(this.context.journey_loaded && this.context.user_challenges_loaded && this.context.standalone_reflections_loaded && this.context.guides_loaded && !this.state.ready){
      this.setState({ready:true});
      window.addEventListener('scroll', this._onWindowScroll);
    }
  }

  _onWindowScroll(event) {
    if(!this.state.journey_visible && window.scrollY >= this.context.background_header_height){
      this.setState({journey_visible: true});
      window.removeEventListener('scroll', this._onWindowScroll);
    }
  }

  _getJourneyHistory() {

    let completed_challenges = this.context.user_challenges.filter(challenge => challenge.completed === true);
    completed_challenges.sort((a, b) => moment(b.challenge_completed_at).isAfter(a.challenge_completed_at));
    let standalone_reflections_index = 0;

    return(
      <React.Fragment>
        {
          completed_challenges.map((challenge, index) => {
            const challenge_completed_at = moment(challenge.challenge_completed_at);
            //console.log('CHALLENGE', challenge.challenge_completed_at);

            //Standalone Reflections
            let standalone_reflections = [];
            this.context.standalone_reflections.forEach((standalone_reflection, index2) => {
              const standalone_reflection_completion_date = moment(standalone_reflection.reflection_completed_at);
              const reflection = this.context.reflections.find((reflection) => { return reflection.id === standalone_reflection.reflection_id });

              //First Challenge
              if(index === 0){

                //If any of the reflections were completed after most recent challenge
                if(standalone_reflection_completion_date.isAfter(challenge_completed_at)){
                  standalone_reflections.push(
                    <ReflectionCard
                        key={standalone_reflections_index}
                        image_url={reflection.image_url}
                        type={reflection.type}
                        title={reflection.title}
                        completion_date={challenge.reflection_completed_at} />
                  );
                  standalone_reflections_index++;
                }

              //Inbetween Challenges
              }else if(index < completed_challenges.length){

                //If any of the reflects were completed between this challenge and the previous one
                const prev_challenge_completed_at = moment(completed_challenges[index-1].challenge_completed_at);
                if(standalone_reflection_completion_date.isBetween(challenge_completed_at, prev_challenge_completed_at)){
                  standalone_reflections.push(
                    <ReflectionCard
                        key={standalone_reflections_index}
                        image_url={reflection.image_url}
                        type={reflection.type}
                        title={reflection.title}
                        completion_date={challenge.reflection_completed_at} />
                  );
                  standalone_reflections_index++;
                }
              }
            });

            //Level Up
            let level_up = null;
            if((completed_challenges.length - index) % 4 === 0){
              level_up = <LevelUpCard level={completed_challenges.length - index} />;
            }

            //Reflection
            let the_reflection = null;
            if(challenge.reflection_id !== null && challenge.reflection_id !== undefined){
              const reflection = this.context.reflections.find((reflection) => { return reflection.id === challenge.reflection_id });
              the_reflection = <ReflectionCard
                                  image_url={reflection.image_url}
                                  type={reflection.type}
                                  title={reflection.title}
                                  completion_date={challenge.reflection_completed_at} />
            }

            //Challenge
            const the_challenge = <ChallengeCard
                      key={index}
                      link={'/challenges/'+challenge.id}
                      theme={challenge.challenge.theme_name}
                      image_url={challenge.challenge.image_url}
                      title={challenge.challenge.title}
                      status="completed"
                      completion_date={challenge.challenge_completed_at} />;

            return(
              <React.Fragment key={index}>
                {standalone_reflections}
                {level_up}
                {the_reflection}
                {the_challenge}
              </React.Fragment>
            );
          })
        }
      </React.Fragment>
    )
  }

  _getJourney() {

    //Journey
    // let journey_reflections_classes = 'blue progress-wrap';
    // let journey_challenges_classes = 'green progress-wrap';
    // let reflection_position = this.context.journey.reflections_completed / 2;
    // if(reflection_position < 1 && reflection_position > 0){ reflection_position = 0.5; }else{ reflection_position = Math.floor(reflection_position); }
    // let challenge_position = this.context.journey.challenges_completed / 2;
    // if(challenge_position < 1 && challenge_position > 0){ challenge_position = 0.5; }else{ challenge_position = Math.floor(challenge_position); }
    // if(this.state.journey_visible){
    //   if(reflection_position === 0.5){
    //     journey_reflections_classes += ' fill-begin';
    //   }else if(reflection_position < 6){
    //     journey_reflections_classes += ' fill-'+reflection_position;
    //   }else{
    //     journey_reflections_classes += ' fill-6';
    //   }
    //
    //   if(challenge_position === 0.5){
    //     journey_challenges_classes += ' fill-begin';
    //   }else if(challenge_position < 6){
    //     journey_challenges_classes += ' fill-'+challenge_position;
    //   }else{
    //     journey_challenges_classes += ' fill-6';
    //   }
    // }

    const ideas_saved = this.context.user_challenges.filter(challenge => challenge.completed === false).length;
    const ideas_tried = this.context.journey.challenges_completed;
    const reflections = this.context.journey.reflections_completed + this.context.journey.standalone_reflections_completed;

    return(
      <React.Fragment>
        <PageHeader subhead={false} bgColor="tan" disableFocus={true}>
          {/*<h1>My Journey</h1>*/}
        </PageHeader>
        <div id="journey-diagram">

          <div className="journey-diagram-header">
            <div className="journey-diagram-circle challenges saved">
              <div className="circle" />
              <span>{ideas_saved}</span>
              <p>Ideas Saved</p>
            </div>
            <div className="journey-diagram-circle challenges tried">
              <div className="circle"/>
              <span>{ideas_tried}</span>
              <p>Ideas Tried</p>
            </div>
            <div className="journey-diagram-circle challenges reflections">
              <div className="circle" />
              <span>{reflections}</span>
              <p>Reflections</p>
            </div>
          </div>

          <GuidesOverviewSlider />
          <br /><br /><br />
          <h2 className="sliderLabel nopad"><i className="icon growth" /> Your Skills</h2>
          <GuidesSlider skills={true} yourSkills={true} />

          {/*<div className="journey-diagram-track">
            <div className="journey-diagram-track-inner">
              <div className="white progress-wrap" />
              <div className={journey_reflections_classes} />
              <div className="reflectionCap"></div>
              <div className={journey_challenges_classes} />
              <div className="challengeCap"></div>
            </div>
          </div>*/}

        </div>
        <div id="journey-history">
          <div className="squiggle">
            <svg viewBox="0 0 1000 31.39">
              <path className="pathItem" d="M0,0V29.86c21.61,2.24,42.07-5.11,53.07-6.72,36.48-5.34,61-3.46,98.63,3.15S206.18,27.62,253,11.53s63.77,6.58,91.48,13.9,43.63-6.72,66-1.95C425.35,26.63,452,30.39,471.75,25c7.09-1.93,56.62-5,67.8-5.67C588,16.39,589,36.39,651.56,30.2,680.08,27.37,710,15.39,758,23.3c48.81,8,54.62,2.37,92.26-4.25s60.15-1.49,96.63,3.85c11,1.61,31.46,9,53.07,6.72V0Z"/>
            </svg>
          </div>
          <h2 className="sliderLabel">Your History</h2>
          {this._getJourneyHistory()}
        </div>
      </React.Fragment>
    );
  }

  render(){
    return (
      <div id="journey">
        <Helmet>
          <title>Nod - Journey</title>
        </Helmet>
        { !this.state.ready ?
            <div></div>
          :
            <React.Fragment>{this._getJourney()}</React.Fragment>
        }
        <FooterUpdator normal />
      </div>
    );
  }
}

Journey.contextType = MyContext;
export default Journey;
