import * as PIXI from 'pixi.js';
import { Howl } from 'howler';
import { TweenMax, Back, Sine, Power4 } from 'gsap';

import BaseReflection from '../../common/BaseReflection';
import Calc from '../../common/Calc';
import SvgSprite from '../../common/SvgSprite';

import earSrc from '../images/play.png';
import pauseSrc from '../images/pause.png';

class AudioPlayerReflection extends BaseReflection {
  init(onComplete) {
    super.init(onComplete);

    this.assetPaths = [earSrc, pauseSrc];

    this.loaded = false;
    this.playing = false;
    this.ended = false;
    this.completeTimeout = null;

    this.waves = [];
    this.waveCurrent = 0;
    this.waveTotal = 10;
    this.waveTick = 0;

    // wave config
    this.waveRadius = 110; // radius in pixels
    this.waveColor = 0xffffff; // hex color
    this.waveInterval = 40; // frames in between each wave pulse
    this.waveDuration = 2.5; // seconds
    this.waveEase = Sine.easeOut; // easing from GSAP
    this.waveStartAlpha = 0.5; // starting alpha
    this.waveEndAlpha = 0; // ending alpha
    this.waveStartScale = 0.5; // starting scale
    this.waveEndScale = 0.8; // ending scale

    this.addEventListeners();
    this.loadAssets();
  }

  reset(width = this.width, height = this.height) {
    super.reset(width, height);

    window.clearTimeout(this.completeTimeout);

    this.wavesContainer = null;
    this.waves.length = 0;
    this.circleContainer = null;
    this.circle = null;
    this.loadingIconContainer = null;
    this.loadingIcon = null;
    this.eatIconContainer = null;
    this.earIcon = null;
    this.pauseIconContainer = null;
    this.pauseIcon = null;

    // this.playingTextContainer = null;
    // this.playingText = null;
    // this.pausedTextContainer = null;
    // this.pausedText = null;

    this.create();

    if (this.loaded) {
      if (this.playing) {
        this.showPixiObj(this.pauseIconContainer);
        //this.playingTextContainer.alpha = 1;
      } else {
        this.showPixiObj(this.earIconContainer);
        //this.pausedTextContainer.alpha = 1;
      }
      this.hidePixiObj(this.loadingIconContainer);
    }
  }

  addEventListeners() {
    super.addEventListeners();

    this.onPointerDown = this.onPointerDown.bind(this);

    this.canvasWrap.addEventListener('mousedown', this.onPointerDown);
    this.canvasWrap.addEventListener('touchstart', this.onPointerDown);
  }

  removeEventListeners() {
    super.removeEventListeners();

    this.canvasWrap.removeEventListener('mousedown', this.onPointerDown);
    this.canvasWrap.removeEventListener('touchstart', this.onPointerDown);
  }

  onPointerDown() {
    if (this.loaded) {
      if (this.playing) {
        this.pause();
      } else {
        this.play();
      }
    }
  }

  initPhysics() {
    super.initPhysics();
  }

  initSounds() {
    super.initSounds();

    this.sounds.track = new Howl({
      src: [this.props.src],
      volume: 0.75
    });

    this.sounds.track.once('load', () => {
      this.loaded = true;
      this.showPixiObj(this.earIconContainer);
      this.hidePixiObj(this.loadingIconContainer);
      //this.pausedTextContainer.alpha = 1;
    });

    this.sounds.track.on('loaderror', (id, error) => {
      if (console && console.error) {
        console.error(`Load failure with track at ${this.props.src}`);
        console.error(`Error message: ${error}`);
      }
    });

    this.sounds.track.on('end', () => {
      this.ended = true;
      this.hidePixiObj(this.pauseIconContainer);
      this.showPixiObj(this.earIconContainer);
      //this.playingTextContainer.alpha = 0;
      //this.pausedTextContainer.alpha = 1;
      this.playing = false;
      this.completeTimeout = window.setTimeout(() => {
        this.onComplete();
      }, 2000);
    });
  }

  create() {
    super.create();

    this.createWaves();
    this.createCircle();
    this.createLoadingIcon();
    this.createEarIcon();
    this.createPauseIcon();
    //this.createPausedText();
    //this.createPlayingText();
  }

  createWaves() {
    this.wavesContainer = new PIXI.Container();
    this.wavesContainer.x = this.width / 2;
    this.wavesContainer.y = this.height / 2;
    this.pixiApp.stage.addChild(this.wavesContainer);

    for (let i = 0; i < this.waveTotal; i++) {
      let wave = {};

      wave.container = new PIXI.Container();
      wave.container.scale.set(0);
      wave.container.alpha = 0;

      wave.circle = new PIXI.Graphics();
      wave.circle.lineStyle(0);
      wave.circle.beginFill(this.waveColor, 1);
      wave.circle.drawCircle(0, 0, this.waveRadius);
      wave.circle.endFill();

      wave.container.addChild(wave.circle);

      this.waves.push(wave);
      this.wavesContainer.addChild(wave.container);
    }
  }

  createCircle() {
    this.circleContainer = new PIXI.Container();
    this.circleContainer.x = this.width / 2;
    this.circleContainer.y = this.height / 2;
    this.pixiApp.stage.addChild(this.circleContainer);

    this.circle = new PIXI.Graphics();
    this.circle.lineStyle(0);
    const c = document.createElement("canvas");
    const ctx = c.getContext("2d");
    const grd = ctx.createLinearGradient(0,0,55,55);
    grd.addColorStop(0, '#31CD6A');
    grd.addColorStop(1, '#31AFFF');
    ctx.fillStyle = grd;
    ctx.fillRect(0,0,55,55);
    this.circle.beginTextureFill(new PIXI.Texture.from(c));

    //this.circle.beginFill(0x31AFFF, 1);
    this.circle.drawCircle(0, 0, 55);
    this.circle.endFill();
    this.circleContainer.addChild(this.circle);
  }

  createLoadingIcon() {
    this.loadingIconContainer = new PIXI.Container();
    this.loadingIconContainer.x = this.width / 2;
    this.loadingIconContainer.y = this.height / 2;
    this.pixiApp.stage.addChild(this.loadingIconContainer);

    let radius = 20;
    let lineWidth = 5;
    let width = radius * 2 + lineWidth + 2;
    let height = radius * 2 + lineWidth + 2;
    let canvas = document.createElement('canvas');
    let ctx = canvas.getContext('2d');
    canvas.width = width * this.dpr;
    canvas.height = height * this.dpr;
    ctx.scale(this.dpr, this.dpr);
    ctx.beginPath();
    ctx.arc(width / 2, height / 2, radius, 0, Math.PI);
    ctx.lineWidth = lineWidth;
    ctx.strokeStyle = '#fff';
    ctx.stroke();

    this.loadingIcon = new PIXI.Sprite.from(canvas);
    this.loadingIcon.anchor.set(0.5);
    this.loadingIcon.scale.set(1 / this.dpr);
    this.loadingIconContainer.addChild(this.loadingIcon);

    canvas = null;
    ctx = null;
  }

  createEarIcon() {
    this.earIconContainer = new PIXI.Container();
    this.earIconContainer.x = this.width / 2;
    this.earIconContainer.y = this.height / 2;
    this.earIconContainer.alpha = 0;
    this.earIconContainer.scale.set(0);
    this.pixiApp.stage.addChild(this.earIconContainer);

    this.earIcon = new SvgSprite(
      0,
      0,
      this.assets[earSrc].data.width / 2,
      this.assets[earSrc]
    );
    this.earIconContainer.addChild(this.earIcon.sprite);
  }

  createPauseIcon() {
    this.pauseIconContainer = new PIXI.Container();
    this.pauseIconContainer.x = this.width / 2;
    this.pauseIconContainer.y = this.height / 2;
    this.pauseIconContainer.alpha = 0;
    this.pauseIconContainer.scale.set(0);
    this.pixiApp.stage.addChild(this.pauseIconContainer);

    this.pauseIcon = new SvgSprite(
      0,
      0,
      this.assets[pauseSrc].data.width / 2,
      this.assets[pauseSrc]
    );
    this.pauseIconContainer.addChild(this.pauseIcon.sprite);
  }

  // createPlayingText() {
  //   this.playingTextContainer = new PIXI.Container();
  //   this.playingTextContainer.x = this.width / 2 - 45;
  //   this.playingTextContainer.y = this.height / 2 + 70;
  //   this.playingTextContainer.alpha = 0;
  //   this.pixiApp.stage.addChild(this.playingTextContainer);
  //
  //   this.playingText = new PIXI.Text('Audio Playing', {fontFamily : 'gesta', fontSize: 16, fill : 0x2A2A24, align : 'center'});
  //   this.playingTextContainer.addChild(this.playingText);
  // };
  //
  // createPausedText(){
  //   this.pausedTextContainer = new PIXI.Container();
  //   this.pausedTextContainer.x = this.width / 2 - 45;
  //   this.pausedTextContainer.y = this.height / 2 + 70;
  //   this.pausedTextContainer.alpha = 0;
  //   this.pixiApp.stage.addChild(this.pausedTextContainer);
  //
  //   this.pausedText = new PIXI.Text('Audio Paused', {fontFamily : 'gesta', fontSize: 16, fill : 0x2A2A24, align : 'center'});
  //   this.pausedTextContainer.addChild(this.pausedText);
  // };

  showPixiObj(pixiObj, ease = Back.easeOut) {
    let obj = { value: 0 };
    TweenMax.to(obj, 0.4, {
      value: 1,
      ease: ease,
      onUpdate: () => {
        pixiObj.alpha = obj.value;
        pixiObj.scale.set(obj.value);
      }
    });
  }

  hidePixiObj(pixiObj, ease = Power4.easeOut) {
    let obj = { value: 1 };
    TweenMax.to(obj, 0.4, {
      value: 0,
      ease: ease,
      onUpdate: () => {
        pixiObj.alpha = obj.value;
        pixiObj.scale.set(obj.value);
      }
    });
  }

  play() {
    this.sounds.track.play();
    this.playing = true;

    this.waveTick = 0;

    this.showPixiObj(this.pauseIconContainer);
    this.hidePixiObj(this.earIconContainer);
    //this.playingTextContainer.alpha = 1;
    //this.pausedTextContainer.alpha = 0;
  }

  pause() {
    this.sounds.track.pause();
    this.playing = false;

    this.showPixiObj(this.earIconContainer);
    this.hidePixiObj(this.pauseIconContainer);
    //this.playingTextContainer.alpha = 0;
    //this.pausedTextContainer.alpha = 1;
  }

  onTick() {
    super.onTick();

    if (!this.loaded) {
      this.loadingIconContainer.rotation += 0.1;
    }

    if (
      this.playing &&
      (this.waveTick % this.waveInterval === 0 || this.waveTick === 0)
    ) {
      let wave = this.waves[this.waveCurrent];
      let obj = { value: 0 };
      TweenMax.to(obj, this.waveDuration, {
        value: 1,
        ease: this.waveEase,
        onUpdate: () => {
          wave.container.alpha = Calc.map(
            obj.value,
            0,
            1,
            this.waveStartAlpha,
            this.waveEndAlpha
          );
          wave.container.scale.set(
            Calc.map(obj.value, 0, 1, this.waveStartScale, this.waveEndScale)
          );
        }
      });
      this.waveCurrent++;
      if (this.waveCurrent >= this.waveTotal) {
        this.waveCurrent = 0;
      }
    }

    this.waveTick++;

    let current = this.sounds.track.seek() || 0;
    let duration = this.sounds.track.duration();
    if (typeof current === 'number' && typeof duration === 'number') {
      this.progressTarget = this.ended ? 1 : current / duration;
    }
  }

  destroy() {
    super.destroy();

    window.clearTimeout(this.completeTimeout);

    this.wavesContainer = null;
    this.waves.length = 0;
    this.waves = null;
    this.circleContainer = null;
    this.circle = null;
    this.loadingIconContainer = null;
    this.loadingIcon = null;
    this.eatIconContainer = null;
    this.earIcon = null;
    this.pauseIconContainer = null;
    this.pauseIcon = null;
  }
}

export default AudioPlayerReflection;
