import React, { Component } from 'react';
require('./style.scss');

class SignInForm extends Component {

  constructor (props) {
    super(props);
    this.state = {
      email_value: '',
      password_value: '',
      validation_message: '',
      submit_disabled: false,
    }

    //Bindings & References
    this._handleEmailChange = this._handleEmailChange.bind(this);
    this._handlePasswordChange = this._handlePasswordChange.bind(this);
    this._handleSubmit = this._handleSubmit.bind(this);
  }

  _handleEmailChange(event) {
    this.setState({email_value: event.target.value});
  }

  _handlePasswordChange(event) {
    this.setState({password_value: event.target.value});
  }

  _handleSubmit(e) {
    e.preventDefault();

    let valid = true;
    let valid_message = '';
    this.setState({validation_message: valid_message});

    //Check email
    if(!this._isValidEmail(this.state.email_value)){
      valid = false;
      valid_message += 'Please use a valid email address. ';
    }

    //Check password
    if(!this._isValidPassword(this.state.password_value)){
      valid = false;
      valid_message += 'Please use a password that is 8 characters or longer. ';
    }

    if(valid){
      this.setState({submit_disabled: true});
      this.props.onSubmit(this.state.email_value, this.state.password_value, (error, message) => {
        if(error) this.setState({validation_message: message, submit_disabled: false});
      });
    }else{
      this.setState({validation_message: valid_message});
    }
  }

  _isValidEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape
    return re.test(email);
  }

  _isValidPassword(password) {
    return (password.length < 8) ? false : true;
  }

  render(){
    return(
      <form data-testid="form" onSubmit={this._handleSubmit}>
        <label htmlFor="email" aria-label="Set Your Email">your email</label>
        <input id="email" type="text" value={this.state.email_value} onChange={this._handleEmailChange} placeholder="email" />
        <label htmlFor="password" aria-label="Set Your Password">password</label>
        <input id="password" type="password" value={this.state.password_value} onChange={this._handlePasswordChange} placeholder="password" />
        {(this.state.validation_message !== '') ? <p className="validationText">{this.state.validation_message}</p> : null}
        <input type="submit" value="Login" disabled={ this.state.submit_disabled ? 'disabled' : false } />
      </form>
    );
  }
}

export default SignInForm;
