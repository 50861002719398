import * as PIXI from 'pixi.js';
import { TweenMax, Power4 } from 'gsap';

import Calc from '../../common/Calc';
import SvgSprite from '../../common/SvgSprite';

class Sphere extends SvgSprite {
  constructor(x, y, width, asset, xAnchor = 0.5, yAnchor = 0.5) {
    super(x, y, width, asset, xAnchor, yAnchor);

    this.sprite.alpha = 0;
    this.sprite.rotation = Calc.rand(0, Math.PI * 2);

    this.container = new PIXI.Container();
    this.container.addChild(this.sprite);
  }

  activate(x, y) {
    this.sprite.alpha = 1;
    this.container.x = x;
    this.container.y = y;

    let obj = { value: 1 };
    TweenMax.to(obj, 2, {
      value: 0,
      ease: Power4.easeOut,
      onUpdate: () => {
        this.container.y = y - 10 * (1 - obj.value);
        this.container.scale.set(obj.value);
      }
    });
  }
}

export default Sphere;
