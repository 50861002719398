import * as Matter from 'matter-js';
import { Howl } from 'howler';
import { TweenMax, Back } from 'gsap';

import BaseReflection from '../../common/BaseReflection';
import Calc from '../../common/Calc';
import RectSprite from '../../common/RectSprite';
import SvgSprite from '../../common/SvgSprite';

import Bubble from './Bubble';
import Sphere from './Sphere';

import bubbleSrc from '../images/bubble.png';
import makerSrc from '../images/maker.png';
import sphereSrc from '../images/sphere.png';

import makeWebmSrc from '../sounds/make.webm';
import makeOggSrc from '../sounds/make.ogg';
import makeMp3Src from '../sounds/make.mp3';
import burst0WebmSrc from '../sounds/burst-0.webm';
import burst0OggSrc from '../sounds/burst-0.ogg';
import burst0Mp3Src from '../sounds/burst-0.mp3';
import burst1WebmSrc from '../sounds/burst-1.webm';
import burst1OggSrc from '../sounds/burst-1.ogg';
import burst1Mp3Src from '../sounds/burst-1.mp3';
import burst2WebmSrc from '../sounds/burst-2.webm';
import burst2OggSrc from '../sounds/burst-2.ogg';
import burst2Mp3Src from '../sounds/burst-2.mp3';
import burst3WebmSrc from '../sounds/burst-3.webm';
import burst3OggSrc from '../sounds/burst-3.ogg';
import burst3Mp3Src from '../sounds/burst-3.mp3';
import burst4WebmSrc from '../sounds/burst-4.webm';
import burst4OggSrc from '../sounds/burst-4.ogg';
import burst4Mp3Src from '../sounds/burst-4.mp3';

class BlowBubblesReflection extends BaseReflection {
  init(onComplete) {
    super.init(onComplete);

    this.assetPaths = [
      bubbleSrc,
      makerSrc,
      sphereSrc,
      burst0WebmSrc,
      burst0OggSrc,
      burst0Mp3Src,
      burst1WebmSrc,
      burst1OggSrc,
      burst1Mp3Src,
      burst2WebmSrc,
      burst2OggSrc,
      burst2Mp3Src,
      burst3WebmSrc,
      burst3OggSrc,
      burst3Mp3Src,
      burst4WebmSrc,
      burst4OggSrc,
      burst4Mp3Src
    ];

    this.bubbles = [];
    this.bubbleCurrent = 0;
    this.bubbleBurstCurrent = 0;
    this.bubbleCount = 10;

    this.spheres = [];
    this.sphereCurrent = 0;
    this.spheresPerBubble = 5;
    this.sphereCount = this.bubbleCount * this.spheresPerBubble;

    this.burstTimeout = null;
    this.completeTimeout = null;
    this.makerTween = null;

    this.addEventListeners();
    this.loadAssets();
  }

  reset(width = this.width, height = this.height) {
    super.reset(width, height);

    window.clearTimeout(this.burstTimeout);
    window.clearTimeout(this.completeTimeout);

    this.maker = null;
    this.bubbles.length = 0;
    this.spheres.length = 0;

    this.create();
  }

  addEventListeners() {
    super.addEventListeners();
  }

  removeEventListeners() {
    super.removeEventListeners();
  }

  initPhysics() {
    super.initPhysics();

    this.engine = Matter.Engine.create();
    this.engine.world.gravity.y = -1;
    Matter.Engine.run(this.engine);
  }

  initSounds() {
    super.initSounds();

    this.sounds.make = new Howl({
      src: [makeWebmSrc, makeOggSrc, makeMp3Src],
      volume: 1
    });

    this.sounds.burst = [];
    [
      [burst0WebmSrc, burst0OggSrc, burst0Mp3Src],
      [burst1WebmSrc, burst1OggSrc, burst1Mp3Src],
      [burst2WebmSrc, burst2OggSrc, burst2Mp3Src],
      [burst3WebmSrc, burst3OggSrc, burst3Mp3Src],
      [burst4WebmSrc, burst4OggSrc, burst4Mp3Src]
    ].forEach(src => {
      this.sounds.burst.push(
        new Howl({
          src: src,
          volume: 0.25
        })
      );
    });
  }

  create() {
    super.create();

    this.createWalls();
    this.createBubbles();
    this.createSpheres();
    this.createMaker();
  }

  createWalls() {
    const buffer = 1000;
    const wallOptions = {
      isStatic: true,
      restitution: 0.5,
      friction: 0.5
    };

    this.wallTop = Matter.Bodies.rectangle(
      this.width / 2,
      -buffer / 2,
      this.width + buffer,
      buffer,
      wallOptions
    );
    Matter.World.add(this.engine.world, this.wallTop);

    this.wallBottom = Matter.Bodies.rectangle(
      this.width / 2,
      this.height + buffer / 2,
      this.width + buffer,
      buffer,
      wallOptions
    );
    Matter.World.add(this.engine.world, this.wallBottom);

    this.wallLeft = Matter.Bodies.rectangle(
      -buffer / 2,
      this.height / 2,
      buffer,
      this.height + buffer,
      wallOptions
    );
    Matter.World.add(this.engine.world, this.wallLeft);

    this.wallRight = Matter.Bodies.rectangle(
      this.width + buffer / 2,
      this.height / 2,
      buffer,
      this.height + buffer,
      wallOptions
    );
    Matter.World.add(this.engine.world, this.wallRight);
  }

  createBubbles() {
    for (let i = 0; i < this.bubbleCount; i++) {
      let width = Calc.rand(50, 70);
      let bubble = new Bubble(0, 0, width, this.assets[bubbleSrc]);
      this.pixiApp.stage.addChild(bubble.container);
      this.bubbles.push(bubble);
    }
  }

  createSpheres() {
    for (let i = 0; i < this.sphereCount; i++) {
      let width = Calc.rand(5, 20);
      let sphere = new Sphere(0, 0, width, this.assets[sphereSrc]);
      this.pixiApp.stage.addChild(sphere.container);
      this.spheres.push(sphere);
    }
  }

  createMaker() {
    let width = 50;
    this.maker = new SvgSprite(
      this.width / 2,
      this.height - width * 0.28,
      50,
      this.assets[makerSrc],
      0.5,
      0.92
    );
    this.makerWidth = this.maker.sprite.width;
    this.makerHeight = this.maker.sprite.height;
    this.pixiApp.stage.addChild(this.maker.sprite);

    this.makerBox = new RectSprite(
      this.width / 2,
      this.height,
      200,
      200,
      'hsla(0, 100%, 50%, 0)',
      0.5,
      1
    );
    this.makerBox.sprite.interactive = true;
    this.makerBox.sprite.on('pointerdown', e => {
      if (this.bubbleCurrent < this.bubbleCount) {
        this.sounds.make.rate(
          Calc.map(this.bubbleCurrent, 0, this.bubbleCount - 1, 0.75, 1.5)
        );
        this.sounds.make.play();

        this.maker.sprite.width = this.makerWidth - 5;
        this.maker.sprite.height = this.makerHeight - 5 / this.maker.srcRatio;

        if (this.makerTween) {
          this.makerTween.kill();
        }
        this.makerTween = TweenMax.to(this.maker.sprite, 0.2, {
          width: this.makerWidth,
          height: this.makerHeight,
          ease: Back.easeOut.config(2)
        });

        let rotation = this.maker.sprite.rotation - Math.PI / 2;
        let mag = this.maker.sprite.height * 0.8;
        let x = this.width / 2 + Math.cos(rotation) * mag;
        let y = this.height + Math.sin(rotation) * mag;
        this.bubbles[this.bubbleCurrent].activate(this.engine.world, x, y);
        this.bubbleCurrent++;
        if (!this.burstTimeout && this.bubbleCurrent === this.bubbleCount) {
          TweenMax.to(this.maker.sprite, 0.5, {
            y: this.height + this.maker.sprite.height * 1.1,
            ease: Back.easeIn.config(2)
          });
          this.burstTimeout = window.setTimeout(() => {
            this.bursting = true;
          }, 1000);
        }
      }
      this.progressTarget = this.bubbleCurrent / this.bubbleCount;
    });
    this.pixiApp.stage.addChild(this.makerBox.sprite);
  }

  onTick() {
    super.onTick();

    for (let i = 0, len = this.bubbles.length; i < len; i++) {
      this.bubbles[i].update();
    }

    this.maker.sprite.rotation = Math.sin(Date.now() * 0.001) * 0.4;

    if (
      this.bursting &&
      this.tick % 5 === 0 &&
      this.bubbleBurstCurrent < this.bubbleCount
    ) {
      for (let i = 0; i < this.spheresPerBubble; i++) {
        let range =
          this.bubbles[this.bubbleBurstCurrent].sprite.width / 2 -
          this.spheres[this.sphereCurrent].sprite.width / 2;
        let x =
          this.bubbles[this.bubbleBurstCurrent].container.x +
          Calc.rand(-range, range);
        let y =
          this.bubbles[this.bubbleBurstCurrent].container.y +
          Calc.rand(-range, range);
        this.spheres[this.sphereCurrent].activate(x, y);
        this.sphereCurrent++;
      }

      this.bubbles[this.bubbleBurstCurrent].burst(this.engine.world);

      let sound = this.sounds.burst[
        this.bubbleBurstCurrent % this.sounds.burst.length
      ];
      sound.rate(Calc.rand(0.5, 0.75));
      sound.play();
      this.bubbleBurstCurrent++;

      if (
        !this.completeTimeout &&
        this.bubbleBurstCurrent === this.bubbleCount
      ) {
        this.completeTimeout = window.setTimeout(() => {
          this.onComplete();
        }, 1500);
      }
    }
  }

  destroy() {
    super.destroy();

    window.clearTimeout(this.burstTimeout);
    window.clearTimeout(this.completeTimeout);

    this.maker = null;
    this.bubbles.length = 0;
    this.bubbles = null;
    this.spheres.length = 0;
    this.spheres = null;
  }
}

export default BlowBubblesReflection;
