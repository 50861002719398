import React, { Component } from 'react';
import MyContext from '../../state/MyContext';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";
//import SlickSlider from "react-slick";
//import PageHeader from '../PageHeader';
import FooterModal from '../FooterModal';
//import ChallengeCard from '../ChallengeCard';
import FeaturedChallenges from '../FeaturedChallenges';
import GuidesOverviewSlider from '../GuidesOverviewSlider';
import UserChallengesSlider from '../UserChallengesSlider';
import MoodMeter from '../MoodMeter';
import img_yoga from '../../assets/images/illustrations/yoga.png';
require('./style.scss');

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ready:false,

      mood_changed: false,
      mood_balance: 'neutral',
      mood_coords: [5,5],
      mood_position: [0.5, 0.5],
      mood_coord_label: '',
    }

    //References & Bindings
    this.footerModal = React.createRef();
    this.footerModalOSNotifications = React.createRef();
  }

  componentDidMount() {
    this.context.updateFooter();
    this.context.updateBackground();

    if(this.context.user_challenge_just_completed) this.context.setState({user_challenge_just_completed:false});
    //if(this.context.came_from_push_notification) this.context.setState({came_from_push_notification:false});

    if(this.context.user_challenge_just_saved || this.context.user_challenge_just_cancelled){ //For a11y alerts
      setTimeout(() => {
        this.context.setState({
          user_challenge_just_saved: false,
          user_challenge_just_cancelled: false
        });
      }, 3000);
    }

    //Preload Content
    if(!this.context.guides_loaded) this.context.getGuides();
    if(!this.context.challenges_loaded) this.context.getChallenges();
    if(!this.context.user_challenges_loaded) this.context.getUserChallenges();
    if(this.context.guides_loaded && this.context.challenges_loaded && this.context.user_challenges_loaded){
      this.context.sortFeaturedChallenges();
      //this.context.setState({homepage_visit_count: this.context.homepage_visit_count + 1});
      this.setState({ready:true});
    }
  }

  componentDidUpdate() {
    if(this.context.guides_loaded && this.context.challenges_loaded && this.context.user_challenges_loaded && !this.state.ready){
      this.context.sortFeaturedChallenges();
      this.setState({ready:true});
    }
  }

  // _getUserChallenges() {
  //   const slider_settings = {
  //     dots: true,
  //     infinite: false,
  //     centerMode: true,
  //     speed: 500,
  //     slidesToShow: 1,
  //     slidesToScroll: 1,
  //     arrows: false,
  //     centerPadding: "20px",
  //     focusOnSelect: true,
  //   };
  //
  //   return (
  //     <div id="active-challenges">
  //       <SlickSlider {...slider_settings}>
  //       {
  //         this.context.user_challenges.map((challenge, index) => {
  //           if(!challenge.completed){
  //             return <ChallengeCard
  //                       key={index}
  //                       link={'/challenges/'+challenge.id}
  //                       theme={challenge.challenge.theme_name}
  //                       image_url={challenge.challenge.image_url}
  //                       title={challenge.challenge.title}
  //                       status={challenge.completed ? 'completed' : 'active'} />;
  //           }else{
  //             return null;
  //           }
  //         })
  //       }
  //       </SlickSlider>
  //     </div>
  //   );
  // }

  _getReflection() {
    return(
      <div className="reflection">
        <div className="squiggle">
          <svg viewBox="0 0 1000 31.39">
            <path className="pathItem" d="M0,0V29.86c21.61,2.24,42.07-5.11,53.07-6.72,36.48-5.34,61-3.46,98.63,3.15S206.18,27.62,253,11.53s63.77,6.58,91.48,13.9,43.63-6.72,66-1.95C425.35,26.63,452,30.39,471.75,25c7.09-1.93,56.62-5,67.8-5.67C588,16.39,589,36.39,651.56,30.2,680.08,27.37,710,15.39,758,23.3c48.81,8,54.62,2.37,92.26-4.25s60.15-1.49,96.63,3.85c11,1.61,31.46,9,53.07,6.72V0Z"/>
          </svg>
        </div>
        <img className="reflection-image" alt="Yoga Light" src={img_yoga} />
        <h2>Feeling restless?</h2>
        <h1>Find some inner calm with a reflection.</h1>
        <Link className="button" to="/reflect/mood">Start Reflection</Link>
        <div className="squiggle bottom">
          <svg viewBox="0 0 1000 31.39">
            <path className="pathItem" d="M0,0V29.86c21.61,2.24,42.07-5.11,53.07-6.72,36.48-5.34,61-3.46,98.63,3.15S206.18,27.62,253,11.53s63.77,6.58,91.48,13.9,43.63-6.72,66-1.95C425.35,26.63,452,30.39,471.75,25c7.09-1.93,56.62-5,67.8-5.67C588,16.39,589,36.39,651.56,30.2,680.08,27.37,710,15.39,758,23.3c48.81,8,54.62,2.37,92.26-4.25s60.15-1.49,96.63,3.85c11,1.61,31.46,9,53.07,6.72V0Z"/>
          </svg>
        </div>
      </div>
    )
  }

  render() {
    const num_of_completed_user_challenges = this.context.getNumberOfCompletedUserChallenges();

    // const handleNotificationsPopUpSubmit = () => {
    //   this.context.areOsNotificationsEnabled().then(granted => {
    //     if(granted){
    //       this.context.setNotificationSetting('notifications', true);
    //       this.context.setNotificationSetting('notificationLastAsked', new Date().getTime());
    //       this.context.setNotificationSetting('notificationHomepageAsked', true);
    //       this.footerModal.current.hide();
    //
    //       //KeenIO Event
    //       this.context.sendKeenEvent('notification', {
    //         page_title: 'home',
    //         skipped:false,
    //         os_declined: false,
    //         snoozed:false,
    //         reminder_selection: null,
    //         notification_title: null,
    //         marked_complete: false,
    //         push_notification: false
    //       });
    //
    //     }else{
    //       this.context.requestEnableOsNotifications().then(granted => {
    //         if(granted){
    //           this.context.setNotificationSetting('notifications', true);
    //           this.context.setNotificationSetting('notificationLastAsked', new Date().getTime());
    //           this.context.setNotificationSetting('notificationHomepageAsked', true);
    //           this.footerModal.current.hide();
    //
    //           //KeenIO Event
    //           this.context.sendKeenEvent('notification', {
    //             page_title: 'home',
    //             skipped:false,
    //             os_declined: false,
    //             snoozed:false,
    //             reminder_selection: null,
    //             notification_title: null,
    //             marked_complete: false,
    //             push_notification: false
    //           });
    //
    //         }else{
    //           this.footerModalOSNotifications.current.show();
    //
    //           //KeenIO Event
    //           this.context.sendKeenEvent('notification', {
    //             page_title: 'home',
    //             skipped:false,
    //             os_declined: true,
    //             snoozed:false,
    //             reminder_selection: null,
    //             notification_title: null,
    //             marked_complete: false,
    //             push_notification: false
    //           });
    //         }
    //       });
    //     }
    //   });
    // }

    // const handleNotificationsPopUpClose = () => {
    //   this.context.setNotificationSetting('notificationLastAsked', new Date().getTime());
    //   this.context.setNotificationSetting('notificationHomepageAsked', true);
    //
    //   //KeenIO Event
    //   this.context.sendKeenEvent('notification', {
    //     page_title: 'home',
    //     skipped:true,
    //     os_declined: false,
    //     snoozed:false,
    //     reminder_selection: null,
    //     notification_title: null,
    //     marked_complete: false,
    //     push_notification: false
    //   });
    // }

    const handleMoodChange = (mood_balance, mood_coords, mood_position, mood_coord_label) => {
      //console.log('mood change', mood_balance, mood_coords, mood_position, coord_label);
      this.setState({
        mood_changed: true,
        mood_balance: mood_balance,
        mood_coords: mood_coords,
        mood_position: mood_position,
        mood_coord_label: mood_coord_label
      })
    };

    const setMood = () => {
      this.context.reflectSetMood(
        true, //changed
        this.state.mood_balance,
        this.state.mood_coords,
        this.state.mood_position,
        this.state.mood_coord_label
      ).then(() => {

        //KeenIO
        // let guide_title = null;
        // let challenge_id = null;
        // let challenge_title = null;
        // let last_challenge_rating = null;
        // if(this.context.user_challenge_just_completed){
        //   const challenge = this.context.challenges.find((thechallenge)  => { return thechallenge.id === this.context.reflect_came_from_challenge_id; });
        //   challenge_id = challenge.id;
        //   challenge_title = challenge.title;
        //   const guide = this.context.guides.find((theguide) => { return theguide.id === challenge.guide_id; });
        //   guide_title = guide.title;
        //   last_challenge_rating = this.context.relfect_last_challenge_rating;
        // }
        this.context.sendKeenEvent('mood', {
          skip: false,
          guide_title: null,
          challenge_id: null,
          challenge_title: null,
          mood_coords_x: this.state.mood_coords[0],
          mood_coords_y: this.state.mood_coords[1],
          mood_balance: this.state.mood_balance,
          mood_label: this.state.mood_coord_label,
          mood_changed: this.state.mood_changed,
          rating: null,
          mood_meter_on_homepage: true,
        });

        this.context.sendMixPanelEvent('mood', {
          skip: false,
          guide_title: null,
          challenge_id: null,
          challenge_title: null,
          mood_coords_x: this.state.mood_coords[0],
          mood_coords_y: this.state.mood_coords[1],
          mood_balance: this.state.mood_balance,
          mood_label: this.state.mood_coord_label,
          mood_changed: this.state.mood_changed,
          rating: null,
          mood_meter_on_homepage: true,
        });

        setTimeout(() => this.props.history.push('/reflect/skills'), 100);
      });
    }

    return (
      <MyContext.Consumer>
        {(context) => (
          <div id="home">
            <Helmet>
              <title>Nod - Home</title>
            </Helmet>
            { !this.state.ready ?
              <div></div>
              :
              <React.Fragment>
                { this.context.user_challenges_loaded ?
                  <React.Fragment>

                    {this.context.user_challenge_just_saved && <p role="alert" style={{opacity:'0'}}>Your idea is saved.</p>}
                    {this.context.user_challenge_just_cancelled && <p role="alert" style={{opacity:'0'}}>Your idea has been cancelled.</p>}

                    <GuidesOverviewSlider showName={true} hideTopSquiggle={true} />
                    <UserChallengesSlider />
                    <FeaturedChallenges />
                    {/*this._getReflection()*/}

                    <div className="mood-meter-wrap">
                      <h4>Not feeling social yet?</h4>
                      <h1>Check in with yourself instead</h1>
                      <MoodMeter
                        a11yLabel="What is your current vibe?"
                        lockWindowWhenClick={true}
                        initialPosition={this.state.mood_position}
                        onChange={(mood_balance, mood_coords, mood_position, mood_coord_label) => {
                          handleMoodChange(mood_balance, mood_coords, mood_position, mood_coord_label);
                        }} />

                        <button className={'mood-button button'} onClick={() => setMood()}>Set Mood</button>
                        <div className="squiggle bottom">
                          <svg viewBox="0 0 1000 31.39">
                            <path className="pathItem" d="M0,0V29.86c21.61,2.24,42.07-5.11,53.07-6.72,36.48-5.34,61-3.46,98.63,3.15S206.18,27.62,253,11.53s63.77,6.58,91.48,13.9,43.63-6.72,66-1.95C425.35,26.63,452,30.39,471.75,25c7.09-1.93,56.62-5,67.8-5.67C588,16.39,589,36.39,651.56,30.2,680.08,27.37,710,15.39,758,23.3c48.81,8,54.62,2.37,92.26-4.25s60.15-1.49,96.63,3.85c11,1.61,31.46,9,53.07,6.72V0Z"/>
                          </svg>
                        </div>
                    </div>
                    <div className="feedback">
                      <h1>Help us make Nod better.</h1>
                      <div className="textLink">
                        <Link to="/feedback">Give Feedback</Link>
                      </div>
                    </div>
                  </React.Fragment>
                  :
                  <div>Loading Challenges...</div>
                }

                { this.context.user_onboarded_just_now ?
                  <FooterModal ref={this.footerModal} className="text-align-left" parentId="home">
                    <h3>Ok, time to explore.</h3>
                    { this.context.onboarding_motivations_changed ?
                      <p>We’ll serve up ideas geared toward your priorities, like <span className="highlighted-text">{this.context.user_motivations[0].name.toLowerCase()}</span>. This is your space to:</p>
                    :
                      <p>We’ll serve up ideas to help you stay connected. This is your space to:</p>
                    }
                    <ol>
                      <li>Browse Ideas</li>
                      <li>Save the ones you want to try</li>
                      <li>Reflect on how it went</li>
                    </ol>
                    {/*<p>Browse ideas and tap <span className="save-badge"></span> when you find one that sounds interesting.</p>*/}
                    <a href="/" className="button" onClick={(e) => { e.preventDefault(); this.footerModal.current.hide();  }}>Explore Nod</a>
                    <br /><br />
                  </FooterModal>
                  :
                  null
                }
                { (this.context.user_challenge_just_completed && num_of_completed_user_challenges <= 1) ?
                    <FooterModal parentId="home">
                      <h3>Nice Work!</h3>
                      <p>Your progress has been added to My Journey. Keep up the momentum. Pick a new challenge from your dashboard.</p>
                      <br /><br />
                    </FooterModal>
                  :
                  null
                }
                {/* this.context.homepage_visit_count >= 1
                  && this.context.getNotificationSetting('notifications') === 'false'
                  && this.context.getNotificationSetting('notificationHomepageAsked') === 'false'
                  && !this.context.user_onboarded_just_now
                  && !(this.context.user_challenge_just_completed && num_of_completed_user_challenges <= 1) ?
                    <React.Fragment>
                      <FooterModal ref={this.footerModal} notificationsPopUp={true} onClose={handleNotificationsPopUpClose}>
                        <p><strong>Life gets crazy busy sometimes</strong></p>
                        <p>Stay on top of your social life with the occasional suggested challenge or reminder to reflect. No spam, just useful stuff.</p>
                        <button className="button" onClick={handleNotificationsPopUpSubmit}>Allow check-ins</button>
                      </FooterModal>
                      <FooterModal ref={this.footerModalOSNotifications} visible={false} ignoreInit={true}>
                        <h3>First, allow Nod to send reminders</h3>
                        <p>Open Settings on your phone to allow Nod to send you notifications.</p>
                      </FooterModal>
                    </React.Fragment>
                  :
                    null
                */}

              </React.Fragment>
            }
          </div>
        )}
      </MyContext.Consumer>
    );
  }
}
Home.contextType = MyContext;
export default Home;
