import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import PageHeader from '../PageHeader';
import FooterModal from '../FooterModal';
import FooterUpdator from '../FooterUpdator';
import MyContext from '../../state/MyContext';

const demo_access_code = '4748';

require('./style.scss');

class DemoAccess extends Component {

  constructor(props){
    super(props);
    this.state = {
      four_digit_value: '',
      validation_message: '',
    }

    this.footerModal = React.createRef();
    this._handleChange = this._handleChange.bind(this);
    this._handleSubmit = this._handleSubmit.bind(this);
    this._handleGetCode = this._handleGetCode.bind(this);
    this._handleEmailUs = this._handleEmailUs.bind(this);
    this._handleCreateAnAccount = this._handleCreateAnAccount.bind(this);
    this._handleAlreadyHaveAccount = this._handleAlreadyHaveAccount.bind(this);
  }

  componentDidMount() {
    if(this.context.user_logged_in) this.props.history.push('/');
  }

  _handleChange(event) {
    this.setState({
      four_digit_value: event.target.value,
      submit_disabled: (event.target.value === ''),
      validation_message: '',
    });
  }

  _handleSubmit(route){
    let valid = true;
    let valid_message = '';
    this.setState({validation_message: valid_message});

    //Check code
    if(this.state.four_digit_value !== demo_access_code){
      valid = false;
      valid_message += 'Please enter the correct Code.';
    }

    if(valid){
      this.setState({submit_disabled: true});
      this.props.history.push(route);
    }else{
      this.setState({validation_message: valid_message});
    }
  }

  _handleGetCode(){
    this.footerModal.current.show();
  }

  _handleEmailUs(e){
    e.preventDefault()
    //this.footerModal.current.hide();
    //this.props.history.push('/feedback');
    if(window.cordova){
      window.cordova.InAppBrowser.open('https://heynod.com/about-nod/#request-demo');
    }else{
      window.open('https://heynod.com/about-nod/#request-demo', '_system');
    }
  }

  _handleCreateAnAccount(e){
    this._handleSubmit('/signup-social');
  }

  _handleAlreadyHaveAccount(e){
    e.preventDefault();
    this._handleSubmit('/login-social');
  }

  render() {
    return (
      <div id="find-your-school">
        <Helmet>
          <title>Nod - Demo Access</title>
        </Helmet>
        <PageHeader bgColor="tan-accent" bgHeaderColor="tan">
          <h1 className="adieu">We’re glad you’re here to demo Nod!</h1>
        </PageHeader>
        <label htmlFor="4-digit-code" aria-label="Enter 4 Digit Code">Enter the 4-digit demo code.</label>
        <input id="4-digit-code" type="text" value={this.state.four_digit_value} onChange={this._handleChange} maxLength="4" />
        <div>
          <button aria-label="how do I get the code?" className="help" onClick={this._handleGetCode}></button>
          <div aria-hidden="true" onClick={this._handleGetCode}>How do I get the code?</div>
          <br /><br />
        </div>
        {(this.state.validation_message !== '') ? <div className="validationText">{this.state.validation_message}</div> : null}
        <input type="submit" role="button" className="button" onClick={this._handleCreateAnAccount} value="Create an account" />
        <div className="textLink">
          <a className="skip" href="/" onClick={this._handleAlreadyHaveAccount}>Already have a demo account?</a>
        </div>
        <FooterModal ref={this.footerModal} visible={false} parentId="find-your-school">
          <h3>Want to demo Nod?</h3>
          <p>Fill out our contact form to request the demo code, and we’ll help you get set up.</p>
          <div className="textLink">
            <a className="skip" href="/" onClick={this._handleEmailUs}>Contact Us</a><br /><br />
          </div>
        </FooterModal>
        <FooterUpdator
          showHome={false}
          backHandler={() => {this.props.history.goBack()}} />
      </div>
    );
  }
}

DemoAccess.contextType = MyContext;
export default DemoAccess;
