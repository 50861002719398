import React, { Component } from 'react';
import MyContext from '../../state/MyContext';
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
//import img_placeholder_shoe from '../../assets/images/placeholder-shoe.png';
require('./style.scss');

const DragHandle = SortableHandle(() => <div className="drag-handle" />);
const SortableItem = SortableElement(({theIndex, item}) => {
  return (
    <li tabIndex="0" className="sortableItem" aria-label={theIndex+1 + ' ' + item.name}>
      <div className="index"><span>{theIndex+1}</span></div>
      <DragHandle />
      {item.name}
    </li>
  );
});
const SortableList = SortableContainer(({items}) => {
  return (
    <ul className="sortable-list">
      {
        items.map((item, index) => {
          return <SortableItem key={`item-${index}`} index={index} theIndex={index} item={item} />;
        })
      }
    </ul>
  );
});

class MotivationSetter extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      whats_important_items: null,
    }
  }


  _shuffle(a) {
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
  }

  componentDidMount(){
    let motivations = [];
    //if(this.context.user_logged_in && this.context.user_onboarded){
      this.context.user_motivations.forEach((motivation) =>{
        if(!motivation.hidden) motivations.push(motivation);
        if(this.props.randomize === true) motivations = this._shuffle(motivations);
      });
    // }else{
    //   this.context.motivations.forEach((motivation) =>{
    //     if(!motivation.hidden) motivations.push(motivation);
    //     if(this.props.randomize === true) motivations = this._shuffle(motivations);
    //   });
    // }
    this.setState({whats_important_items:motivations, ready:true});
    if(this.props.onInit) this.props.onInit(motivations); //this.context.motivations
  }

  _onSortEnd({oldIndex, newIndex}) {
    var newarray = this.state.whats_important_items.slice();
    newarray.splice(newIndex, 0, newarray.splice(oldIndex, 1)[0]);
    this.setState({whats_important_items:newarray});
    //this.props.onSortEnd(newarray.concat(newarray)); //For testing duplicates problem
    this.props.onSortEnd(newarray);
  }

  render(){
    if(this.state.ready){
      return (
        <div id="motivation-setter">
          <h2 className="pointers">Drag & Rank</h2>
          <SortableList
            items={this.state.whats_important_items}
            onSortEnd={this._onSortEnd.bind(this)} />
        </div>
      );
    }else{
      return null;
    }
  }
}

MotivationSetter.contextType = MyContext;
export default MotivationSetter;
