import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import MyContext from '../../state/MyContext';
import PageHeader from '../PageHeader';
import FooterUpdator from '../FooterUpdator';
import SignInForm from './SignInForm';
import loading_gif from '../../assets/images/loading.gif';
require('./style.scss');

class SignIn extends Component {

  constructor (props) {
    super();
    this.state = {
      authenticating: false
    }

    //Bindings & References
    this._formSubmit = this._formSubmit.bind(this);
  }

  componentDidMount() {
    if(this.context.user_logged_in) this.props.history.push('/');
  }

  _formSubmit(email, password, callback) {
    this.setState({authenticating:true});
    this.context.signInUser(email, password).then(result => {
      this.setState({authenticating:false});
      if(result.success){
        this.context.sessionStarted();
        if(this.context.user_onboarded){
          this.props.history.push('/');
        }else{
          this.props.history.push('/onboarding/onboard');
        }
      }else{
        callback(true, 'Your credentials were incorrect. If you just signed up, make sure to check your email and verify your account from this device.');
      }
    }).catch(err => {
      this.setState({authenticating:false});
      callback(true, 'Something went wrong. Please try again.');
    });
  }

  render(){
    return(
      <MyContext.Consumer>
        {(context) => (
          <div id="sign-in">
            <Helmet>
              <title>Nod - Sign In</title>
            </Helmet>
            <div className={this.state.authenticating ? 'loading show' : 'loading'}><img src={loading_gif} alt="loading" /></div>
            <PageHeader bgColor="tan-accent" bgHeaderColor="tan">
              <h1 className="adieu">Welcome Back!</h1>
            </PageHeader>
            <SignInForm onSubmit={this._formSubmit} />
            <div className="textLink">
              <Link to="/password-reset">Forgot your password?</Link>
            </div>
            <FooterUpdator
              showHome={false}
              backHandler={() => {this.props.history.goBack()}}
              nextText="Or Create Account"
              nextHandler={() => this.props.history.push('/signup')} />
          </div>
        )}
      </MyContext.Consumer>
    );
  }
}

SignIn.contextType = MyContext;
export default SignIn;
