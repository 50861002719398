import * as PIXI from 'pixi.js';

class RippleRenderer {
  constructor(parentWidth, parentHeight, mapSrc) {
    this.parentWidth = parentWidth;
    this.parentHeight = parentHeight;
    this.mapSrc = mapSrc;

    this.createRenderer();
  }

  createRenderer() {
    this.dpr = window.devicePixelRatio;
    this.canvas = document.createElement('canvas');
    this.ctx = this.canvas.getContext('2d');
    this.canvas.width = this.parentWidth * this.dpr;
    this.canvas.height = this.parentHeight * this.dpr;
    this.ctx.scale(this.dpr, this.dpr);

    this.sprite = new PIXI.Sprite.from(this.canvas);
    this.sprite.x = 0;
    this.sprite.y = 0;
    this.sprite.width = this.parentWidth;
    this.sprite.height = this.parentHeight;
    this.sprite.anchor.set(0);

    this.mapSprite = new PIXI.Sprite.from(this.mapSrc);
    this.mapSprite.width = this.parentWidth;
    this.mapSprite.height = this.parentHeight;
    this.mapSprite.texture.baseTexture.wrapMode = PIXI.WRAP_MODES.REPEAT;

    this.displacementFilter = new PIXI.filters.DisplacementFilter(
      this.mapSprite
    );
    this.displacementFilter.resolution = window.devicePixelRatio;
    this.sprite.filters = [this.displacementFilter];
  }

  render(ripples) {
    this.mapSprite.position.x -= 0.5;

    this.ctx.clearRect(0, 0, this.parentWidth, this.parentHeight);

    let i = ripples.length;
    while (i--) {
      let ripple = ripples[i];
      this.ctx.beginPath();
      this.ctx.moveTo(ripple.x + ripple.radius, ripple.y);
      this.ctx.arc(ripple.x, ripple.y, ripple.radius, 0, Math.PI * 2);
      this.ctx.lineWidth = ripple.lineWidth;
      this.ctx.strokeStyle = ripple.color;
      this.ctx.stroke();
    }

    this.sprite.texture.update();
  }

  destroy() {
    this.canvas = null;
    this.ctx = null;
    this.sprite = null;
  }
}

export default RippleRenderer;
