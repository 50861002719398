import * as PIXI from 'pixi.js';
import { Howl } from 'howler';
import { TweenMax, Power4, Back } from 'gsap';

import BaseReflection from '../../common/BaseReflection';
import Calc from '../../common/Calc';
import SvgSprite from '../../common/SvgSprite';

import cloud0Src from '../images/cloud-0.png';
import cloud1Src from '../images/cloud-1.png';
import cloud2Src from '../images/cloud-2.png';
import cloud3Src from '../images/cloud-3.png';
import cloud4Src from '../images/cloud-4.png';
import cloud5Src from '../images/cloud-5.png';
import cloud6Src from '../images/cloud-6.png';
import sunSrc from '../images/sun.png';

import shineWebmSrc from '../sounds/shine.webm';
import shineOggSrc from '../sounds/shine.ogg';
import shineMp3Src from '../sounds/shine.mp3';
import whooshWebmSrc from '../sounds/whoosh.webm';
import whooshOggSrc from '../sounds/whoosh.ogg';
import whooshMp3Src from '../sounds/whoosh.mp3';

class StormySkyReflection extends BaseReflection {
  init(onComplete) {
    super.init(onComplete);

    this.assetPaths = [
      cloud0Src,
      cloud1Src,
      cloud2Src,
      cloud3Src,
      cloud4Src,
      cloud5Src,
      cloud6Src,
      sunSrc,
      shineWebmSrc,
      shineOggSrc,
      shineMp3Src,
      whooshWebmSrc,
      whooshOggSrc,
      whooshMp3Src
    ];

    this.clouds = [];
    this.cloudCurrent = 6;
    this.cloudCount = 7;

    this.pointer = {
      down: {
        x: 0,
        y: 0
      },
      move: {
        x: 0,
        y: 0
      },
      up: {
        x: 0,
        y: 0
      }
    };

    this.itemScale = 0.4;

    this.addEventListeners();
    this.loadAssets();
  }

  reset(width = this.width, height = this.height) {
    super.reset(width, height);

    this.clouds.length = 0;
    this.sunContainer = null;
    this.sun = null;

    this.create();
  }

  addEventListeners() {
    super.addEventListeners();

    this.onPointerDown = this.onPointerDown.bind(this);
    this.onPointerMove = this.onPointerMove.bind(this);
    this.onPointerUp = this.onPointerUp.bind(this);
    this.canvasWrap.addEventListener('mousedown', this.onPointerDown);
    this.canvasWrap.addEventListener('mousemove', this.onPointerMove);
    this.canvasWrap.addEventListener('mouseup', this.onPointerUp);
    this.canvasWrap.addEventListener('touchstart', this.onPointerDown);
    this.canvasWrap.addEventListener('touchmove', this.onPointerMove);
    this.canvasWrap.addEventListener('touchend', this.onPointerUp);
  }

  removeEventListeners() {
    super.removeEventListeners();

    this.canvasWrap.removeEventListener('mousedown', this.onPointerDown);
    this.canvasWrap.removeEventListener('mousemove', this.onPointerMove);
    this.canvasWrap.removeEventListener('mouseup', this.onPointerUp);
    this.canvasWrap.removeEventListener('touchstart', this.onPointerDown);
    this.canvasWrap.removeEventListener('touchmove', this.onPointerMove);
    this.canvasWrap.removeEventListener('touchend', this.onPointerUp);
  }

  onPointerDown(e) {
    let x;
    let y;
    if (e.type === 'mousedown') {
      x = e.clientX - this.xOffset;
      y = e.clientY - this.yOffset;
    } else if (e.type === 'touchstart') {
      x = e.changedTouches[0].clientX - this.xOffset;
      y = e.changedTouches[0].clientY - this.yOffset;
    }

    this.pointer.down.x = x;
    this.pointer.down.y = y;
  }

  onPointerMove(e) {
    let x;
    let y;
    if (e.type === 'mousemove') {
      x = e.clientX - this.xOffset;
      y = e.clientY - this.yOffset;
    } else if (e.type === 'touchmove') {
      x = e.changedTouches[0].clientX - this.xOffset;
      y = e.changedTouches[0].clientY - this.yOffset;
    }

    this.pointer.move.x = x;
    this.pointer.move.y = y;
  }

  onPointerUp(e) {
    let x;
    let y;
    if (e.type === 'mouseup') {
      x = e.clientX - this.xOffset;
      y = e.clientY - this.yOffset;
    } else if (e.type === 'touchend') {
      x = e.changedTouches[0].clientX - this.xOffset;
      y = e.changedTouches[0].clientY - this.yOffset;
    }

    this.pointer.up.x = x;
    this.pointer.up.y = y;
    let dist = Math.abs(this.pointer.up.x - this.pointer.down.x);

    if (dist > 10) {
      if (this.pointer.down.x < this.pointer.up.x) {
        this.moveCloud(true);
      } else {
        this.moveCloud(false);
      }
    }
  }

  initPhysics() {
    super.initPhysics();
  }

  initSounds() {
    super.initSounds();

    this.sounds.shine = new Howl({
      src: [shineWebmSrc, shineOggSrc, shineMp3Src],
      volume: 0.5
    });

    this.sounds.whoosh = {};
    this.sounds.whoosh.count = 10;
    this.sounds.whoosh.current = 0;
    this.sounds.whoosh.pool = [];

    for (let i = 0; i < this.sounds.whoosh.count; i++) {
      this.sounds.whoosh.pool.push(
        new Howl({
          src: [whooshWebmSrc, whooshOggSrc, whooshMp3Src],
          volume: 0.5
        })
      );
    }
  }

  playWhooshSound() {
    this.sounds.whoosh.pool[this.sounds.whoosh.current].rate(
      Calc.rand(0.75, 1)
    );
    this.sounds.whoosh.pool[this.sounds.whoosh.current].play();

    this.sounds.whoosh.current++;
    if (this.sounds.whoosh.current >= this.sounds.whoosh.count) {
      this.sounds.whoosh.current = 0;
    }
  }

  create() {
    super.create();

    this.sunContainer = new PIXI.Container();
    this.sunContainer.x = this.width / 2;
    this.sunContainer.y = this.height / 2;
    this.sunContainer.scale.set(0.5);
    this.pixiApp.stage.addChild(this.sunContainer);

    this.sun = new SvgSprite(
      0,
      0,
      this.assets[sunSrc].data.width * this.itemScale * 2,
      this.assets[sunSrc]
    );
    this.sunContainer.addChild(this.sun.sprite);

    [
      cloud0Src,
      cloud1Src,
      cloud2Src,
      cloud3Src,
      cloud4Src,
      cloud5Src,
      cloud6Src
    ]
      .sort(() => Math.random() - 0.5)
      .forEach((src, i) => {
        let angle = Calc.rand(Math.PI * 2);
        angle = (i / 7) * Math.PI * 2;
        let magnitude = Calc.rand(25, 100);
        let x = this.width / 2 + Math.cos(angle) * magnitude;
        let y = this.height / 2 + Math.sin(angle) * magnitude;

        let cloud = new SvgSprite(
          x,
          y,
          this.assets[src].data.width * this.itemScale,
          this.assets[src]
        );
        this.clouds.push(cloud);
        this.pixiApp.stage.addChild(cloud.sprite);
      });
  }

  moveCloud(right = true) {
    if (this.cloudCurrent < 0) {
      return;
    }

    this.playWhooshSound();

    let cloudSprite = this.clouds[this.cloudCurrent].sprite;
    let cloudWidth = cloudSprite.width;
    let x = right ? this.width + cloudWidth / 2 : -cloudWidth / 2;
    let duration = 0.75;
    let ease = Power4.easeOut;

    TweenMax.to(cloudSprite.position, duration, {
      x: x,
      ease: ease
    });

    this.cloudCurrent--;

    if (this.cloudCurrent < 0) {
      setTimeout(() => {
        this.sounds.shine.play();
      }, 200);
      TweenMax.to(this.sunContainer.scale, 1, {
        x: 1,
        y: 1,
        ease: Back.easeInOut
      });
      this.resetTimeout = setTimeout(() => {
        this.onComplete();
      }, 3000);
    }

    this.progressTarget = 1 - (this.cloudCurrent + 1) / this.cloudCount;
  }

  onTick() {
    super.onTick();
  }

  destroy() {
    super.destroy();

    window.clearTimeout(this.resetTimeout);

    this.clouds.length = 0;
    this.clouds = null;
    this.sunContainer = null;
    this.sun = null;
  }
}

export default StormySkyReflection;
