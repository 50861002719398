import BaseComponent from '../common/BaseComponent';
import Reflection from './scripts/BlowBubblesReflection';

class BlowBubblesComponent extends BaseComponent {
  constructor(props) {
    super(props);
    this.Reflection = Reflection;
  }
}

export default BlowBubblesComponent;
