import React, { Component } from 'react';
import ReactDOM from 'react-dom';
require('./style.scss');

export const MOOD_COLORS = ['#000000', '#dd7194', '#5c95eb', '#49c6b8', '#eef533'];
export const MOOD_COLOR_ACCENTS = ['#b5b5b5', '#ce95b4', '#76adf0', '#68d0cd', '#f9fbc6'];
const COORD_LABELS = {
  inner: {
    0:'Awake',
    1:'A little tense',
    2:'A little unhappy',
    3:'A little down',
    4:'A little sleepy',
    5:'A little relaxed',
    6:'A little happy',
    7:'Upbeat'
  },
  middle: {
    0:'Awake',
    1:'Stressed',
    2:'A little unhappy',
    3:'Sad',
    4:'A little sleepy',
    5:'Relaxed',
    6:'A little happy',
    7:'Excited'
  },
  outer: {
    0:'Alert',
    1:'Very Stressed',
    2:'Unhappy',
    3:'Depressed',
    4:'Sleepy',
    5:'Very Relaxed',
    6:'Happy',
    7:'Ecstatic'
  }
}

const COORD_LABELS_FOR_SELECT_INPUT = [
  {balance:'neutral', coords:[5,6], position:[0.50,0.35], label:'Awake'},
  {balance:'neutral', coords:[5,9], position:[0.50,0.00], label:'Alert'},
  {balance:'positive', coords:[6,7], position:[0.65,0.31], label:'Excited'},
  {balance:'positive', coords:[8,8], position:[0.83,0.13], label:'Ecstatic'},
  {balance:'positive', coords:[6,6], position:[0.60,0.37], label:'Upbeat'},
  {balance:'positive', coords:[7,5], position:[0.72,0.45], label:'A little happy'},
  {balance:'positive', coords:[8,5], position:[0.89,0.45], label:'Happy'},
  {balance:'neutral', coords:[5,5], position:[0.55,0.55], label:'A little relaxed'},
  {balance:'positive', coords:[7,4], position:[0.73,0.67], label:'Relaxed'},
  {balance:'positive', coords:[8,3], position:[0.89,0.80], label:'Very Relaxed'},
  {balance:'neutral', coords:[5,4], position:[0.49,0.62], label:'A little sleepy'},
  {balance:'neutral', coords:[5,1], position:[0.50,0.96], label:'Sleepy'},
  {balance:'negative', coords:[4,4], position:[0.41,0.60], label:'A little down'},
  {balance:'negative', coords:[4,3], position:[0.31,0.74], label:'Sad'},
  {balance:'negative', coords:[2,2], position:[0.16,0.86], label:'Depressed'},
  {balance:'negative', coords:[4,5], position:[0.37,0.53], label:'A little unhappy'},
  {balance:'negative', coords:[1,5], position:[0.00,0.51], label:'Unhappy'},
  {balance:'negative', coords:[4,6], position:[0.41,0.41], label:'A little tense'},
  {balance:'negative', coords:[3,7], position:[0.30,0.31], label:'Stressed'},
  {balance:'negative', coords:[2,8], position:[0.13,0.16], label:'Very Stressed'},
];

export const getRegion = (pos_x, pos_y) => {
  let region;
  if(pos_x > 0.45 && pos_x < 0.55 && pos_y > 0.45 && pos_y < 0.55){
    region = 0;
  }else if(pos_x < 0.5 && pos_y < 0.5){
    region = 1;
  }else if(pos_x < 0.5 && pos_y > 0.5){
    region = 2;
  }else if(pos_x > 0.5 && pos_y > 0.5){
    region = 3;
  }else if(pos_x > 0.5 && pos_y < 0.5){
    region = 4;
  }
  return region;
}

class MoodMeter extends Component {

  constructor(props) {
    super(props);
    this.state = {
      touched: false,
      handle_left: 0,
      handle_top: 0,
      position_x: 0.5,
      position_y: 0.5,
      coord_label:'',
      radius_boundary:0,
    }
    this.width  = 280;
    this.gateopen = true;
    this.colors = MOOD_COLORS;
    this.color_accents = MOOD_COLOR_ACCENTS;
    this.coord_labels = COORD_LABELS;

    this.scrollY = window.scrollY;
    this.touch_gestures = true;

    //Bindings & References
    this.canvas = React.createRef();
    this.ctx = null;
    this.moodMeterInner = React.createRef();
    this._onStart = this._onStart.bind(this);
    this._onEnd = this._onEnd.bind(this);
    this._onMove = this._onMove.bind(this);
    this._selectInputChange = this._selectInputChange.bind(this);
  }

  _setTouchable() {
    let touch_gestures = window.localStorage.getItem('@nod:touch_gestures');
    if(touch_gestures === 'false') touch_gestures = false;
    if(touch_gestures === null) touch_gestures = true;
    this.touch_gestures = touch_gestures;
  }

  _lockWindow() {
    this.scrollY = window.scrollY
    document.querySelector('html').classList.add('locked');
    document.getElementById('app').style.top = (-this.scrollY) + 'px';
  }

  _unlockWindow() {
    document.querySelector('html').classList.remove('locked');
    document.getElementById('app').style.top = null;
    window.scrollTo(0, this.scrollY)
  }

  componentDidMount(){
    if(!this.props.disabled && !this.props.lockWindowWhenClick) this._lockWindow();
    if(this.props.initialPosition){
      const initialPositionX = this.props.initialPosition[0];
      const initialPositionY = this.props.initialPosition[1];
      const mood_meter_bounding_rect = ReactDOM.findDOMNode(this.moodMeterInner.current).getBoundingClientRect();
      const half_circle = mood_meter_bounding_rect.width/2;
      const left = mood_meter_bounding_rect.width * initialPositionX;
      const top = mood_meter_bounding_rect.height * initialPositionY;
      //const coord_x = Math.round(this.props.initialPosition[0] * 8) + 1;
      //const coord_y = 9 - Math.round(this.props.initialPosition[1] * 8);
      const offset_x = left - half_circle;
      const offset_y = top - half_circle;
      const angle = Math.atan2(offset_x, offset_y);
      const dist = Math.sqrt(offset_x * offset_x + offset_y * offset_y);
      const radius_boundary = Math.round(dist / half_circle * 4);
      let region =  Math.round( (angle + Math.PI) / (Math.PI*2) * 8 );
      if(region === 8) region = 0;
      const coord_label = this._findLabel(radius_boundary, region);
      this.setState({
        handle_left:left,
        handle_top:top,
        position_x: initialPositionX,
        position_y: initialPositionY,
        coord_label: coord_label,
        radius_boundary:radius_boundary,
        touched:true});
    }
    this.ctx = this.canvas.current.getContext('2d');
    this._setTouchable();
  }

  componentWillUnmount(){
    if(!this.props.disabled && !this.props.lockWindowWhenClick) this._unlockWindow();
  }

  componentDidUpdate() {
    if(this.gateopen){
      this._drawCanvas();
      this.gateopen = false;
      setTimeout(() => {
        this.gateopen = true;
      }, 40);
    }
  }

  //////////////////////////////////////////////////////////////////////////////
  //Canvas Drawing//////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////

  _gradient(a, b) { return (b.y-a.y)/(b.x-a.x); }

  _bzCurve(points, f, t, color) {

      //Prep
      //f = 0, will be straight line
      //t suppose to be 1, but changing the value can control the smoothness too
      if (typeof(f) == 'undefined') f = 0.3;
      if (typeof(t) == 'undefined') t = 0.6;
      this.ctx.beginPath();
      this.ctx.moveTo(points[0].x, points[0].y);

      //Draw
      let m = 0;
      let dx1 = 0;
      let dy1 = 0;
      let preP = points[0];
      let nexP, dx2, dy2;
      for (let i = 1; i < points.length; i++) {
        let curP = points[i];
        nexP = points[i + 1];
        if(nexP){
          m = this._gradient(preP, nexP);
          dx2 = (nexP.x - curP.x) * -f;
          dy2 = dx2 * m * t;
        }else{
          dx2 = 0;
          dy2 = 0;
        }
        this.ctx.bezierCurveTo(preP.x - dx1, preP.y - dy1, curP.x + dx2, curP.y + dy2, curP.x, curP.y);
        dx1 = dx2;
        dy1 = dy2;
        preP = curP;
      }
      this.ctx.stroke();
      this.ctx.fillStyle = color;
      this.ctx.fill();
  }

  _generateLinePoints(horizontal_position, vertical_pos, amplitude, frequency) {
    let lines = [];
    let X = 0;
    let Y = 0;
    let p = [];
    let t = 1; //to control width of X
    const freq = this.width/frequency;
    for(let i=0; i<this.width; i++){
        //Y = Math.floor((Math.random() * this.width) + vertical_pos);
        Y = Math.sin(i+horizontal_position) * amplitude + vertical_pos;
        p = { x: X*freq, y: Y };
        lines.push(p);
        X = X + t;
    }
    lines.push({x: this.width, y:this.width}); //Bottom Right
    lines.push({x: 0, y:this.width}); //Bottom Left
    return lines;
  }

  _generateWave(horizontal_position, vertical_pos, amplitude, frequency, region){

    // Generate random data
    var lines = this._generateLinePoints(horizontal_position, vertical_pos, amplitude, frequency);
    var lines2 = []
    for (var i = 0; i < lines.length; i++ ) {
      lines2[i] = {};
      lines2[i].x = lines[i].x;
      const y_variation = 0; //Math.round(Math.random() * 10);
      lines2[i].y = lines[i].y + y_variation + 20;
    }

    //draw straight line
    // this.ctx.beginPath();
    // this.ctx.setLineDash([5]);
    // this.ctx.lineWidth = 1;
    // this._bzCurve(lines, 0, 1, this.ctx.strokeStyle);

    //draw smooth line
    this.ctx.setLineDash([0]);
    this.ctx.lineWidth = 2;
    this.ctx.strokeStyle = this.colors[region];
    this._bzCurve(lines, 0.3, 1, this.ctx.strokeStyle);

    //draw smooth line
    this.ctx.setLineDash([0]);
    this.ctx.lineWidth = 2;
    this.ctx.strokeStyle = this.color_accents[region];
    this._bzCurve(lines2, 0.3, 1, this.ctx.strokeStyle);

  }

  _drawCanvas() {

    const region = getRegion(this.state.position_x, this.state.position_y);

    this.ctx.fillStyle = this.color_accents[region];
    this.ctx.fillRect(0, 0, this.width, this.width);

    const vertical_offset = -45;
    const vertical_spread = 75;
    for(let i=0; i<5; i++){
      const horizontal_position = this.state.position_y * 40;
      const vertical_pos = i * vertical_spread + vertical_offset;
      const amplitude = this.state.position_x * 50 + 10;
      const frequency = (1 - this.state.position_y) * 75 + 5;
      this._generateWave(horizontal_position, vertical_pos, amplitude, frequency, region);
    }

    if(region === 0){
      var grd = this.ctx.createLinearGradient(0, this.width, this.width, this.width);
      grd.addColorStop(0, "#B9FB84");
      grd.addColorStop(1, "#62E2FF");
      this.ctx.fillStyle = grd;
      this.ctx.globalAlpha = 0.9;
      this.ctx.fillRect(0, 0, this.width, this.width);
    }
  }

  //////////////////////////////////////////////////////////////////////////////
  //Interaction/////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////

  // _handleChange(balance, mood){
  //   this.props.onChange(balance, mood); //Add changed parameter
  // }

  _findLabel(radius_boundary, region){
    let coord_label = '';
    switch(radius_boundary){
      case 0: //Off
        coord_label = '';
        break;
      case 1: //Inner
        coord_label = this.coord_labels.inner[region];
        break;
      case 2: //Middle
        coord_label = this.coord_labels.middle[region];
        break;
      default: //Outer
        coord_label = this.coord_labels.outer[region];
        break;
    }
    return coord_label;
  }

  _onStart(evt) {
    if(!this.props.disabled){
      if(this.props.lockWindowWhenClick) this._lockWindow();
      this.setState({mousedown:true, touched:true});
      this._onMove(evt, true);
    }
  }

  _onEnd(evt) {
    if(!this.props.disabled){
      if(this.props.lockWindowWhenClick) this._unlockWindow();
      this.setState({mousedown:false});
    }
  }

  _onMove(evt, bypass_mouse_down) {
    if((this.state.mousedown || bypass_mouse_down) && !this.props.disabled){

      //Touch Position
      let x = evt.clientX;
      let y = evt.clientY;
      if(evt.type === 'touchmove' || evt.type === 'touchstart'){
        x = evt.touches[0].clientX;
        y = evt.touches[0].clientY;
      };

      //Bounding Box
      const mood_meter_bounding_rect = ReactDOM.findDOMNode(this.moodMeterInner.current).getBoundingClientRect();
      const half_circle = mood_meter_bounding_rect.width/2;
      const center_x = mood_meter_bounding_rect.x + half_circle;
      const center_y = mood_meter_bounding_rect.y + half_circle;

      //Position
      const offset_x = x - center_x;
      const offset_y = y - center_y;
      const dist = Math.sqrt(offset_x * offset_x + offset_y * offset_y);
      const angle = Math.atan2(offset_x, offset_y);
      let pos_x, pos_y;
      if(dist < half_circle){
        pos_x = offset_x + half_circle;
        pos_y = offset_y + half_circle;
      }else{
        pos_x = center_x - mood_meter_bounding_rect.x + Math.sin(angle) * half_circle;
        pos_y = center_y - mood_meter_bounding_rect.y + Math.cos(angle) * half_circle;
      }

      //Coordinates
      const position_x = pos_x / mood_meter_bounding_rect.width;
      const position_y = pos_y / mood_meter_bounding_rect.height;
      const coord_x = Math.round(position_x * 8) + 1;
      const coord_y = 9 - Math.round(position_y * 8);
      const radius_boundary = Math.round(dist / half_circle * 4);
      let region =  Math.round( (angle + Math.PI) / (Math.PI*2) * 8 );
      if(region === 8) region = 0;
      const coord_label = this._findLabel(radius_boundary, region);

      //Mood balance
      let mood_balance = 'neutral';
      if(coord_x < 5) mood_balance = 'negative';
      if(coord_x > 5) mood_balance = 'positive';

      this.props.onChange(mood_balance, [coord_x, coord_y], [position_x, position_y], coord_label);
      this.setState({
        handle_left: pos_x,
        handle_top: pos_y,
        position_x: position_x,
        position_y: position_y,
        coord_label: coord_label,
        radius_boundary: radius_boundary
      });
    }
  }

  _getSelectItems() {
    const items_sorted = COORD_LABELS_FOR_SELECT_INPUT.sort(function(a, b) {
      var textA = a.label.toUpperCase();
      var textB = b.label.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
    const items = items_sorted.map((item,index) => {
      return <option key={index} value={item.label}>{item.label}</option>
    })
    return items;
  }

  _selectInputChange(e) {
    const item = COORD_LABELS_FOR_SELECT_INPUT.find(item => item.label = e.target.value);
    this.props.onChange(item.balance, item.coords, item.position, item.label);
  }

  render(){
    if(this.touch_gestures || this.props.disabled){
      let handle_style;
      if(this.state.touched){
        handle_style = {left:this.state.handle_left+'px', top:this.state.handle_top+'px'};
      }else{
        handle_style = {left:'50%', top:'50%'};
      }
      let mood_meter_classes = 'noselect';
      if(this.state.radius_boundary > 2) mood_meter_classes += ' dimmed-labels';
      return(
        <div id="mood-meter" className={mood_meter_classes}>
          <div
            ref={this.moodMeterInner}
            id="mood-meter-inner"
            onMouseDown={this._onStart}
            onMouseUp={this._onEnd}
            onMouseMove={this._onMove}
            onTouchStart={this._onStart}
            onTouchEnd={this._onEnd}
            onTouchMove={this._onMove}
          >
            <div id="canvas-wrapper">
              <canvas ref={this.canvas} width={this.width} height={this.width}/>
            </div>
            <div id="mood-meter-handle" style={handle_style}>
              <div id="mood-meter-handle-label">{this.state.coord_label}</div>
            </div>
          </div>
          <div className="mood-meter-label top">Alert</div>
          <div className="mood-meter-label top-left">Very Stressed</div>
          <div className="mood-meter-label left">Unhappy</div>
          <div className="mood-meter-label bottom-left">Depressed</div>
          <div className="mood-meter-label bottom">Sleepy</div>
          <div className="mood-meter-label bottom-right">Very Relaxed</div>
          <div className="mood-meter-label right">Happy</div>
          <div className="mood-meter-label top-right">Ecstatic</div>
        </div>
      );
    }else{
      return(
        <div id="mood-meter" className={this.props.a11yLabel ? ' a11y' : ''}>
          <div className="dropdownWrap">
            <label htmlFor="mood-meter-select">
              {this.props.a11yLabel}
              <select name="mood-meter-select" id="mood-meter-select" onChange={this._selectInputChange}>
                <option value='none'>- Choose a mood -</option>
                {this._getSelectItems()}
              </select>
            </label>
          </div>
        </div>
      );
    }

  }
}

export default MoodMeter;
