import Calc from '../../common/Calc';

class Ripple {
  constructor(x, y, radiusTarget, strength) {
    this.x = x;
    this.y = y;
    this.radiusTarget = radiusTarget;
    this.strength = strength;

    this.progress = 0;
    this.speed = 0.01;
    this.radius = 0;
    this.done = false;
    this.lineWidth = 1;
    this.color = 'hsla(0, 0%, 100%, 0)';
    this.hasHit = false;
  }

  update() {
    if (this.progress < 1) {
      this.progress += this.speed;
    } else {
      this.progress = 1;
      this.done = true;
    }

    this.lineWidth = Calc.map(
      this.progress,
      0,
      1,
      8 * this.strength,
      3 * this.strength
    );
    this.color = `hsla(0, 0%, 100%, ${(1 - this.progress) * this.strength})`;

    this.radius = this.radiusTarget * this.progress;
  }
}

export default Ripple;
