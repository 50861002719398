import * as PIXI from 'pixi.js';
import { Howl } from 'howler';
import { TweenMax, Power0 } from 'gsap';

import BaseReflection from '../../common/BaseReflection';
import Calc from '../../common/Calc';
import SvgSprite from '../../common/SvgSprite';

import Star from './Star';

import groundSrc from '../images/ground.png';
import forestSrc from '../images/forest.png';
import earthSrc from '../images/earth.png';
import largeStar0Src from '../images/large-star-0.png';
import largeStar1Src from '../images/large-star-1.png';
import largeStar2Src from '../images/large-star-2.png';
import smallStar0Src from '../images/small-star-0.png';
import smallStar1Src from '../images/small-star-1.png';
import smallStar2Src from '../images/small-star-2.png';
import shootingStarSrc from '../images/shooting-star.png';
import spaceSrc from '../images/space.png';
import squirrelSrc from '../images/squirrel.png';

import sparkleMp3Src from '../sounds/sparkle.webm';
import sparkleOggSrc from '../sounds/sparkle.ogg';
import sparkleWebmSrc from '../sounds/sparkle.mp3';
import windMp3Src from '../sounds/wind.webm';
import windOggSrc from '../sounds/wind.ogg';
import windWebmSrc from '../sounds/wind.mp3';

class SquirrelZoomReflection extends BaseReflection {
  init(onComplete) {
    super.init(onComplete);

    this.assetPaths = [
      groundSrc,
      forestSrc,
      earthSrc,
      largeStar0Src,
      largeStar1Src,
      largeStar2Src,
      smallStar0Src,
      smallStar1Src,
      smallStar2Src,
      shootingStarSrc,
      spaceSrc,
      squirrelSrc,
      sparkleWebmSrc,
      sparkleOggSrc,
      sparkleMp3Src,
      windWebmSrc,
      windOggSrc,
      windMp3Src
    ];

    this.zMin = -100;
    this.zMax = 200;
    this.zFade = 80;
    this.zGap = 250;
    this.zStartOffset = 0;
    this.zTotal = this.zGap * 4 - (this.zGap - this.zFade);
    this.fov = 100;

    this.completion = 0;
    this.completionVelocity = 0;
    this.pointerPairLast = [];
    this.pointerPairCurrent = [];
    this.pointerDown = false;
    this.soundStrength = 0;
    this.completed = false;
    this.completeTimeout = null;
    this.stars = [];

    this.addEventListeners();
    this.loadAssets();
  }

  reset(width = this.width, height = this.height) {
    super.reset(width, height);

    window.clearTimeout(this.completeTimeout);

    this.completion = 0;
    this.completionVelocity = 0;
    this.pointerPairLast.length = 0;
    this.pointerPairCurrent.length = 0;
    this.pointerDown = false;
    this.soundStrength = 0;
    this.completed = false;
    this.completeTimeout = null;

    this.squirrel = null;
    this.squirrelContainer = null;
    this.forest = null;
    this.forestContainer = null;
    this.earth = null;
    this.earthContainer = null;
    this.space = null;
    this.spaceContainer = null;
    this.stars.length = 0;
    this.shootingStarContainer = null;
    this.shootingStar = null;

    this.create();
  }

  addEventListeners() {
    super.addEventListeners();

    this.onPointerDown = this.onPointerDown.bind(this);
    this.onPointerMove = this.onPointerMove.bind(this);
    this.onPointerUp = this.onPointerUp.bind(this);

    this.canvasWrap.addEventListener('mousedown', this.onPointerDown);
    this.canvasWrap.addEventListener('mouseup', this.onPointerUp);

    this.canvasWrap.addEventListener('touchstart', this.onPointerDown);
    this.canvasWrap.addEventListener('touchmove', this.onPointerMove);
    this.canvasWrap.addEventListener('touchend', this.onPointerUp);
  }

  removeEventListeners() {
    super.removeEventListeners();

    this.canvasWrap.removeEventListener('mousedown', this.onPointerDown);
    this.canvasWrap.removeEventListener('mouseup', this.onPointerUp);

    this.canvasWrap.removeEventListener('touchstart', this.onPointerDown);
    this.canvasWrap.removeEventListener('touchmove', this.onPointerMove);
    this.canvasWrap.removeEventListener('touchend', this.onPointerUp);
  }

  onPointerDown(e) {
    if (!this.windPlaying) {
      this.sounds.wind.play();
      this.windPlaying = true;
    }
    if (e.type === 'mousedown') {
      this.pointerDown = true;
    }
  }

  onPointerMove(e) {
    if (e.type === 'touchmove') {
      if (e.touches.length === 2) {
        if (this.pointerPairCurrent.length) {
          this.pointerPairLast[0] = {
            x: this.pointerPairCurrent[0].x,
            y: this.pointerPairCurrent[0].y
          };
          this.pointerPairLast[1] = {
            x: this.pointerPairCurrent[1].x,
            y: this.pointerPairCurrent[1].y
          };
        }

        this.pointerPairCurrent[0] = {
          x: e.touches[0].clientX - this.xOffset,
          y: e.touches[0].clientY - this.yOffset
        };
        this.pointerPairCurrent[1] = {
          x: e.touches[1].clientX - this.xOffset,
          y: e.touches[1].clientY - this.yOffset
        };

        if (this.pointerPairLast.length) {
          let dx1 = this.pointerPairCurrent[0].x - this.pointerPairCurrent[1].x;
          let dy1 = this.pointerPairCurrent[0].y - this.pointerPairCurrent[1].y;
          let d1 = Math.sqrt(dx1 * dx1 + dy1 * dy1);

          let dx2 = this.pointerPairLast[0].x - this.pointerPairLast[1].x;
          let dy2 = this.pointerPairLast[0].y - this.pointerPairLast[1].y;
          let d2 = Math.sqrt(dx2 * dx2 + dy2 * dy2);

          let diff = d2 - d1;

          if (this.completionVelocity < 0.05 && this.completion < 1) {
            if (
              (this.completion < 0 && diff > 0) ||
              (this.completion > 1 && diff < 0)
            ) {
              this.completionVelocity -= diff * 0.00001;
            } else {
              this.completionVelocity -= diff * 0.00005;
            }
          }
        }
      }
    }
  }

  onPointerUp(e) {
    this.pointerPairCurrent.length = 0;
    this.pointerPairLast.length = 0;
    if (e.type === 'mouseup') {
      this.pointerDown = false;
    }
  }

  initPhysics() {
    super.initPhysics();
  }

  initSounds() {
    super.initSounds();

    this.sounds.sparkle = new Howl({
      src: [sparkleWebmSrc, sparkleOggSrc, sparkleMp3Src],
      volume: 0.025
    });

    this.windPlaying = false;

    this.sounds.wind = new Howl({
      src: [windWebmSrc, windOggSrc, windMp3Src],
      loop: true,
      volume: 0
    });
  }

  create() {
    super.create();

    this.createSquirrel();
    this.createForest();
    this.createEarth();
    this.createSpace();
  }

  createSquirrel() {
    this.squirrelContainer = new PIXI.Container();
    this.squirrelContainer.x = this.width / 2;
    this.squirrelContainer.y = this.height / 2;
    this.squirrelContainer.zOrigin = this.zStartOffset - this.zGap * 0;
    this.squirrelContainer.z = this.squirrelContainer.zOrigin;
    this.pixiApp.stage.addChild(this.squirrelContainer);

    this.squirrelGround = new SvgSprite(0, 0, 614, this.assets[groundSrc]);
    this.squirrelContainer.addChild(this.squirrelGround.sprite);

    this.squirrel = new SvgSprite(0, 0, 600, this.assets[squirrelSrc]);
    this.squirrel.sprite.width = 200;
    this.squirrel.sprite.height =
      this.squirrel.sprite.width / this.squirrel.srcRatio;
    this.squirrelContainer.addChild(this.squirrel.sprite);
  }

  createForest() {
    this.forestContainer = new PIXI.Container();
    this.forestContainer.x = this.width / 2;
    this.forestContainer.y = this.height / 2;
    this.forestContainer.zOrigin = this.zStartOffset - this.zGap * 1;
    this.forestContainer.z = this.forestContainer.zOrigin;
    this.pixiApp.stage.addChild(this.forestContainer);

    this.forestGround = new SvgSprite(0, 65, 614, this.assets[groundSrc]);
    this.forestContainer.addChild(this.forestGround.sprite);

    this.forest = new SvgSprite(0, 0, 600, this.assets[forestSrc]);
    this.forest.sprite.width = 500;
    this.forest.sprite.height = this.forest.sprite.width / this.forest.srcRatio;
    this.forestContainer.addChild(this.forest.sprite);
  }

  createEarth() {
    this.earthContainer = new PIXI.Container();
    this.earthContainer.x = this.width / 2;
    this.earthContainer.y = this.height / 2;
    this.earthContainer.zOrigin = this.zStartOffset - this.zGap * 2;
    this.earthContainer.z = this.earthContainer.zOrigin;
    this.pixiApp.stage.addChild(this.earthContainer);

    this.earth = new SvgSprite(0, 0, 600, this.assets[earthSrc]);
    this.earth.sprite.width = 500;
    this.earth.sprite.height = this.earth.sprite.width / this.earth.srcRatio;
    this.earthContainer.addChild(this.earth.sprite);
  }

  createSpace() {
    this.spaceContainer = new PIXI.Container();
    this.spaceContainer.x = this.width / 2;
    this.spaceContainer.y = this.height / 2;
    this.spaceContainer.zOrigin = this.zStartOffset - this.zGap * 3;
    this.spaceContainer.z = this.spaceContainer.zOrigin;
    this.pixiApp.stage.addChild(this.spaceContainer);

    this.starScale = 0.6;

    this.largeStarAssets = [
      this.assets[largeStar0Src],
      this.assets[largeStar1Src],
      this.assets[largeStar2Src]
    ];
    this.largeStarPositions = [
      { x: -100, y: -120 },
      { x: -230, y: -65 },
      { x: -145, y: 90 },
      { x: 10, y: 30 },
      { x: 220, y: -40 },
      { x: 210, y: 0 },
      { x: 190, y: 85 }
    ];
    this.largeStarPositions.forEach((pos, i) => {
      let asset = this.largeStarAssets[i % this.largeStarAssets.length];
      let star = new Star(
        pos.x,
        pos.y,
        asset.data.width * this.starScale,
        asset
      );
      this.stars.push(star);
      this.spaceContainer.addChild(star.container);
    });

    this.smallStarAssets = [
      this.assets[smallStar0Src],
      this.assets[smallStar1Src],
      this.assets[smallStar2Src]
    ];
    this.smallStarPositions = [
      { x: -210, y: -25 },
      { x: -190, y: -20 },
      { x: -130, y: 130 },
      { x: -110, y: 110 },
      { x: -30, y: -20 },
      { x: -30, y: 90 },
      { x: -15, y: 105 },
      { x: 85, y: -120 },
      { x: 105, y: 20 },
      { x: 125, y: 10 },
      { x: 155, y: 70 },
      { x: 145, y: -105 },
      { x: 240, y: -170 },
      { x: 190, y: -110 },
      { x: 195, y: -90 },
      { x: 180, y: -50 }
    ];
    this.smallStarPositions.forEach((pos, i) => {
      let asset = this.smallStarAssets[i % this.smallStarAssets.length];
      let star = new Star(
        pos.x,
        pos.y,
        asset.data.width * this.starScale,
        asset
      );
      this.stars.push(star);
      this.spaceContainer.addChild(star.container);
    });

    this.shootingStarContainer = new PIXI.Container();
    this.shootingStarContainer.x = this.width / 2;
    this.shootingStarContainer.y = this.height * 2;
    this.pixiApp.stage.addChild(this.shootingStarContainer);

    this.shootingStar = new SvgSprite(
      0,
      -this.height * 1.55,
      this.assets[shootingStarSrc].data.width * this.starScale,
      this.assets[shootingStarSrc]
    );
    this.shootingStar.sprite.rotation = 0.1;
    this.shootingStar.sprite.alpha = 0;
    this.shootingStarContainer.addChild(this.shootingStar.sprite);

    // this.space = new SvgSprite(0, 0, 600, this.assets[spaceSrc]);
    // this.space.sprite.width = 500;
    // this.space.sprite.height = this.space.sprite.width / this.space.srcRatio;
    // this.space.sprite.alpha = 0.2;
    // this.spaceContainer.addChild(this.space.sprite);
  }

  projectItem(item) {
    item.z = item.zOrigin + this.completion * this.zTotal;

    let alpha = 1;
    if (item.z >= this.zMin && item.z <= this.zMin + this.zFade) {
      alpha = Calc.map(item.z, this.zMin, this.zMin + this.zFade, 0, 1);
    } else if (item.z <= this.zMax && item.z >= this.zMax - this.zFade) {
      alpha = Calc.map(item.z, this.zMax, this.zMax - this.zFade, 0, 1);
    } else if (item.z < this.zMin || item.z > this.zMax) {
      alpha = 0;
    }

    item.alpha = alpha;

    let scale = Calc.clamp(this.fov / (this.fov + item.z), 0, Infinity);
    item.scale.set(scale);
  }

  onTick() {
    super.onTick();

    let time = Date.now();
    for (let i = 0, len = this.stars.length; i < len; i++) {
      let star = this.stars[i];
      star.update(time);
    }

    if (this.pointerDown && this.completion < 1) {
      this.completionVelocity += 0.0005;
    }

    if (this.completion < 1) {
      this.completion += this.completionVelocity;
      if (this.pointerPairCurrent.length !== 2 && this.completion < 0) {
        this.completion += (0 - this.completion) * 0.3;
      } else if (this.pointerPairCurrent.length !== 2 && this.completion > 1) {
        this.completion += (1 - this.completion) * 0.3;
      } else {
        this.completion += this.completionVelocity;
      }
    } else {
      this.completion = 1;
      if (!this.completed) {
        this.completed = true;
        this.sounds.sparkle.play();
        for (let i = 0, len = this.stars.length; i < len; i++) {
          let star = this.stars[i];
          star.twinkling = true;
        }
        let obj = { value: 0 };
        TweenMax.to(obj, 2.5, {
          value: 1,
          ease: Power0.easeNone,
          onUpdate: () => {
            this.shootingStarContainer.rotation = Calc.map(
              obj.value,
              0,
              1,
              0.35,
              -0.35
            );

            if (obj.value <= 0.1) {
              this.shootingStar.sprite.alpha = Calc.map(
                obj.value,
                0,
                0.1,
                0,
                1
              );
            } else if (obj.value >= 0.9) {
              this.shootingStar.sprite.alpha = Calc.map(
                obj.value,
                0.9,
                1,
                1,
                0
              );
            } else {
              this.shootingStar.sprite.alpha = 1;
            }
          }
        });
        this.completeTimeout = window.setTimeout(() => {
          this.onComplete();
        }, 3000);
      }
    }

    this.soundStrengthTarget = Math.abs(this.completionVelocity);
    this.soundStrength = (this.soundStrengthTarget - this.soundStrength) * 0.5;

    let vol = Calc.map(this.soundStrength, 0, 0.03, 0, 1);
    vol = Calc.clamp(vol, 0, 1);
    this.sounds.wind.volume(vol);

    let rate = Calc.map(this.soundStrength, 0, 0.03, 1, 1.5);
    rate = Calc.clamp(rate, 1, 1.5);
    this.sounds.wind.rate(rate);

    this.completionVelocity *= 0.92;

    this.projectItem(this.squirrelContainer);
    this.projectItem(this.forestContainer);
    this.projectItem(this.earthContainer);
    this.projectItem(this.spaceContainer);

    this.progressTarget = this.completion;
  }

  destroy() {
    super.destroy();

    window.clearTimeout(this.completeTimeout);

    this.completion = 0;
    this.completionVelocity = 0;
    this.pointerPairLast.length = 0;
    this.pointerPairCurrent.length = 0;
    this.soundStrength = 0;
    this.completed = false;
    this.completeTimeout = null;

    this.squirrel = null;
    this.squirrelContainer = null;
    this.forest = null;
    this.forestContainer = null;
    this.earth = null;
    this.earthContainer = null;
    this.space = null;
    this.spaceContainer = null;
    this.stars.length = 0;
    this.stars = null;
    this.shootingStarContainer = null;
    this.shootingStar = null;
  }
}

export default SquirrelZoomReflection;
