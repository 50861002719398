import React, { Component } from 'react';
import { withRouter } from 'react-router';
import moment from 'moment';
import MyContext from './MyContext';
import API from './API.js';
import KeenIOService from '../services/keenio';
import MixPanelService from '../services/mixpanel';
import BranchService from '../services/branch';
import GAService from '../services/ga';
import Notifications from '../services/notifications';
import UrlRedirects from "../components/UrlRedirects/UrlRedirects";

class MyProvider extends Component {
  constructor(props) {
    super();
    this.state = {
      online: true,
      debug: (process.env.REACT_APP_DEBUG === 'true') ? true : false,
      disable_web_access: (process.env.REACT_APP_DISABLE_WEB_ACCESS === 'true') ? true : false,
      session_start: new Date(),
      sso_session: false,
      sso_logging_in: false,
      sso_token: null,
      research_id: null,
      session_id: null,
      marketing_channel:null,
      came_from_push_notification: false,
      came_from_push_notification_yes_action: false,
      push_notification_action_text: null,
      push_notification_title: null,
      notification_action_button_clicked: null,

      last_path: '/',
      last_last_path: null,
      page_view_count: 1,

      //Layout
      header_show: false,
      background_header_height: 0,
      background_header_color: 'tan',
      background_color: 'tan', //'blue',
      footer_type: 'normal', //normal or subnav
      footer_show: false,
      footer_subnav_show_home: false,
      footer_subnav_show_signin: false,
      footer_subnav_home_handler: null,
      footer_subnav_back_handler: null,
      footer_subnav_skip_handler: null,
      footer_subnav_skip_text: 'Skip',
      footer_subnav_next_text: null,
      footer_subnav_next_handler: null,
      footer_modal_present: false,

      //Initialization & Authentication
      init_data_loaded: false,
      instances: [],
      motivations: [],
      more_info_age: null,
      more_info_gender: null,
      more_info_grade_level:null,
      more_info_ethnicity: null,

      tailor_question_lack_companionship: 'none',
      tailor_question_left_out: 'none',
      tailor_question_isolated_from_others: 'none',
      tailor_question_expect_to_be_living: 'none',
      tailor_question_classes_be_held: 'none',

      onboarding_loneliness_changed: false,
      onboarding_motivations: null,
      onboarding_motivations_changed: false,
      onboarding_more_info_changed: false,

      //User state - These are set by some of the auth functions below
      pilot_hash: null,
      user: {public_user:{}},
      user_logged_in: false,
      user_onboarded: false,
      user_onboarded_just_now: false,

      //Guides & Challenge Lists
      guides_loaded: false,
      guides: [],
      challenges_loaded: false,
      challenges: [],
      featured_challenges: [],

      //User Challenges
      user_challenges_loaded: false,
      user_challenges: [],
      user_challenge_just_completed: false,

      user_challenge_just_saved: false,
      user_challenge_just_cancelled: false,

      //Challenges
      challenge_date: '',
      challenge_date_value: new Date().getTime(),
      challenge_date_skipped: false,

      //Reflections
      reflections: [],
      reflect_came_from_challenge_id: null, //Which challenge they just came from
      relfect_last_challenge_rating: null, //The last challenge rating they made
      reflect_mood_changed: false,
      reflect_mood_balance: 'neutral',
      reflect_mood_coords: [5,5],
      reflect_mood_position: [0.5,0.5],
      reflect_mood_coord_label: '',
      reflect_skill_choice: null,
      reflect_filtered_group: [], //These are the reflections filtered by the mood the user chose
      reflect_current:null,
      reflect_option: null,
      reflect_finished: null,
      reflect_start_time: 0,
      reflections_slide: 0,

      //Standalone Reflections
      standalone_reflections: [],
      standalone_reflections_loaded: false,

      //Myself
      journey: null,
      journey_loaded: false,
    };

    //Services
    this.keenio = new KeenIOService();
    this.mixpanel = new MixPanelService();
    this.notifications = new Notifications();
    this.ga = new GAService(this.state.debug);

    //Bindings & References
    this._getInitData = this._getInitData.bind(this);
  }

  //Updating State - Emulating the printing function from Redux/////////////////
  _updateStateBefore(action){
    const old_state = Object.assign({}, this.state);
    if(this.state.debug) console.log(action, 'BEFORE', old_state);
  }

  _updateState(action, state) {
    const new_state = Object.assign(this.state, state);
    if(this.state.debug){
      console.log(action, 'PAYLOAD', state);
      console.log(action, 'AFTER', new_state);
    }
    this.setState(new_state);
  }

  //Services////////////////////////////////////////////////////////////////////
  _sendKeenEvent(event, params) {
    if(event === 'session_start'){
      const random = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
      this._updateState('UPDATE_SESSION_ID', {session_id: new Date().getTime() + '_' + random});
    }

    let pathname = this.props.location.pathname;

    //Override / to /home or /onboarding when logged in
    if(this.state.user_logged_in && pathname === '/'){
      if(this.state.user_onboarded){
        pathname = '/home';
      }else{
        pathname = '/onboarding';
      }
    }
    if(this.state.user_logged_in && params.path){
      if(params.path === '/') params.path = '/home';
    }
    if(this.state.user_logged_in && params.prior_path){
      if(this.state.user_onboarded){
        if(params.prior_path === '/') params.prior_path = '/home';
      }else{
        if(params.prior_path === '/') params.prior_path = '/onboarding';
      }
    }

    setTimeout(() => this.keenio.sendKeenEvent(event, params, pathname, this.state));
  }

  _sendMixPanelEvent(event, params) {
    if(event === 'session_start'){
      const random = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
      this._updateState('UPDATE_SESSION_ID', {session_id: new Date().getTime() + '_' + random});
    }

    let pathname = this.props.location.pathname;

    //Override / to /home or /onboarding when logged in
    if(this.state.user_logged_in && pathname === '/'){
      if(this.state.user_onboarded){
        pathname = '/home';
      }else{
        pathname = '/onboarding';
      }
    }
    if(this.state.user_logged_in && params.path){
      if(params.path === '/') params.path = '/home';
    }
    if(this.state.user_logged_in && params.prior_path){
      if(this.state.user_onboarded){
        if(params.prior_path === '/') params.prior_path = '/home';
      }else{
        if(params.prior_path === '/') params.prior_path = '/onboarding';
      }
    }

    setTimeout(() => this.mixpanel.sendMixPanelEvent(event, params, pathname, this.state));
  }

  //Misc////////////////////////////////////////////////////////////////////////
  _APIUnauthorizedRejection() {
    this._signOutState();
  }

  _signOutState() {
    this._updateState('SIGN_OUT_STATE', {
      footer_show: true,
      footer_modal_present: false,
      user_logged_in: false,
      user: {},
      user_challenges_loaded: false,
    });
    setTimeout(() => {
      this.props.history.push('/');
    }, 250);
  }

  _getInitData() {
    let mythis = this;
    return new Promise(function(resolve, reject) {
      const action_name = 'GET_INIT_DATA';
      mythis._updateStateBefore(action_name);
      API.getInitData().then((result) => {

        // MixPanel Identify
        if(result.user?.id) mythis.mixpanel.sendMixPanelIdentity(result.user.id, mythis.state.debug);

        let new_notifications = 0;
        if(result.user !== undefined){
          mythis.notifications.refresh(result.user.id);
          new_notifications = mythis.notifications.getNewNotificationCount();
        }

        //Sort the instances
        result.instances = result.instances.sort((a, b) => (a.title > b.title) ? 1 : -1);

        setTimeout(() => {

          //Get new motivations
          let new_motivations = [];
          result.motivations.forEach((motivation) => {
            if(!motivation.hidden) new_motivations.push(motivation);
          });

          //If there are user motivations
          if(result.user_motivations){

            //If empty user_motivations, give them the new motivations
            if(result.user_motivations.length === 0){
              result.user_motivations = new_motivations;
            }else{

              //If any of their saved motivations are now hidden, let's give them all the new guides
              let user_motivation_is_hidden = false;
              result.user_motivations.forEach((user_motivation) => {
                if(user_motivation.hidden) user_motivation_is_hidden = true;
              });
              if(user_motivation_is_hidden) result.user_motivations = new_motivations;
            }

            //Remove Duplicates
            result.user_motivations = result.user_motivations.map(JSON.stringify).reverse() // convert to JSON string the array content, then reverse it (to check from end to begining)
            .filter(function(item, index, arr){ return arr.indexOf(item, index + 1) === -1; }) // check if there is any occurence of the item in whole array
            .reverse().map(JSON.parse) // revert it to original state
          }

          const welcome_slide_index = Math.round(Math.random() * (result.onboarding.welcome_slides.length-1));
          mythis._updateState(action_name, {
            ...result,
            welcome_slide_set: result.onboarding.welcome_slides[welcome_slide_index],
            welcome_slide_index: welcome_slide_index,
            user_logged_in: (result.user !== undefined),
            user_onboarded: (result.user !== undefined) ? result.user.onboarded : false,
            init_data_loaded: true,
            challenges_loaded: true,
            new_notifications: new_notifications
          });
          resolve(true);

        }, 500);

      }, (err) => {
        console.log(action_name + ' err', err);
        reject(err);
      });
    });
  };

  render() {
    return (
      <MyContext.Provider
        value={{
          ...this.state, //Expose entire state

          ////////////////////////////////////////////////////////////////////
          //Utilities & Misc//////////////////////////////////////////////////
          ////////////////////////////////////////////////////////////////////

          //Set State
          setState: (state) => {
            let mythis = this;
            return new Promise(function(resolve, reject) {
              const action_name = 'SET_STATE';
              mythis._updateStateBefore(action_name);
              mythis._updateState(action_name, {
                ...state
              });
              resolve(true);
            });
          },

          sendGAPageLoad: (path) => {
            this.ga.sendGAPageLoad(path);
          },

          sendGAEvent: (params) => {
            this.ga.sendGAEvent(params);
          },

          sendKeenEvent: (event, params) => {
            this._sendKeenEvent(event, params);
          },

          sendMixPanelEvent: (event, params) => {
            this._sendMixPanelEvent(event, params);
          },

          findInstanceByName: (name) => {
            return this.state.instances.find( instance => instance.name === name);
          },

          findInstanceById: (id) => {
            return this.state.instances.find( instance => instance.id === id);
          },

          getUserInstance: () => {
            if(this.state.user !== null){
              return this.state.instances.find( instance => instance.id === this.state.user.school_id);
            }else{
              return this.state.instances.find( instance => instance.id === this.state.user_onboarding_school_id);
            }
          },

          updateFooter: (
            type = 'normal',
            visible = true,
            showHome = null,
            showSignIn = null,
            homeHandler = null,
            backHandler = null,
            skipHandler = null,
            skipText = 'Skip',
            nextText = null,
            nextHandler = null
          ) => {
            this._updateState('UPDATE_FOOTER', {
              footer_type: type,
              footer_show: visible,
              footer_subnav_show_home: showHome,
              footer_subnav_show_signin: showSignIn,
              footer_subnav_home_handler: homeHandler,
              footer_subnav_back_handler: backHandler,
              footer_subnav_skip_handler: skipHandler,
              footer_subnav_skip_text: skipText,
              footer_subnav_next_text: nextText,
              footer_subnav_next_handler: nextHandler
            });
          },

          updateFooterNextText: (nextText) => {
            this._updateState('UPDATE_FOOTER_NEXT_TEXT', {
              footer_subnav_next_text: nextText
            });
          },

          footerModalPresent: () => {
            this._updateState('FOOTER_MODAL_PRESENT', {
              footer_modal_present: true,
            });
          },

          footerModalNotPresent: () => {
            this._updateState('FOOTER_MODAL_NOT_RESENT', {
              footer_modal_present: false,
            });
          },

          updateBackground: (height = 0, bg_header_color = 'tan', bg_color = 'tan') => {
            this._updateState('UPDATE_BACKGROUND', {
              background_header_height: height,
              background_header_color: bg_header_color,
              background_color: bg_color
            });
          },

          ////////////////////////////////////////////////////////////////////
          //Initialization & Authentication///////////////////////////////////
          ////////////////////////////////////////////////////////////////////

          sessionStarted: (resumed = false) => {
            setTimeout(() => {

              //Session Start KeenIO Event
              this._sendKeenEvent('session_start', {
                sso: this.context.sso_session,
                channel: this.context.marketing_channel
              });

              //Session Start MixPanel Event
              this._sendMixPanelEvent('session_start', {
                sso: this.context.sso_session,
                channel: this.context.marketing_channel
              });

              //Push Notification KeenIO Event
              if(this.state.came_from_push_notification && this.state.user_logged_in && !resumed){
                this._sendKeenEvent('notification', {
                  page_title: 'push',
                  skipped:false,
                  os_declined: false,
                  snoozed:false,
                  reminder_selection: null,
                  notification_title: this.state.push_notification_action_text,
                  marked_complete: false,
                  push_notification: true,
                  notification_action_button_clicked: this.state.notification_action_button_clicked,
                  code_location: 'MyProvider'
                });
                this._updateState('SESSION_STARTED', {
                  came_from_push_notification:false
                });
              }

              //Push Notification MixPanel Event
              if(this.state.came_from_push_notification && this.state.user_logged_in && !resumed){
                this._sendMixPanelEvent('notification', {
                  page_title: 'push',
                  skipped:false,
                  os_declined: false,
                  snoozed:false,
                  reminder_selection: null,
                  notification_title: this.state.push_notification_action_text,
                  marked_complete: false,
                  push_notification: true,
                  notification_action_button_clicked: this.state.notification_action_button_clicked,
                  code_location: 'MyProvider'
                });
                this._updateState('SESSION_STARTED', {
                  came_from_push_notification:false
                });
              }

            }, 500);
          },

          //Get Init Data
          getInitData: this._getInitData,

          //Social Auth Facebook
          socialAuthFacebook: (access_token) => {
            let mythis = this;
            return new Promise(function(resolve, reject) {
              const action_name = 'SOCIAL_AUTH_FACEBOOK';
              mythis._updateStateBefore(action_name);
              API.socialAuthFacebook(access_token).then((response) => {
                setTimeout(() =>{
                  mythis._getInitData().then(ok => {

                    const welcomeScreenText = mythis.state.welcome_slide_set['slide'+(mythis.state.welcome_slide_index+1)];

                    //KeenIO
                    if(response.accountCreated){
                      mythis._sendKeenEvent('registration', {
                        sso: true,
                        sso_facebook: true,
                        sso_google: false,
                        sso_apple: false,
                        welcome_screen: welcomeScreenText,
                        channel: mythis.state.marketing_channel,
                        user_id: response.user_id
                      });
                    }else{
                      mythis._sendKeenEvent('login', {
                        sso: true,
                        sso_facebook: true,
                        sso_google: false,
                        sso_apple: false,
                        welcome_screen: welcomeScreenText,
                        channel: mythis.state.marketing_channel
                      });
                    }

                    //MixPanel
                    if(response.accountCreated){
                      mythis._sendMixPanelEvent('registration', {
                        sso: true,
                        sso_facebook: true,
                        sso_google: false,
                        sso_apple: false,
                        welcome_screen: welcomeScreenText,
                        channel: mythis.state.marketing_channel,
                        user_id: response.user_id
                      });
                    }else{
                      mythis._sendMixPanelEvent('login', {
                        sso: true,
                        sso_facebook: true,
                        sso_google: false,
                        sso_apple: false,
                        welcome_screen: welcomeScreenText,
                        channel: mythis.state.marketing_channel
                      });
                    }

                    resolve(ok);
                  });
                }, 1000);
              }, (err) => {
                console.log(action_name + ' err', err);
                reject(err);
              });
            });
          },

          //Social Auth Google
          socialAuthGoogle: (access_token) => {
            let mythis = this;
            return new Promise(function(resolve, reject) {
              const action_name = 'SOCIAL_AUTH_GOOGLE';
              mythis._updateStateBefore(action_name);
              API.socialAuthGoogle(access_token).then((response) => {
                setTimeout(() =>{
                  mythis._getInitData().then(ok => {

                    const welcomeScreenText = mythis.state.welcome_slide_set['slide'+(mythis.state.welcome_slide_index+1)];

                    //KeenIO
                    if(response.accountCreated){
                      mythis._sendKeenEvent('registration', {
                        sso: true,
                        sso_google: true,
                        sso_facebook: false,
                        sso_apple: false,
                        welcome_screen: welcomeScreenText,
                        channel: mythis.state.marketing_channel,
                        user_id: response.user_id
                      });
                    }else{
                      mythis._sendKeenEvent('login', {
                        sso: true,
                        sso_google: true,
                        sso_facebook: false,
                        sso_apple: false,
                        welcome_screen: welcomeScreenText,
                        channel: mythis.state.marketing_channel
                      });
                    }

                    //MixPanel
                    if(response.accountCreated){
                      mythis._sendMixPanelEvent('registration', {
                        sso: true,
                        sso_google: true,
                        sso_facebook: false,
                        sso_apple: false,
                        welcome_screen: welcomeScreenText,
                        channel: mythis.state.marketing_channel,
                        user_id: response.user_id
                      });
                    }else{
                      mythis._sendMixPanelEvent('login', {
                        sso: true,
                        sso_google: true,
                        sso_facebook: false,
                        sso_apple: false,
                        welcome_screen: welcomeScreenText,
                        channel: mythis.state.marketing_channel
                      });
                    }

                    resolve(ok);
                  });
                }, 1000);
              }, (err) => {
                console.log(action_name + ' err', err);
                reject(err);
              });
            });
          },

          //Social Auth Apple
          socialAuthApple: (access_token) => {
            let mythis = this;
            return new Promise(function(resolve, reject) {
              const action_name = 'SOCIAL_AUTH_APPLE';
              mythis._updateStateBefore(action_name);
              API.socialAuthApple(access_token).then((response) => {
                setTimeout(() =>{
                  mythis._getInitData().then(ok => {

                    const welcomeScreenText = mythis.state.welcome_slide_set['slide'+(mythis.state.welcome_slide_index+1)];

                    //KeenIO
                    if(response.accountCreated){
                      mythis._sendKeenEvent('registration', {
                        sso: true,
                        sso_apple: true,
                        sso_facebook: false,
                        sso_google: false,
                        welcome_screen: welcomeScreenText,
                        channel: mythis.state.marketing_channel,
                        user_id: response.user_id
                      });
                    }else{
                      mythis._sendKeenEvent('login', {
                        sso: true,
                        sso_apple: true,
                        sso_facebook: false,
                        sso_google: false,
                        welcome_screen: welcomeScreenText,
                        channel: mythis.state.marketing_channel
                      });
                    }

                    //MixPanel
                    if(response.accountCreated){
                      mythis._sendMixPanelEvent('registration', {
                        sso: true,
                        sso_apple: true,
                        sso_facebook: false,
                        sso_google: false,
                        welcome_screen: welcomeScreenText,
                        channel: mythis.state.marketing_channel,
                        user_id: response.user_id
                      });
                    }else{
                      mythis._sendMixPanelEvent('login', {
                        sso: true,
                        sso_apple: true,
                        sso_facebook: false,
                        sso_google: false,
                        welcome_screen: welcomeScreenText,
                        channel: mythis.state.marketing_channel
                      });
                    }

                    resolve(ok);
                  });
                }, 1000);
              }, (err) => {
                console.log(action_name + ' err', err);
                reject(err);
              });
            });
          },

          //Register User
          registerUser: (first_name, last_name, email, password, agree_to_terms, pilot_hash=null) => {
            let mythis = this;
            return new Promise(function(resolve, reject) {
              const action_name = 'REGISTER_USER';
              mythis._updateStateBefore(action_name);
              API.registerUser(first_name, last_name, email, password, mythis.state.user.school_id, agree_to_terms, pilot_hash).then((result) => {

                const welcomeScreenText = mythis.state.welcome_slide_set['slide'+(mythis.state.welcome_slide_index+1)];
                const school_instance = mythis.state.instances.find(instance => instance.id === mythis.state.user.school_id);

                //KeenIO
                mythis._sendKeenEvent('registration', {
                  sso: mythis.state.sso_session,
                  sso_facebook: false,
                  sso_google: false,
                  sso_apple: false,
                  welcome_screen: welcomeScreenText,
                  channel: mythis.state.marketing_channel,
                  user_id: result.user_id,
                  school_id: mythis.state.user.school_id,
                  school_name: school_instance.title,
                  seconds_since_registration: 0,
                  days_since_registration: 0,
                  weeks_since_registration: 0,
                  months_since_registration: 0,
                });

                //MixPanel
                mythis._sendMixPanelEvent('registration', {
                  sso: mythis.state.sso_session,
                  sso_facebook: false,
                  sso_google: false,
                  sso_apple: false,
                  welcome_screen: welcomeScreenText,
                  channel: mythis.state.marketing_channel,
                  user_id: result.user_id,
                  school_id: mythis.state.user.school_id,
                  school_name: school_instance.title,
                  seconds_since_registration: 0,
                  days_since_registration: 0,
                  weeks_since_registration: 0,
                  months_since_registration: 0,
                });

                resolve(result);
              }, (err) => {
                console.log(action_name + ' err', err);
                reject(err);
              });
            });
          },

          //Verify User
          verifyUser: (token, email) => {
            let mythis = this;
            return new Promise(function(resolve, reject) {
              const action_name = 'VERIFY_USER';
              mythis._updateStateBefore(action_name);
              API.verifyUser(token, email).then((result) => {
                resolve(result);
              }, (err) => {
                console.log(action_name + ' err', err);
                reject(err);
              });
            });
          },

          //Sign In User SSO
          signInUserSSO: (sso_token) => {
            let mythis = this;
            return new Promise(function(resolve, reject) {
              const action_name = 'SIGN_IN_USER_SSO';
              mythis._updateStateBefore(action_name);
              API.signInUserSSO(sso_token).then((result) => {
                mythis._updateState(action_name, {
                  init_data_loaded:false,
                  sso_logging_in:false,
                });
                setTimeout(() => {
                  resolve({success: true});
                },100);
              }, (err) => {
                console.log(action_name + ' err', err);
                reject(err);
              });
            });
          },

          //Sign In User
          signInUser: (email, password)  => {
            let mythis = this;
            return new Promise(function(resolve, reject) {
              const action_name = 'SIGN_IN_USER';
              mythis._updateStateBefore(action_name);
              API.signInUser(email, password).then((result) => {

                if(result.errors){

                  resolve({
                    success: false,
                    errors: result.errors
                  });

                }else{

                  const welcomeScreenText = mythis.state.welcome_slide_set['slide'+(mythis.state.welcome_slide_index+1)];

                  mythis._getInitData().then(ok => {

                    //KeenIO
                    mythis._sendKeenEvent('login', {
                      sso: mythis.state.sso_session,
                      sso_facebook: false,
                      sso_google: false,
                      sso_apple: false,
                      welcome_screen: welcomeScreenText,
                      channel: mythis.state.marketing_channel
                    });

                    //MixPanel
                    mythis._sendMixPanelEvent('login', {
                      sso: mythis.state.sso_session,
                      sso_facebook: false,
                      sso_google: false,
                      sso_apple: false,
                      welcome_screen: welcomeScreenText,
                      channel: mythis.state.marketing_channel
                    });

                    //mythis._updateState(action_name, {
                      //...result,
                      //footer_show: true,
                      //user_logged_in: true,
                      //user_onboarded: result.user.onboarded,
                    //});
                    resolve({
                      success: true,
                    });

                  });

                }
              }, (err) => {
                console.log(action_name + ' err', err);
                reject(err);
              });
            });
          },

          //Sign Out User
          signOutUser: ()  => {
            let mythis = this;
            return new Promise(function(resolve, reject) {
              const action_name = 'SIGN_OUT_USER';
              mythis._updateStateBefore(action_name);
              API.signOutUser().then((result) => {
                // mythis._updateState(action_name, {
                //   footer_show: false,
                //   user_logged_in: false,
                //   user: {},
                //   user_challenges_loaded: false,
                // });
                mythis._signOutState();
                resolve(true);
              }, (err) => {
                console.log(action_name + ' err', err);
                reject(err);
              });
            });
          },

          //Reset Password
          resetPassword: (email) => {
            let mythis = this;
            return new Promise(function(resolve, reject) {
              const action_name = 'RESET_PASSWORD';
              mythis._updateStateBefore(action_name);
              API.resetPassword(email).then((result) => {
                resolve(result);
              }, (err) => {
                console.log(action_name + ' err', err);
                reject(err);
              });
            });
          },

          //Reset Passord Verify
          resetPasswordVerify: (email, password, token) => {
            let mythis = this;
            return new Promise(function(resolve, reject) {
              const action_name = 'RESET_PASSWORD_VERIFY';
              mythis._updateStateBefore(action_name);
              API.resetPasswordVerify(email, password, token).then((result) => {
                resolve(result);
              }, (err) => {
                console.log(action_name + ' err', err);
                reject(err);
              });
            });
          },

          ssoAssert: (sso) => {
            let mythis = this;
            return new Promise(function(resolve, reject) {
              const action_name = 'SSO_ASSERT';
              mythis._updateStateBefore(action_name);
              if(window.cordova){
                if(window.device.platform === 'iOS'){ //window.device.version = 12.2
                  sso += '?params=inApp=true';
                  if(mythis.state.research_id !== null) sso = sso + '_pilotHash=' + mythis.state.research_id;
                  window.plugins.ASWebAuthSession.start(encodeURIComponent('nodfrontend://'), sso,
                    function(msg){
                      resolve({type: 'history', url:msg.slice(14)});
                    }, function (err) {
                      console.log("Error " + err);
                    }
                  );
                }else{
                  if(mythis.state.research_id !== null) sso = sso + '?params=pilotHash=' + mythis.state.research_id;
                  resolve({type: 'location', url:sso});
                }
              }else{
                if(mythis.state.research_id !== null) sso = sso + '?params=pilotHash=' + mythis.state.research_id;
                resolve({type: 'location', url:sso});
              }
            });
          },

          ////////////////////////////////////////////////////////////////////
          //Welcome and Onboarding////////////////////////////////////////////
          ////////////////////////////////////////////////////////////////////
          welcomeSelectInstance: (instance_id) => {
            let mythis = this;
            return new Promise(function(resolve, reject) {
              const action_name = 'WELCOME_SELECT_INSTANCE';
              mythis._updateStateBefore(action_name);
              //_sendKeenEvent('welcome_screen_view', params) //include which A/B test we have
              mythis._updateState(action_name, {
                user: Object.assign(mythis.state.user, {school_id: instance_id})
              });
              resolve(true);
            });
          },

          welcomeSetLoneliness: (loneliness_rating) => {
            let mythis = this;
            return new Promise(function(resolve, reject) {
              const action_name = 'WELCOME_SET_LONELINESS';
              mythis._updateStateBefore(action_name);
              let settings = { loneliness_rating: loneliness_rating };
              API.updateProfile(settings).then((result) => {
                const public_user = Object.assign(mythis.state.user.public_user, {loneliness_rating: loneliness_rating});
                mythis._updateState(action_name, {
                  user: Object.assign(mythis.state.user, {public_user: public_user})
                });
                resolve(true);
              });
            });
          },

          welcomeSetMoreInfo: () => { //age, grade_level, gender, ethnicity
            let mythis = this;
            return new Promise(function(resolve, reject) {
              const action_name = 'WELCOME_SET_LONELINESS';
              mythis._updateStateBefore(action_name);
              let settings = {
                // more_info_age: age,
                // more_info_grade_level: grade_level,
                // more_info_gender: gender,
                // more_info_ethnicity: ethnicity
                age: mythis.state.more_info_age,
                grade_level: mythis.state.more_info_grade_level,
                gender: mythis.state.more_info_gender,
                ethnicity: mythis.state.more_info_ethnicity
              };
              API.updateProfile(settings).then((result) => {
                const user = Object.assign(mythis.state.user, {
                  public_user: settings
                  // public_user: {
                  //   // age: age,
                  //   // gender: gender,
                  //   // grade_level: grade_level,
                  //   // ethnicity: ethnicity
                  //   age: mythis.state.more_info_age,
                  //   grade_level: mythis.state.more_info_grade_level,
                  //   gender: mythis.state.more_info_gender,
                  //   ethnicity: mythis.state.more_info_ethnicity
                  // }
                });
                mythis._updateState(action_name, {
                  user: user,
                });
                resolve(true);
              }, (err) => {
                if(err === 'Unauthorized') mythis._APIUnauthorizedRejection();
                reject(err)
              });
            });
          },

          welcomeFinish: () => {
            let mythis = this;
            return new Promise(function(resolve, reject) {
              const action_name = 'WELCOME_SET_MORE_INFO';
              mythis._updateStateBefore(action_name);

              let settings = {
                //grade_level: grade_level,
                //gender: gender,
                //ethnicity: ethnicity,
                onboarded: true,
              };

              API.updateProfile(settings).then((result) => {

                // const user = Object.assign(mythis.state.user, {
                //   public_user: {
                //     gender: gender,
                //     grade_level: grade_level,
                //     ethnicity: ethnicity
                //   }
                // });

                mythis._updateState(action_name, {
                  user_onboarded: true,
                  user_onboarded_just_now: true,
                  //user: user,
                });
                resolve(true);
              }, (err) => {
                console.log(action_name + ' err', err);
                if(err === 'Unauthorized') mythis._APIUnauthorizedRejection();
                reject(err);
              });
            });
          },

          ////////////////////////////////////////////////////////////////////
          //Guides & Challenge Lists//////////////////////////////////////////
          ////////////////////////////////////////////////////////////////////

          getGuides: () => {
            let mythis = this;
            return new Promise(function(resolve, reject) {
              const action_name = 'GET_GUIDES';
              mythis._updateStateBefore(action_name);
              API.getGuides().then((guides) => {

                //Order the guides by the user's motivations
                let guides_sorted = [];
                let wildcard_guide = null;
                let user_motivation_is_hidden = false;
                const wild_card_search_string = 'Wild Card';
                mythis.state.user_motivations.forEach((motivation) => {

                  //Check if any user motivation is hidden
                  if(motivation.hidden) user_motivation_is_hidden = true;

                  guides.forEach((guide) => {

                    //Set the Wildcard Guide
                    if(guide.title.includes(wild_card_search_string)){
                      wildcard_guide = guide;
                    }else{

                      //Match the guide to the motivation and add it to the list
                      if(guide.motivation.id === motivation.id)guides_sorted.push(guide);
                    }
                  });
                });

                //If any of their saved motivations are now hidden, let's give them all the new guides
                if(user_motivation_is_hidden){
                  guides.forEach((guide) => {
                    if(!guide.title.includes(wild_card_search_string)) guides_sorted.push(guide);
                  });
                }

                //Add the wildcard to the end
                if(wildcard_guide !== null) guides_sorted.push(wildcard_guide);

                mythis._updateState(action_name, {
                  guides_loaded: true,
                  guides_all: guides,
                  guides: guides_sorted,
                });
                resolve(true);
              }, (err) => {
                console.log(action_name + ' err', err);
                if(err === 'Unauthorized') mythis._APIUnauthorizedRejection();
                reject(err);
              });
            });
          },

          getGuideBySlug: (slug) => {
            return this.state.guides.find(function(guide) {
              return guide.slug === slug;
            });
          },

          getSkillBySlug: (slug) => {
            return this.state.cbt_skills.find(function(skill) {
              return skill.slug === slug;
            });
          },

          getChallenges: () => {
            let mythis = this;
            return new Promise(function(resolve, reject) {
              const action_name = 'GET_CHALLENGES';
              mythis._updateStateBefore(action_name);
              API.getChallenges().then((result) => {
                mythis._updateState(action_name, {
                  challenges_loaded: true,
                  challenges: result,
                });
                resolve(true);
              }, (err) => {
                console.log(action_name + ' err', err);
                reject(err);
              });
            });
          },

          getChallengeByID: (challenge_id) => {
            return this.state.challenges.find(function(challenge) {
              return challenge.id === challenge_id;
            });
          },

          //Featured challenge algorithm
          sortFeaturedChallenges: () => {
            let mythis = this;

            //Create group arrays in order of motivation preference
            let guide_cards = [];
            this.state.guides.forEach(function(guide, index){
              guide_cards[index] = [];
              if(guide !== null && !guide.hidden){ //Exclude wildcard group and hidden guides
                mythis.state.challenges.forEach(function(challenge) {
                  if(!challenge.hidden){

                    //Add challenges that have not been accepted or completed to each group
                    const user_challenge = mythis.state.user_challenges.find(function(thechallenge) {
                      return thechallenge.challenge.id === challenge.id;
                    });
                    if(guide.id === challenge.guide_id && user_challenge === undefined){
                      guide_cards[index].push(challenge);
                    }
                  }
                });
              }
            });

            //Sort group arrays by difficulty
            guide_cards.forEach(function(guide, index){
              guide.sort((a,b) => (a.difficulty > b.difficulty) ? 1 : ((b.difficulty > a.difficulty) ? -1 : 0));
            });

            //Flatten into a single weaved array
            let longest_array_length = 0;
            guide_cards.forEach((guide) => { if(guide.length > longest_array_length) longest_array_length = guide.length; });
            let flattened_array = [];
            for(let i=0; i<longest_array_length; i++){
              guide_cards.forEach((guide) => {
                if(guide[i] !== undefined) flattened_array.push(guide[i]);
              });
            }

            //Get Wilcard Challenges that aren't in user_challenges
            let wildcard_challenges = [];
            this.state.challenges.forEach((challenge, index) => {
              if(challenge.guide_id === 4){
                const user_challenge = this.state.user_challenges.find(function(user_challenge) {
                  return user_challenge.challenge.id === challenge.id;
                });
                if(user_challenge === undefined) wildcard_challenges.push(challenge);
              }
            });

            //Inject wildcard every three sets
            let wildcard_inject_index = 0;
            let wildcard_start_injecting_index = 0;
            const wildcard_start_injecting_threshold = 1;
            const flattend_injected_array = [];
            flattened_array.forEach((challenge, index) => {
              flattend_injected_array.push(challenge);
              if(index % 3 === 0 && wildcard_start_injecting_index > wildcard_start_injecting_threshold){
                if(wildcard_inject_index < wildcard_challenges.length){
                  flattened_array.push(wildcard_challenges[wildcard_inject_index]);
                }
                wildcard_inject_index++;
              }
              if(index % 3 === 0) wildcard_start_injecting_index++;
            });

            const action_name = 'SORT_FEATURED_CHALLENGES';
            mythis._updateStateBefore(action_name);
            this._updateState(action_name, {
              featured_challenges: flattened_array,
              featured_challenge_index: 0,
            });
          },

          ////////////////////////////////////////////////////////////////////
          //User Challenges///////////////////////////////////////////////////
          ////////////////////////////////////////////////////////////////////

          getUserChallenges: () => {
            let mythis = this;
            return new Promise(function(resolve, reject) {
              const action_name = 'GET_USER_CHALLENGES';
              mythis._updateStateBefore(action_name);
              API.getUserChallenges().then((result) => {
                mythis._updateState(action_name, {
                  user_challenges_loaded: true,
                  user_challenges: result,
                });
                resolve(true);
              }, (err) => {
                console.log(action_name + ' err', err);
                if(err === 'Unauthorized') mythis._APIUnauthorizedRejection();
                reject(err);
              });
            });
          },

          getNumberOfActiveUserChallenges: () => {
            let active_challenges = 0;
            this.state.user_challenges.forEach((challenge) => {
              if(!challenge.completed) active_challenges++;
            });
            return active_challenges;
          },

          getNumberOfCompletedUserChallenges: () => {
            let completed_challenges = 0;
            this.state.user_challenges.forEach((challenge) => {
              if(challenge.completed) completed_challenges++;
            });
            return completed_challenges;
          },

          getUserChallengeByChallengeID: (challenge_id) => {
            const user_challenge = this.state.user_challenges.find(function(challenge) {
              return challenge.challenge.id === challenge_id;
            });
            return user_challenge ? user_challenge : false;
          },

          getChallengesCompletedByGuides: (guide_id) => {
            let challenges_completed = 0;
            this.state.user_challenges.forEach((challenge) => {
              if(challenge.completed && challenge.challenge.guide_id === guide_id) challenges_completed++;
            });
            return challenges_completed;
          },

          acceptChallenge: (id) => {
            let mythis = this;
            return new Promise(function(resolve, reject) {
              const action_name = 'ACCEPT_CHALLENGE';
              mythis._updateStateBefore(action_name);
              API.acceptChallenge(id).then((result) => {

                const challenge = mythis.state.challenges.find((thechallenge) => { return thechallenge.id === id; });
                const guide = mythis.state.guides.find((theguide) => { return theguide.id === challenge.guide_id; });

                //KeenIO
                mythis._sendKeenEvent('challenge', {
                  guide_title: guide.title,
                  challenge_id: id,
                  challenge_title: challenge.title,
                  status: 'accepted',
                  rating: null,
                  approach: null,
                  skill_title: challenge.cbt_skill ? challenge.cbt_skill.title : null,
                  prior_path: mythis.state.last_last_path,
                  back:false,
                  home:false,
                  push_notification_title: mythis.state.push_notification_title,
                  exit_app: false
                });

                //MixPanel
                mythis._sendMixPanelEvent('challenge', {
                  guide_title: guide.title,
                  challenge_id: id,
                  challenge_title: challenge.title,
                  status: 'accepted',
                  rating: null,
                  approach: null,
                  skill_title: challenge.cbt_skill ? challenge.cbt_skill.title : null,
                  prior_path: mythis.state.last_last_path,
                  back:false,
                  home:false,
                  push_notification_title: mythis.state.push_notification_title,
                  exit_app: false
                });

                result.created_at = moment().utc().format("YYYY-MM-DD HH:mm:ss");

                const appended_challenges = mythis.state.user_challenges;
                appended_challenges.push(result);
                mythis._updateState(action_name, {
                  user_challenges: appended_challenges,
                  user_challenge_just_saved: true,
                });
                resolve(true);
              }, (err) => {
                console.log(action_name + ' err', err);
                if(err === 'Unauthorized') mythis._APIUnauthorizedRejection();
                reject(err);
              });
            });
          },

          updateUserChallenge: (id, settings) => {
            let mythis = this;
            return new Promise(function(resolve, reject) {
              const action_name = 'UPDATE_USER_CHALLENGE';
              mythis._updateStateBefore(action_name);
              API.updateUserChallenge(id, settings).then((result) => {

                let user_challenge = mythis.state.user_challenges.find((thechallenge) => { return thechallenge.challenge.id === id; });
                const guide = mythis.state.guides_all.find((theguide) => { return theguide.id === user_challenge.challenge.guide_id; });

                //Set completed date
                if(settings.completed === true){
                  //let current_datetime = new Date()
                  //settings.challenge_completed_at = current_datetime.getFullYear() + "-" + (current_datetime.getMonth() + 1) + "-" + current_datetime.getDate() + " " + current_datetime.getHours() + ":" + current_datetime.getMinutes() + ":" + current_datetime.getSeconds();
                  settings.challenge_completed_at = moment().utc().format("YYYY-MM-DD HH:mm:ss");

                  //KeenIO
                  mythis._sendKeenEvent('challenge', {
                    guide_title: guide.title,
                    challenge_id: id,
                    challenge_title: user_challenge.challenge.title,
                    status: 'completed',
                    rating: (settings.rating !== null && settings.rating !== undefined) ? settings.rating : null,
                    approach: settings.approach,
                    skill_title: user_challenge.challenge.cbt_skill ? user_challenge.challenge.cbt_skill.title : null,
                    prior_path: mythis.state.last_last_path,
                    back:false,
                    home:false,
                    push_notification_title: mythis.state.push_notification_title,
                    exit_app: false
                  });

                  //MixPanel
                  mythis._sendMixPanelEvent('challenge', {
                    guide_title: guide.title,
                    challenge_id: id,
                    challenge_title: user_challenge.challenge.title,
                    status: 'completed',
                    rating: (settings.rating !== null && settings.rating !== undefined) ? settings.rating : null,
                    approach: settings.approach,
                    skill_title: user_challenge.challenge.cbt_skill ? user_challenge.challenge.cbt_skill.title : null,
                    prior_path: mythis.state.last_last_path,
                    back:false,
                    home:false,
                    push_notification_title: mythis.state.push_notification_title,
                    exit_app: false
                  });
                }

                // if(settings.rating !== null && settings.rating !== undefined){
                //   mythis._sendKeenEvent('challenge_rating', {
                //     guide_title: guide.title,
                //     challenge_id: id,
                //     challenge_title: user_challenge.challenge.title,
                //     rating: settings.rating
                //   });
                // }

                //Find user challenge and update it's settings
                const user_challenges = mythis.state.user_challenges.map(a => Object.assign({}, a));
                user_challenge = user_challenges.find(function(thechallenge) {
                  return thechallenge.challenge.id === id;
                });
                Object.assign(user_challenge, settings);

                mythis._updateState(action_name, {
                  reflect_came_from_challenge_id: id,
                  relfect_last_challenge_rating: settings.rating,
                  user_challenges: user_challenges,
                  came_from_push_notification_yes_action: false,
                });
                resolve(true);
              }, (err) => {
                console.log(action_name + ' err', err);
                if(err === 'Unauthorized') mythis._APIUnauthorizedRejection();
                reject(err);
              });
            });
          },

          cancelUserChallenge: (id) => {
            let mythis = this;
            return new Promise(function(resolve, reject) {
              const action_name = 'CANCEL_USER_CHALLENGE';
              mythis._updateStateBefore(action_name);
              API.cancelUserChallenge(id).then((result) => {

                //Remove from User Challenges
                const user_challenges = mythis.state.user_challenges.filter(function(thechallenge){
                  return thechallenge.challenge.id !== id;
                });

                const user_challenge = mythis.state.user_challenges.find((thechallenge) => { return thechallenge.challenge.id === id; });
                const guide = mythis.state.guides.find((theguide) => { return theguide.id === user_challenge.challenge.guide_id; });

                //KeenIO
                mythis._sendKeenEvent('challenge', {
                  guide_title: guide.title,
                  challenge_id: id,
                  challenge_title: user_challenge.challenge.title,
                  status: 'cancelled',
                  rating: null,
                  approach: null,
                  skill_title: user_challenge.challenge.cbt_skill ? user_challenge.challenge.cbt_skill.title : null,
                  prior_path: mythis.state.last_last_path,
                  back:false,
                  home:false,
                  push_notification_title: mythis.state.push_notification_title,
                  exit_app: false
                });

                //MixPanel
                mythis._sendMixPanelEvent('challenge', {
                  guide_title: guide.title,
                  challenge_id: id,
                  challenge_title: user_challenge.challenge.title,
                  status: 'cancelled',
                  rating: null,
                  approach: null,
                  skill_title: user_challenge.challenge.cbt_skill ? user_challenge.challenge.cbt_skill.title : null,
                  prior_path: mythis.state.last_last_path,
                  back:false,
                  home:false,
                  push_notification_title: mythis.state.push_notification_title,
                  exit_app: false
                });

                //State
                mythis._updateState(action_name, {
                  user_challenges: user_challenges,
                  user_challenge_just_cancelled: true,
                });
                resolve(true);
              }, (err) => {
                console.log(action_name + ' err', err);
                if(err === 'Unauthorized') mythis._APIUnauthorizedRejection();
                reject(err);
              });
            });
          },

          ////////////////////////////////////////////////////////////////////
          //Reflections///////////////////////////////////////////////////////
          ////////////////////////////////////////////////////////////////////
          reflectSetMood: (mood_changed, mood_balance, mood_coords, mood_position, mood_coord_label) => {
            let mythis = this;
            return new Promise(function(resolve, reject) {
              const action_name = 'REFLECT_SET_MOOD';
              mythis._updateStateBefore(action_name);

              //Create group of reflections with the same mood
              let reflect_filtered_group = [];
              mythis.state.reflections.forEach((reflection, index) => {
                if(reflection.mood.includes(mood_balance)) reflect_filtered_group.push(reflection);
              });

              if(mythis.state.user_challenge_just_completed){

                const settings = {
                  mood_after: mood_position.join(','),
                  completed: true,
                }
                API.updateUserChallenge(mythis.state.reflect_came_from_challenge_id, settings).then(() => {

                  //Find user challenge and update it's settings
                  const user_challenges = mythis.state.user_challenges.map(a => Object.assign({}, a));
                  let user_challenge = user_challenges.find(function(thechallenge) {
                    return thechallenge.challenge.id === mythis.state.reflect_came_from_challenge_id;
                  });
                  Object.assign(user_challenge, settings);

                  mythis._updateState(action_name, {
                    reflect_mood_changed: mood_changed,
                    reflect_mood_balance: mood_balance,
                    reflect_mood_coords: mood_coords,
                    reflect_mood_position: mood_position,
                    reflect_mood_coord_label: mood_coord_label,
                    reflect_filtered_group: reflect_filtered_group,
                    user_challenges: user_challenges
                  });
                  resolve(true);
                }, (err) => {
                  if(err === 'Unauthorized') mythis._APIUnauthorizedRejection();
                  reject(err);
                });

              }else{

                mythis._updateState(action_name, {
                  reflect_mood_changed: mood_changed,
                  reflect_mood_balance: mood_balance,
                  reflect_mood_coords: mood_coords,
                  reflect_mood_position: mood_position,
                  reflect_mood_coord_label: mood_coord_label,
                  reflect_filtered_group: reflect_filtered_group,
                });
                resolve(true);

              }
            });
          },

          reflectSetReflectionChoice: (reflection) => {
            let mythis = this;
            return new Promise(function(resolve, reject) {
              const action_name = 'REFLECT_SET_REFLECTION_CHOICE';
              mythis._updateStateBefore(action_name);
              mythis._updateState(action_name, {
                reflect_current: reflection,
                reflect_start_time: new Date().getTime(),
                reflect_option: null,
              });
              resolve(true);
            });
          },

          reflectOptionChoice: (option) => {
            let mythis = this;
            return new Promise(function(resolve, reject) {
              const action_name = 'REFLECT_OPTION_CHOICE';
              mythis._updateStateBefore(action_name);
              mythis._updateState(action_name, {
                reflect_option: option
              });
              resolve(true);
            });
          },

          reflectFinish: (id) => {
            let mythis = this;
            return new Promise(function(resolve, reject) {
              const action_name = 'REFLECT_FINISH';
              mythis._updateStateBefore(action_name);
              //if(mythis.state.user_challenge_just_completed){
                const settings = {
                  reflection_id: id
                }
                API.updateUserChallenge(mythis.state.reflect_came_from_challenge_id, settings).then(() => {

                  //Set completed date
                  let current_datetime = new Date()
                  settings.reflection_completed_at = current_datetime.getFullYear() + "-" + (current_datetime.getMonth() + 1) + "-" + current_datetime.getDate() + " " + current_datetime.getHours() + ":" + current_datetime.getMinutes() + ":" + current_datetime.getSeconds()

                  //Find user challenge and update it's settings
                  const user_challenges = mythis.state.user_challenges.map(a => Object.assign({}, a));
                  let user_challenge = user_challenges.find(function(thechallenge) {
                    return thechallenge.challenge.id === mythis.state.reflect_came_from_challenge_id;
                  });
                  Object.assign(user_challenge, settings);

                  mythis._updateState(action_name, {
                    reflect_finished: true,
                    user_challenges: user_challenges
                  });
                  resolve(true);
                }, (err) => {
                  if(err === 'Unauthorized') mythis._APIUnauthorizedRejection();
                  reject(err);
                });
              // }else{
              //  mythis._updateState(action_name, {
              //    reflect_finished: true
              //  });
              //  resolve(true);
              // }
            });
          },

          reflectSkip: () => {
            let mythis = this;
            return new Promise(function(resolve, reject) {
              const action_name = 'REFLECT_SKIP';
              mythis._updateStateBefore(action_name);
              mythis._updateState(action_name, {
                reflect_finished: false
              });
              resolve(true);
            });
          },

          ////////////////////////////////////////////////////////////////////
          //Standalone Reflections////////////////////////////////////////////
          ////////////////////////////////////////////////////////////////////

          standaloneReflectFinish: (id, mood_position) => {
            let mythis = this;
            return new Promise(function(resolve, reject) {
              const action_name = 'STANDALONE_REFLECT_FINISH';
              mythis._updateStateBefore(action_name);
                API.saveStandaloneReflection(id, mood_position.join(',')).then((result) => {
                  mythis._updateState(action_name, {
                    reflect_finished: true,
                    user_challenge_just_completed: false,
                    standalone_reflections_loaded: false,
                  });
                  resolve(true);
                }, (err) => {
                  if(err === 'Unauthorized') mythis._APIUnauthorizedRejection();
                  reject(err);
                });
            });
          },

          getStandaloneReflections: () => {
            let mythis = this;
            return new Promise(function(resolve, reject) {
              const action_name = 'GET_STANDALONE_REFLECTIONS';
              mythis._updateStateBefore(action_name);
                API.getStandaloneReflections().then((result) => {
                  mythis._updateState(action_name, {
                    standalone_reflections_loaded: true,
                    standalone_reflections: result,
                  });
                  resolve(true);
                }, (err) => {
                  if(err === 'Unauthorized') mythis._APIUnauthorizedRejection();
                  reject(err);
                });
            });
          },

          ////////////////////////////////////////////////////////////////////
          //Myself////////////////////////////////////////////////////////////
          ////////////////////////////////////////////////////////////////////

          updateMotivations: (motivations) => {
            let mythis = this;
            return new Promise(function(resolve, reject) {
              const action_name = 'WELCOME_SORT_MOTIVATIONS';
              mythis._updateStateBefore(action_name);

              //Remove Duplicates
              motivations = motivations.map(JSON.stringify).reverse() // convert to JSON string the array content, then reverse it (to check from end to begining)
              .filter(function(item, index, arr){ return arr.indexOf(item, index + 1) === -1; }) // check if there is any occurence of the item in whole array
              .reverse().map(JSON.parse) // revert it to original state

              //Create list to save
              let motivations_id_list = '';
              motivations.forEach((motivation, index)=>{
                motivations_id_list += motivation.id;
                if(index < motivations.length-1) motivations_id_list += ',';
              });

              API.updateMotivations(motivations_id_list).then((result) => {
                mythis._updateState(action_name, {
                  user_motivations: motivations
                });
                resolve(true);
              }, (err) => {
                console.log(action_name + ' err', err);
                if(err === 'Unauthorized') mythis._APIUnauthorizedRejection();
                reject(err);
              });
            });
          },

          updateProfile: (settings) => {
            let mythis = this;
            return new Promise(function(resolve, reject) {
              const action_name = 'UPDATE_PROFILE';
              mythis._updateStateBefore(action_name);
              API.updateProfile(settings).then((result) => {

                //Merge public_user
                let public_user = Object.assign(mythis.state.user.public_user, {});
                if(settings.gender) public_user.gender = settings.gender;
                if(settings.age) public_user.age = settings.age;
                if(settings.grade_level) public_user.grade_level = settings.grade_level;
                if(settings.ethnicity) public_user.ethnicity = settings.ethnicity;
                if(settings.loneliness_rating) public_user.loneliness_rating = settings.loneliness_rating;
                if(settings.checkback_feel_less_lonely) public_user.checkback_feel_less_lonely = settings.checkback_feel_less_lonely;
                if(settings.checkback_help_improve_mood) public_user.checkback_help_improve_mood = settings.checkback_help_improve_mood;
                if(settings.checkback_helpful_tips) public_user.checkback_helpful_tips = settings.checkback_helpful_tips;

                //Merge user
                let user = Object.assign(mythis.state.user, {});
                user.public_user = public_user;
                //let user = { public_user: public_user }
                if(settings.first_name) user.first_name = settings.first_name;
                if(settings.last_name) user.last_name = settings.last_name;
                if(settings.onboarded !== undefined) user.onboarded = settings.onboarded;
                mythis._updateState(action_name, {
                  //user: Object.assign(mythis.state.user, user),
                  user: user,
                });
                resolve(true);
              }, (err) => {
                console.log(action_name + ' err', err);
                if(err === 'Unauthorized') mythis._APIUnauthorizedRejection();
                reject(err);
              });
            });
          },

          // Journey
          getJourney: () =>{
            let mythis = this;
            return new Promise(function(resolve, reject) {
              const action_name = 'GET_JOURNEY';
              mythis._updateStateBefore(action_name);
              API.getJourney().then((result) => {
                mythis._updateState(action_name, {
                  journey: result,
                  journey_loaded:true,
                });
                resolve(true);
              }, (err) => {
                console.log(action_name + ' err', err);
                if(err === 'Unauthorized') mythis._APIUnauthorizedRejection();
                reject(err);
              });
            });
          },

          sendFeedback: (category, body, email) =>{
            let mythis = this;
            return new Promise(function(resolve, reject) {
              const action_name = 'SEND_FEEDBACK';
              mythis._updateStateBefore(action_name);
              API.sendFeedback(category, body, email).then((result) => {
                resolve(true);
              }, (err) => {
                console.log(action_name + ' err', err);
                reject(err);
              });
            });
          },

          ////////////////////////////////////////////////////////////////////
          //Notifications/////////////////////////////////////////////////////
          ////////////////////////////////////////////////////////////////////

          notificationsRefresh: () => {
            this.notifications.refresh();
            const new_notifications = this.notifications.getNewNotificationCount();
            const action_name = 'NOTIFICATIONS_REFRESH';
            this._updateState(action_name, {
              new_notifications: new_notifications
            });
          },

          areOsNotificationsEnabled: () => {
            if(this.state.debug) console.log('ARE_OS_NOTIFICATIONS_ENABLED');
            return this.notifications.areOsNotificationsEnabled();
          },

          requestEnableOsNotifications: () => {
            if(this.state.debug) console.log('REQUEST_ENABLE_OS_NOTIFICATIONS');
            return this.notifications.requestEnableOsNotifications();
          },

          getNotificationSetting: (which) => {
            if(this.state.debug) console.log('GET_NOTIFICATION_SETTING', which);
            return this.notifications.getNotificationSetting(which);
          },

          setNotificationSetting: (which, value) => {
            if(this.state.debug) console.log('SET_NOTIFICATION_SETTING', which, value);
            return this.notifications.setNotificationSetting(which, value);
          },

          getAllExpiredNotifications: () => {
            if(this.state.debug) console.log('GET_ALL_EXPIRED_NOTIFICATIONS');
            return this.notifications.getAllExpiredNotifications();
          },

          getAllScheduledNotifications: () => {
            if(this.state.debug) console.log('GET_ALL_SCHEDULED_NOTIFICATIONS');
            return this.notifications.getAllScheduledNotifications();
          },

          getExpiredChallengeNotifactionFromId: (id) => {
            if(this.state.debug) console.log('GET_EXPIRED_CHALLENGE_NOTIFICATION_BY_ID');
            return this.notifications.getExpiredChallengeNotifactionFromId(id);
          },

          expiredNotificationsViewed: () => {
            if(this.state.debug) console.log('EXPIRED_NOTIFICATIONS_VIEWED');
            return this.notifications.expiredNotificationsViewed();
          },

          createNotification: (trigger, title, text, category, actionText, redirectRoute) => {
            if(this.state.debug) console.log('CREATE_NOTIFICATION', trigger, title, text, category, actionText, redirectRoute);
            return this.notifications.createNotification(trigger, title, text, category, actionText, redirectRoute);
          },

          createChallengeNotification: (challengeId, notificationOptions) => {
            if(this.state.debug) console.log('CREATE_CHALLENGE_NOTIFICATION', challengeId, notificationOptions);
            return this.notifications.createChallengeNotification(challengeId, notificationOptions);
          },

          cancelChallengeNotification: (challengeId) => {
            if(this.state.debug) console.log('CANCEL_CHALLENGE_NOTIFICATION', challengeId);
            return this.notifications.cancelChallengeNotification(challengeId);
          },

          updateChallengeNotificationTime: (challengeId, newTime) => {
            if(this.state.debug) console.log('UPDATE_CHALLENGE_NOTIFICATION_TIME', challengeId, newTime);
            return this.notifications.updateChallengeNotificationTime(challengeId, newTime);
          },

          updateAllNotificationTimes: (newTime) => {
            if(this.state.debug) console.log('UPDATE_ALL_NOTIFICATION_TIMES', newTime);
            return this.notifications.updateAllNotificationTimes(newTime);
          },

          ////////////////////////////////////////////////////////////////////
          //BranchIO//////////////////////////////////////////////////////////
          ////////////////////////////////////////////////////////////////////

          followBranchRedirect: (redirectScope) => {
            if(this.state.debug) console.log('FOLLOW_BRANCH_REDIRECT');
            return new BranchService().followBranchRedirect(redirectScope);
          },

          makeBranchLink: (linkData) => {
            if(this.state.debug) console.log('MAKE_BRANCH_LINK');
            return new BranchService().makeBranchLink(linkData);
          }

        }}>
        <UrlRedirects redirectScope={this}/>
        {this.props.children}
      </MyContext.Provider>
    );
  }
}

export default withRouter(MyProvider);
