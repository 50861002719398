import React, { Component } from 'react';
import MyContext from '../../state/MyContext';
import "wicg-inert";
import wild_thing_mole_img from '../../assets/images/illustrations/wild-thing-mole.png';
require('./style.scss');

class FooterModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: this.props.visible === undefined ? true : this.props.visible,
      previousActiveElement: document.activeElement,
    }

    this._close = this._close.bind(this);
    this._closeOutside = this._closeOutside.bind(this);
    this._open = this._open.bind(this);
    this.closeButton = React.createRef();
    this.footerModalInner = React.createRef();
  }

  componentDidMount() {
    if(this.props.ignoreInit !== true){
      if(this.props.visible === false){
        this.context.footerModalNotPresent();
      }else{
        this.context.footerModalPresent();
        this._setAllNonModalElementsInert(true);
      }
    }else{
      if(this.props.visible !== false) this._setAllNonModalElementsInert(true);
    }
  }

  componentDidUpdate() {
    if(this.state.visible && this.closeButton.current){
      setTimeout(() => {
        if(this.closeButton.current) this.closeButton.current.focus();
      }, 100);
    }else{
      setTimeout(() => {
        if(this.footerModalInner.current) this.footerModalInner.current.focus();
      }, 100)
    }
  }

  show() {
    this.setState({visible:true, previousActiveElement: document.activeElement});
    this._setAllNonModalElementsInert(true);
    this.context.footerModalPresent();
  }

  hide() {
    this.setState({visible:false});
    this.state.previousActiveElement.focus();
    this._setAllNonModalElementsInert(false);
    this.context.footerModalNotPresent();
    if(this.context.user_onboarded_just_now) this.context.setState({user_onboarded_just_now:false});
  }

  _setAllNonModalElementsInert(inert) {
    if(this.props.parentId){
      Array.from(document.getElementById(this.props.parentId).children).forEach(child => {
        if(child.getAttribute('role') !== 'dialog') child.inert = inert;
      });
    }else{
      console.log('please add the parent div to the footer modal');
    }

    if(!this.props.ignoreFooterInert && document.getElementById('footer')){
      Array.from(document.getElementById('footer').children).forEach(child => {
        child.inert = inert;
      });
    }
  }

  _close(e) {
    e.preventDefault();
    this.setState({visible:false});
    this._setAllNonModalElementsInert(false);
    this.state.previousActiveElement.focus();
    if(this.context.footer_modal_present) this.context.footerModalNotPresent();
    if(this.context.user_onboarded_just_now) this.context.setState({user_onboarded_just_now:false});
    if(this.context.user_challenge_just_completed) this.context.setState({user_challenge_just_completed:false});
    if(this.props.onClose) this.props.onClose();
  }

  _closeOutside(e) {
    e.preventDefault();
    if(e.target.classList.contains('footer-modal') && this.props.disableBackgroundClick === undefined){
      this.setState({visible:false});
      this._setAllNonModalElementsInert(false);
      this.state.previousActiveElement.focus();
      if(this.context.footer_modal_present) this.context.footerModalNotPresent();
      if(this.context.user_onboarded_just_now) this.context.setState({user_onboarded_just_now:false});
      if(this.context.user_challenge_just_completed) this.context.setState({user_challenge_just_completed:false});
      if(this.props.onClose) this.props.onClose();
    }
  }

  _open(e) {
    e.preventDefault();
    this.setState({visible:true, previousActiveElement: document.activeElement});
    this._setAllNonModalElementsInert(true);
    if(this.context.footer_modal_present) this.context.footerModalPresent();
    if(this.props.onOpen !== undefined) this.props.onOpen();
  }

  render(){
    let classes = 'footer-modal';
    let tabIndex = '-1';
    if(this.state.visible){
      classes += ' visible';
      tabIndex = '0';
    }
    if(this.props.className) classes += ' ' + this.props.className;
    if(this.props.pushBottom === true) classes += ' push-bottom';
    if(this.props.notificationsPopUp === true) classes += ' notification-pop-up';
    return (
      <React.Fragment>
        { this.props.descriptor === true && !this.state.visible ? <button className="footer-modal_inner_toggle help" onClick={this._open} aria-label="help"/> : null }
        <div role="dialog" className={classes} onClick={this._closeOutside}>
          <div tabIndex="-1" ref={this.footerModalInner} className="footer-modal_inner">
            { this.props.descriptor !== true && this.props.disableCloseButton !== true ?
              <a ref={this.closeButton} tabIndex={tabIndex} role="button" className="footer-modal_inner_close" href="/" onClick={this._close} aria-label="close">&nbsp;</a>
            : null }
            { this.props.notificationsPopUp ? <img className="notification-image" alt="Wild Thing Mole" src={wild_thing_mole_img} /> : null}
            { this.state.visible && this.props.children }
          </div>
        </div>
      </React.Fragment>
    );
  }
}
FooterModal.contextType = MyContext;
export default FooterModal;
