import React, { Component } from 'react';
import { withRouter } from "react-router";
import MyContext from '../../state/MyContext';
require('./style.scss');

/*
  How does this work?

  The PageHeader component updates context.background_header_height depending on the height of the contents within.
  Different pages have this PageHeader component, tharefore updating when you navigate to those pages.
  This looks to see if the height has changed. If so, it animates the height of background.

  The curvature consists of 3 svg files positioned next to eachother, offset by 1 svg length to the left.
  As you navigate, they animate to the left or right X pixels, direction determined by PUSH or POP.
  If they reach the left or right side they jump to 0 without animating, then animate to the next position
  This creates a continuous loop from the left or right direction.

*/

class Background extends Component {

  constructor(props) {
    super();
    this.state = {
      background_header_height: 0,
      path_animated: true,
      path_xpos: 0,
    }
    this.background_path_width = 1000; //needs to match width in CSS
  }

  componentDidMount() {
    if(this.context.background_header_height !== this.state.background_header_height){
      this.setState({background_header_height: this.context.background_header_height});
    }
  }

  componentDidUpdate(){
    if(this.context.background_header_height !== this.state.background_header_height){

      let direction = -1;
      if(this.props.history.action === 'POP') direction = 1;

      const path_xpos_inc = 200;
      let path_xpos = this.state.path_xpos + (path_xpos_inc * direction);

      //Check if you'll reached left or right side - if so, jump to 0 without animating, then animate next movement
      let edge = false;
      if(path_xpos < -this.background_path_width) edge = 1;
      if(path_xpos > this.background_path_width) edge = -1;
      if(edge !== false){
        path_xpos = 0;
        this.setState({path_animated: false, path_xpos: path_xpos});
      }else{
        setTimeout(() =>{ //We want to delay this slighly so the un-animated state update above can finish.
          this.setState({background_header_height: this.context.background_header_height, path_animated: true, path_xpos: path_xpos});
        }, 10);
      }
    }
  }

  render(){
    let bgclasses = this.context.background_header_color;
    if(this.state.background_header_height <= 45) bgclasses += ' hide';
    let pathclasses = 'path';
    if(this.state.path_animated) pathclasses += ' animated';
    return (
      <div id="background" className={bgclasses} style={{height:this.state.background_header_height+'px'}}>
        <div className={pathclasses} style={{transform: "translateX(" + this.state.path_xpos + "px)"}}>
          <svg viewBox="0 0 1000 31.39" preserveAspectRatio="none">
            <path className="pathItem" d="M0,0V29.86c21.61,2.24,42.07-5.11,53.07-6.72,36.48-5.34,61-3.46,98.63,3.15S206.18,27.62,253,11.53s63.77,6.58,91.48,13.9,43.63-6.72,66-1.95C425.35,26.63,452,30.39,471.75,25c7.09-1.93,56.62-5,67.8-5.67C588,16.39,589,36.39,651.56,30.2,680.08,27.37,710,15.39,758,23.3c48.81,8,54.62,2.37,92.26-4.25s60.15-1.49,96.63,3.85c11,1.61,31.46,9,53.07,6.72V0Z"/>
          </svg>
          <svg viewBox="0 0 1000 31.39" preserveAspectRatio="none">
            <path className="pathItem" d="M0,0V29.86c21.61,2.24,42.07-5.11,53.07-6.72,36.48-5.34,61-3.46,98.63,3.15S206.18,27.62,253,11.53s63.77,6.58,91.48,13.9,43.63-6.72,66-1.95C425.35,26.63,452,30.39,471.75,25c7.09-1.93,56.62-5,67.8-5.67C588,16.39,589,36.39,651.56,30.2,680.08,27.37,710,15.39,758,23.3c48.81,8,54.62,2.37,92.26-4.25s60.15-1.49,96.63,3.85c11,1.61,31.46,9,53.07,6.72V0Z"/>
          </svg>
          <svg viewBox="0 0 1000 31.39" preserveAspectRatio="none">
            <path className="pathItem" d="M0,0V29.86c21.61,2.24,42.07-5.11,53.07-6.72,36.48-5.34,61-3.46,98.63,3.15S206.18,27.62,253,11.53s63.77,6.58,91.48,13.9,43.63-6.72,66-1.95C425.35,26.63,452,30.39,471.75,25c7.09-1.93,56.62-5,67.8-5.67C588,16.39,589,36.39,651.56,30.2,680.08,27.37,710,15.39,758,23.3c48.81,8,54.62,2.37,92.26-4.25s60.15-1.49,96.63,3.85c11,1.61,31.46,9,53.07,6.72V0Z"/>
          </svg>
        </div>
      </div>
    );
  }
}

Background.contextType = MyContext;
export default withRouter(Background);
