import React, { Component } from 'react';
import FooterUpdator from '../FooterUpdator';
require('./style.scss');

class TermsAndConditions extends Component {
  render(){
    return (
      <div id="terms-and-conditions">

        <p><b>Exhibit A: </b><b>TERMS OF USE</b></p>
        <p><span style={{fontWeight: 400}}>Effective as of: 09.01.19</span></p>
        <p><span style={{fontWeight: 400}}>Welcome! This mobile application or website is operated by Grit Digital Health, LLC (“Grit”).  Your use of this application or website, including the application “Nod”, and/or Grit’s other products:  </span><a href="https://you.xxx.edu"><span style={{fontWeight: 400}}>https://you.XXX.edu</span></a><span style={{fontWeight: 400}}>, </span><a href="https://gritdigitalhealth.com"><span style={{fontWeight: 400}}>https://gritdigitalhealth.com</span></a><span style={{fontWeight: 400}}>, </span><a href="https://youatcollege.com"><span style={{fontWeight: 400}}>https://youatcollege.com</span></a><span style={{fontWeight: 400}}>,</span><span style={{fontWeight: 400}}> and </span><a href="http://mantherapy.org"><span style={{fontWeight: 400}}>http://mantherapy.org</span></a><span style={{fontWeight: 400}}>  (collectively, the “Product”) is subject to these Terms of Use (the “Terms”).  The entities that operate any portion(s) of the Product or that own any portion(s) of the Product Property, including Grit and the Nod Entities (as defined herein), are collectively referred to as the “Product Entities” Or “We” or “Us.”</span></p>
        <p><span style={{fontWeight: 400}}>Additionally, the Terms apply to your use of any of the services offered by the Product Entities on the Product or accessible through the sites of our partners and affiliates (the “Services”). Your use of the Product, including but not limited to, setting up a Product account, constitutes your binding acceptance of these Terms, including any modifications made to the Terms.  Read these terms carefully and completely as they set forth your rights and obligations with respect to your use of the Product. Your rights to use the Product are limited by applicable federal, state, and local laws and regulations.  </span></p>
        <p><b>IF YOU DO NOT WISH TO BE BOUND BY THESE TERMS, YOU ARE NOT AUTHORIZED TO USE THE PRODUCT IN ANY WAY.  </b></p>
        <p><b>REVIEW THE TERMS CAREFULLY. THIS AGREEMENT REQUIRES THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN JURY TRIALS OR CLASS ACTIONS, AND ALSO LIMITS THE REMEDIES AVAILABLE TO YOU IN THE EVENT OF A DISPUTE.</b></p>
        <ul>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>1. Product and Services Do Not Provide Medical or Educational Advice.</span></li>
        </ul>
        <p><span style={{fontWeight: 400}}>The Product and Services operated by the Product Entities are designed for educational and entertainment purposes only.</span><b>  THE PRODUCT, ITS CONTENT, AND SERVICES DO NOT CONSTITUTE MENTAL HEALTH OR MEDICAL ADVICE, DIAGNOSIS, OR TREATMENT, OR EDUCATIONAL ADVICE.</b><span style={{fontWeight: 400}}>  Accordingly, the information set forth on the product (or in any materials downloaded or purchased from this product) is not a substitute for professional mental health or medical advice, diagnosis, or treatment or educational advice. </span></p>
        <p><b>IF YOU ARE IN NEED OF MEDICAL OR MENTAL HEALTH ASSISTANCE, NOW OR IN THE FUTURE, YOU MUST CONTACT A MEDICAL OR MENTAL HEALTH PROVIDER.  IF YOU ARE HAVING A MEDICAL OR MENTAL HEALTH EMERGENCY CALL 911. </b><span style={{fontWeight: 400}}>This Product is not actively monitored</span><b>.  </b></p>
        <p><span style={{fontWeight: 400}}>Please do not rely on this Product or Services to make medical, mental health or educational decisions.  These decisions should be made in conjunction with individual healthcare providers or educational counselors.  Before taking any action based on any of the information on the Product, please consult your physician, mental health professional or your college counselor. Never disregard professional mental health or medical advice or delay in seeking treatment because of something you have seen on the Product.</span></p>
        <ul>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>2. Acceptance of Terms.</span></li>
        </ul>
        <p><span style={{fontWeight: 400}}>The Product Entities are pleased to provide the information on the Product conditioned upon your acceptance, without modification, of the terms, conditions and notices comprising the Terms.  The Terms may be updated and modified by Grit or the Product Entities from time to time without notice to you by posting revised Terms on the Product. Grit will notify you of any major modifications to the Terms that affect data use.  You can review the most current version of the Terms at any time by clicking on the terms and conditions link from any page on the Product. By using the Product after any modification of the Terms, you agree to be bound by such modification(s).  Any changes to the Terms will be highlighted for 30 days after such changes are made. Grit or the Product Entities have the right, but not the obligation, to take any of the following actions in our sole discretion without providing any prior notice to you and without any liability to you or any third party:</span></p>
        <p><span style={{fontWeight: 400}}>(1)</span> <span style={{fontWeight: 400}}>change or terminate all or any part of the Product or the Services; </span></p>
        <p><span style={{fontWeight: 400}}>(2)</span> <span style={{fontWeight: 400}}>restrict or terminate your access to all or any part of the Product or the Services; or</span></p>
        <p><span style={{fontWeight: 400}}>(3)</span> <span style={{fontWeight: 400}}>refuse, move, or remove any content that is available on the Product and any material that you submit to the Product.</span></p>
        <ul>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>3. License/Access to the Product.</span><span style={{fontWeight: 400}}>  </span></li>
        </ul>
        <p><span style={{fontWeight: 400}}>The Product Entities or Grit hereby grant you a revocable and nonexclusive right and license to use and access the Product (including any underlying software) in a manner that is consistent with the Terms and the Product’s intended purposes. The Product Entities and Grit reserve the right to terminate this license for any or no reason and at any time without notice to you, including, but not limited to, for breach of any term contained in these Terms.</span></p>
        <p><span style={{fontWeight: 400}}>The Product Entities are providing you with the information and functionality in the Product.  You are responsible for providing all equipment necessary to establish a connection to the Internet, access to the Internet, and any telephone, wireless or other connection and service fees associated with such access.  Any and all parts of the Product and related Services are subject to availability.  </span></p>
        <p><span style={{fontWeight: 400}}>The Product is available only to individuals and entities that can form legally-binding contracts under applicable law.  Without limiting the foregoing, the Product is intended for adults 18 years of age or older. By accessing the Product, you represent that you are 18 years of age or older.  If you do not qualify, please do not use the Product. The Product Entities and Grit reserve the right to deny access and/or registration to the Product to anyone at any time in its sole and absolute discretion.</span></p>
        <ul>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>4. Account and Password Security.</span><span style={{fontWeight: 400}}>  </span></li>
        </ul>
        <p><span style={{fontWeight: 400}}>The Product Entities may allow Product users to select a password and account designation upon completing a registration process for an online service provided through the Product.  You are responsible for maintaining the confidentiality of your password and account and are fully responsible for all activities that occur under your password and account. You agree to: (i) notify Grit immediately of any unauthorized use of your password or account or any breach of security, and (ii) ensure that you fully exit from your account at the end of each session.  You may only set up one Product account and must do so in your own name. You further agree not to use anyone else's password on the Product. The Product Entities cannot and will not be liable for any loss or damage arising from your failure to comply with this provision or any of the Terms. If the Product Entities or Grit suspends or terminates your Product account under these Terms, you acknowledge that all information and content associated with such account will no longer be available to you.</span></p>
        <p><span style={{fontWeight: 400}}>You may cancel your Product account at any time by contacting </span><a href="mailto:inquiries@gritdigitalhealth.com"><span style={{fontWeight: 400}}>inquiries@gritdigitalhealth.com</span></a><span style={{fontWeight: 400}}> and following the instructions you will receive.</span></p>
        <ul>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>5. User Obligations.</span><span style={{fontWeight: 400}}> </span></li>
        </ul>
        <p><span style={{fontWeight: 400}}>In consideration of your use of the Product and/or the Services, you agree to be subject to certain obligations.  You agree that you will only use the Product and Services for their intended purposes, and not for other commercial ventures without first seeking approval from the Product Entities or Grit.  For any personal information that you provide to us through the Product, you agree to provide true, accurate, current and complete information about yourself as prompted and you further agree that you will not use the Product for any purpose that is unlawful or prohibited by these Terms.  If you provide any information that is untrue, inaccurate, incomplete or not current, or if the Product Entities or Grit have reasonable grounds to suspect that such information is untrue, inaccurate, incomplete or not current, the Product Entities and Grit have the right to refuse any and all current or future use of the Product (or any portion thereof).   </span></p>
        <p><span style={{fontWeight: 400}}>By using the Product, you may need to interact with other Product users.  You are solely responsible for any such interaction and agree to do so in a manner that is legal, respectful, and consistent with these Terms.  Product Entities are not responsible for the conduct of any other user who may interact with you, regardless of whether or not the contact is made through the Product.  </span></p>
        <ul>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>6. Other Prohibited Conduct.</span><span style={{fontWeight: 400}}>  </span></li>
        </ul>
        <p><span style={{fontWeight: 400}}>Without limitation, you agree not to engage in any activity on the Product that:</span></p>
        <ul>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>defames, threatens, abuses or otherwise violates the legal rights of others;</span></li>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>is harmful to children, profane, obscene, indecent or racially or ethnically offensive;</span></li>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>infringes another's rights to intellectual property, publicity, privacy, or contract;</span></li>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>collects or stores personal information about other Product users;</span></li>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>contains unsolicited or unauthorized advertisements, promotions, commercial solicitations, contests or surveys (unless you have Grit’s written consent to do so); </span></li>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>contains, transmits or disseminates spam or unsolicited bulk email, chain letters, or information intended to assist in the placement of a bet or wager; </span></li>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>contains, transmits or disseminates viruses, corrupted files, or any other similar software or programs that may damage or adversely affect the operation of another's computer, the Product, or any software, hardware or other related equipment;</span></li>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>attempts to defeat or circumvent security features, or attempts to utilize the Product for any purpose other than its intended purposes</span></li>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>disrupts, imposes an unreasonable or disproportionately large strain on, or otherwise interferes with the Product or the networks or servers used by the Product Entities;</span></li>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>is designed to hack into or gain unauthorized access to protected areas of the Product and/or the Product Entities’ computers, servers or networks, and/or any computers or systems used by other users of the Product;</span></li>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>makes unauthorized attempts to modify any information stored on the Product;</span></li>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>uses any automated technology such as a robot, spider, or scraper to access, scrape, or data mine the Product;</span></li>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>impersonates any person or entity, misrepresents your connection or affiliation with a person or entity, or otherwise communicates false or misleading information or content through the Product; </span></li>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>violates any federal, state, or local laws or regulations; or</span></li>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>constitutes, discusses, incites or promotes illegal activity.</span></li>
        </ul>
        <p><span style={{fontWeight: 400}}>The previous list of prohibitions is not exclusive.  The Product Entities and Grit reserve the right (but not the obligation) to review, refuse, edit, or delete any UGC you post to this Product and to terminate your access to the Product or to any Interactive Area at any time without notice for any reason whatsoever.</span></p>
        <ul>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>7. Content.</span><span style={{fontWeight: 400}}>  </span></li>
        </ul>
        <p><span style={{fontWeight: 400}}> The Product Entities do not endorse or represent the reliability, accuracy, quality, or usefulness of any information, or services displayed on the Product and the opinions and recommendations contained on the Product are not necessarily those of the Product Entities nor endorsed by any of these parties.  The Product Entities shall not assume any responsibility or liability for any inaccuracies, errors or omissions in the Product or Services. It is solely your responsibility to evaluate the accuracy, completeness, and usefulness of all opinions, advice, statements, representations, services, and other information provided through the Product or Services.  You acknowledge that you may not rely on any content on the Product and that any reliance on any content, opinions or recommendations offered on the Product or Services is done at your risk.</span><b> </b></p>
        <p><span style={{fontWeight: 400}}>.  </span></p>
        <p> </p>
        <ul>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>8. Third-Party Websites.</span></li>
        </ul>
        <p><span style={{fontWeight: 400}}>Although we hope that you will find the material on this Product informative, the Product contains links to third-party websites and resources that are not controlled and/or affiliated with the Product Entities or Grit and provided for informational purposes only.  If you use these links, you will leave the Product. You access any linked sites or online locations at your own risk. Providing links to these sites by us should not be interpreted as endorsement or approval by the Product Entities or Grit of the organizations sponsoring or operating these sites or offering their products or services.  The links are not meant to indicate any association with the Product Entities or Grit. The Product Entities make no representations or warranties, express or implied, with respect to the information provided on this Product or any third-party website which may be accessed by a link from this Product, including any representations or warranties as to accuracy or completeness.  Because the Product Entities have no control over third-party websites and resources, you acknowledge and agree that the Product Entities are not responsible for the information and contents of such third-party websites and we do not endorse and are not responsible or liable for any content, statements, representations, advertising, policies, products, services or other materials on or available from such sites or resources.  You further acknowledge and agree that the Product Entities shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused in connection with your use or reliance on any such content, information, goods, or services available on or through any such site or resource. If you access the linked sites, you will be subject to the terms of use, privacy policies, and other policies applicable to those sites.</span></p>
        <p><span style={{fontWeight: 400}}>The Product Entities or Grit may also allow interaction between the Product and other third party sites such as Twitter and other social media sites.  This may include “Like” buttons or other interactions through third party buttons or plugins on the Product that when used, may allow you to share content from the Product or other content with other persons on or through the third party sites or elsewhere.  Please consult the privacy policies of these third party sites before using them to make sure you are comfortable with the level of sharing. The Product Entities have no control over these third party sites and you use these interaction functions at your own risk.  The Product Entities are in no way liable for any harm to you as a result of using one of these interaction functions.  </span></p>
        <p><span style={{fontWeight: 400}}>Third party terms of use, privacy policies, and other policies, will in no way affect the Product Entities’ rights or your obligations under these Terms.</span></p>
        <ul>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>9. Intellectual and Other Proprietary Rights.</span><span style={{fontWeight: 400}}>  </span></li>
        </ul>
        <p><span style={{fontWeight: 400}}>The Product and the Services and the content contained on this Product and in the Services are provided solely for your personal non-commercial use in accordance with these Terms. You agree not to reproduce, duplicate, copy, sell, resell or exploit for any commercial purposes, any portion of the Product, use of the Product, or access to the Product.</span></p>
        <p><span style={{fontWeight: 400}}> Other than the exceptions referenced in these Terms or noted elsewhere, the major exception being UGC uploaded/posted by Product users which remains the property of the users, you acknowledge and agree that all other content contained in the Product is the property of the Product Entities or Grit and is protected by copyright, trademark, or patent law, and other proprietary rights and laws (referred to hereinas “Product Property”).  </span></p>
        <p><span style={{fontWeight: 400}}>Certain elements of Product Property, including but not limited to, text, graphics, photos, images, video, audio, color selections, organization and layout, are copyright protected under United States and international copyright laws.  Except as expressly authorized by Grit, you agree not to modify, distribute, copy, reproduce or create derivative works based on Product Property, in whole or in part. You may not reverse engineer, decompile, or disassemble the Product Property or its underlying technologies, except to the extent the foregoing restriction is expressly prohibited by applicable law.  No right, title or interest in any Product Property is transferred to you as a result of you accessing, downloading, or printing such content from the Product. You acknowledge that you have no right, title or interest in or to the Product and/or Product Property. Grit independently operates all portions of the Product and independently owns all portions of the Product Property, except for the following portion(s) of the Product or Product Property: 1) Grit has created the Nod application in collaboration with Hopelab Foundation, Inc. (“Hopelab” together with Grit, the “Nod Entities”) and the Nod application is jointly owned by the Nod Entities.</span></p>
        <p><span style={{fontWeight: 400}}>Those portions of the Product Property owned by all or any of the Product Entities are the copyright of all or any of the Product Entities as applicable.  All rights not expressly granted herein are reserved by the Product Entities. You agree that all of the Product Entities’ trademarks, trade names, service marks, logos and service names are trademarks and are property of the Product Entities (the “Marks”).  You agree not to display or use in any manner the Marks, without the express prior permission of the respective owner. Any use of Product Property must display the appropriate copyright, trademark and other proprietary notices.</span></p>
        <p><span style={{fontWeight: 400}}>There may be other content located on the Product not owned by the Product Entities, and you should respect those property rights as well.  Other marks, names and logos on the Product are the property of their respective owners.  </span></p>
        <p> </p>
        <ul>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>10. </span><span style={{fontWeight: 400}}>Privacy</span><span style={{fontWeight: 400}}>.</span></li>
        </ul>
        <p><span style={{fontWeight: 400}}>By accessing the Product or Services, you acknowledge that you have reviewed and understand Grit’s Privacy Policy, located in the app store and via the Nod app, and expressly incorporated herein, and expressly consent to your information being collected, processed, stored, and shared according to the terms of the Privacy Policy.</span></p>
        <ul>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>11. DISCLAIMER OF WARRANTIES.</span><span style={{fontWeight: 400}}> </span></li>
        </ul>
        <p><span style={{fontWeight: 400}}>You expressly understand and agree that:</span></p>
        <p><span style={{fontWeight: 400}}>To the extent permitted by law, your use of the product is at your sole risk and the Product and information and material in the Product, including any goods or Services provided on or through the product, are provided on an “AS IS” and “AS AVAILABLE” basis.  </span></p>
        <p><span style={{fontWeight: 400}}>To the extent permitted by law, </span><b>THE PRODUCT ENTITIES AND/OR THEIR AFFILIATES MAKE NO EXPRESS OR IMPLIED WARRANTIES, REPRESENTATIONS OR ENDORSEMENTS WHATSOEVER WITH RESPECT TO THE PRODUCT, INFORMATION AND MATERIAL IN THE PRODUCT, OR ANY GOODS OR SERVICES OFFERED ON OR THROUGH THE PRODUCT</b><span style={{fontWeight: 400}}> and the Product Entities do not warrant or represent that any content or information on or in the Product or Services are reliable, accurate, complete, uninterrupted, error free, secure or free of defects, viruses, or bugs</span></p>
        <p><span style={{fontWeight: 400}}>To the extent permitted by law, </span><b>WITH RESPECT TO THE PRODUCT AND INFORMATION AND MATERIAL IN THE PRODUCT, THE PRODUCT ENTITIES AND/OR THEIR AFFILIATES EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, SECURITY, COMPLETENESS, TIMELINESS, APPROPRIATENESS, ACCURACY, TITLE, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT OR THAT THE PRODUCT WILL BE WITHOUT ERROR OR INVULNERABLE TO VIRUSES, WORMS, OR OTHER HARMFUL SOFTWARE OR HARDWARE.</b><span style={{fontWeight: 400}}>  </span></p>
        <p><span style={{fontWeight: 400}}>You hereby acknowledge that the product may not be available due to any number of factors including, without limitation, periodic system maintenance, scheduled or unscheduled, acts of god, unauthorized access, viruses, denial of service or other attacks, technical failure of the product, telecommunications infrastructure, or disruption, and therefore, to the extent permitted by law, </span><b>THE PRODUCT ENTITIES EXPRESSLY DISCLAIM ANY EXPRESS OR IMPLIED WARRANTIES REGARDING PRODUCT USE AND/OR AVAILABILITY, ACCESSIBILITY, SECURITY OR PERFORMANCE CAUSED BY SUCH FACTORS. </b></p>
        <p><span style={{fontWeight: 400}}>No advice or information, whether oral or written, obtained by you from the Product Entities or through or from the Productor Services shall create any warranty not expressly stated herein.</span></p>
        <ul>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>12. </span><span style={{fontWeight: 400}}>RELEASE OF LIABILITY AND INDEMNIFICATION </span></li>
        </ul>
        <p><span style={{fontWeight: 400}}>To the extent permitted by law</span><b>, YOU HEREBY RELEASE, WAIVE, AND HOLD HARMLESS THE PRODUCT ENTITIES, THEIR AGENTS, MANAGERS, AFFILIATES, LICENSORS, LICENSEES, SUCCESSORS AND ASSIGNS AND EACH OF THEIR RESPECTIVE DIRECTORS, OFFICERS, CONTRACTORS, OWNERS, EMPLOYEES, AGENTS, ATTORNEYS, SERVICE PROVIDERS , PARTNERS</b> <b>AND/OR AFFILIATES, AND ANY PARTY INVOLVED IN THE CREATION, PRODUCTION OR TRANSMISSION OF THE PRODUCT OR SERVICES</b> <b> (COLLECTIVELY, “Released Parties”) FROM ALL DAMAGES, LIABILITIES, CLAIMS, ACTIONS, DEMANDS, AND COSTS OF EVERY KIND AND NATURE, KNOWN AND UNKNOWN, SUSPECTED AND UNSUSPECTED, DISCLOSED AND UNDISCLOSED, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THESE TERMS, THE PRIVACY POLICY, ANY USE BY YOU OF THE PRODUCT OR SERVICES, ANY ACTION TAKEN BY THE PRODUCT ENTITIES OR GRIT DURING OR AS A RESULT OF ANY INVESTIGATION BY THE PRODUCT ENTITIES OR GRIT, AND/OR ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY THE PRODUCT ENTITIES OR GRIT OR LAW ENFORCEMENT RELATED TO YOUR USE OF THE PRODUCT OR SERVICES.</b></p>
        <p><b>California residents or users: YOU EXPRESSLY WAIVE CA CIVIL CODE §1542, </b><span style={{fontWeight: 400}}>which states: “A general release does not extend to claims which the creditor or releasing party does not know or suspect to exist in his or her favor at the time of executing the release, which if known by him or her must have materially affected his or her settlement with the debtor or released party.”</span></p>
        <p><span style={{fontWeight: 400}}>To the extent permitted by law, any material, information, products or services downloaded or otherwise obtained through the use of the Product is done at your own discretion and risk and you will be solely responsible for any damages, including but not limited to damage to your computer system or loss of data that results from the download of any such material and/or due to malware.  The Product Entities do not assume responsibility to you or others for any modification, suspension or discontinuance of the Product or Services, or for any failure by the Product Entities to enforce the provisions contained in the Terms or the Privacy Policy. The Product Entities are not responsible for any harm or damages of any kind that may occur to you due to any glitches, hacks, breaches or any other unauthorized access to the aforementioned parties’ computers or network systems, including any hardware or devices, including but not limited to those that may or may not result in the disclosure of your personal information you provided to us.</span></p>
        <p><span style={{fontWeight: 400}}>To the extent permitted by law, </span><b>YOU AGREE TO DEFEND, INDEMNIFY, AND HOLD HARMLESS THE RELEASED PARTIES </b><span style={{fontWeight: 400}}>from and against any and all claims, costs, proceedings, demands, liabilities, losses, damages, and expenses (including, without limitation, reasonable attorney’s fees and legal costs) of any kind or nature, arising from or relating to, or alleged to arise from or relate to: (a) your use of or reliance on the Product or Services; (b) any content you post or download, (c) your use of or reliance on any user or third-party content, or (d) any actual or alleged breach of these Terms or the Privacy Policy by you or anyone using your account.  If we assume the defense of such a matter, you will reasonably cooperate with us in such defense. Note that this, and all other sections of the Terms, are applicable only to the extent permitted by law. For example, some states have statutes, rules, or regulations that do not allow a state government, a state entity, such as a state educational institution, or state employee to be bound by provisions in a contract that purport to impose obligations for release of liability, indemnification, limitations of liability, or binding arbitration.  Nothing in the Terms shall override or be considered a waiver by a covered user of any such statute, rule, or regulation.   </span></p>
        <p><span style={{fontWeight: 400}}>The proprietary rights, disclaimer of warranties, representations made by you, release of liabilities, indemnities, limitations of liability, and any other relevant language that is meant to remain in effect after this agreement ends shall survive the termination of this agreement or these Terms.</span></p>
        <ul>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>13. LIMITS ON LIABILITY</span><span style={{fontWeight: 400}}>.</span></li>
        </ul>
        <p><span style={{fontWeight: 400}}>To the extent permitted by law, </span><b>IN NO EVENT WILL THE RELEASED PARTIES BE LIABLE FOR</b><span style={{fontWeight: 400}}>:</span></p>
        <ul>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>the contents of any communication, message, or information posted by other third parties;</span></li>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>the content of any website or application or other product not controlled, owned, or operated by the Product Entities or Grit that is accessed from or linked to this Product;</span></li>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>the content, services or information provided by any website, application, or other product purporting to be operated by the Product Entities or Grit or their affiliates, whether or not actually affiliated with, controlled, owned, or operated by the Product Entities or Grit;</span></li>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>any damages or injury caused by, including but not limited to, any failure of performance, error, omission, interruption, defect, delay in operation of transmission, computer virus, or line failure; and no term or condition of these Terms shall be construed or interpreted as a waiver, express or implied, of any of the immunities, rights, benefits, protections, or other provisions of state and federal law, as applicable now or hereafter amended; and</span></li>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>any damages whatsoever, including but not limited to, any direct, indirect, incidental, special, exemplary, punitive, actual, consequential damages, or other indirect damages, including loss of revenue or income, loss of use damages, lost data, loss of goodwill, business interruption, pain and suffering, emotional distress, or similar damages, even if the Product Entities or Grit have been advised of the possibility of such damages, arising out of or relating to: (i) the use or inability to use the Product or any goods or services offered on or through the Product; (ii) any transaction conducted through or facilitated by the Product; (iii) any claim attributable to errors, omissions, or inaccuracies on the Product; and/or (iv) any other matter relating to the Product, any good or service offered on or through the Product, and/or any links on the Product.   </span></li>
        </ul>
        <p><span style={{fontWeight: 400}}>To the extent permitted by law</span><span style={{fontWeight: 400}}>, </span><b>IN NO EVENT WILL THE COLLECTIVE LIABILITY OF THE RELEASED PARTIES TO ANY PARTY, REGARDLESS OF THE TYPE OF ACTION WHETHER IN CONTRACT, TORT, OR OTHERWISE, EXCEED THE GREATER OF $100.00 OR THE AMOUNT YOU PAID TO THE PRODUCT ENTITIES OR GRIT AND/OR THE AFOREMENTIONED PARTIES FOR THE APPLICABLE GOOD OR SERVICE OUT OF WHICH THE LIABILITY AROSE.  </b></p>
        <p><b>IF YOU ARE DISSATISFIED WITH THESE TERMS, THE PRODUCT, THE SERVICES, OR ANY GOOD OR SERVICES OFFERED ON OR THROUGH THE PRODUCT OR SERVICES, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE PRODUCT AND SERVICES.  </b></p>
        <ul>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>14. Termination.</span><span style={{fontWeight: 400}}>  </span></li>
        </ul>
        <p><span style={{fontWeight: 400}}>You agree that the Product Entities or Grit in their sole discretion and with or without notice, may terminate your use of the Product (or any part thereof) for any reason, including, without limitation, for lack of use or if the Product Entities or Grit believe that you have violated or acted inconsistently with the letter or spirit of the Terms, or in the case of any activity by you that may harm the Product Entities or Grit or other users, including, but limited to, fraud, abuse of promotions or privileges, or misuse of the Product or the Services.  The Product Entities or Grit may also at any time discontinue providing the Product, or any part thereof, with or without notice. You agree that any termination of your access to the Product under any provision of the Terms may be affected without prior notice, and acknowledge and agree that the Product Entities or Grit may immediately bar any further access to the Product. Further, you agree that the Product Entities shall not be liable to you or any third party for any termination of your access to the Product. </span></p>
        <ul>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>15. Notice and Procedure for Copyright Infringement Claim.</span><span style={{fontWeight: 400}}>  </span></li>
        </ul>
        <p><span style={{fontWeight: 400}}>Pursuant to 17 U.S.C. Section 512, as amended by Title II of the Digital Millennium Copyright Act (the "DMCA"), the Product Entities and Grit reserve the right, but not the obligation, to terminate your right to use the Product if the Product Entities or Grit determines in their sole and absolute discretion that you are involved in any activity that may be infringing, including alleged acts of infringement, regardless of whether the material or activity is ultimately determined to be infringing.  Grit accommodates and does not interfere with standard technical measures used by copyright owners to protect their materials. In addition, pursuant to 17 U.S.C. Section 512(c), as amended, the Product Entities or Grit have implemented or will implement procedures for receiving written notification of claimed infringements and for processing such claims in accordance with the Act. The designated agent of the Product Entities and Grit to receive notification of claimed infringement is:</span></p>
        <p><span style={{fontWeight: 400}}>Douglas Sparks </span></p>
        <p><span style={{fontWeight: 400}}>Grit Digital Health, LLC</span></p>
        <p><span style={{fontWeight: 400}}>2128 15th Street</span></p>
        <p><span style={{fontWeight: 400}}>Denver, CO 80202</span></p>
        <p><span style={{fontWeight: 400}}>Phone:   720-407-8920</span></p>
        <p><span style={{fontWeight: 400}}>Facsimile: 303-455-0408</span></p>
        <p><span style={{fontWeight: 400}}>E-mail:  </span><a href="mailto:admin@gritdigitalhealth.com"><span style={{fontWeight: 400}}>admin@gritdigitalhealth.com</span></a></p>
        <p> </p>
        <p><span style={{fontWeight: 400}}>The Product Entities respect the intellectual property of others, and we ask our users to do the same.  If you believe that your work has been copied in a way that constitutes copyright infringement, please send a detailed message to the designated agent(s) listed above with the following information (under the DMCA, the following information must be included in the message):  (i) an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright or other intellectual property interest; (ii) an identification of the copyrighted work or other intellectual property that you claim has been infringed, or, if multiple works at a single online site are covered by a single notification, a representative list of such works at that site; (iii) a description of where the material that you claim is infringing is located on the Product that is reasonably sufficient to permit the Product Entities or Grit to locate the material; (iv) information reasonably sufficient to permit the Product Entities or Grit to contact you as the complaining party, such as your address, telephone number, and email address; (v) a statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law; and (vi) a statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright or intellectual property owner or authorized to act on the copyright or intellectual property owner's behalf.</span></p>
        <p><span style={{fontWeight: 400}}>If you believe that any content on the Product violates or infringes your intellectual property rights, other than related to copyright, please send a detailed email to </span><a href="mailto:inquiries@gritdigitalhealth.com"><span style={{fontWeight: 400}}>inquiries@gritdigitalhealth.com</span></a><span style={{fontWeight: 400}}> detailing your allegation.  </span></p>
        <ul>
        <li><span style={{fontWeight: 400}}>16. Dispute Resolution, Arbitration, Choice of Law.</span></li>
        </ul>
        <p><b>PLEASE READ THIS CAREFULLY. IT AFFECTS YOUR RIGHTS.</b></p>
        <p><span style={{fontWeight: 400}}>To the extent permitted by law,</span><b> YOU AGREE THAT BY USING THE PRODUCT OR SERVICES, YOU ARE WAIVING THE RIGHT TO A COURT OR JURY TRIAL OR TO PARTICIPATE IN A CLASS ACTION.</b><span style={{fontWeight: 400}}> You agree that you may bring claims against the product entities or released parties only in your individual capacity and not as a plaintiff or class member in any purported class, representative, or collective proceeding. Any arbitration will take place on an individual basis; class arbitrations and class actions are not permitted.</span></p>
        <p><b>YOU AGREE THAT ANY AND ALL CLAIMS AND DISPUTES ARISING FROM OR RELATING IN ANY WAY TO THE SUBJECT MATTER OF THESE TERMS, YOUR USE OF THE PRODUCTS AND/OR SERVICES, OR YOUR DEALINGS WITH THE PRODUCT ENTITIES SHALL BE FINALLY SETTLED AND RESOLVED THROUGH BINDING INDIVIDUAL ARBITRATION AS DESCRIBED IN THIS SECTION.</b><span style={{fontWeight: 400}}> This agreement to arbitrate is intended to be interpreted broadly. The arbitration will be governed by the commercial arbitration rules and the supplementary procedures for consumer related disputes of the American Arbitration Association (“AAA”), as modified by this section. The arbitration will be conducted by Judicial Arbiter Group, Inc. (“JAG”) using one arbitrator with substantial experience in resolving commercial contract disputes, who shall be selected from the appropriate list of JAG arbitrators in accordance with the arbitration rules and procedures of JAG. If JAG is unable or unwilling to arbitrate a dispute, then the dispute may be referred to any other arbitration organization or arbitrator that you and Grit agree upon in writing or that is appointed pursuant to section 5 of the Federal Arbitration Act. For any claim where the total amount of the award sought is $10,000 or less, you must abide by the following rules: (a) the arbitration shall be conducted solely based on telephone or online appearances and/or written submissions; and (b) the arbitration shall not involve any personal appearance by the parties or witnesses unless otherwise mutually agreed by the parties. If the claim exceeds $10,000, the right to a hearing will be determined by the AAA rules, and the hearing (if any) must take place in Denver, Colorado. The arbitrator’s ruling is binding and may be entered as a judgment in any court of competent jurisdiction, or application may be made to such court for judicial acceptance of any award and an order of enforcement, as the case may be.</span></p>
        <p><span style={{fontWeight: 400}}>There is no judge or jury in arbitration. Arbitration procedures are simpler and more limited than rules applicable in court and review by a court is limited. You will not be able to have a court or jury trial or participate in a class action or class arbitration. You understand and agree that by agreeing to resolve any dispute through individual arbitration, you are waiving the right to a court or jury trial. Any dispute shall be arbitrated on an individual basis, and not as a class action, representative action, class arbitration or any similar proceeding. The arbitrator may not consolidate the claims of multiple parties.</span></p>
        <p><b>ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING IN ANY WAY TO THESE TERMS OF USE, YOUR USE OF THE PRODUCTS AND SERVICES, OR YOUR DEALINGS WITH THE PRODUCT ENTITIES MUST BE COMMENCED IN ARBITRATION WITHIN TWO (2) YEARS AFTER THE CAUSE OF ACTION ACCRUES</b><span style={{fontWeight: 400}}>. After that two (2)-year period, such cause of action or claim is permanently barred. Some jurisdictions do not allow time limitations other than those set forth in such state’s statute of limitations laws. In such cases, the applicable statute of limitations provided for under the laws of such state shall apply.</span></p>
        <p><span style={{fontWeight: 400}}>You agree that all challenges to the validity and applicability of the arbitration provision—i.e. whether a particular claim or dispute is subject to arbitration—shall be determined by the arbitrator. If the arbitration provision in this section is found unenforceable or to not apply for a given dispute, then the proceeding must be brought exclusively in the state courts of competent jurisdiction or the united states district court located in Denver, Colorado, as appropriate, and you agree to submit to the personal jurisdiction of each of these courts for the purpose of litigating such claims or disputes, and you still waive your right to a jury trial, waive your right to initiate or proceed in a class or collective action, and remain bound by any and all limitations on liability and damages included in these terms. This arbitration agreement will survive termination of your use of the Product and services and your relationship with the Product Entities. This arbitration agreement involves interstate commerce and, therefore, shall be governed by the Federal Arbitration Act, 9 U.S.C. §§ 1-16 (“FAA”), and not by state law. Information on AAA and how to start arbitration can be found at www.adr.org or by calling 800-778-7879.</span></p>
        <p><b>IF YOU WISH TO OPT-OUT OF THE AGREEMENT TO ARBITRATE,</b><span style={{fontWeight: 400}}> within 45 days of when you first use the Product or Services or submit through the Services a request for information, </span><b>YOU MUST SEND US A LETTER STATING, “REQUEST TO OPT-OUT OF AGREEMENT TO ARBITRATE”</b><span style={{fontWeight: 400}}> to the following address:</span></p>
        <p><span style={{fontWeight: 400}}>Grit Digital Health, LLC</span></p>
        <p><span style={{fontWeight: 400}}>2128 15th Street</span></p>
        <p><span style={{fontWeight: 400}}>Denver, CO 80202</span></p>
        <p><span style={{fontWeight: 400}}>In the event you opt out of the arbitration provision, you agree to litigate exclusively in the state or Federal courts in Denver, Colorado, and you hereby consent and submit to the personal jurisdiction of such courts for the purpose of litigating any such action. These Terms will be governed by the laws of the State of Colorado, without giving effect to any principles of conflicts of laws.</span></p>
        <ul>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>17. U.S. Geographic Limitation.</span><span style={{fontWeight: 400}}>  </span></li>
        </ul>
        <p><span style={{fontWeight: 400}}>The Product Entities or Grit operates the Product from Grit’s headquarters in the United States, and the Product is intended only for users within the United States and use outside of the United States is prohibited.  If you use the Product outside the United States, you are responsible for following your applicable local laws and determining, among other things, whether your use of the Product violates any of those local laws. By using the Product, you agree and acknowledge that information about you, including personally identifiable information, may be transmitted to, processed in, and stored in the United States.</span></p>
        <p><span style={{fontWeight: 400}}>Certain software elements of the Product and related Services may be subject to U.S. export laws and controls.  As such, no software may be downloaded or exported to any country or foreign citizen that is under a U.S. embargo or that would otherwise violate U.S. law or regulations.</span></p>
        <ul>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>18. Comments &amp; Feedback.</span></li>
        </ul>
        <p><span style={{fontWeight: 400}}>  Under California Civil Code § 1789.3, California Product users have the right to contact the Product Entities or Grit with any complaints or to seek additional information. You may email the Product Entities or Grit at </span><a href="mailto:inquiries@gritdigitalhealth.com"><span style={{fontWeight: 400}}>inquiries@gritdigitalhealth.com</span></a><span style={{fontWeight: 400}}>. You may also call (720) 407-8920. For any physical documents, you may send mail to 2128 15th Street, Denver, CO 80202.If California users have any questions or complaints about the Product Entities they may also contact The Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs through writing at 1625 North Market Blvd., Suite N 112, Sacramento, CA 95834, or by telephone at (916) 445-1254 or (800) 952-5210.  Hearing impaired persons may call (916) 928-1227 or (800) 326-2297 via TTY device.</span></p>
        <ul>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>19. Waiver.</span></li>
        </ul>
        <p><span style={{fontWeight: 400}}>The Product Entities’ failure to exercise or enforce any right or provision of the Terms shall not constitute a waiver of such right or provision unless acknowledged and agreed to by the Product Entities in writing.</span></p>
        <p> </p>
        <ul>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>20. Severability.</span><span style={{fontWeight: 400}}>  </span></li>
        </ul>
        <p> </p>
        <p><span style={{fontWeight: 400}}>If any provision of these Terms is held by a court of competent jurisdiction to be illegal, invalid or unenforceable under any present or future laws, such provision shall be fully severable, and the remaining provisions shall constitute the parties’ agreement and remain in full force and effect to the extent permitted by law.</span></p>
        <p> </p>
        <ul>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>21. Time Limitation.</span><span style={{fontWeight: 400}}>  </span></li>
        </ul>
        <p> </p>
        <p><span style={{fontWeight: 400}}>You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to the Terms or use of the Product must be filed within two (2) years after such claim or cause of action arose or be forever barred.</span></p>
        <p> </p>
        <ul>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>22. Headings.</span><span style={{fontWeight: 400}}> </span></li>
        </ul>
        <p> </p>
        <p><span style={{fontWeight: 400}}>The section headings and sub-headings contained in the Terms are for convenience only and have no legal or contractual effect.</span></p>
        <p> </p>
        <ul>
        <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>23. Entire Agreement.</span><span style={{fontWeight: 400}}> </span></li>
        </ul>
        <p> </p>
        <p><span style={{fontWeight: 400}}>These Terms constitute the entire agreement between you and the Product Entities or Grit with respect to the Product and supersede all prior communications, promises and proposals, whether oral, written, or electronic, between you and the Product Entities or Grit with respect to the Product.</span></p>
        <p> </p>

        <FooterUpdator
          showHome={true}
          backHandler={() => this.props.history.goBack() } />
      </div>
    );
  }
}

export default TermsAndConditions;
