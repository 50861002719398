import React, { Component } from 'react';
import MyContext from '../../state/MyContext';
import { withRouter } from "react-router";
import login_button_apple from '../../assets/images/login-buttons/apple.png';
import login_button_facebook from '../../assets/images/login-buttons/facebook.png';
import login_button_google from '../../assets/images/login-buttons/google.png';
require('./style.scss');

class SocialLoginButtons extends Component {

  constructor (props) {
    super();
    this.state = {
      authenticating: false,
    }

    this.isOS13OrAbove = false;
    if(window.cordova) this.isOS13OrAbove = window.device.platform === 'iOS' && parseInt(window.device.version.split('.')[0]) >= 13;

    //Bindings & References
    this._facebookLogin = this._facebookLogin.bind(this);
    this._googleLogin = this._googleLogin.bind(this);
    this._appleLogin = this._appleLogin.bind(this);

  }

  _facebookLogin(){
    if(this.props.active){
      var mythis = this;
      this.setState({authenticating:true});
      this.props.onLoading(true);
      window.facebookConnectPlugin.login(['public_profile', 'email'],
        function(data){
          mythis.context.socialAuthFacebook(data.authResponse.accessToken).then((ok) =>{
            mythis.context.sessionStarted();
            mythis.setState({authenticating:false});
            mythis.props.onLoading(false);
            if(ok) mythis.props.history.push('/');
          });
        }, function(error){
          mythis.setState({authenticating:false});
          mythis.props.onLoading(false);
          console.log('facebookLogin Error:', error);
        }
      );
    }else{
      this.props.clickedWhileInactive();
    }
  }

  _googleLogin(){
    if(this.props.active){
      var mythis = this;
      this.setState({authenticating:true});
      this.props.onLoading(true);
      window.plugins.googleplus.login({'webClientId': process.env.REACT_APP_GOOGLE_ID},
        function(data){
          mythis.context.socialAuthGoogle(data.accessToken).then((ok) =>{
            mythis.context.sessionStarted();
            mythis.setState({authenticating:false});
            mythis.props.onLoading(false);
            if(ok) mythis.props.history.push('/');
          });
        }, function(error){
          mythis.setState({authenticating:false});
          mythis.props.onLoading(false);
          console.log('googleLogin Error:', error);
        }
      );
    }else{
      this.props.clickedWhileInactive();
    }
  }

  _appleLogin(){
    if(this.props.active){
      var mythis = this;
      this.setState({authenticating:true});
      this.props.onLoading(true);
      window.cordova.plugins.SignInWithApple.signin({ requestedScopes: [0, 1] },
        function(data){
          mythis.context.socialAuthApple(data).then((ok) =>{
            mythis.context.sessionStarted();
            mythis.setState({authenticating:false});
            mythis.props.onLoading(false);
            if(ok) mythis.props.history.push('/');
          });
        }, function(error){
          mythis.setState({authenticating:false});
          mythis.props.onLoading(false);
          console.log('appleLogin Error:', error);
        }
      );
    }else{
      this.props.clickedWhileInactive();
    }
  }

  render(){
    return(
      <div id="social-login-buttons">
        {/*
        <button disabled={ this.state.authenticating ? 'disabled' : false } onClick={this._facebookLogin}><img src={login_button_facebook} alt="Continue with Facebook" /></button>
        <button disabled={ this.state.authenticating ? 'disabled' : false } onClick={this._googleLogin}><img src={login_button_google} alt="Continue with Google" /></button>
        {this.isOS13OrAbove ? <button disabled={ this.state.authenticating ? 'disabled' : false } onClick={this._appleLogin}><img src={login_button_apple} alt="Continue with Apple" /></button> : null}
        */}
      </div>
    );
  }
}

SocialLoginButtons.contextType = MyContext;
export default withRouter(SocialLoginButtons);
