class BranchService {

  followBranchRedirect(redirectFunc) {
    const branch_data = JSON.parse(window.localStorage.branchData);
    if(branch_data.redirectPath || branch_data['$deeplink_path']) {
      console.log('data.redirectPath present', branch_data);

      let channel = null;
      if(branch_data['~channel']) channel = branch_data['~channel'];
      if(branch_data['$deeplink_path']){
        redirectFunc(branch_data['$deeplink_path'], channel);
      }else if(branch_data.redirectPath){
        redirectFunc(branch_data.redirectPath, channel);
      }

    }else{
      console.log('data.redirectPath not present', branch_data);
    }
  }

  makeBranchLink(linkData) {
    return new Promise((resolve, reject) => {
      window.branch.link({data: linkData}, function(err, link) {
        resolve(link);
      });
    });
  }
}

export default BranchService;
