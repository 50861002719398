import * as PIXI from 'pixi.js';
import { TweenMax, Back, Expo } from 'gsap';

import Calc from '../../common/Calc';

class Mole {
  constructor(x, y, width, asset, sounds, mask, onHitCallback, index) {
    this.x = x;
    this.y = y;
    this.width = width;
    this.asset = asset;
    this.sounds = sounds;
    this.mask = mask;
    this.onHitCallback = onHitCallback;
    this.index = index;

    this.dpr = window.devicePixelRatio;
    this.srcWidth = this.asset.texture.width;
    this.srcHeight = this.asset.texture.height;
    this.srcRatio = this.srcWidth / this.srcHeight;
    this.destWidth = this.width;
    this.destHeight = this.width / this.srcRatio;

    this.yMove = this.destHeight;
    this.isHidden = true;
    this.hitExpand = this.destWidth * 1 * this.dpr;
    this.showTimeout = null;
    this.done = false;

    this.canvas = document.createElement('canvas');
    this.ctx = this.canvas.getContext('2d');
    this.canvas.width = this.destWidth * this.dpr;
    this.canvas.height = this.destHeight * this.dpr;
    this.ctx.scale(this.dpr, this.dpr);
    this.ctx.drawImage(this.asset.data, 0, 0, this.destWidth, this.destHeight);

    this.sprite = new PIXI.Sprite(PIXI.Texture.from(this.canvas));
    this.sprite.x = this.x;
    this.sprite.y = this.y + this.yMove;
    this.sprite.width = this.destWidth;
    this.sprite.height = this.destHeight;
    this.sprite.anchor.set(0.5);
    this.sprite.interactive = true;
    this.sprite.hitArea = new PIXI.Rectangle(
      -0.5 * this.sprite.width * 2 - this.hitExpand / 2,
      -0.5 * this.sprite.height * 2 - this.hitExpand / 2,
      this.sprite.width * 2 + this.hitExpand,
      this.sprite.height * 2 + this.hitExpand
    );

    this.sprite.on('pointerdown', this.onPointerDown.bind(this));

    this.sprite.mask = this.mask.sprite;
    this.showTimeout = window.setTimeout(() => {
      this.show();
    }, Calc.rand(1000, 3000));

    this.canvas = null;
    this.ctx = null;
  }

  onPointerDown() {
    if (!this.isHidden && !this.done) {
      let sound = Calc.randArr(this.sounds);
      sound.play();
      this.hide();
      this.onHitCallback(this.sprite.x, this.sprite.y, this.index);
    }
  }

  show() {
    if (!this.isHidden || this.done) {
      return;
    }

    window.clearTimeout(this.showTimeout);

    this.sprite.mask = this.mask.sprite;
    this.mask.sprite.alpha = 1;
    this.isHidden = false;

    let obj = { value: 1 };
    if (this.hideTween) {
      this.hideTween.kill();
    }
    if (this.showTween) {
      this.showTween.kill();
    }
    this.showTween = TweenMax.to(obj, 0.1, {
      value: 0,
      ease: Back.easeOut.config(2),
      onUpdate: () => {
        this.sprite.y = this.y + this.yMove * obj.value;
        if (obj.value < 0.05) {
          this.sprite.mask = null;
          this.mask.sprite.alpha = 0;
        }
        this.isHidden = false;
      }
    });
  }

  hide() {
    if (this.isHidden) {
      return;
    }

    this.isHidden = true;
    this.sprite.mask = this.mask.sprite;
    this.mask.sprite.alpha = 1;

    let obj = { value: 0 };
    if (this.hideTween) {
      this.hideTween.kill();
    }
    if (this.showTween) {
      this.showTween.kill();
    }
    this.hideTween = TweenMax.to(obj, 0.25, {
      value: 1,
      ease: Expo.easeOut,
      onUpdate: () => {
        this.sprite.y = this.y + this.yMove * obj.value;
      },
      onComplete: () => {
        if (!this.done) {
          window.clearTimeout(this.showTimeout);
          this.showTimeout = window.setTimeout(() => {
            this.show();
          }, Calc.rand(1000, 3500));
        }
      }
    });
  }
}

export default Mole;
