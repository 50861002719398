import * as PIXI from 'pixi.js';

import Calc from '../../common/Calc';
import SvgSprite from '../../common/SvgSprite';

class TurtleNormal extends SvgSprite {
  constructor(x, y, width, asset, limbAssets) {
    super(x, y, width, asset, 0.5, 0.5);

    this.limbAssets = limbAssets;

    this.container = new PIXI.Container();
    this.container.x = this.x;
    this.container.y = this.y;
    this.container.alpha = 0;

    this.sprite.x = 0;
    this.sprite.y = 0;

    this.activated = false;

    this.createLimbs();

    this.container.addChild(this.armRight.sprite);
    this.container.addChild(this.legRight.sprite);
    this.container.addChild(this.armLeft.sprite);
    this.container.addChild(this.legLeft.sprite);
    this.container.addChild(this.sprite);
  }

  createLimbs() {
    let scale = this.sprite.width / this.asset.data.width;

    this.armRight = new SvgSprite(
      -40,
      27,
      this.limbAssets.armRight.data.width * scale,
      this.limbAssets.armRight,
      0.5,
      0.5
    );
    this.armRight.rotationBase = 0;
    this.armRight.sprite.rotation = this.armRight.rotationBase;

    this.armLeft = new SvgSprite(
      -20,
      39,
      this.limbAssets.armLeft.data.width * scale,
      this.limbAssets.armLeft,
      0.5,
      0.5
    );
    this.armLeft.rotationBase = 0;
    this.armLeft.sprite.rotation = this.armLeft.rotationBase;

    this.legRight = new SvgSprite(
      30,
      35,
      this.limbAssets.legRight.data.width * scale,
      this.limbAssets.legRight,
      0.5,
      0.5
    );
    this.legRight.rotationBase = 0;
    this.legRight.sprite.rotation = this.legRight.rotationBase;

    this.legLeft = new SvgSprite(
      50,
      35,
      this.limbAssets.legLeft.data.width * scale,
      this.limbAssets.legLeft,
      0.5,
      0.5
    );
    this.legLeft.rotationBase = 0;
    this.legLeft.sprite.rotation = this.legLeft.rotationBase;
  }

  activate(x) {
    this.container.alpha = 1;
    this.container.x = x;
    this.activated = true;
  }

  update(time) {
    if (!this.activated) {
      return;
    }

    let speedMin = 0.25;
    let speedMax = 0.75;
    let limbMove = 1.5;
    let limbRotate = 0.05;
    let timeOffset = 15;
    let timeMult = 0.005;

    this.container.x -= Calc.map(
      Math.sin(time * timeMult),
      -1,
      1,
      speedMin,
      speedMax
    );

    this.armRight.sprite.x =
      this.armRight.x + Math.cos(time * timeMult + timeOffset * 0) * -limbMove;
    this.armRight.sprite.y =
      this.armRight.y + Math.sin(time * timeMult + timeOffset * 0) * limbMove;
    this.armRight.sprite.rotation =
      Math.cos(time * timeMult + timeOffset * 0) * limbRotate;

    this.armLeft.sprite.x =
      this.armLeft.x + Math.cos(time * timeMult + timeOffset * 1) * -limbMove;
    this.armLeft.sprite.y =
      this.armLeft.y + Math.sin(time * timeMult + timeOffset * 1) * limbMove;
    this.armLeft.sprite.rotation =
      Math.cos(time * timeMult + timeOffset * 1) * limbRotate;

    this.legRight.sprite.x =
      this.legRight.x + Math.cos(time * timeMult + timeOffset * 2) * -limbMove;
    this.legRight.sprite.y =
      this.legRight.y + Math.sin(time * timeMult + timeOffset * 2) * limbMove;
    this.legRight.sprite.rotation =
      Math.cos(time * timeMult + timeOffset * 2) * limbRotate;

    this.legLeft.sprite.x =
      this.legLeft.x + Math.cos(time * timeMult + timeOffset * 3) * -limbMove;
    this.legLeft.sprite.y =
      this.legLeft.y + Math.sin(time * timeMult + timeOffset * 3) * limbMove;
    this.legLeft.sprite.rotation =
      Math.cos(time * timeMult + timeOffset * 3) * limbRotate;
  }
}

export default TurtleNormal;
