import React, { Component } from 'react';
import { HashRouter } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import MyProvider from './state/MyProvider';
import MainWrapper from './components/MainWrapper';

class App extends Component {
  render(){
    return (
      <HashRouter>
        <ScrollToTop>
          <MyProvider>
            <MainWrapper />
          </MyProvider>
        </ScrollToTop>
      </HashRouter>
    );
  }
}

export default App;
