import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import MyContext from '../../state/MyContext';
import PageHeader from '../PageHeader';
import FooterUpdator from '../FooterUpdator';
import FooterModal from '../FooterModal';
import PasswordResetForm from './PasswordResetForm';
import PasswordResetRequestForm from './PasswordResetRequestForm';
require('./style.scss');

class PasswordReset extends Component {

  constructor (props) {
    super(props);
    this.state = {
      token: this.props.match.params.token ? this.props.match.params.token : null,
      token_email: this.props.location.search.includes('?email=') ? this.props.location.search.substring(7) : null,
      token_email_verified: this.props.location.search.includes('?email=') ? this._isValidEmail(this.props.location.search.substring(7)) : false,
    }

    //Bindings & References
    this._resetPasswordRequestFormSubmit = this._resetPasswordRequestFormSubmit.bind(this);
    this._resetPasswordFormSubmit = this._resetPasswordFormSubmit.bind(this);
    this.footerModal = React.createRef();
  }

  _isValidEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape
    return re.test(email);
  }

  _resetPasswordRequestFormSubmit(email, callback){
    this.context.resetPassword(email).then((result) => {
      if(result.success){
        this.footerModal.current.show();
      }else{
        let error_message  = '';
        Object.keys(result.errors).forEach(function (key) {
          error_message += result.errors[key]+' ';
        });
        callback(true, error_message);
      }
    }).catch((err) => {
      callback(true, 'Something went wrong, please try again.');
    });
  }

  _resetPasswordFormSubmit(token_email, password, token, callback){
    this.context.resetPasswordVerify(token_email, password, token).then((result) => {
      if(result.success){
        this.footerModal.current.show();
      }else{
        callback(true, 'Something went wrong, please try again.');
      }
    }).catch((err) => {
      callback(true, 'Something went wrong, please try again.');
    });
  }

  _handleBackToLogin(e){
    e.preventDefault();
    this.footerModal.current.hide();
    this.props.history.push('/login');
  }

  render(){
    return(
      <MyContext.Consumer>
        {(context) => (
            <div>
            { this.state.token ?
                <div id="reset-password">
                  <Helmet>
                    <title>Nod - Reset Password</title>
                  </Helmet>
                  <PageHeader bgColor="tan-accent" bgHeaderColor="tan">
                    <h1>Create a new password for your account.</h1>
                  </PageHeader>
                  { this.state.token_email_verified ?
                    <PasswordResetForm onSubmit={this._resetPasswordFormSubmit} token={this.state.token} tokenEmail={this.state.token_email} />
                  :
                    <p>Something went wrong. Please try again.</p>
                  }
                  <FooterModal ref={this.footerModal} visible={false} parentId="reset-password">
                    <h3>Your password is reset!</h3>
                    <div className="textLink">
                      <a href="/" onClick={(e) => this._handleBackToLogin(e)}>Back to sign in</a>
                    </div>
                    <br /><br />
                  </FooterModal>
                  <FooterUpdator
                    showHome={true}
                    backHandler={() => {this.props.history.goBack()}} />
                </div>
              :
                <div id="reset-password">
                  <Helmet>
                    <title>Nod - Reset Password Request</title>
                  </Helmet>
                  <PageHeader bgColor="tan-accent" bgHeaderColor="tan">
                    <h1>Reset Your Password</h1>
                  </PageHeader>
                  <PasswordResetRequestForm onSubmit={this._resetPasswordRequestFormSubmit} />
                  <FooterModal ref={this.footerModal} visible={false} parentId="reset-password">
                    <h3>Check your email!</h3>
                    <p>We sent you an email with a link to reset your password.</p>
                    <div className="textLink">
                      <a href="/" onClick={(e) => this._handleBackToLogin(e)}>Back to sign in</a>
                    </div>
                    <br /><br />
                  </FooterModal>
                  <FooterUpdator
                    showHome={false}
                    backHandler={() => {this.props.history.goBack()}} />
                </div>
            }
          </div>
        )}
      </MyContext.Consumer>
    );
  }
}

PasswordReset.contextType = MyContext;
export default PasswordReset;
