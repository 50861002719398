import React, { Component } from 'react';
import MyContext from '../../state/MyContext';
import GuideOverviewCard from '../GuideOverviewCard';
import SlickSlider from 'react-slick';
import {
  STATE_UNTRIED,
  STATE_ACTIVE,
  STATE_COMPLETED
} from '../ChallengeDots';
require('./style.scss');

class GuidesOverviewSlider extends Component {

  constructor(props) {
    super();
    this.state = {
      gbColorClass:'tan',
    }
    this.guides = [];
  }

  componentWillMount(){
    this.guides = this.context.guides.filter(guide => !guide.hidden);
    this.setState({gbColorClass:this.guides[0].theme_name});
  }

  render(){

    const slider_settings = {
      dots: true,
      infinite: false,
      centerMode: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      centerPadding: "20px",
      focusOnSelect: true,
      appendDots: dots => {
        return (
          <ul>
            {dots.map((dot, index) => {
              return (
                <li className={dot.props.className} key={index}>
                  <button aria-label={'your goal ' + (index+1)} onClick={dot.props.children.props.onClick}>{index+1}</button>
                </li>
              );
            })}
          </ul>
        );
      },
      onInit: () => {

        //Keen
        this.context.sendKeenEvent('your_goals_slider', {
          guide_id: this.guides[0].id,
          guide_title: this.guides[0].title,
          position: 0,
          swipe: null
        });

        //MixPanel
        this.context.sendMixPanelEvent('your_goals_slider', {
          guide_id: this.guides[0].id,
          guide_title: this.guides[0].title,
          position: 0,
          swipe: null
        });

      },
      beforeChange: (oldIndex, newIndex) => {
        const theme = this.guides[newIndex].theme_name;
        this.setState({gbColorClass:theme});

        //Keen
        this.context.sendKeenEvent('your_goals_slider', {
          guide_id: this.guides[newIndex].id,
          guide_title: this.guides[newIndex].title,
          position: newIndex,
          swipe: newIndex > oldIndex ? 'left' : 'right'
        });

        //MixPanel
        this.context.sendMixPanelEvent('your_goals_slider', {
          guide_id: this.guides[newIndex].id,
          guide_title: this.guides[newIndex].title,
          position: newIndex,
          swipe: newIndex > oldIndex ? 'left' : 'right'
        });
      }
    };

    return (
      <div className={'guides-overview-slider ' + this.state.gbColorClass}>
        {!this.props.hideTopSquiggle ?
          <div className="squiggle top">
            <svg viewBox="0 0 1000 31.39">
              <path className="pathItem" d="M0,0V29.86c21.61,2.24,42.07-5.11,53.07-6.72,36.48-5.34,61-3.46,98.63,3.15S206.18,27.62,253,11.53s63.77,6.58,91.48,13.9,43.63-6.72,66-1.95C425.35,26.63,452,30.39,471.75,25c7.09-1.93,56.62-5,67.8-5.67C588,16.39,589,36.39,651.56,30.2,680.08,27.37,710,15.39,758,23.3c48.81,8,54.62,2.37,92.26-4.25s60.15-1.49,96.63,3.85c11,1.61,31.46,9,53.07,6.72V0Z"/>
            </svg>
          </div>
        :null
        }

        {this.props.showName ?
          <h1 aria-label={this.context.user.first_name !== '' ? 'Hi '+this.context.user.first_name+'! Welcome to the dashhboard.' : 'Hey there! Welcome to the dashboard.'} className="hello-name">
            {this.context.user.first_name !== '' ? 'Hi '+this.context.user.first_name+'!' : 'Hey there!'}
          </h1>
        : null}
        <h2 className="sliderLabel"><i className="icon star" /> Your Goals</h2>
        <SlickSlider {...slider_settings}>
        {
          this.guides.map((guide, index) => {

            const challenges = this.context.challenges.filter(challenge => challenge.guide_id === guide.id);

            let dot_values = [];
            challenges.forEach(challenge => {
              if(!challenge.hidden){
                const user_challenge = this.context.user_challenges.filter(user_challenge => user_challenge.id === challenge.id);
                if(user_challenge.length > 0){
                  dot_values.push(user_challenge[0].completed ? STATE_COMPLETED : STATE_ACTIVE);
                }else{
                  dot_values.push(STATE_UNTRIED);
                }
              }
            });

            //if(guide !== null){
              return <GuideOverviewCard
                        key={index}
                        link={'/guides/'+guide.slug}
                        theme={guide.theme_name}
                        image_url={guide.image_url}
                        title={guide.title}
                        dotValues={dot_values} />;
            // }else{
            //   return null;
            // }
          })
        }
        </SlickSlider>
        <div className="squiggle bottom">
          <svg viewBox="0 0 1000 31.39">
            <path className="pathItem" d="M0,0V29.86c21.61,2.24,42.07-5.11,53.07-6.72,36.48-5.34,61-3.46,98.63,3.15S206.18,27.62,253,11.53s63.77,6.58,91.48,13.9,43.63-6.72,66-1.95C425.35,26.63,452,30.39,471.75,25c7.09-1.93,56.62-5,67.8-5.67C588,16.39,589,36.39,651.56,30.2,680.08,27.37,710,15.39,758,23.3c48.81,8,54.62,2.37,92.26-4.25s60.15-1.49,96.63,3.85c11,1.61,31.46,9,53.07,6.72V0Z"/>
          </svg>
        </div>
      </div>
    );
  }
}

GuidesOverviewSlider.contextType = MyContext;
export default GuidesOverviewSlider;
