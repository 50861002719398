import * as PIXI from 'pixi.js';
import { BulgePinchFilter } from '@pixi/filter-bulge-pinch';
import { Howl } from 'howler';

import BaseReflection from '../../common/BaseReflection';
import Calc from '../../common/Calc';
import Ease from '../../common/Ease';
import RectSprite from '../../common/RectSprite';

import Bubble from './Bubble';
import Fish from './Fish';

import bubbleSrc from '../images/bubble.png';
import fishClosedSrc from '../images/fish-closed.png';
import fishOpenSrc from '../images/fish-open.png';
import mapSrc from '../images/map.jpg';

import bubbleWebmSrc from '../sounds/bubble.webm';
import bubbleOggSrc from '../sounds/bubble.ogg';
import bubbleMp3Src from '../sounds/bubble.mp3';

class AnimalBreathingReflection extends BaseReflection {
  init(onComplete) {
    super.init(onComplete);

    this.assetPaths = [
      bubbleSrc,
      fishClosedSrc,
      fishOpenSrc,
      mapSrc,
      bubbleWebmSrc,
      bubbleOggSrc,
      bubbleMp3Src
    ];

    this.itemScale = 0.4;

    this.breathCount = 10; // total breaths to do for reflection
    this.breathDuration = 4000; // duration for each breath
    this.breathTotalDuration = this.breathCount * this.breathDuration; // duration for entire reflection
    this.breathAlternate = true; // in or out
    this.breathStartTime = null; // start of first breath
    this.breathRecentTime = null; // start of last breath
    this.breathCurrentProgress = 0; // current breath progress
    this.breathTotalProgress = 0; // overall progress

    this.bubbles = [];

    this.started = false;

    this.completeTimeout = null;

    this.addEventListeners();
    this.loadAssets();
  }

  reset(width = this.width, height = this.height) {
    super.reset(width, height);

    window.clearTimeout(this.completeTimeout);

    this.mapSprite = null;
    this.fishContainer = null;
    this.fish = null;
    this.breathMeterFrontContainer = null;
    this.breathMeterFront = null;
    this.breathMeterBackContainer = null;
    this.breathMeterBack = null;

    this.started = false;

    this.bubbles.length = 0;

    this.create();
  }

  addEventListeners() {
    super.addEventListeners();

    this.onPointerDown = this.onPointerDown.bind(this);

    this.canvasWrap.addEventListener('mousedown', this.onPointerDown);
    this.canvasWrap.addEventListener('touchstart', this.onPointerDown);
  }

  removeEventListeners() {
    super.removeEventListeners();

    this.canvasWrap.removeEventListener('mousedown', this.onPointerDown);
    this.canvasWrap.removeEventListener('touchstart', this.onPointerDown);
  }

  onPointerDown() {
    if (!this.started) {
      this.started = true;
      this.breathAlternate = true;
      this.breathStartTime = Date.now();
      this.breathRecentTime = Date.now();
      this.breathCurrentProgress = 0;
      this.breathTotalProgress = 0;
    }
  }

  initPhysics() {
    super.initPhysics();
  }

  initSounds() {
    super.initSounds();

    this.sounds.bubble = {};
    this.sounds.bubble.count = 10;
    this.sounds.bubble.current = 0;
    this.sounds.bubble.pool = [];

    for (let i = 0; i < this.sounds.bubble.count; i++) {
      this.sounds.bubble.pool.push(
        new Howl({
          src: [bubbleWebmSrc, bubbleOggSrc, bubbleMp3Src],
          volume: 0.4
        })
      );
    }
  }

  playBubbleSound() {
    this.sounds.bubble.pool[this.sounds.bubble.current].rate(
      Calc.rand(0.5, 0.75)
    );
    this.sounds.bubble.pool[this.sounds.bubble.current].play();

    this.sounds.bubble.current++;
    if (this.sounds.bubble.current >= this.sounds.bubble.count) {
      this.sounds.bubble.current = 0;
    }
  }

  create() {
    super.create();

    this.sceneContainer = new PIXI.Container();
    this.pixiApp.stage.addChild(this.sceneContainer);

    this.mapSprite = new PIXI.Sprite.from(mapSrc);
    this.mapSprite.width = this.width / 0.5;
    this.mapSprite.height = this.height / 0.5;
    this.mapSprite.texture.baseTexture.wrapMode = PIXI.WRAP_MODES.REPEAT;
    this.pixiApp.stage.addChild(this.mapSprite);

    this.bulgeFilter = new BulgePinchFilter();
    this.bulgeFilter.radius =
      (this.assets[fishClosedSrc].data.width * this.itemScale) / 2;
    this.bulgeFilter.strength = -0.05;
    this.bulgeFilter.center = [0.4, 0.5];
    this.bulgeFilter.resolution = window.devicePixelRatio;

    this.displacementFilter = new PIXI.filters.DisplacementFilter(
      this.mapSprite
    );
    this.displacementFilter.resolution = window.devicePixelRatio;

    this.fishContainer = new PIXI.Container();
    this.fishContainer.x = this.width / 2 + 10;
    this.fishContainer.y = this.height / 2;
    this.fishContainer.rotation = 0.1;
    this.fishContainer.scale.set(0.85);
    this.fishContainer.filters = [this.bulgeFilter];
    this.sceneContainer.addChild(this.fishContainer);

    this.sceneContainer.filters = [this.displacementFilter];

    this.fish = new Fish(
      0,
      0,
      this.assets[fishClosedSrc].data.width * this.itemScale,
      this.assets[fishClosedSrc],
      this.assets[fishOpenSrc]
    );
    this.fishContainer.addChild(this.fish.sprite);

    this.breathMeterBackContainer = new PIXI.Container();
    this.breathMeterBackContainer.x = this.width / 2;
    this.breathMeterBackContainer.y = this.height / 2 + 130;
    this.pixiApp.stage.addChild(this.breathMeterBackContainer);

    this.breathMeterBack = new RectSprite(
      0,
      0,
      this.fish.sprite.width * 0.75,
      4,
      'hsla(0, 0%, 0%, 0.1)'
    );
    this.breathMeterBackContainer.addChild(this.breathMeterBack.sprite);

    this.breathMeterFrontContainer = new PIXI.Container();
    this.breathMeterFrontContainer.x = this.width / 2;
    this.breathMeterFrontContainer.y = this.height / 2 + 130;
    this.breathMeterFrontContainer.scale.set(0, 1);
    this.pixiApp.stage.addChild(this.breathMeterFrontContainer);

    this.breathMeterFront = new RectSprite(
      0,
      0,
      this.fish.sprite.width * 0.75,
      4,
      'hsla(0, 0%, 0%, 1)'
    );
    this.breathMeterFrontContainer.addChild(this.breathMeterFront.sprite);
  }

  createBubble() {
    let bubble = new Bubble(
      this.fishContainer.x - 115 + this.breathCurrentProgress * 25,
      this.fishContainer.y,
      Calc.rand(3, 10),
      this.assets[bubbleSrc]
    );
    this.bubbles.push(bubble);
    this.sceneContainer.addChild(bubble.container);
  }

  onTick() {
    super.onTick();

    let i = this.bubbles.length;
    while (i--) {
      let bubble = this.bubbles[i];
      bubble.update();
      if (
        bubble.container.x <= -bubble.sprite.width ||
        bubble.container.x >= this.width + bubble.sprite.width ||
        bubble.container.y <= -bubble.sprite.height ||
        bubble.container.y >= this.height + bubble.sprite.height
      ) {
        this.sceneContainer.removeChild(bubble.sprite);
        this.bubbles.splice(i, 1);
        bubble = null;
      }
    }

    this.mapSprite.position.x -= 0.5;

    if (!this.started) {
      return;
    }

    this.breathTotalProgress =
      (Date.now() - this.breathStartTime) / this.breathTotalDuration;
    this.breathCurrentProgress =
      (Date.now() - this.breathRecentTime) / this.breathDuration;

    if (this.breathCurrentProgress >= 1) {
      this.breathRecentTime = Date.now();
      this.breathAlternate = !this.breathAlternate;
      this.breathCurrentProgress = this.breathAlternate ? 0 : 0;

      if (this.breathAlternate) {
        this.fish.sprite.texture = this.fish.fishClosedTexture;
      } else {
        this.fish.sprite.texture = this.fish.fishOpenTexture;
      }
    }

    let eased = Ease.inOutSine(
      this.breathAlternate
        ? this.breathCurrentProgress
        : 1 - this.breathCurrentProgress,
      0,
      1,
      1
    );
    this.fishContainer.scale.set(0.95 + Calc.map(eased, 0, 1, -1, 1) * 0.1);
    this.bulgeFilter.strength = 0.05 + Calc.map(eased, 0, 1, -1, 1) * 0.1;
    this.breathMeterFrontContainer.scale.set(eased, 1);

    if (this.tick % 20 === 0 && !this.breathAlternate) {
      this.createBubble();
      this.playBubbleSound();
    }

    this.progressTarget = this.breathTotalProgress;

    if (this.breathTotalProgress >= 1) {
      this.started = false;
      this.fish.sprite.texture = this.fish.fishClosedTexture;
      this.completeTimeout = window.setTimeout(() => {
        this.onComplete();
      }, 3000);
    }
  }

  destroy() {
    super.destroy();

    window.clearTimeout(this.completeTimeout);

    this.mapSprite = null;
    this.fishContainer = null;
    this.fish = null;
    this.breathMeterFrontContainer = null;
    this.breathMeterFront = null;
    this.breathMeterBackContainer = null;
    this.breathMeterBack = null;

    this.bubbles.length = 0;
    this.bubbles = null;
  }
}

export default AnimalBreathingReflection;
