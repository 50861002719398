import React, { Component } from 'react';
import MyContext from '../../state/MyContext';
import {Helmet} from "react-helmet";
import PageHeader from '../PageHeader';
import ChallengeCard from '../ChallengeCard';
import FooterUpdator from '../FooterUpdator';
import first_image from '../../assets/images/illustrations/hatched-chick.png';
import goodwork_image from '../../assets/images/illustrations/keep-swimming.png';
import triedall_image from '../../assets/images/illustrations/complete-trophy.png';
import nearly_there from '../../assets/images/illustrations/nearly-there.png';

import ChallengeDots, {
  STATE_UNTRIED,
  STATE_ACTIVE,
  STATE_CLOSE,
  STATE_COMPLETED
} from '../ChallengeDots';
require('./style.scss');

class Guide extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ready:false,
    };

    this.isSkill = props.match.path === '/skills/:skill';

    this.guide_slug = null;
    this.guide = null;
    this.skill_slug = null;
    this.skill = null;

    this.dot_values = [];
    this.progress = STATE_UNTRIED;
  }

  componentDidMount() {
    if(!this.context.user_logged_in) this.props.history.push('/');

    //Preload content
    if(!this.context.guides_loaded) this.context.getGuides();
    if(!this.context.challenges_loaded) this.context.getChallenges();
    if(!this.context.user_challenges_loaded) this.context.getUserChallenges();
    if(this.context.guides_loaded && this.context.challenges_loaded && this.context.user_challenges_loaded){
      this.isSkill = this.props.match.path === '/skills/:skill';
      this._getGuideFromSlug();
      this._gatherDotValues();
      this.setState({ready:true});
    }
  }

  componentDidUpdate() {
    if(this.context.guides_loaded && this.context.challenges_loaded && this.context.user_challenges_loaded && !this.state.ready){
      this.isSkill = this.props.match.path === '/skills/:skill';
      this._getGuideFromSlug();
      this._gatherDotValues();
      this.setState({ready:true});
    }
  }

  _getGuideFromSlug() {
    if(this.isSkill){
      this.skill_slug = this.props.match.params.skill ? this.props.match.params.skill : null;
      if(this.skill_slug !== null){
        this.skill = this.context.getSkillBySlug(this.skill_slug);
      }else{
        this.props.history.push('/guides');
      }
    }else{
      this.guide_slug = this.props.match.params.guide ? this.props.match.params.guide : null;
      if(this.guide_slug !== null){
        this.guide = this.context.getGuideBySlug(this.guide_slug);
      }else{
        this.props.history.push('/guides');
      }
    }
  }

  _gatherDotValues() {

    let challenges = [];
    if(this.isSkill){
      challenges = this.context.challenges.filter(challenge => {
        if(challenge.cbt_skill){ return challenge.cbt_skill.id === this.skill.id } return false;
      });
    }else{
      challenges = this.context.challenges.filter(challenge => challenge.guide_id === this.guide.id)
    }

    //Dot Values
    let dot_values = [];
    challenges.forEach(challenge => {
      if(!challenge.hidden){
        const user_challenge = this.context.user_challenges.filter(user_challenge => user_challenge.id === challenge.id);
        if(user_challenge.length > 0){
          dot_values.push(user_challenge[0].completed ? STATE_COMPLETED : STATE_ACTIVE);
        }else{
          dot_values.push(STATE_UNTRIED);
        }
      }
    });
    this.dot_values = dot_values;

    //Challenge Percentage
    let percentage_completed = 0;
    this.dot_values.forEach(state => {
      if(state === STATE_COMPLETED) percentage_completed++;
    });
    percentage_completed = percentage_completed / this.dot_values.length;

    //State
    if(dot_values.includes(STATE_ACTIVE) || dot_values.includes(STATE_COMPLETED)) this.progress = STATE_ACTIVE;
    if(percentage_completed >= 0.70) this.progress = STATE_CLOSE;
    if(!dot_values.includes(STATE_UNTRIED) && !dot_values.includes(STATE_ACTIVE)) this.progress = STATE_COMPLETED;
  }

  render(){
    return (
      <div id="guide">
        {
          this.state.ready ?
            <React.Fragment>
              <Helmet>
                <title>Nod - {this.isSkill ? 'Skills - ' + this.skill.title : 'Guides - ' + this.guide.title}</title>
              </Helmet>
              <PageHeader bgHeaderColor={this.isSkill ? 'tan-accent' : this.guide.theme_name} bgColor="tan">
                <div className={this.isSkill ? 'static' : 'static'}>
                  <img src={this.isSkill ? this.skill.image_url : this.guide.image_url} alt="guide" />
                </div>
                <h1>{this.isSkill ? this.skill.title : this.guide.title}</h1>
                <ChallengeDots dotValues={this.dot_values} theme={this.isSkill ? 'tan' : this.guide.theme_name} />
                {/*<p>You've tried {this.context.getChallengesCompletedByGuides(this.guide.id)} of {this.guide.total_challenges}</p>*/}
              </PageHeader>

              <div className="challenges-list">
                {
                  this.context.challenges.map((challenge, index) => {

                    let ok = false;
                    if(this.isSkill){
                      if(challenge.cbt_skill && !challenge.hidden){
                        if(challenge.cbt_skill.id === this.skill.id) ok = true;
                      }
                    }else{
                      ok = challenge.guide_id === this.guide.id && !challenge.hidden;
                    }

                    if(ok){
                      const user_challenge = this.context.getUserChallengeByChallengeID(challenge.id);
                      let status = 'inactive';
                      if(user_challenge) status = user_challenge.completed ? 'completed' : 'active';
                      let completed_at = null;
                      if(user_challenge) completed_at = user_challenge.challenge_completed_at !== null ? user_challenge.challenge_completed_at : null;
                      return <ChallengeCard
                                key={index}
                                link={'/challenges/'+challenge.id}
                                theme={challenge.theme_name}
                                image_url={challenge.image_url}
                                title={challenge.title}
                                status={status}
                                completion_date={completed_at} />;
                    }else{
                      return null;
                    }
                  })
                }
              </div>

              <div className={"guide_sub-header-section " + (this.isSkill ? 'tan' : this.guide.theme_name)}>
                <div className="squiggle">
                  <svg viewBox="0 0 1000 31.39">
                    <path className="pathItem" d="M0,0V29.86c21.61,2.24,42.07-5.11,53.07-6.72,36.48-5.34,61-3.46,98.63,3.15S206.18,27.62,253,11.53s63.77,6.58,91.48,13.9,43.63-6.72,66-1.95C425.35,26.63,452,30.39,471.75,25c7.09-1.93,56.62-5,67.8-5.67C588,16.39,589,36.39,651.56,30.2,680.08,27.37,710,15.39,758,23.3c48.81,8,54.62,2.37,92.26-4.25s60.15-1.49,96.63,3.85c11,1.61,31.46,9,53.07,6.72V0Z"/>
                  </svg>
                </div>

                {this.progress === STATE_UNTRIED ?
                  <React.Fragment>
                    <img src={first_image} alt="guide" />
                    <h3>What will you try first?</h3>
                  </React.Fragment>
                :null
                }

                {this.progress === STATE_ACTIVE ?
                  <React.Fragment>
                    <img src={goodwork_image} alt="guide" />
                    <h3>Keep up the good work!</h3>
                  </React.Fragment>
                :null
                }

                {this.progress === STATE_CLOSE ?
                  <React.Fragment>
                    <img src={nearly_there} alt="guide" />
                    <h3>You’re almost there!</h3>
                  </React.Fragment>
                :null
                }

                {this.progress === STATE_COMPLETED ?
                  <React.Fragment>
                    <img src={triedall_image} alt="guide" />
                    <h3>Way to go! You tried all our ideas.</h3>
                  </React.Fragment>
                :null
                }

                <div className="squiggle bottom">
                  <svg viewBox="0 0 1000 31.39">
                    <path className="pathItem" d="M0,0V29.86c21.61,2.24,42.07-5.11,53.07-6.72,36.48-5.34,61-3.46,98.63,3.15S206.18,27.62,253,11.53s63.77,6.58,91.48,13.9,43.63-6.72,66-1.95C425.35,26.63,452,30.39,471.75,25c7.09-1.93,56.62-5,67.8-5.67C588,16.39,589,36.39,651.56,30.2,680.08,27.37,710,15.39,758,23.3c48.81,8,54.62,2.37,92.26-4.25s60.15-1.49,96.63,3.85c11,1.61,31.46,9,53.07,6.72V0Z"/>
                  </svg>
                </div>
              </div>

              <div className={"guide-footer " + (this.isSkill ? 'tan' : this.guide.theme_name)}>
                <div className="squiggle">
                  <svg viewBox="0 0 1000 31.39">
                    <path className="pathItem" d="M0,0V29.86c21.61,2.24,42.07-5.11,53.07-6.72,36.48-5.34,61-3.46,98.63,3.15S206.18,27.62,253,11.53s63.77,6.58,91.48,13.9,43.63-6.72,66-1.95C425.35,26.63,452,30.39,471.75,25c7.09-1.93,56.62-5,67.8-5.67C588,16.39,589,36.39,651.56,30.2,680.08,27.37,710,15.39,758,23.3c48.81,8,54.62,2.37,92.26-4.25s60.15-1.49,96.63,3.85c11,1.61,31.46,9,53.07,6.72V0Z"/>
                  </svg>
                </div>
                <h1>Student Perspective</h1>
                {this.isSkill ?
                  <div dangerouslySetInnerHTML={{__html: this.skill.student_perspective_text}}></div>
                :
                  <div dangerouslySetInnerHTML={{__html: this.guide.motivation.student_quote_text}}></div>
                }
                {this.isSkill ?
                  <div className="squiggle bottom">
                    <svg viewBox="0 0 1000 31.39">
                      <path className="pathItem" d="M0,0V29.86c21.61,2.24,42.07-5.11,53.07-6.72,36.48-5.34,61-3.46,98.63,3.15S206.18,27.62,253,11.53s63.77,6.58,91.48,13.9,43.63-6.72,66-1.95C425.35,26.63,452,30.39,471.75,25c7.09-1.93,56.62-5,67.8-5.67C588,16.39,589,36.39,651.56,30.2,680.08,27.37,710,15.39,758,23.3c48.81,8,54.62,2.37,92.26-4.25s60.15-1.49,96.63,3.85c11,1.61,31.46,9,53.07,6.72V0Z"/>
                    </svg>
                  </div>
                :null
                }
              </div>

              {this.isSkill ?
                <div className={"guide-footer tan"}>
                  <div className="squiggle">
                    <svg viewBox="0 0 1000 31.39">
                      <path className="pathItem" d="M0,0V29.86c21.61,2.24,42.07-5.11,53.07-6.72,36.48-5.34,61-3.46,98.63,3.15S206.18,27.62,253,11.53s63.77,6.58,91.48,13.9,43.63-6.72,66-1.95C425.35,26.63,452,30.39,471.75,25c7.09-1.93,56.62-5,67.8-5.67C588,16.39,589,36.39,651.56,30.2,680.08,27.37,710,15.39,758,23.3c48.81,8,54.62,2.37,92.26-4.25s60.15-1.49,96.63,3.85c11,1.61,31.46,9,53.07,6.72V0Z"/>
                    </svg>
                  </div>
                  <h1>Why open up?</h1>
                  <div dangerouslySetInnerHTML={{__html: this.skill.why_text}}></div>
                  {/*<p>Many social situations call for polite small talk, not heart-to-heart conversations, making it difficult to really connect. One way to overcome this is through “self-disclosure”—that is, sharing more about yourself with other people.</p>
                  <p>Start small, and as trust builds you can go deeper. These ideas will give you practice opening up to other students.</p>*/}
                </div>
              :null
              }

              <FooterUpdator
                showHome={true}
                backHandler={() => {this.props.history.goBack()}} />
            </React.Fragment>
          :
            <div>Loading...</div>
        }
      </div>
    );
  }
}

Guide.contextType = MyContext;
export default Guide;
