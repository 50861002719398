import BaseComponent from '../common/BaseComponent';
import Reflection from './scripts/SquirrelZoomReflection';

class SquirrelZoomComponent extends BaseComponent {
  constructor(props) {
    super(props);
    this.Reflection = Reflection;
  }
}

export default SquirrelZoomComponent;
