import React, { Component } from 'react';
import MyContext from '../../state/MyContext';
import PageHeader from '../PageHeader';
import FooterUpdator from '../FooterUpdator';

require('./style.scss');

class Feedback extends Component {

  constructor(props) {
    super();
    this.state = {
      validation_message: '',
      category_value: 'none',
      feedback_value: '',
      email_value: '',
      submit_disabled: false,
    }

    this._handleSubmit = this._handleSubmit.bind(this);
  }

  _updateSelect(name, event) {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    this.setState({
      [name] : value
    });
  }

  _handleSubmit(e) {
    e.preventDefault();

    let valid = true;
    let valid_message = '';
    this.setState({validation_message: valid_message});

    //Check email
    if(!this._isValidEmail(this.state.email_value)){
      valid = false;
      valid_message += 'Please use a valid email address. ';
    }

    //Check category_value
    if(this.state.category_value === 'none'){
      valid = false;
      valid_message += 'Please choose a category. ';
    }

    //Check feedback_value
    if(this.state.feedback_value.trim().length < 1){
      valid = false;
      valid_message += 'Please enter your feedback. ';
    }

    if(valid){
      this.setState({submit_disabled: true});
      this.context.sendFeedback(this.state.category_value, this.state.feedback_value, this.state.email_value).then((result) => {
        this.setState({submit_disabled: false, validation_message: 'Thank you!'});
      });
    }else{
      this.setState({validation_message: valid_message});
    }

  };

  _isValidEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape
    return re.test(email);
  }

  render(){
    return (
      <div id="feedback">
        <PageHeader bgColor="tan"></PageHeader>
        <form onSubmit={this._handleSubmit}>
          <label htmlFor="email">Email</label>
          <input id="email" type="text" value={this.state.email_value} onChange={(e) => this._updateSelect('email_value', e)} placeholder="email" />
          <label htmlFor="first_name">Select a Category</label>
          <div className="dropdownWrap">
            <select value={this.state.category_value}  onChange={(e) => this._updateSelect('category_value', e)}>
              <option value='none'>- 3 Options -</option>
              <option value='report-an-issue'>Ideas for Improvement</option>
              <option value='general feedback'>Report an Issue</option>
              <option value='request demo access'>Request Demo Access</option>
            </select>
          </div>
          <label htmlFor="feedback">Leave your feedback:</label>
          <textarea rows="4" id="feedback" value={this.state.feedback_value} onChange={(e) => this._updateSelect('feedback_value', e)} placeholder="write your feedback here…" />
          {(this.state.validation_message !== '') ? <p className="validationText">{this.state.validation_message}</p> : null}
          <input type="submit" value="Submit" disabled={ this.state.submit_disabled ? 'disabled' : false } />
        </form>
        <FooterUpdator
          showHome={false}
          backHandler={() => {this.props.history.goBack()}} />
      </div>
    );
  }
}
Feedback.contextType = MyContext;
export default Feedback;
