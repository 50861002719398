import React, { Component } from 'react';
import MyContext from '../../state/MyContext';
import {Helmet} from "react-helmet";
import PageHeader from '../PageHeader';
import FooterUpdator from '../FooterUpdator';
require('./style.scss');

class EmailVerification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      token: this.props.match.params.token ? this.props.match.params.token : null,
      token_email: this.props.location.search.includes('?email=') ? this.props.location.search.substring(7) : null,
      submit_disabled: false,
    };
  }

  componentDidMount() {
    setTimeout(() =>{
      this.context.footerModalNotPresent();
    },500);
  }

  submitVerify(token, email){
    this.setState({submit_disabled: true});
    this.context.verifyUser(token, email).then(result => {
      if(result.success){
        setTimeout(() => {
          this.context.getInitData().then(() => {
            setTimeout(() => {
              if(this.context.user_logged_in){
                this.props.history.push('/onboarding/onboard'); // was '/'
              }else{
                this.props.history.push('/login');
              }
            },500);
          });
        },500);
      }else{
        this.setState({message:result.message, submit_disabled: false});
      }
    });
  }

  render() {
    return (
      <div className="verify">
        <Helmet>
          <title>Nod - Verify</title>
        </Helmet>
        <PageHeader bgColor="tan-accent" bgHeaderColor="tan">
          <h1>Click the button below to verify your account and log in.</h1>
        </PageHeader>
        {this.state.message !== '' ? <p>{this.state.message}</p> : null}
        {/*<a href="/" className="button" onClick={(e) => {e.preventDefault(); this.submitVerify(this.state.token, this.state.token_email); } }>Verify</a>*/}
        <input className="button" type="submit" value="Verify" disabled={ this.state.submit_disabled ? 'disabled' : false } onClick={(e) => {e.preventDefault(); this.submitVerify(this.state.token, this.state.token_email); } }/>
        <FooterUpdator
          showHome={false}
          backHandler={() => {this.props.history.goBack()}} />
      </div>
    );
  }
}

EmailVerification.contextType = MyContext;
export default EmailVerification;
