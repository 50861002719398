import React, { Component } from 'react';
import { Link } from 'react-router-dom';
require('./style.scss');

class BubbleCard extends Component {
  render(){
    return (
      <Link to={this.props.link} className={'bubble-card ' + this.props.theme + (this.props.skill ? ' skill' : ' no')} aria-label={this.props.title}>
        <div className="bubble-card_bubble">
          <div className={this.props.animated ? 'animated' : ''}>
            <img src={this.props.image_url} alt="" />
          </div>
        </div>
        <p aria-hidden="true">{this.props.title}</p>
      </Link>
    );
  }
}

export default BubbleCard;
