import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import MyContext from '../../state/MyContext';
import PageHeader from '../PageHeader';
import NotificationCard from '../NotificationCard';
import FooterUpdator from '../FooterUpdator';
require('./style.scss');

class Notifications extends Component {

  constructor(props) {
    super(props);
    this.state = {
      os_notifications_enabled: false,
      expired_notifications: []
    };
  }

  componentDidMount() {
    this.context.areOsNotificationsEnabled().then((result) => {
      if(result === true){
        this.context.notificationsRefresh();
        this.context.getAllExpiredNotifications().then((expired_notifications) => {
          this.context.getAllScheduledNotifications().then((upcoming_notifications) => {
            this.setState({
              os_notifications_enabled: true,
              expired_notifications: expired_notifications,
              upcoming_notifications: upcoming_notifications
            });
            this.context.expiredNotificationsViewed();
            this.context.setState({new_notifications: 0});
          });
        });
      }
    });
  }

  _getExpiredNotifications() {
    return (
      <React.Fragment>
      { this.state.expired_notifications.length === 0 ?
          <div>There are no notifications for you.</div>
        :
          <React.Fragment>
            { this.state.expired_notifications.map((notification, index) => {
              const data = JSON.parse(notification.data);
              return <NotificationCard
                        key={index}
                        class={data.category}
                        title={notification.title}
                        text={notification.text}
                        viewed={notification.viewed}
                        link={data.redirectRoute}
                        actionText={data.actionText} />
              })
            }
          </React.Fragment>
      }
      </React.Fragment>
    );
  }

  _getUpcomingNotifications() {
    if(this.context.debug){
      return (
        <React.Fragment>
        <h3>Upcoming Notifications:</h3>
        {
          this.state.upcoming_notifications.map((notification, index) => {
            const data = JSON.parse(notification.data);
            return <NotificationCard
                      key={index}
                      class={data.category}
                      title={notification.title}
                      text={notification.text}
                      viewed={notification.viewed}
                      link={data.redirectRoute}
                      actionText={data.actionText}
                      date={new Date(notification.trigger.at).toString()} />
          })
        }
        </React.Fragment>
      );
    }else{
      return null;
    }
  }

  render(){
    return (
      <div id="notifications">
        <Helmet>
          <title>Nod - Notifications</title>
        </Helmet>
        <PageHeader bgColor="tan" />
        { !this.state.os_notifications_enabled ?
            <div>
              <h1>Notifications are disabled</h1>
              <p>Please go back into your settings and enable them for Nod</p>
            </div>
          :
            <div>
              {this._getExpiredNotifications()}
              {this.context.debug ? this._getUpcomingNotifications() : null}
            </div>
        }
        <FooterUpdator normal />
      </div>
    );
  }
}

Notifications.contextType = MyContext;
export default Notifications;
