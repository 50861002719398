import React, { Component } from 'react';
import MyContext from '../../state/MyContext';
import { Link } from 'react-router-dom';
require('./style.scss');

class FeaturedChallengesEndCard extends Component {

  _handleClick() {
    this.context.sendKeenEvent('featured_challenges', {
      position: null,
      guide_title: null,
      challenge_id: null,
      challenge_title: null,
      swipe: null,
      visit_ideas_view: true,
      visit_ideas_view_clickthrough: true,
      skill: null
    });

    this.context.sendMixPanelEvent('featured_challenges', {
      position: null,
      guide_title: null,
      challenge_id: null,
      challenge_title: null,
      swipe: null,
      visit_ideas_view: true,
      visit_ideas_view_clickthrough: true,
      skill: null
    });
  }

  render(){
    return (
      <div className="featured-challenges-end-card">
        <div className="inner">
          <div className="content">
            <h1>Those are our top 5 ideas for you</h1>
            <p>Try one out—you might be surprised<br /> what you get out of it.</p>
            <p className="swipe-back">Swipe back and pick one.</p>
          </div>
        </div>
        <div className="or">or</div>
        <Link to="/guides" onClick={() => this._handleClick()}>Pick one from all ideas</Link>
      </div>
    );
  }
}

FeaturedChallengesEndCard.contextType = MyContext;
export default FeaturedChallengesEndCard;
