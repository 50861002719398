import Session from './session';
import moment from 'moment';
//import { startServer } from './ServerMock';

//MOCK SERVER IS A WORK IN PROGRESS
const DEBUG = false; //We'll want to turn this on for testing somehow
//if(DEBUG) startServer(); //in progress and crashes webview because module not found
const HOST = DEBUG ? '/mock-api' : process.env.REACT_APP_BACKEND_API_URL;

const INIT_URL = HOST + '/init';
const REGISTER_URL = HOST + '/register';
const VERIFY_URL = HOST + '/user/verification';
//const LOGIN_URL = HOST + '/login';
const OAUTH_URL = HOST + '/oauth/token';
const LOGOUT_URL = HOST + '/logout';
const PASSWORD_FORGOT_URL = HOST + '/password/email';
const PASSWORD_RESET_URL = HOST + '/password/reset';
const SOCIAL_AUTH_FACEBOOK = HOST + '/auth/social/facebook';
const SOCIAL_AUTH_GOOGLE = HOST + '/auth/social/google';
const SOCIAL_AUTH_APPLE = HOST + '/auth/social/apple';
const PROFILE_URL = HOST + '/profile';
const MOTIVATIONS_URL = HOST + '/motivations';
const GUIDES_URL = HOST + '/guides';
const CHALLENGES_URL = HOST + '/challenges';
const USER_CHALLENGES_URL = HOST + '/user-challenges';
const STANDALONE_REFLECTIONS_URL = HOST + '/standalone-reflections';
const JOURNEY_URL = HOST + '/journey';
const FEEDBACK_URL = HOST + '/feedback/save';
const MILESTONES_URL = HOST + '/milestones';

let ClientOAuth2 = require('client-oauth2');
let nodAuth = new ClientOAuth2({
  clientId: process.env.REACT_APP_PASSPORT_ID,
  clientSecret: process.env.REACT_APP_PASSPORT_SECRET,
  accessTokenUri: OAUTH_URL,
  //authorizationUri: OAUTH_URL,
  //redirectUri: 'http://example.com/auth/github/callback',
  //scopes: ['notifications', 'gist']
});

const getAccess = () => {
  return new Promise(function(resolve, reject) {
    const session = new Session();
    const expiration = session.getExpiration();
    if(expiration !== null && expiration !== ''){
      const accessToken = session.get();
      //console.log('EXP: ' + new Date(expiration) +' NOW: ' + new Date());
      if(new Date(expiration).getTime() <= new Date().getTime()){
        //console.log('REFRESHING');
        //Refresh the token
        const refreshToken = session.getRefresh();
        var token = nodAuth.createToken(accessToken, refreshToken);
        token.refresh().then((user) => {
          session.save(user.accessToken);
          session.saveRefresh(user.refreshToken);
          session.saveExpiration(user.expires);
          resolve(user.accessToken);
        }).catch(err =>  {
          session.clear();
          resolve(null);
        })

      }else{ //Still valid
        resolve(accessToken);
      }
    }else{
      resolve(null);
    }
  });
}

const API = {

  ////////////////////////////////////////////////////////////////////
  //Initialization & Authentication///////////////////////////////////
  ////////////////////////////////////////////////////////////////////

  clearAccess: () => {
    return new Session().clear();
  },

  getInitData: () => {
    return new Promise(function(resolve, reject) {
      getAccess().then(token => {

        let headers = new Headers({'Accept': 'application/json'});;
        if(token !== null) headers = new Headers({'Accept': 'application/json', 'Authorization': 'Bearer ' + token});
        fetch(INIT_URL, {
          headers: headers,
          method: 'GET',
          mode: 'cors',
          credentials: 'omit',
        })
        .then(res => {
          if(res.status >= 500) reject('getInitData Server Error');
          if(res.status === 401){

            //Try again after clearing session - this happens if the sessionId comes back 401
            new Session().clear();
            getAccess().then(token => {
              let headers = new Headers({'Accept': 'application/json'});;
              if(token !== null) headers = new Headers({'Accept': 'application/json', 'Authorization': 'Bearer ' + token});
              fetch(INIT_URL, {
                headers: headers,
                method: 'GET',
                mode: 'cors',
                credentials: 'omit',
              })
              .then(res => {
                if(res.status >= 500) reject('getInitData Server Error');
                res.json().then( json => {
                  resolve(json);
                });
              }).catch( (err) => {
                reject(err);
              });
            });

          }else{
            res.json().then( json => {
              resolve(json);
            });
          }
        }).catch( (err) => {
          reject(err);
        });

      });
    });
  },

  socialAuthFacebook: (access_token) => {
    return new Promise(function(resolve, reject) {

      const session = new Session();
      session.clear();

      const body = new FormData();
      body.append('access_token', access_token);
      //for(var pair of body.entries()) {console.log(pair[0]+ ', ' + pair[1]);}
      fetch(SOCIAL_AUTH_FACEBOOK, {
        headers: new Headers({'Accept': 'application/json'}),
        method: 'POST',
        mode: 'cors',
        credentials: 'omit',
        body
      })
      .then(res => {
        if(res.status >= 500) reject('socialAuthFacebook Server Error');
        res.json().then( json => {
          session.save(json.access_token);
          session.saveRefresh(json.refresh_token);
          session.saveExpiration(new Date().getTime() + json.expires_in);
          resolve({success: true, accountCreated: json.accountCreated});
        });
      }).catch( (err) => {
        reject(err);
      });

    });
  },

  socialAuthGoogle: (access_token) => {
    return new Promise(function(resolve, reject) {

      const session = new Session();
      session.clear();

      const body = new FormData();
      body.append('access_token', access_token);
      //for(var pair of body.entries()) {console.log(pair[0]+ ', ' + pair[1]);}
      fetch(SOCIAL_AUTH_GOOGLE, {
        headers: new Headers({'Accept': 'application/json'}),
        method: 'POST',
        mode: 'cors',
        credentials: 'omit',
        body
      })
      .then(res => {
        if(res.status >= 500) reject('socialAuthFacebook Server Error');
        res.json().then( json => {
          session.save(json.access_token);
          session.saveRefresh(json.refresh_token);
          session.saveExpiration(new Date().getTime() + json.expires_in);
          resolve({success: true, accountCreated: json.accountCreated});
        });
      }).catch( (err) => {
        reject(err);
      });

    });
  },

  socialAuthApple: (data) => {
    return new Promise(function(resolve, reject) {

      const session = new Session();
      session.clear();

      const body = new FormData();
      body.append('access_token', data.identityToken);
      body.append('user_id', data.user);
      body.append('email', data.email);
      body.append('user[name][firstName]', data.fullName.givenName);
      body.append('user[name][lastName]', data.fullName.familyName);
      //for(var pair of body.entries()) {console.log(pair[0]+ ', ' + pair[1]);}
      fetch(SOCIAL_AUTH_APPLE, {
        headers: new Headers({'Accept': 'application/json'}),
        method: 'POST',
        mode: 'cors',
        credentials: 'omit',
        body
      })
      .then(res => {
        if(res.status >= 500) reject('socialAuthApple Server Error');
        res.json().then( json => {
          session.save(json.access_token);
          session.saveRefresh(json.refresh_token);
          session.saveExpiration(new Date().getTime() + json.expires_in);
          resolve({success: true, accountCreated: json.accountCreated});
        });
      }).catch( (err) => {
        reject(err);
      });

    });
  },

  registerUser: (first_name, last_name, email, password, school_id, agree_to_terms, pilot_hash=null) => {
    return new Promise(function(resolve, reject) {

      if(!DEBUG){

        const body = new FormData();
        body.append('first_name', first_name);
        body.append('last_name', last_name);
        body.append('email', email);
        body.append('plaintext_password', password);
        body.append('school_id', school_id);
        body.append('agree_to_terms', agree_to_terms);
        if(pilot_hash) body.append('pilot_hash', pilot_hash);
        //for(var pair of body.entries()) {console.log(pair[0]+ ', ' + pair[1]);}
        fetch(REGISTER_URL, {
          headers: new Headers({'Accept': 'application/json'}),
          method: 'POST',
          mode: 'cors',
          credentials: 'omit',
          body
        })
        .then(res => {
          if(res.status >= 500) reject('registerUser Server Error');
          res.json().then( json => {
            resolve(json);
          });
        }).catch( (err) => {
          reject(err);
        });

      }else{

        setTimeout(()=>{
          resolve({
            "success": true,
            "message": "Signup complete",

            "user": { //Temporary for testing
              "id": 1,
              "first_name": "Tom",
              "last_name": "Metz",
              "onboarded": false,
              "school_id": 1
            }
          });
        },500);

      }

    });
  },

  verifyUser: (token, email) => {
    return new Promise(function(resolve, reject) {

      if(!DEBUG){

        const session = new Session();
        session.clear();

        fetch(`${VERIFY_URL}/${email}/${token}`, {
          method: 'POST',
          mode: 'cors',
          credentials: 'omit'
        })
        .then(res => {
          if(res.status >= 500) reject('verifyUser Server Error');
          res.json().then( json => {
            if(json.access_token){
              session.save(json.access_token);
              session.saveRefresh(json.refresh_token);
              session.saveExpiration(new Date().getTime() + json.expires_in);
            }
            resolve(json);
          });
        }).catch( (err) => {
          reject(err);
        });

      }else{

        setTimeout(()=>{
          resolve({
            "success": true,
            "message": "Signup complete",

            "user": { //Temporary for testing
              "id": 1,
              "first_name": "Tom",
              "last_name": "Metz",
              "onboarded": false,
              "school_id": 1
            }
          });
        },500);

      }

    });
  },

  resetPassword: (email) => {
    return new Promise(function(resolve, reject) {

      if(!DEBUG){

        new Session().clear();
        const body = new FormData();
        body.append('email', email);
        return fetch(PASSWORD_FORGOT_URL, {
          //headers: new Headers({'X-Session-Id': null}),
          method: 'POST',
          mode: 'cors',
          credentials: 'omit',
          body
        })
        .then( res => {
          if (res.status >= 500) reject('resetPassword Server Error');
          res.json().then( json => {
            resolve(json);
          });
        }).catch( err => {
          reject(err);
        });

      }else{

        setTimeout(()=>{
          resolve({
            "success": true,
          });
        },500);

      }

    });
  },

  resetPasswordVerify: (email, password, token) => {
    return new Promise(function(resolve, reject) {

      if(!DEBUG){

        new Session().clear();
        const body = new FormData();
        body.append('token', token);
        body.append('email', email);
        body.append('password', password);
        body.append('password_confirmation', password);
        //for(var pair of body.entries()) {console.log(pair[0]+ ', ' + pair[1]);}
        return fetch(PASSWORD_RESET_URL, {
          //headers: new Headers({'X-Session-Id': null}),
          method: 'POST',
          mode: 'cors',
          credentials: 'omit',
          body
        })
        .then( res => {
          if (res.status >= 500) reject('resetPasswordVerify Server Error');
          if (res.status >= 400) return res.json().then(json => reject(json));
          res.json().then( json => {
            resolve(json);
          });
        }).catch( err => {
          reject(err);
        });

      }else{

        setTimeout(()=>{
          resolve({
            "success": true,
          });
        },500);

      }

    });
  },

  signInUserSSO: (sso_token) => {
    return new Promise(function(resolve, reject) {

      const session = new Session();
      session.clear();
      nodAuth.owner.getToken('bogus_email', 'bogus_password', {
        headers: {
          //'X-Session-Id': sso_token,
          'Accept': 'application/json'
        },
        body: {
          grant_type: 'sso-session',
          client_id: process.env.REACT_APP_PASSPORT_ID,
          client_secret: process.env.REACT_APP_PASSPORT_SECRET,
          'session-id': sso_token,
          scope: '*'
        }
      }, {grant_type: 'sso-session'})
      .then(function (user) {
        if(user.error) return resolve(false);
        session.save(user.accessToken);
        session.saveRefresh(user.refreshToken);
        session.saveExpiration(user.expires);
        resolve(true);
      }).catch( (err) => {
        reject(err);
      });
    });
  },

  signInUser: (email, password) => {
    return new Promise(function(resolve, reject) {

      if(!DEBUG){

        //Get token
        const session = new Session();
        session.clear();
        nodAuth.owner.getToken(email, password, {
          headers: { 'Content-Type':'application/x-www-form-urlencoded', },
          body: {
            client_id: process.env.REACT_APP_PASSPORT_ID,
            client_secret: process.env.REACT_APP_PASSPORT_SECRET,
            scope: '*'
          }
        }).then(function (user) {
          if(user.error) return resolve(user);
          session.save(user.accessToken);
          session.saveRefresh(user.refreshToken);
          session.saveExpiration(user.expires);
          resolve(true);
        }).catch((err) => {
          console.log('Err', err);
          resolve({errors: err});
        });

      }else{

        setTimeout(()=>{
          resolve({
              "user": {
                "id": 1,
                "first_name": "Tom",
                "last_name": "Metz",
                "onboarded": false,
                "school_id": 1
              },
              "notification": {}
          });
        },500);

      }

    });
  },

  signOutUser: () => {
    return new Promise(function(resolve, reject) {

      if(!DEBUG){

        getAccess().then(token => {

          const session = new Session();
          session.clear();
          fetch(LOGOUT_URL, {
            headers: new Headers({'Authorization': 'Bearer ' + token}),
            method: 'POST',
            mode: 'cors',
            credentials: 'omit'
          })
          .then(res => {
            if(res.status >= 500) reject('signOutUser Server Error');
            res.json().then( json => {
              session.clear();
              resolve(json);
            });
          }).catch( (err) => {
            reject(err);
          });

        });

      }else{

        setTimeout(()=>{
          resolve({
            "success":true
          });
        },500);

      }

    });
  },

  ////////////////////////////////////////////////////////////////////
  //Guides & Challenge Lists//////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////

  getGuides: () => {
    return new Promise(function(resolve, reject) {

      if(!DEBUG){

        getAccess().then(token => {

          fetch(GUIDES_URL, {
            headers: new Headers({'Accept': 'application/json', 'Authorization': 'Bearer ' + token}),
            method: 'GET',
            mode: 'cors',
            credentials: 'omit'
          })
          .then(res => {
            if(res.status >= 500) reject('getGuides Server Error');
            if(res.status >= 400) reject('Unauthorized');
            res.json().then( json => {
              resolve(json);
            });
          }).catch( (err) => {
            reject(err);
          });

        });

      }else{

        setTimeout(()=>{
          resolve([
              {
                "id": 1,
                "slug": "get-past-the-small-talk",
                "title": "Get Past the Small Talk",
                "completed_challenges": 1,
                "total_challenges": 10,
                "image_url": "/images/placeholder-shoe.png",
                "motivation_id": 1,
                "theme_name": "blue",
                "motivation": {
                  "id": 1,
                  "name": "Expanding my social circle",
                  "slug": "expanding-my-social-circle",
                  "theme": "blue"
                }
              },
              {
                "id": 2,
                "slug": "another-guide-title",
                "title": "Another Guide Title",
                "completed_challenges": 3,
                "total_challenges": 10,
                "image_url": "/images/placeholder-shoe.png",
                "motivation_id": 2,
                "theme_name": "green",
                "motivation": {
                  "id": 2,
                  "name": "Feeling more socially confident",
                  "slug": "feeling-more-socially-confident",
                  "theme": "green"
                },
              },
              {
                "id": 3,
                "slug": "another-guide-title2",
                "title": "Another Guide Title2",
                "completed_challenges": 3,
                "total_challenges": 10,
                "image_url": "/images/placeholder-shoe.png",
                "motivation_id": 2,
                "theme_name": "red",
                "motivation": {
                  "id": 3,
                  "name": "Getting past the small talk",
                  "slug": "getting-past-the-small-talk",
                  "theme": "red"
                }
              },
              {
                "id": 4,
                "slug": "wild-card-challenges",
                "title": "Wild Card Challenges",
                "completed_challenges": 2,
                "total_challenges": 10,
                "image_url": "/images/placeholder-shoe.png",
                "theme_name": "purple",
              }
            ]);
        },500);

      }

    });
  },

  getChallenges: () => {
    return new Promise(function(resolve, reject) {

      if(!DEBUG){

        getAccess().then(token => {

          fetch(CHALLENGES_URL, {
            headers: new Headers({'Accept': 'application/json', 'Authorization': 'Bearer ' + token}),
            method: 'GET',
            mode: 'cors',
            credentials: 'omit'
          })
          .then(res => {
            if(res.status >= 500) reject('getChallenges Server Error');
            res.json().then( json => {
              resolve(json);
            });
          }).catch( (err) => {
            reject(err);
          });

        });

      }else{

        setTimeout(()=>{
          resolve([
              {
                "id": 1,
                "title": "Acts of Kindness",
                "guide_id": 1,
                "image_url": "/images/placeholder-shoe.png",
                "introduction": "<p>Your challenge, if you choose to accept it, is to complete one act of kindness in the next 24 hours.</p>",
                "main_content": "<p>Doing good for someone else can brighten their day - and yours. Acts of kindness can be big or small. Simple or bold. Spontaneous or planned. And you can do them for friends, family, or strangers. What matters is that each kindness you do comes from the heart.</p>",
                "difficulty": 1,
                "theme": "blue",
                "partipants": 559
              },
              {
                "id": 2,
                "title": "Another Challenge",
                "guide_id": 2,
                "image_url": "/images/placeholder-shoe.png",
                "introduction": "<p>Your challenge, if you choose to accept it, is to...</p> <p>Doing good for someone else can brighten their day - and yours.Doing good for someone else can brighten their day - and yours.Doing good for someone else can brighten their day - and yours.</p>",
                "main_content": "<p>Give someone a real compliment about something distinctive they’re wearing.</p>",
                "difficulty": 10,
                "theme": "green",
                "partipants": 689
              },
              {
                "id": 3,
                "title": "Wilcard",
                "guide_id": 4,
                "image_url": "/images/placeholder-shoe.png",
                "introduction": "<p>Your challenge, if you choose to accept it, is to...</p>",
                "main_content": "<p>Doing good for someone else can brighten their day - and yours...</p>",
                "difficulty": 5,
                "theme": "purple",
                "partipants": 6859
              },
              {
                "id": 4,
                "title": "Acts of Kindness 2",
                "guide_id": 1,
                "image_url": "/images/placeholder-shoe.png",
                "introduction": "<p>Your challenge, if you choose to accept it, is to complete one act of kindness in the next 24 hours.</p>",
                "main_content": "<p>Doing good for someone else can brighten their day - and yours. Acts of kindness can be big or small. Simple or bold. Spontaneous or planned. And you can do them for friends, family, or strangers. What matters is that each kindness you do comes from the heart.</p>",
                "difficulty": 2,
                "theme": "blue",
                "partipants": 559
              },
              {
                "id": 5,
                "title": "Another Challenge 2",
                "guide_id": 2,
                "image_url": "/images/placeholder-shoe.png",
                "introduction": "<p>Your challenge, if you choose to accept it, is to...</p>",
                "main_content": "<p>Doing good for someone else can brighten their day - and yours...</p>",
                "difficulty": 11,
                "theme": "green",
                "partipants": 689
              },
              {
                "id": 6,
                "title": "This Challenge 2",
                "guide_id": 3,
                "image_url": "/images/placeholder-shoe.png",
                "introduction": "<p>Your challenge, if you choose to accept it, is to...</p>",
                "main_content": "<p>Doing good for someone else can brighten their day - and yours...</p>",
                "difficulty": 4,
                "theme": "red",
                "partipants": 6859
              },
              {
                "id": 7,
                "title": "Wildcard 2",
                "guide_id": 4,
                "image_url": "/images/placeholder-shoe.png",
                "introduction": "<p>Your challenge, if you choose to accept it, is to complete one act of kindness in the next 24 hours.</p>",
                "main_content": "<p>Doing good for someone else can brighten their day - and yours. Acts of kindness can be big or small. Simple or bold. Spontaneous or planned. And you can do them for friends, family, or strangers. What matters is that each kindness you do comes from the heart.</p>",
                "difficulty": 1,
                "theme": "purple",
                "partipants": 559
              },
              {
                "id": 8,
                "title": "Another Challenge",
                "guide_id": 2,
                "image_url": "/images/placeholder-shoe.png",
                "introduction": "<p>Your challenge, if you choose to accept it, is to...</p>",
                "main_content": "<p>Doing good for someone else can brighten their day - and yours...</p>",
                "difficulty": 10,
                "theme": "green",
                "partipants": 689
              },
              {
                "id": 9,
                "title": "Wildcard 3",
                "guide_id": 4,
                "image_url": "/images/placeholder-shoe.png",
                "introduction": "<p>Your challenge, if you choose to accept it, is to...</p>",
                "main_content": "<p>Doing good for someone else can brighten their day - and yours...</p>",
                "difficulty": 5,
                "theme": "purple",
                "partipants": 6859
              },
              {
                "id": 10,
                "title": "Acts of Kindness 3",
                "guide_id": 1,
                "image_url": "/images/placeholder-shoe.png",
                "introduction": "<p>Your challenge, if you choose to accept it, is to complete one act of kindness in the next 24 hours.</p>",
                "main_content": "<p>Doing good for someone else can brighten their day - and yours. Acts of kindness can be big or small. Simple or bold. Spontaneous or planned. And you can do them for friends, family, or strangers. What matters is that each kindness you do comes from the heart.</p>",
                "difficulty": 2,
                "theme": "blue",
                "partipants": 559
              },
              {
                "id": 11,
                "title": "Another Challenge 3",
                "guide_id": 2,
                "image_url": "/images/placeholder-shoe.png",
                "introduction": "<p>Your challenge, if you choose to accept it, is to...</p>",
                "main_content": "<p>Doing good for someone else can brighten their day - and yours...</p>",
                "difficulty": 11,
                "theme": "green",
                "partipants": 689
              },
              {
                "id": 12,
                "title": "This Challenge 3",
                "guide_id": 3,
                "image_url": "/images/placeholder-shoe.png",
                "introduction": "<p>Your challenge, if you choose to accept it, is to...</p>",
                "main_content": "<p>Doing good for someone else can brighten their day - and yours...</p>",
                "difficulty": 4,
                "theme": "red",
                "partipants": 6859
              }
            ]);
        },500);

      }

    });
  },

  ////////////////////////////////////////////////////////////////////
  //User Challenges///////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////

  getUserChallenges: () => {
    return new Promise(function(resolve, reject) {

      if(!DEBUG){

        getAccess().then(token => {

          fetch(USER_CHALLENGES_URL, {
            headers: new Headers({'Accept': 'application/json', 'Authorization': 'Bearer ' + token}),
            method: 'GET',
            mode: 'cors',
            credentials: 'omit'
          })
          .then(res => {
            if(res.status >= 500) reject('getChallenges Server Error');
            if(res.status >= 400) reject('Unauthorized');
            res.json().then( json => {
              resolve(json);
            });
          }).catch( (err) => {
            reject(err);
          });

        });

      }else{

        setTimeout(()=>{
          resolve([
              {
                "id": 1,
                "challenge": {
                  "id": 1,
                  "title": "Acts of Kindness",
                  "guide_id": 1,
                  "image_url": "/images/placeholder-shoe.png",
                  "introduction": "<p>Your challenge, if you choose to accept it, is to complete one act of kindness in the next 24 hours.</p>",
                  "main_content": "<p>Doing good for someone else can brighten their day - and yours. Acts of kindness can be big or small. Simple or bold. Spontaneous or planned. And you can do them for friends, family, or strangers. What matters is that each kindness you do comes from the heart.</p>",
                  "difficulty": 1,
                  "theme": "blue",
                  "partipants": 559
                },
                "completed": true
              },
              {
                "id": 2,
                "challenge": {
                  "id": 2,
                  "title": "Another Challenge",
                  "guide_id": 2,
                  "image_url": "/images/placeholder-shoe.png",
                  "introduction": "<p>Your challenge, if you choose to accept it, is to...</p>",
                  "main_content": "<p>Doing good for someone else can brighten their day - and yours...</p>",
                  "difficulty": 10,
                  "theme": "green",
                  "partipants": 689
                },
                "completed": false
              },
              {
                "id": 3,
                "challenge": {
                  "id": 3,
                  "title": "Wildcard",
                  "guide_id": 4,
                  "image_url": "/images/placeholder-shoe.png",
                  "introduction": "<p>Your challenge, if you choose to accept it, is to...</p>",
                  "main_content": "<p>Doing good for someone else can brighten their day - and yours...</p>",
                  "difficulty": 5,
                  "theme": "purple",
                  "partipants": 6859
                },
                "completed": false
              },
            ]);
        },500);

      }

    });
  },

  acceptChallenge: (id) => {
    return new Promise(function(resolve, reject) {

      if(!DEBUG){

        getAccess().then(token => {

          fetch(CHALLENGES_URL + '/'+id+'/accept', {
            headers: new Headers({'Accept': 'application/json', 'Authorization': 'Bearer ' + token}),
            method: 'POST',
            mode: 'cors',
            credentials: 'omit',
          })
          .then(res => {
            if(res.status >= 500) reject('acceptChallenge Server Error');
            if(res.status >= 400) reject('Unauthorized');
            res.json().then( json => {
              resolve(json);
            });
          }).catch( (err) => {
            reject(err);
          });

        });

      }else{

        setTimeout(()=>{
          resolve({
            "id": 3,
            "challenge": {
              "id": 7,
              "title": "Wildcard 2",
              "guide_id": 4,
              "image_url": "/images/placeholder-shoe.png",
              "introduction": "<p>Your challenge, if you choose to accept it, is to complete one act of kindness in the next 24 hours.</p>",
              "main_content": "<p>Doing good for someone else can brighten their day - and yours. Acts of kindness can be big or small. Simple or bold. Spontaneous or planned. And you can do them for friends, family, or strangers. What matters is that each kindness you do comes from the heart.</p>",
              "difficulty": 1,
              "theme": "purple",
              "partipants": 559
            },
            "completed": false
          });
        },500);

      }

    });
  },

  updateUserChallenge: (id, settings) => {
    return new Promise(function(resolve, reject) {

      if(!DEBUG){

        getAccess().then(token => {

          const body = new FormData();
          if(settings.mood_after) body.append('mood_after', settings.mood_after);
          if(settings.reflection_id) body.append('reflection_id', settings.reflection_id);
          if(settings.rating) body.append('rating', settings.rating);
          if(settings.completed !== undefined) body.append('completed', settings.completed ? 1 : 0);
          //for(var pair of body.entries()) {console.log(pair[0]+ ', ' + pair[1]);}
          fetch(USER_CHALLENGES_URL + '/'+id, {
            headers: new Headers({'Accept': 'application/json', 'Authorization': 'Bearer ' + token}),
            method: 'POST',
            mode: 'cors',
            credentials: 'omit',
            body
          })
          .then(res => {
            if(res.status >= 500) reject('updateUserChallenge Server Error');
            if(res.status >= 400) reject('Unauthorized');
            res.json().then( json => {
              resolve(json);
            });
          }).catch( (err) => {
            reject(err);
          });

        });

      }else{

        setTimeout(()=>{
          resolve({
            success:true
          });
        },500);

      }
    });
  },

  cancelUserChallenge: (id, settings) => {
    return new Promise(function(resolve, reject) {
      if(!DEBUG){

        getAccess().then(token => {

          fetch(CHALLENGES_URL + '/'+id+'/cancel', {
            headers: new Headers({'Accept': 'application/json', 'Authorization': 'Bearer ' + token}),
            method: 'POST',
            mode: 'cors',
            credentials: 'omit',
          })
          .then(res => {
            if(res.status >= 500) reject('cancelUserChallenge Server Error');
            if(res.status >= 400) reject('Unauthorized');
            res.json().then( json => {
              resolve(json);
            });
          }).catch( (err) => {
            reject(err);
          });

        });

      }else{

        setTimeout(()=>{
          resolve({
            success:true
          });
        },500);

      }
    });
  },

  ////////////////////////////////////////////////////////////////////
  //Standalone Reflections////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////

  saveStandaloneReflection: (reflection_id, mood_after) => {
    return new Promise(function(resolve, reject) {

      if(!DEBUG){

        getAccess().then(token => {

          const body = new FormData();
          if(reflection_id) body.append('reflection_id', reflection_id);
          if(mood_after) body.append('mood_after', mood_after);
          //for(var pair of body.entries()) {console.log(pair[0]+ ', ' + pair[1]);}
          fetch(STANDALONE_REFLECTIONS_URL, {
            headers: new Headers({'Accept': 'application/json', 'Authorization': 'Bearer ' + token}),
            method: 'POST',
            mode: 'cors',
            credentials: 'omit',
            body
          })
          .then(res => {
            if(res.status >= 500) reject('saveStandaloneReflection Server Error');
            if(res.status >= 400) reject('Unauthorized');
            res.json().then( json => {
              resolve(json);
            });
          }).catch( (err) => {
            reject(err);
          });

        });

      }else{

        setTimeout(()=>{
          resolve({
            success:true
          });
        },500);

      }
    });
  },

  getStandaloneReflections: () => {
    return new Promise(function(resolve, reject) {

      if(!DEBUG){

        getAccess().then(token => {

          fetch(STANDALONE_REFLECTIONS_URL, {
            headers: new Headers({'Accept': 'application/json', 'Authorization': 'Bearer ' + token}),
            method: 'GET',
            mode: 'cors',
            credentials: 'omit'
          })
          .then(res => {
            if(res.status >= 500) reject('getStandaloneReflections Server Error');
            if(res.status >= 400) reject('Unauthorized');
            res.json().then( json => {
              resolve(json);
            });
          }).catch( (err) => {
            reject(err);
          });

        });

      }else{

      }

    });
  },

  ////////////////////////////////////////////////////////////////////
  //Myself////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////

  updateMotivations: (motivations) => {
    return new Promise(function(resolve, reject) {

      if(!DEBUG){

        getAccess().then(token => {

          const body = new FormData();
          body.append('motivations', motivations);
          fetch(MOTIVATIONS_URL, {
            headers: new Headers({'Accept': 'application/json', 'Authorization': 'Bearer ' + token}),
            method: 'POST',
            mode: 'cors',
            credentials: 'omit',
            body
          })
          .then(res => {
            if(res.status >= 500) reject('updateMotivations Server Error');
            if(res.status >= 400) reject('Unauthorized');
            res.json().then( json => {
              resolve(json);
            });
          }).catch( (err) => {
            reject(err);
          });

        });

      }else{

        setTimeout(()=>{
          resolve({
            success:true
          });
        },500);

      }

    });
  },

  updateProfile: (settings) => {
    return new Promise(function(resolve, reject) {

      if(!DEBUG){

        getAccess().then(token => {

          const body = new FormData();
          if(settings.first_name) body.append('first_name', settings.first_name);
          if(settings.last_name) body.append('last_name', settings.last_name);
          if(settings.gender) body.append('gender', settings.gender);
          if(settings.age) body.append('age', settings.age);
          if(settings.grade_level) body.append('grade_level', settings.grade_level);
          if(settings.ethnicity) body.append('ethnicity', settings.ethnicity);
          if(settings.onboarded !== undefined) body.append('onboarded', settings.onboarded);
          if(settings.loneliness_rating !== undefined) body.append('loneliness_rating', settings.loneliness_rating);
          if(settings.checkback_feel_less_lonely) body.append('checkback_feel_less_lonely', settings.checkback_feel_less_lonely);
          if(settings.checkback_help_improve_mood) body.append('checkback_help_improve_mood', settings.checkback_help_improve_mood);
          if(settings.checkback_helpful_tips) body.append('checkback_helpful_tips', settings.checkback_helpful_tips);

          //for(var pair of body.entries()) {console.log(pair[0]+ ', ' + pair[1]);}
          fetch(PROFILE_URL, {
            headers: new Headers({'Accept': 'application/json', 'Authorization': 'Bearer ' + token}),
            method: 'POST',
            mode: 'cors',
            credentials: 'omit',
            body
          })
          .then(res => {
            if(res.status >= 500) reject('updateMotivations Server Error');
            if(res.status >= 400) reject('Unauthorized');
            res.json().then( json => {
              resolve(json);
            });
          }).catch( (err) => {
            reject(err);
          });

        });

      }else{

        setTimeout(()=>{
          resolve({
            success:true
          });
        },500);

      }

    });
  },

  getJourney: () => {
    return new Promise(function(resolve, reject) {

      if(!DEBUG){

        getAccess().then(token => {

          fetch(JOURNEY_URL, {
            headers: new Headers({'Accept': 'application/json', 'Authorization': 'Bearer ' + token}),
            method: 'GET',
            mode: 'cors',
            credentials: 'omit'
          })
          .then(res => {
            if(res.status >= 500) reject('getJourney Server Error');
            if(res.status >= 400) reject('Unauthorized');
            res.json().then( json => {
              resolve(json);
            });
          }).catch( (err) => {
            reject(err);
          });

        });

      }else{

        setTimeout(()=>{
          resolve({
            "challenges_completed":12,
            "reflections_completed":6
          });
        },500);

      }

    });
  },

  sendFeedback: (category, feedback, email) => {
    return new Promise(function(resolve, reject) {

      if(!DEBUG){

        getAccess().then(token => {

          const body = new FormData();
          body.append('category', category);
          body.append('body', feedback);
          body.append('email', email);
          //for(var pair of body.entries()) {console.log(pair[0]+ ', ' + pair[1]);}
          fetch(FEEDBACK_URL, {
            headers: new Headers({'Accept': 'application/json', 'Authorization': 'Bearer ' + token}),
            method: 'POST',
            mode: 'cors',
            credentials: 'omit',
            body
          })
          .then(res => {
            if(res.status >= 500) reject('sendFeedback Server Error');
            res.json().then( json => {
              resolve(json);
            });
          }).catch( (err) => {
            reject(err);
          });

        });

      }else{

        setTimeout(()=>{
          resolve(true);
        },500);

      }

    });
  },

  addMilestone: () => {
    return new Promise(function(resolve, reject) {

      if(!DEBUG){

        getAccess().then(token => {

          const body = new FormData();
          body.append('milestone_slug', 'test_milestone');
          body.append('milestone_at', moment().format("YYYY-MM-DD HH:mm:ss"));
          //body.append('user_challenge_id', 3);
          body.append('data[test]', 'test');
          //for(var pair of body.entries()) {console.log(pair[0]+ ', ' + pair[1]);}
          fetch(MILESTONES_URL, {
            headers: new Headers({'Accept': 'application/json', 'Authorization': 'Bearer ' + token}),
            method: 'POST',
            mode: 'cors',
            credentials: 'omit',
            body
          })
          .then(res => {
            if(res.status >= 500) reject('addMilestone Server Error');
            if(res.status >= 400) reject('Unauthorized');
            res.json().then( json => {
              console.log('Milestones Test', json);
              resolve(true);
            });
          }).catch( (err) => {
            reject(err);
          });

        });

      }else{

      }

    });
  },

}

export default API;
