import BaseComponent from '../common/BaseComponent';
import Reflection from './scripts/FlipTheTurtleReflection';

class FlipTheTurtleComponent extends BaseComponent {
  constructor(props) {
    super(props);
    this.Reflection = Reflection;
  }
}

export default FlipTheTurtleComponent;
