import React from 'react';

const GradeOptions = ({ typeId = 1 }) => (
    <>
        {typeId === 1 
            ? (
                <>
                    <option value='A first year'>A first year</option>
                    <option value='A sophomore'>A sophomore</option>
                    <option value='A junior'>A junior</option>
                    <option value='A senior'>A senior</option>
                    <option value='A fifth-year senior'>A fifth-year senior</option>
                    <option value='A graduate student'>A graduate student</option>
                    <option value='Not graduating'>Not graduating</option>
                    <option value='Non-matriculated'>Non-matriculated</option>
                    <option value='Faculty/staff'>Faculty/staff</option>
                </>
            )
            : (
                <>
                    <option value='A freshman'>A freshman</option>
                    <option value='A sophomore'>A sophomore</option>
                    <option value='A junior'>A junior</option>
                    <option value='A senior'>A senior</option>
                    <option value='Other'>Other</option>
                </>
            )
        }
    </>
);

export default GradeOptions