import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
require('./style.scss');

class ReflectionCard extends Component {

  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render(){
    const classes = 'reflection-card type-' + this.props.type.toLowerCase();
    let cta_text = '';
    switch(this.props.type){
      default:
      case 'A':
        cta_text = 'Guided Reflection';
        break;
      case 'B':
        cta_text = 'Guided Meditation';
        break;
      case 'C':
        cta_text = 'Interactive Reflection';
        break;
    }

    if(this.props.link === undefined){
      if(this.props.onClick === undefined){
        return (
          <div className={classes}>
            <h1>{this.props.title}</h1>
          </div>
        );
      }else{
        return (
          <a href="/" onClick={this.props.onClick} className={classes} aria-label={this.props.title + ' ' + cta_text}>
            <h1>{this.props.title}</h1>
            <div aria-hidden="true" className="cta">{cta_text}</div>
            <div aria-hidden="true" className="tag-wrap">
              <div className="tag"><i className="icon clock"/><span className="time"></span> MIN</div>
            </div>
          </a>
        );
      }

    }else{
      let completed = null;
      if(this.props.completion_date !== undefined && this.props.completion_date !== null){
        completed = moment(this.props.completion_date).format("YYYY-MM-DD");
      }
      return (
        <Link to={this.props.link} className={classes} aria-label={this.props.title + ' Try Again'}>
          <h1>{this.props.title}</h1>
          <p aria-hidden="true">Try Again</p>
          <span aria-hidden="true">{completed}</span>
        </Link>
      );
    }
  }
}

export default ReflectionCard;
