import * as PIXI from 'pixi.js';
import * as Matter from 'matter-js';
import PolyDecomp from 'poly-decomp';

import Calc from '../../common/Calc';
import SvgSprite from '../../common/SvgSprite';

window.decomp = PolyDecomp;

class TurtleFlipped extends SvgSprite {
  constructor(x, y, width, asset, sound, collisionVertices, limbAssets) {
    super(x, y, width, asset, 0.5, 0.5);

    this.sound = sound;
    this.collisionVertices = collisionVertices;
    this.limbAssets = limbAssets;

    this.container = new PIXI.Container();
    this.container.x = this.x;
    this.container.y = this.y;

    this.sprite.x = 0;
    this.sprite.y = 0;
    this.sprite.interactive = true;
    this.sprite.hitArea = new PIXI.Rectangle(
      -0.5 * this.sprite.width * 2 - 50,
      -0.5 * this.sprite.height * 2 - 50,
      this.sprite.width * 2 + 100,
      this.sprite.height * 2 + 100
    );

    this.body = Matter.Bodies.fromVertices(
      this.x,
      this.y,
      this.collisionVertices,
      {
        restitution: 0.5,
        friction: 0.5
      }
    );

    this.sprite.on('pointerdown', this.onPointerDown.bind(this));

    this.deactivated = false;

    this.createLimbs();

    this.container.addChild(this.armRight.sprite);
    this.container.addChild(this.legRight.sprite);
    this.container.addChild(this.sprite);
    this.container.addChild(this.armLeft.sprite);
    this.container.addChild(this.legLeft.sprite);
  }

  onPointerDown(e) {
    if (this.deactivated) {
      return;
    }

    this.sound.rate(Calc.rand(0.75, 1.25));
    this.sound.play();

    let dx = this.body.position.x - e.data.global.x;
    let dy = this.body.position.y - e.data.global.y;
    let angle = Math.atan2(dy, dx);
    let force = 0.1 * this.body.mass;
    let xForce = (Math.cos(angle) * force) / 3;
    let yForce = -force;

    Matter.Body.applyForce(
      this.body,
      { x: e.data.global.x, y: e.data.global.y },
      {
        x: xForce,
        y: yForce
      }
    );
  }

  createLimbs() {
    let scale = this.sprite.width / this.asset.data.width;

    this.armRight = new SvgSprite(
      25,
      -30,
      this.limbAssets.armRight.data.width * scale,
      this.limbAssets.armRight,
      0.1,
      0.9
    );
    this.armRight.rotationBase = -0.2;
    this.armRight.sprite.rotation = this.armRight.rotationBase;

    this.armLeft = new SvgSprite(
      15,
      24,
      this.limbAssets.armLeft.data.width * scale,
      this.limbAssets.armLeft,
      0.5,
      0.25
    );
    this.armLeft.rotationBase = -0.4;
    this.armLeft.sprite.rotation = this.armLeft.rotationBase;

    this.legRight = new SvgSprite(
      -37,
      -27,
      this.limbAssets.legRight.data.width * scale,
      this.limbAssets.legRight,
      0.2,
      0.9
    );
    this.legRight.rotationBase = -0.6;
    this.legRight.sprite.rotation = this.legRight.rotationBase;

    this.legLeft = new SvgSprite(
      -36,
      10,
      this.limbAssets.legLeft.data.width * scale,
      this.limbAssets.legLeft,
      0.9,
      0.1
    );
    this.legLeft.rotationBase = -0.4;
    this.legLeft.sprite.rotation = this.legLeft.rotationBase;
  }

  deactivate() {
    this.deactivated = true;
    this.container.alpha = 0;
  }

  update(time) {
    if (this.deactivated) {
      return;
    }

    this.container.x = this.body.position.x;
    this.container.y = this.body.position.y;
    this.container.rotation = this.body.angle;

    this.armRight.sprite.rotation =
      this.armRight.rotationBase + Math.cos(time * 0.01) * 0.1;

    this.armLeft.sprite.rotation =
      this.armLeft.rotationBase + Math.sin(time * 0.01) * 0.1;

    this.legRight.sprite.rotation =
      this.legRight.rotationBase + Math.cos(time * 0.01 + 300) * 0.1;

    this.legLeft.sprite.rotation =
      this.legLeft.rotationBase + Math.sin(time * 0.01 + 300) * 0.1;
  }
}

export default TurtleFlipped;
