import BaseComponent from '../common/BaseComponent';
import Reflection from './scripts/AudioPlayerReflection';

class AudioPlayerComponent extends BaseComponent {
  constructor(props) {
    super(props);
    this.Reflection = Reflection;
  }
}

export default AudioPlayerComponent;
