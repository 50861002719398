import React, { Component } from 'react';
import MyContext from '../../state/MyContext';
import ChallengeTeaser from '../ChallengeTeaser';
import SlickSlider from "react-slick";
//import CircleCard from '../CircleCard';
import FeaturedChallengesEndCard from '../FeaturedChallengesEndCard';
require('./style.scss');

class Reflect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      featured_slider_classes: '',
      //featured_challenge_slide: 0,
      go_back_slide: false,
      back_forward_transition_occuring: false,
      gbColorClass: 'blue'

    }
  }

  _getFeaturedChallenges() {

    //Get the saved history index
    let featured_challenges;
    let history_index = window.localStorage.getItem('@nod:featuredChallengeHistoryIndex');
    if(history_index === null || parseInt(history_index) >= this.context.featured_challenges.length){
      window.localStorage.setItem('@nod:featuredChallengeHistoryIndex', 0);
      history_index = 0;
    }else{
      history_index = parseInt(history_index);
      if(history_index > 0 && !this.state.go_back_slide) this.setState({go_back_slide:true});
    }

    //Get reflection group slice and disable shuffle back
    featured_challenges = this.context.featured_challenges.slice(history_index, history_index+5); //3
    //const featured_challenge_shuffle_back_disabled = history_index <= 0 ? true : false;

    //Slider settings
    const slider_settings = {
      dots: true,
      speed: 250,
      //initialSlide: history_index > 0 ? 1 : 0, //so you don't land on "back" slide
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: true,
      infinite: false,
      centerPadding: "8%",
      arrows: false,
      focusOnSelect: true,
      adaptiveHeight: true,
      className: this.state.featured_slider_classes,
      appendDots: dots => {
        return (
          <ul>
            {dots.map((dot, index) => {
              return (
                <li className={dot.props.className} key={index}>
                  <button aria-label={'recommended idea ' + (index+1)} onClick={dot.props.children.props.onClick}>{index+1}</button>
                </li>
              );
            })}
          </ul>
        );
      },
      onInit: () => {
        setTimeout(() => {
          //this.context.updateBackground(this.context.background_header_height, 'tan', featured_challenges[0].theme_name);

          this.setState({gbColorClass: featured_challenges[0] ? featured_challenges[0].theme_name : 'blue'});

          const guide = this.context.guides.find((theguide) => { return theguide.id === featured_challenges[0].guide_id; });

          //Keen
          this.context.sendKeenEvent('featured_challenges', {
            position: 0,
            guide_title: guide.title,
            challenge_id: featured_challenges[0].id,
            challenge_title: featured_challenges[0].title,
            swipe: null,
            visit_ideas_view: null,
            visit_ideas_view_clickthrough: null,
            skill: featured_challenges[0].cbt_skill ? featured_challenges[0].cbt_skill.title : null
          });

          //MixPanel
          this.context.sendMixPanelEvent('featured_challenges', {
            position: 0,
            guide_title: guide.title,
            challenge_id: featured_challenges[0].id,
            challenge_title: featured_challenges[0].title,
            swipe: null,
            visit_ideas_view: null,
            visit_ideas_view_clickthrough: null,
            skill: featured_challenges[0].cbt_skill ? featured_challenges[0].cbt_skill.title : null
          });

        }, 100);
      },
      beforeChange: (oldIndex, newIndex) => {
        if( oldIndex !== newIndex){
          //let theme = 'tan';
          //let pagination_slide = true;
          let challenge_index = newIndex;

          //If paginated AND if not the first "back" slide AND if not last "see more" slide
          // if(history_index > 0 && newIndex !== 4 && newIndex !== 0){
          //   theme = featured_challenges[newIndex-1].theme_name;
          //   pagination_slide = false;
          //   challenge_index = newIndex-1;
          // }

          //If not paginated AND if not last "see more" slide
          //if(history_index === 0 && newIndex !== 3){
            //theme = featured_challenges[newIndex].theme_name;
            //pagination_slide = false;
          //}

          //this.context.updateBackground(this.context.background_header_height, 'tan', theme);

          if(newIndex === featured_challenges.length){

            this.setState({gbColorClass: 'blue'});

            //KeenIO
            this.context.sendKeenEvent('featured_challenges', {
              position: challenge_index,
              guide_title: null,
              challenge_id: (challenge_index === 5) ? '[Visit Ideas]' : featured_challenges[challenge_index].id,
              challenge_title: (challenge_index === 5) ? '[Visit Ideas]' : featured_challenges[challenge_index].title,
              swipe: newIndex > oldIndex ? 'left' : 'right',
              visit_ideas_view: true,
              visit_ideas_view_clickthrough: null,
              skill: null
            });

            //MixPanel
            this.context.sendMixPanelEvent('featured_challenges', {
              position: challenge_index,
              guide_title: null,
              challenge_id: (challenge_index === 5) ? '[Visit Ideas]' : featured_challenges[challenge_index].id,
              challenge_title: (challenge_index === 5) ? '[Visit Ideas]' : featured_challenges[challenge_index].title,
              swipe: newIndex > oldIndex ? 'left' : 'right',
              visit_ideas_view: true,
              visit_ideas_view_clickthrough: null,
              skill: null
            });

          }else{
            this.setState({gbColorClass: featured_challenges[newIndex] ? featured_challenges[newIndex].theme_name : 'blue'});

            const guide = this.context.guides.find((theguide) => { return theguide.id === featured_challenges[challenge_index].guide_id; });

            //if(!pagination_slide && !this.state.back_forward_transition_occuring){
              //Keen
              this.context.sendKeenEvent('featured_challenges', {
                position: challenge_index,
                guide_title: guide.title,
                challenge_id: (challenge_index === 5) ? '[Visit Ideas]' : featured_challenges[challenge_index].id,
                challenge_title: (challenge_index === 5) ? '[Visit Ideas]' : featured_challenges[challenge_index].title,
                swipe: newIndex > oldIndex ? 'left' : 'right',
                visit_ideas_view: null,
                visit_ideas_view_clickthrough: null,
                skill: featured_challenges[challenge_index].cbt_skill ? featured_challenges[challenge_index].cbt_skill.title : null
              });

              //MixPanel
              this.context.sendMixPanelEvent('featured_challenges', {
                position: challenge_index,
                guide_title: guide.title,
                challenge_id: (challenge_index === 5) ? '[Visit Ideas]' : featured_challenges[challenge_index].id,
                challenge_title: (challenge_index === 5) ? '[Visit Ideas]' : featured_challenges[challenge_index].title,
                swipe: newIndex > oldIndex ? 'left' : 'right',
                visit_ideas_view: null,
                visit_ideas_view_clickthrough: null,
                skill: featured_challenges[challenge_index].cbt_skill ? featured_challenges[challenge_index].cbt_skill.title : null
              });
            //}
          }

        }

      },
      afterChange: (newIndex) => {
        //this.setState({featured_challenge_slide: newIndex});
      }
    };

    // const handleBack = (e) => {
    //   e.preventDefault();
    //   if(!featured_challenge_shuffle_back_disabled){
    //     this.setState({featured_slider_classes:'shuffle', going_back:true, back_forward_transition_occuring: true});
    //
    //     //History
    //     history_index = history_index - 3;
    //     if(history_index < 0) history_index = this.context.featured_challenges.length-3;
    //     window.localStorage.setItem('@nod:featuredChallengeHistoryIndex', history_index);
    //
    //     //Navigate
    //     if(history_index > 0){
    //       this.featuredChallengesSlider.slickGoTo(1);
    //     }else{
    //       this.featuredChallengesSlider.slickGoTo(0);
    //     }
    //
    //     setTimeout(() => {
    //       this.setState({featured_slider_classes:'', go_back_slide: history_index > 0});
    //       setTimeout(() => {
    //         const next_featured_challenges = this.context.featured_challenges.slice(history_index, history_index+3);
    //         //this.context.updateBackground(this.context.background_header_height, 'tan', next_featured_challenges[0].theme_name);
    //
    //         //Keen
    //         this.context.sendKeenEvent('featured_challenges', {
    //           //back: true,
    //           //forward: false,
    //           position: 0, //history_index
    //           challenge_id: next_featured_challenges[0].id,
    //           challenge_title: next_featured_challenges[0].title,
    //           interaction_pattern: 'go back'
    //         });
    //
    //         this.setState({back_forward_transition_occuring:false, gbColorClass:featured_challenges[0].theme_name});
    //
    //       },10);
    //     }, 550);
    //   }
    // };

    // const handleShuffle = (e) => {
    //   e.preventDefault();
    //   this.setState({featured_slider_classes:'shuffle', back_forward_transition_occuring: true});
    //
    //   //History
    //   history_index = history_index + 3;
    //   if(history_index >= this.context.featured_challenges.length) history_index = 0;
    //   window.localStorage.setItem('@nod:featuredChallengeHistoryIndex', history_index);
    //
    //   //Navigate
    //   if(history_index > 0){
    //     this.featuredChallengesSlider.slickGoTo(1);
    //   }else{
    //     this.featuredChallengesSlider.slickGoTo(0);
    //   }
    //
    //   setTimeout(() => {
    //     this.setState({featured_slider_classes:'', go_back_slide:true});
    //     setTimeout(() => {
    //       const next_featured_challenges = this.context.featured_challenges.slice(history_index, history_index+3);
    //       //this.context.updateBackground(this.context.background_header_height, 'tan', featured_challenges[0].theme_name);
    //
    //       //Keen
    //       this.context.sendKeenEvent('featured_challenges', {
    //         //back: false,
    //         //forward: true,
    //         position: 0, //history_index
    //         challenge_id: next_featured_challenges[0].id,
    //         challenge_title: next_featured_challenges[0].title,
    //         interaction_pattern: 'see more'
    //       });
    //
    //       this.setState({back_forward_transition_occuring:false, gbColorClass:featured_challenges[0].theme_name});
    //
    //     },10);
    //   }, 550);
    // };

    return (
      <div id="featured-challenges" className={this.state.gbColorClass}>
        <div className="squiggle">
          <svg viewBox="0 0 1000 31.39">
            <path className="pathItem" d="M0,0V29.86c21.61,2.24,42.07-5.11,53.07-6.72,36.48-5.34,61-3.46,98.63,3.15S206.18,27.62,253,11.53s63.77,6.58,91.48,13.9,43.63-6.72,66-1.95C425.35,26.63,452,30.39,471.75,25c7.09-1.93,56.62-5,67.8-5.67C588,16.39,589,36.39,651.56,30.2,680.08,27.37,710,15.39,758,23.3c48.81,8,54.62,2.37,92.26-4.25s60.15-1.49,96.63,3.85c11,1.61,31.46,9,53.07,6.72V0Z"/>
          </svg>
        </div>
        <h2 className="sliderLabel">Recommended Ideas</h2>
        <SlickSlider ref={slider => (this.featuredChallengesSlider = slider)} {...slider_settings}>
          {/* this.state.go_back_slide ?
            <CircleCard
              onClick={(e) => { handleBack(e) }}
              text="Go back to previous suggestions"
              classes="tan back"
              divider={true} />
          : null
          */}
          { featured_challenges.map((challenge, index) => {
              const guide = this.context.guides.find(guide => guide.id === challenge.guide_id);
              const skill = challenge.cbt_skill ? challenge.cbt_skill : null;
              let tags = [];
              tags.push({className: 'goal', title: guide.title});
              if(skill) tags.push({className: 'skill', title: skill.title});
              return <ChallengeTeaser
                        key={index}
                        link={'/challenges/'+challenge.id}
                        theme={challenge.theme_name}
                        image_url={challenge.image_url}
                        title={challenge.title}
                        introduction={challenge.introduction}
                        partipants={challenge.participants}
                        tags={tags} />;
            })
          }
          <FeaturedChallengesEndCard />
          {/* history_index < this.context.featured_challenges.length - 3 ?
            <CircleCard
              onClick={(e) => { handleShuffle(e) }}
              text="Show me 3 more suggestions"
              classes="tan forward"
              divider={true} />
          : null
          */}
        </SlickSlider>
        {/*<div className="featured-challenges-controls">
          <a href="/" className="back-shuffle" onClick={handleBack} disabled={featured_challenge_shuffle_back_disabled} >&nbsp;</a>
          <a href="/" className="shuffle" onClick={handleShuffle} >&nbsp;</a>
        </div>*/}

        <div className="squiggle bottom">
          <svg viewBox="0 0 1000 31.39">
            <path className="pathItem" d="M0,0V29.86c21.61,2.24,42.07-5.11,53.07-6.72,36.48-5.34,61-3.46,98.63,3.15S206.18,27.62,253,11.53s63.77,6.58,91.48,13.9,43.63-6.72,66-1.95C425.35,26.63,452,30.39,471.75,25c7.09-1.93,56.62-5,67.8-5.67C588,16.39,589,36.39,651.56,30.2,680.08,27.37,710,15.39,758,23.3c48.81,8,54.62,2.37,92.26-4.25s60.15-1.49,96.63,3.85c11,1.61,31.46,9,53.07,6.72V0Z"/>
          </svg>
        </div>
      </div>
    );
  }

  render(){
    return this.context.featured_challenges.length > 0 ? this._getFeaturedChallenges() : null;
  }
}

Reflect.contextType = MyContext;
export default Reflect;
