import * as PIXI from 'pixi.js';

class RectSprite {
  constructor(x, y, width, height, color, xAnchor = 0.5, yAnchor = 0.5) {
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
    this.color = color;
    this.xAnchor = xAnchor;
    this.yAnchor = yAnchor;

    this.dpr = window.devicePixelRatio;
    this.canvas = document.createElement('canvas');
    this.ctx = this.canvas.getContext('2d');
    this.canvas.width = this.width * this.dpr;
    this.canvas.height = this.height * this.dpr;
    this.ctx.scale(this.dpr, this.dpr);
    this.ctx.fillStyle = this.color;
    this.ctx.fillRect(0, 0, this.width, this.height);

    this.sprite = new PIXI.Sprite(PIXI.Texture.from(this.canvas));
    this.sprite.width = this.width;
    this.sprite.height = this.height;
    this.sprite.x = this.x;
    this.sprite.y = this.y;
    this.sprite.anchor.set(this.xAnchor, this.yAnchor);

    this.canvas = null;
    this.ctx = null;
  }
}

export default RectSprite;
