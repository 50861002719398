import { Howl } from 'howler';

import BaseReflection from '../../common/BaseReflection';
import Calc from '../../common/Calc';
import SvgSprite from '../../common/SvgSprite';

import Mole from './Mole';
import Particle from './Particle';
import Star from './Star';

import hole0Src from '../images/hole-0.png';
import hole1Src from '../images/hole-1.png';
import hole2Src from '../images/hole-2.png';
import holeMask0Src from '../images/hole-mask-0.png';
import holeMask1Src from '../images/hole-mask-1.png';
import holeMask2Src from '../images/hole-mask-2.png';
import mole0Src from '../images/mole-0.png';
import mole1Src from '../images/mole-1.png';
import mole2Src from '../images/mole-2.png';
import mole3Src from '../images/mole-3.png';
import shrub0Src from '../images/shrub-0.png';
import shrub1Src from '../images/shrub-1.png';
import shrub3Src from '../images/shrub-3.png';
import shrub2Src from '../images/shrub-2.png';
import starHitSrc from '../images/star-hit.png';

import victoryWebmSrc from '../sounds/victory.webm';
import victoryOggSrc from '../sounds/victory.ogg';
import victoryMp3Src from '../sounds/victory.mp3';
import whack0WebmSrc from '../sounds/whack-0.webm';
import whack0OggSrc from '../sounds/whack-0.ogg';
import whack0Mp3Src from '../sounds/whack-0.mp3';
import whack1WebmSrc from '../sounds/whack-1.webm';
import whack1OggSrc from '../sounds/whack-1.ogg';
import whack1Mp3Src from '../sounds/whack-1.mp3';
import whack2WebmSrc from '../sounds/whack-2.webm';
import whack2OggSrc from '../sounds/whack-2.ogg';
import whack2Mp3Src from '../sounds/whack-2.mp3';
import whack3WebmSrc from '../sounds/whack-3.webm';
import whack3OggSrc from '../sounds/whack-3.ogg';
import whack3Mp3Src from '../sounds/whack-3.mp3';

class WhackAMoleReflection extends BaseReflection {
  init(onComplete) {
    super.init(onComplete);

    this.assetPaths = [
      hole0Src,
      hole1Src,
      hole2Src,
      holeMask0Src,
      holeMask1Src,
      holeMask2Src,
      mole0Src,
      mole1Src,
      mole2Src,
      mole3Src,
      shrub0Src,
      shrub1Src,
      shrub2Src,
      shrub3Src,
      starHitSrc,
      victoryWebmSrc,
      victoryOggSrc,
      victoryMp3Src,
      whack0WebmSrc,
      whack0OggSrc,
      whack0Mp3Src,
      whack1WebmSrc,
      whack1OggSrc,
      whack1Mp3Src,
      whack2WebmSrc,
      whack2OggSrc,
      whack2Mp3Src,
      whack3WebmSrc,
      whack3OggSrc,
      whack3Mp3Src
    ];

    this.holes = [];
    this.holeMasks = [];
    this.moles = [];
    this.shrubs = [];

    this.stars = [];
    this.starCount = 15;

    this.particles = [];
    this.particleCount = 150;

    this.holeScale = 0.15;
    this.shrubScale = 0.2;
    this.moleScale = 0.17;
    this.posScale = 0.75;

    this.hitCount = 0;
    this.hitTotal = 15;

    this.completeTimeout = null;

    this.setCoords();

    this.addEventListeners();
    this.loadAssets();
  }

  reset(width = this.width, height = this.height) {
    super.reset(width, height);

    window.clearTimeout(this.completeTimeout);

    this.setCoords();

    this.holes.length = 0;
    this.holeMasks.length = 0;
    this.moles.length = 0;
    this.shrubs.length = 0;
    this.stars.length = 0;
    this.particles.length = 0;

    this.hitCount = 0;

    this.create();
  }

  setCoords() {
    this.holeCoords = [
      {
        x: this.width / 2 - 90 * this.posScale,
        y: this.height / 2 - 120 * this.posScale
      },
      {
        x: this.width / 2 + 130 * this.posScale,
        y: this.height / 2 - 140 * this.posScale
      },
      {
        x: this.width / 2 + 40 * this.posScale,
        y: this.height / 2 - 20 * this.posScale
      },
      {
        x: this.width / 2 - 110 * this.posScale,
        y: this.height / 2 + 70 * this.posScale
      },
      {
        x: this.width / 2 + 100 * this.posScale,
        y: this.height / 2 + 120 * this.posScale
      }
    ];

    this.shrubCoords = [
      {
        x: this.width / 2 - 150 * this.posScale,
        y: this.height / 2 - 210 * this.posScale
      },
      {
        x: this.width / 2 + 20 * this.posScale,
        y: this.height / 2 - 140 * this.posScale
      },
      {
        x: this.width / 2 + 120 * this.posScale,
        y: this.height / 2 - 240 * this.posScale
      },
      {
        x: this.width / 2 - 130 * this.posScale,
        y: this.height / 2 - 10 * this.posScale
      },
      {
        x: this.width / 2 + 140 * this.posScale,
        y: this.height / 2 + 20 * this.posScale
      },
      {
        x: this.width / 2 - 120 * this.posScale,
        y: this.height / 2 + 200 * this.posScale
      },
      {
        x: this.width / 2 + 0 * this.posScale,
        y: this.height / 2 + 90 * this.posScale
      },
      {
        x: this.width / 2 + 160 * this.posScale,
        y: this.height / 2 + 220 * this.posScale
      }
    ];
  }

  addEventListeners() {
    super.addEventListeners();
  }

  removeEventListeners() {
    super.removeEventListeners();
  }

  initPhysics() {
    super.initPhysics();
  }

  initSounds() {
    super.initSounds();

    this.sounds.victory = new Howl({
      src: [victoryWebmSrc, victoryOggSrc, victoryMp3Src],
      volume: 0.5
    });

    this.sounds.whacks = [];
    [
      [whack0WebmSrc, whack0OggSrc, whack0Mp3Src],
      [whack1WebmSrc, whack1OggSrc, whack1Mp3Src],
      [whack2WebmSrc, whack2OggSrc, whack2Mp3Src],
      [whack3WebmSrc, whack3OggSrc, whack3Mp3Src]
    ].forEach(src => {
      this.sounds.whacks.push(
        new Howl({
          src: src,
          volume: 0.5
        })
      );
    });
  }

  create() {
    super.create();

    this.createHoles();
    this.createHoleMasks();
    this.createMoles();
    this.createShrubs();
    this.createStars();
    this.createParticles();
  }

  createHoles() {
    let srcs = [hole2Src, hole1Src, hole0Src, hole1Src, hole2Src];
    this.holeCoords.forEach((coord, i) => {
      let asset = this.assets[srcs[i]];
      let width = asset.data.width * this.holeScale;
      let hole = new SvgSprite(coord.x, coord.y, width, asset);
      this.holes.push(hole);
      this.pixiApp.stage.addChild(hole.sprite);
    });
  }

  createHoleMasks() {
    let srcs = [
      holeMask2Src,
      holeMask1Src,
      holeMask0Src,
      holeMask1Src,
      holeMask2Src
    ];
    this.holeCoords.forEach((coord, i) => {
      let asset = this.assets[srcs[i]];
      let width = asset.data.width * this.holeScale;
      let holeMask = new SvgSprite(coord.x, coord.y, width, asset);
      this.holeMasks.push(holeMask);
      this.pixiApp.stage.addChild(holeMask.sprite);
    });
  }

  createMoles() {
    let srcs = [mole2Src, mole1Src, mole0Src, mole1Src, mole2Src];
    this.holeCoords.forEach((coord, i) => {
      let asset = this.assets[srcs[i]];
      let width = asset.data.width * this.moleScale;
      let mole = new Mole(
        coord.x,
        coord.y + [50, 48, 25, 48, 50][i] * this.holeScale,
        width,
        asset,
        this.sounds.whacks,
        this.holeMasks[i],
        this.onHit.bind(this),
        i,
        0.5,
        1
      );
      this.moles.push(mole);
      this.pixiApp.stage.addChild(mole.sprite);
    });
  }

  createShrubs() {
    let srcs = [
      shrub0Src,
      shrub1Src,
      shrub3Src,
      shrub1Src,
      shrub2Src,
      shrub2Src,
      shrub3Src,
      shrub1Src
    ];
    this.shrubCoords.forEach((coord, i) => {
      let asset = this.assets[srcs[i]];
      let width = asset.data.width * this.shrubScale;
      let shrub = new SvgSprite(coord.x, coord.y, width, asset);
      this.shrubs.push(shrub);
      this.pixiApp.stage.addChild(shrub.sprite);
    });
  }

  createStars() {
    for (let i = 0; i < this.starCount; i++) {
      let moleRef = this.moles[Math.floor(i / 3)];
      let angle = Calc.map(i % 3, 0, 2, -Math.PI / 2 - 0.7, -Math.PI / 2 + 0.7);
      let radius = 120 * this.moleScale;
      let x = moleRef.x + Math.cos(angle) * radius;
      let y = moleRef.y + Math.sin(angle) * radius;
      let width = 100 * this.moleScale;
      let star = new Star(
        x,
        y,
        width,
        this.assets[starHitSrc],
        0.5,
        0.5,
        angle
      );
      this.pixiApp.stage.addChild(star.container);
      this.stars.push(star);
    }
  }

  createParticles() {
    for (let i = 0; i < this.particleCount; i++) {
      let width = Calc.rand(3, 12);
      let height = Calc.rand(3, 12);
      let particle = new Particle(
        -100,
        -100,
        width,
        height,
        `hsla(${Calc.rand(0, 360)}, 95%, 60%, 1)`,
        0.5,
        0.5,
        -Math.PI / 2 + Calc.rand(-0.2, 0.2),
        Calc.rand(0, 15)
      );
      this.pixiApp.stage.addChild(particle.container);
      this.particles.push(particle);
    }
  }

  onHit(x, y, i) {
    this.hitCount++;

    for (let k = 0; k < 3; k++) {
      this.stars[i * 3 + k].activate(x, y - 50 * this.moleScale);
    }

    if (this.hitCount === this.hitTotal) {
      this.sounds.victory.play();

      this.holeCoords.forEach((coord, i) => {
        let per = Math.floor(this.particles.length / this.holeCoords.length);
        for (let j = 0; j < per; j++) {
          this.particles[i * per + j].activate(
            coord.x,
            coord.y + 100 * this.holeScale
          );
        }
      });

      for (let i = 0; i < this.moles.length; i++) {
        let mole = this.moles[i];
        window.clearTimeout(mole.showTimeout);
        mole.done = true;
        mole.hide();
      }

      this.completeTimeout = window.setTimeout(() => {
        this.onComplete();
      }, 3000);
    }

    this.progressTarget = this.hitCount / this.hitTotal;
  }

  onTick() {
    super.onTick();

    let time = Date.now();
    for (let i = 0, len = this.stars.length; i < len; i++) {
      this.stars[i].update(time);
    }
    for (let i = 0, len = this.particles.length; i < len; i++) {
      this.particles[i].update(time);
    }
  }

  destroy() {
    super.destroy();

    window.clearTimeout(this.completeTimeout);

    this.moles.length = 0;
    this.moles = null;
    this.holes.length = 0;
    this.holes = null;
    this.holeMasks.length = 0;
    this.holeMasks = null;
    this.shrubs.length = 0;
    this.shrubs = null;
    this.stars.length = 0;
    this.stars = null;
    this.particles.length = 0;
    this.particles = null;
  }
}

export default WhackAMoleReflection;
