import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
require('./style.scss');

class ChallengeCard extends Component {
  render(){
    let completed = null;
    if(this.props.completion_date !== undefined && this.props.completion_date !== null){
      completed = moment(this.props.completion_date).format("YYYY-MM-DD");
    }
    return (
      <Link to={this.props.link} className={'challenge-card ' + this.props.theme} aria-label={this.props.title}>
        <div className="activeImage"><img src={this.props.image_url} alt="" /></div>
        <div className="textWrap">
          <h1>{this.props.title}</h1>
          <div className="ctaWrap">
            <div className="cta">
              {this.props.status === 'inactive' ? <p>See Tips</p> : null}
              {this.props.status === 'active' ? <p>Saved</p> : null}
              {this.props.status === 'completed' && completed !== null ? <p>Tried this: <span className="date">{completed}</span></p> : null}
            </div>
            <div className="icon">
              {this.props.status === 'inactive' ? <div className="inactive" /> : null}
              {this.props.status === 'active' ? <div className="active" /> : null}
              {this.props.status === 'completed' ? <div className="completed" /> : null}
            </div>
          </div>
        </div>
      </Link>
    );
  }
}

export default ChallengeCard;
