import React, { Component } from 'react';
import MyContext from '../../state/MyContext';
import { Link } from 'react-router-dom';
import PageHeader from '../PageHeader';
import FooterUpdator from '../FooterUpdator';
import img_yoga from '../../assets/images/illustrations/yoga.png';
require('./style.scss');

class Reflect extends Component {

  componentDidMount() {
    this.context.updateFooter();
  }

  render(){
    return (
      <div id="reflections">
        <PageHeader bgColor="tan" bgHeaderColor="tan-accent" classes="centered">
          <h1>Reflect</h1>
          <p>Short exercises to shift your<br /> mood & mindset</p>
        </PageHeader>
        <div id="start-reflection">
          <img className="reflect-image" alt="" src={img_yoga} />
          <Link className="button" to='/reflect/mood'>Start Reflection</Link><br />
        </div>
        <div id="why-reflect">
          <div className="squiggle">
            <svg viewBox="0 0 1000 31.39">
              <path className="pathItem" d="M0,0V29.86c21.61,2.24,42.07-5.11,53.07-6.72,36.48-5.34,61-3.46,98.63,3.15S206.18,27.62,253,11.53s63.77,6.58,91.48,13.9,43.63-6.72,66-1.95C425.35,26.63,452,30.39,471.75,25c7.09-1.93,56.62-5,67.8-5.67C588,16.39,589,36.39,651.56,30.2,680.08,27.37,710,15.39,758,23.3c48.81,8,54.62,2.37,92.26-4.25s60.15-1.49,96.63,3.85c11,1.61,31.46,9,53.07,6.72V0Z"/>
            </svg>
          </div>
          <h2>Why reflect?</h2>
          <p>Social situations suck sometimes. Other times, they’re great. Reflecting on the good (or reframing the awkward) paves the way for forward progress.</p>
        </div>
        <FooterUpdator normal />
      </div>
    );
  }
}

Reflect.contextType = MyContext;
export default Reflect;
