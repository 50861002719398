import * as PIXI from 'pixi.js';
import { TweenMax, Back } from 'gsap';

import Calc from '../../common/Calc';
import SvgSprite from '../../common/SvgSprite';

class Bubble extends SvgSprite {
  constructor(x, y, width, asset, xAnchor = 0.5, yAnchor = 0.5) {
    super(x, y, width, asset, xAnchor, yAnchor);

    this.sprite.alpha = 0;
    this.sprite.x = 0;
    this.sprite.y = 0;
    this.sprite.rotation = Calc.rand(0, Math.PI * 2);

    this.container = new PIXI.Container();
    this.container.x = x;
    this.container.y = y;
    this.container.scale.set(0);
    this.container.addChild(this.sprite);

    this.timeOffset = Calc.rand(10000);
    this.waveMag = Calc.rand(0.1, 0.6);
    this.waveTimeMult = Calc.rand(0.0001, 0.001);
    this.velocity = {
      x: 0,
      y: 0
    };

    let obj = { value: 0 };
    TweenMax.to(obj, 0.3, {
      value: 1,
      ease: Back.easeOut.config(4),
      onUpdate: () => {
        this.sprite.alpha = obj.value;
        this.container.scale.set(obj.value);
      }
    });
  }

  update() {
    this.velocity.x =
      Math.sin((Date.now() + this.timeOffset) * this.waveTimeMult) *
      this.waveMag;
    this.velocity.y -= 0.05;

    // this.container.x += this.velocity.x;
    this.container.y += this.velocity.y;
  }
}

export default Bubble;
