import mixpanel from 'mixpanel-browser';
import { osName, osVersion } from 'react-device-detect';
import moment from 'moment';
import { sha256 } from 'js-sha256';
var pjson = require('../../package.json');

const uniqueness_events = ['app_flow']; //Added events here that should have the mixpanel uniqueness_token

class MixPanelService {

  constructor() {
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, { debug:true });
  }

  sendMixPanelEvent(event, params, location_pathname, state) {
    const device_time = moment().format("YYYY-MM-DD HH:mm:ss");

    let school;
    if(state.user.school_id){
      school = state.instances.find(instance => instance.id === state.user.school_id);
    }

    // Alias the user upon registration
    // Register Institution Group if doesn't already exist and set the user to that group
    if(event === 'registration'){
      this.sendMixPanelAlias(params.user_id, state.debug); //Getting user_id from params because user is not yet logged in
      this.sendMixPanelGroup(school, state.debug);
    }

    // Identify the user upon session start (which also happens after login)
    // Register Institution Group if doesn't already exist and set the user to that group
    if(event === 'session_start'){
      // Identity is also sent in MyProvider _getInitData()
      if(state.user.id) this.sendMixPanelIdentity(state.user.id, state.debug);
      if(school) this.sendMixPanelGroup(school, state.debug);
    }

    // Create/Update the user's profile
    // We need to do this on session_start for previously registered users
    if(['session_start', 'demographics'].includes(event)){
      if(state.user_logged_in) this.sendMixPanelProfile(state.user, school, state.debug);
    }

    //Global Events
    let global_params = {
      device: osName+' '+osVersion,
      path: params.path ? params.path : location_pathname,
      environment: process.env.REACT_APP_KEEN_ENV,
      // research_id: state.research_id,
      session_id: state.session_id,
      app_version: pjson.version,
      device_time: device_time,
      marketing_channel: state.marketing_channel,
    };

    if(state.user_logged_in){
      // Don't need these anymore since events are associated with Mixpanel User's and Institution groups.
      // global_params.school_id = state.user.school_id;
      // global_params.user_id = state.user.id;
      // global_params.research_id = state.user.pilot_hash; //This is from the user object
      global_params.grade_level = state.user.public_user.grade_level;
      global_params.gender = state.user.public_user.gender;
      global_params.ethnicity = state.user.public_user.ethnicity;
      global_params.age = state.user.public_user.age;

      const registration_date = moment(state.user.genesis);
      const seconds_from_utc = moment().utcOffset() * 60;
      const now_date = moment().utc().subtract(seconds_from_utc, 's');
      global_params.seconds_since_registration = Math.abs(now_date.diff(registration_date, 'seconds'));
      global_params.days_since_registration = Math.abs(now_date.diff(registration_date, 'days'));
      global_params.weeks_since_registration = Math.abs(now_date.diff(registration_date, 'weeks'));
      global_params.months_since_registration = Math.abs(now_date.diff(registration_date, 'months'));

      if(school) global_params.school_name = school.title;
    }

    params = Object.assign(params, global_params);

    if(uniqueness_events.includes(event)){
      params.uniqueness_token = sha256(event + params.path + state.session_id + device_time);
    }

    if(state.debug) console.log('MixPanel', event, params);

    //Send event
    try {
      mixpanel.track(event, params)
    }catch(err){
      if(state.debug) console.log('MixPanel Error:', event, err);
    }
  }

  sendMixPanelAlias(userID, debug) {
    try {
      mixpanel.alias(userID);
      if(debug) console.log('MixPanel Alias', userID);
    }catch(err){}
  }

  sendMixPanelIdentity(userID, debug) {
    try {
      mixpanel.identify(userID);
      if(debug) console.log('MixPanel Identify User', userID);
    }catch(err){}
  }

  sendMixPanelProfile(user, school, debug) {
    const profile = {
      "User Id" : user.id,
      "School Id" : school.id,
      "School Name" : school.title,
      "Grade Level" : user.public_user.grade_level,
      "Gender" : user.public_user.gender,
      "Ethnicity" : user.public_user.ethnicity,
      "Age" : user.public_user.age,
      "Research Id" : user.pilot_hash,
    }

    try {
      mixpanel.people.set(profile);
      if(debug) console.log('MixPanel Set Profile', profile);
    }catch(err){}
  }

  sendMixPanelGroup(school, debug) {
    try {
      //Create the group if hasn't been created
      mixpanel.add_group('Institution Id', school.id);
      mixpanel.get_group('Institution Id', school.id).set({
        'Institution Id': school.id,
        'Institution Name': school.title,
      });

      //Assing the user to the group
      mixpanel.set_group('Institution Id', school.id);
      if(debug) console.log('MixPanel Group', school.id);
    }catch(err){}
  }

}

export default MixPanelService;
