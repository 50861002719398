import React, { Component } from 'react';
require('./style.scss');

class PasswordResetRequestForm extends Component {

  constructor (props) {
    super(props);
    this.state = {
      email_value: '',
      validation_message: '',
      submit_disabled: false,
    }

    //Bindings & References
    this._handleEmailChange = this._handleEmailChange.bind(this);
    this._handleSubmit = this._handleSubmit.bind(this);
  }

  _handleEmailChange(event) {
    this.setState({email_value: event.target.value});
  }

  _handleSubmit(e) {
    e.preventDefault();

    let valid = true;
    let valid_message = '';

    //Check email
    if(!this._isValidEmail(this.state.email_value)){
      valid = false;
      valid_message += 'Please use a valid email address. ';
    }

    if(valid){
      this.setState({submit_disabled: true});
      this.props.onSubmit(this.state.email_value, (error, message) => {
        if(error) this.setState({validation_message: message, submit_disabled: false});
      });
    }else{
      this.setState({validation_message: valid_message, submit_disabled: false});
    }
  }

  _isValidEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape
    return re.test(email);
  }

  _isValidPassword(password) {
    return (password.length < 8) ? false : true;
  }

  render(){
    return(
      <form onSubmit={this._handleSubmit}>
        <label htmlFor="email">your email</label><br />
        <input id="email" type="text" value={this.state.email_value} onChange={this._handleEmailChange} placeholder="email" />
        {(this.state.validation_message !== '') ? <p>{this.state.validation_message}</p> : null}
        <input type="submit" value="Send" disabled={ this.state.submit_disabled ? 'disabled' : false } />
      </form>
    );
  }
}

export default PasswordResetRequestForm;
