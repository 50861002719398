import React, { Component } from 'react';
import MyContext from '../../state/MyContext';
require('./style.scss');

class PageHeader extends Component {

  constructor(props) {
    super(props);
    this.state = {

    }

    //Bindings & References
    this.pageHeader = React.createRef();
  }

  componentDidMount() {
    if(this.props.disabled !== true) this.initiateResize();
  }

  initiateResize() {
    setTimeout(() => {
      const bg_header_color = this.props.bgHeaderColor !== undefined ? this.props.bgHeaderColor : 'tan';
      const bg_color = this.props.bgColor !== undefined ? this.props.bgColor : 'tan';
      let height = this.pageHeader.current ? this.pageHeader.current.clientHeight : 0;
      if(this.props.subhead) height += 100;
      this.context.updateBackground(height, bg_header_color, bg_color);
    }, 10);
  }

  render(){
    let classes = '';
    if(!this.props.children) classes = ' hidden';
    return (
      <div className={classes} ref={this.pageHeader} id="page-header">
        {this.props.children}
      </div>
    );
  }
}

PageHeader.contextType = MyContext;
export default PageHeader;
