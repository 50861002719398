import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import MyContext from '../../state/MyContext';
import SlickSlider from "react-slick";
import FooterUpdator from '../FooterUpdator';
import PageHeader from '../PageHeader';
//import FindYourSchoolPicklist from '../FindYourSchoolPicklist';
import 'rc-slider/assets/index.css';
//import welcome_sprite_1 from '../../assets/images/illustrations/Onboarding-Bulldogs_Can.png';
import welcome_sprite_1 from '../../assets/images/illustrations/parrot.png';
import welcome_sprite_2 from '../../assets/images/illustrations/Onboarding-wild-things-racoon.png';
import welcome_sprite_3 from '../../assets/images/illustrations/Onboarding-lightbulb.png';
require('./style.scss');

class Welcome extends Component {
  constructor(props) {
    super(props);

    this.state = {

      //Slide - Select School
      predefined_instance_check: false,

      instance_id: 'none',
      // instance_validated: false,
      // instances_hidden_revealed: false,
      // instances_hidden: [65, 67, 68, 104], //Cactus, Non-SSO, Grit, Hopelab, Demo Access
      // instances_hidden_button_click_count: 0,
    }

    //Bindings & References
    this.getStartedSlide0 = React.createRef();
    this.getStartedSlide1 = React.createRef();
    this.getStartedSlide2 = React.createRef();
    this.footerModal = React.createRef();
  }

  componentDidMount() {

    if(this.context.user_logged_in) this.props.history.push('/');

    //Check if instance is defined in the url
    if(this.props.match.params.instance_id !== undefined){

      const instance = this.context.findInstanceByName(this.props.match.params.instance_id);
      if(instance !== undefined){
        this.context.welcomeSelectInstance(instance.id).then(() => {
          return this.props.history.push('/welcome/select-school'); //get-started
        });
      }

    }else if(this.props.match.params.research_id !== undefined){

      const instance = this.context.findInstanceByName('university-of-oregon');
      if(instance !== undefined){
        this.context.welcomeSelectInstance(instance.id).then(() => {
          this.context.setState({research_id: this.props.match.params.research_id});
          return this.props.history.push('/welcome/select-school'); //get-started
        });
      }

    }else{

      //Redirects to / if the user instance has not been set
      // if(this.props.match.path !== '/' && this.context.user.school_id === undefined){
      //  this.props.history.push('/');

          //if they are logged in, on the homepage but not yet onboarded
          //}else if(this.context.user_logged_in && this.props.match.path === '/' && !this.context.user_onboarded){
          //  this.props.history.push('/onboarding/onboard');

          //If they are logged in, on the homepage and onboarded
          // }else if(this.context.user_logged_in && this.props.match.path !== '/' && this.context.user_onboarded){
          //   this.props.history.push('/');

      //Otherwise you are good to go
      //}else{
        this.setState({predefined_instance_check:true});
        this.context.updateFooter('normal', false);
      //}

    }
  }

  //Render//////////////////////////////////////////////////////////////////////
  _getGetStartedSlide() {

    const slider_settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      focusOnSelect: true,
      appendDots: dots => {
        return (
          <ul>
            {dots.map((dot, index) => {
              return (
                <li className={dot.props.className} key={index}>
                  <button aria-label={'welcome screen ' + (index+1)} onClick={dot.props.children.props.onClick}>{index+1}</button>
                </li>
              );
            })}
          </ul>
        );
      },
      beforeChange: (oldIndex, newIndex) => {
        switch(newIndex){
          default:
          case 0:
            this.getStartedSlide0.current.initiateResize();
            break;
          case 1:
            this.getStartedSlide1.current.initiateResize();
            break;
          case 2:
            this.getStartedSlide2.current.initiateResize();
            break;
        }
      },
      afterChange: (newIndex) => {
        this.setState({get_started_current_slide: newIndex});
        this.context.setState({welcome_slide_index:newIndex});
      }
    };

    // const handleSubmit = (sso = null) => { //This function needs testing when uncommented
    //   if(sso !== null){
    //     this.context.setState({sso_session:false}); //Hack relating to the branchio hack in MainWrapper.jsx
    //     this.context.ssoAssert(sso).then((result) => {
    //       switch(result.type){
    //         default:
    //         case 'history':
    //           this.props.history.push(result.url);
    //           break;
    //         case 'location':
    //           window.location.replace(result.url);
    //           break;
    //       }
    //     });
    //   }else{
    //     this.props.history.push('/signup');
    //   }
    // }

    //const instance = this.context.getUserInstance();
    const welcome_slide_0 = this.context.welcome_slide_set.slide1;//.replace('{{school_name}}', instance.title);
    const welcome_slide_1 = this.context.welcome_slide_set.slide2;//.replace('{{school_name}}', instance.title);
    const welcome_slide_2 = this.context.welcome_slide_set.slide3;//.replace('{{school_name}}', instance.title);

    //Hack to disallow scrolling
    document.querySelector('html').classList.add('noscroll'); //This is turned off in MainWrapper.jsx _historyChangeHandler()

    return (
      <div className="slide-inner">
        <Helmet>
          <title>Nod - Get Started</title>
        </Helmet>
        <SlickSlider {...slider_settings}>
          <div className="touch-slide">
            <div aria-hidden="true" className="swipe-to-continue">Swipe to continue</div>
            <PageHeader ref={this.getStartedSlide0} bgColor="gradient" bgHeaderColor="tan">
              <div dangerouslySetInnerHTML={{__html: welcome_slide_0}}></div>
            </PageHeader>
            <div className="challengeImgStatic"><img src={welcome_sprite_1} alt="" /></div>
          </div>
          <div className="touch-slide">
            <PageHeader ref={this.getStartedSlide1} bgColor="gradient" bgHeaderColor="tan" disabled={true}>
              <div dangerouslySetInnerHTML={{__html: welcome_slide_1}}></div>
            </PageHeader>
            <div className="challengeImg animated"><img src={welcome_sprite_2} alt="" /></div>
          </div>
          <div className="touch-slide">
            <PageHeader ref={this.getStartedSlide2} bgColor="gradient" bgHeaderColor="tan" disabled={true}>
              <div dangerouslySetInnerHTML={{__html: welcome_slide_2}}></div>
            </PageHeader>
            <div className="challengeImg animated"><img src={welcome_sprite_3} alt="" /></div>
          </div>
        </SlickSlider>
        <br />

        {<FooterUpdator //Was for Nod19
          showHome={false}
          showSignIn={false}
          nextText="Get Started"
          nextHandler={() => {
            //this.context.welcomeSelectInstance(process.env.REACT_APP_C19_SCHOOL_ID).then((ok) => {
              //this.props.history.push('/signup'); // /sign-up-social
              this.props.history.push('/welcome/select-school');
            //});
          }} />}
        {/*(instance.sso === null) ?
          <FooterUpdator
            showHome={false}
            backHandler={() => this.props.history.goBack() }
            showSignIn={true}
            nextText="Try It"
            nextHandler={() => { handleSubmit(); }} />
          :
          <FooterUpdator
            showHome={false}
            backHandler={() => this.props.history.goBack() }
            showSignIn={false}
            nextText={'Login with school id'}
            nextHandler={() => { handleSubmit(instance.sso); }} />
        */}

      </div>
    );
  }

  // _getSelectSchoolSlide() {
  //   return (
  //     <div className="slide-inner">
  //       <Helmet>
  //         <title>Nod - Select School</title>
  //       </Helmet>
  //       <PageHeader bgColor="tan-accent" bgHeaderColor="tan">
  //         <h1>Hey there!<br />Search and select your school to begin.</h1>
  //       </PageHeader>
  //       <h3>Find your school</h3>
  //       <FindYourSchoolPicklist ssoAssertOnConfirm={true} />
  //     </div>
  //   );
  // }

  render() {
    if(this.state.predefined_instance_check){

      //Pull in the correct content by looking at the location
      let the_content = null;
      switch(this.props.match.params.screen) {

        default:
        case 'get-started':
          the_content = this._getGetStartedSlide();
          break;

        // case 'select-school':
        //   the_content = this._getSelectSchoolSlide();
        //   break;

      }

      //Classes and Render
      let slide_classes = 'slide';
      (this.props.match.params.screen !== undefined) ? slide_classes += ' slide_'+this.props.match.params.screen : slide_classes += ' slide_select-school';
      return (
        <MyContext.Consumer>
          {(context) => (
            <div id="welcome">
              <div className={slide_classes}>
                {the_content}
              </div>
            </div>
          )}
        </MyContext.Consumer>
      );

    }else{
      return (
        <MyContext.Consumer>
          {(context) => (
            <div id="welcome"></div>
          )}
        </MyContext.Consumer>
      );
    }
  }
}

Welcome.contextType = MyContext;
export default Welcome;
