class Notifications {

  constructor() {

    //Actions are setup in the UrlRedirects component so they have access to context

    //Vars
    this.user_id = 0;
    this.debug = (process.env.REACT_APP_DEBUG === 'true') ? true : false;
    this.wait_to_refresh_time = 1000;
    this.expired_notification_removal_delay = this.debug ? (1 * 60 * 1000) : (3 * 24 * 60 * 60 * 1000); // 3 days

    //Suggested Notifications
    this.suggested_notifications = [
      {
        type: 'try-a-challenge',
        recurringInterval: this.debug ? (1 * 30 * 1000) : (7 * 24 * 60 * 60 * 1000), //1 week
        redirectRoute: '/guides',
        variations: [
          {
            title: '🧐 Looking for a reason to reach out?',
            text: 'We’ve got some ideas for you.',
            actionText: 'Try an Idea',
          },
          {
            title: '👋 Did you know?',
            text: 'Sharing your video during a virtual hangout makes it feel more connected',
            actionText: 'Check In',
          },
          {
            title: '🌻 How’s it going out there?',
            text: 'Try expressing gratitude to someone today. It’s scientifically proven to lift your mood.',
            actionText: 'Check In',
          },
          {
            title: '🙌 You’ve got this.',
            text: 'Making stronger connections takes time. We’re here if you need any suggestions.',
            actionText: 'Check In',
          },
        ]
      },
      {
        type: 'how-are-things',
        recurringInterval: this.debug ? (1 * 40 * 1000) : (29 * 24 * 60 * 60 * 1000), //1 month
        redirectRoute: '/myself/mystatus',
        variations: [
          {
            title: 'How are you liking Nod?',
            text: 'Do you have a minute to let us know what you think?',
            actionText: 'Answer 3 questions',
          },
          {
            title: 'Help us make Nod better',
            text: 'We’d love to know if Nod is helping you.',
            actionText: 'Answer 3 questions',
          },
          {
            title: 'We want to make Nod better for you!',
            text: 'Take a moment to answer three quick questions.',
            actionText: 'Give feedback',
          },
        ]
      },
      {
        type: 'try-a-reflection',
        recurringInterval: this.debug ? (1 * 50 * 1000) : (29 * 24 * 60 * 60 * 1000), //1 month
        redirectRoute: '/reflect',
        variations: [
          {
            title: 'Feel like taking a time out?',
            text: 'Try taking a reflection to gain some perspective.',
            actionText: 'Try a Reflection',
          },
        ]
      }
    ];

    this.localStorageInitialize();
    //this.areOsNotificationsEnabled();
  }

  localStorageInitialize(){

    //Remove old unused storage items if they still exist
    if(window.localStorage.getItem('@nod:notificationNudges') !== null && window.localStorage.getItem('@nod:notificationNudges') !== '')
      window.localStorage.removeItem('@nod:notificationNudges');
    if(window.localStorage.getItem('@nod:notificationSuggestions') !== null && window.localStorage.getItem('@nod:notificationSuggestions') !== '')
      window.localStorage.removeItem('@nod:notificationSuggestions');
    if(window.localStorage.getItem('@nod:notificationLastAsked') !== null && window.localStorage.getItem('@nod:notificationLastAsked') !== '')
      window.localStorage.removeItem('@nod:notificationLastAsked');
    if(window.localStorage.getItem('@nod:notificationHomepageAsked') !== null && window.localStorage.getItem('@nod:notificationHomepageAsked') !== '')
      window.localStorage.removeItem('@nod:notificationHomepageAsked');

    //Set local storage defaults if not already set

    //@nod:notificationsUserId - The user id for this set of notifications
    if(window.localStorage.getItem('@nod:notificationsUserId') === null || window.localStorage.getItem('@nod:notificationsUserId') === '')
      window.localStorage.setItem('@nod:notificationsUserId', 0);

    //@nod:notificationsScheduled - A copy of the os notifications
    if(window.localStorage.getItem('@nod:notificationsScheduled') === null || window.localStorage.getItem('@nod:notificationsScheduled') === '')
      window.localStorage.setItem('@nod:notificationsScheduled', '[]');

    //@nod:notificationsExpired - Notifications that have occured (so you can display them on the notification page)
    if(window.localStorage.getItem('@nod:notificationsExpired') === null || window.localStorage.getItem('@nod:notificationsExpired') === '')
      window.localStorage.setItem('@nod:notificationsExpired', '[]');

    //@nod:notifications - Active and Passive Notifications Enabled
    if(window.localStorage.getItem('@nod:notifications') === null || window.localStorage.getItem('@nod:notifications') === '')
      window.localStorage.setItem('@nod:notifications', false);

    //@nod:notificationTime - The time of day that notifications occur
    if(window.localStorage.getItem('@nod:notificationTime') === null || window.localStorage.getItem('@nod:notificationTime') === '')
      window.localStorage.setItem('@nod:notificationTime', '12:00');

    //@nod:notificationAskSkips - Number of times we ask the user to enable notifications and they decline
    if(window.localStorage.getItem('@nod:notificationAskSkips') === null || window.localStorage.getItem('@nod:notificationAskSkips') === '')
      window.localStorage.setItem('@nod:notificationAskSkips', 0);

  }

  //////////////////////////////////////////////////////////////////////////////
  // REFRESH////// /////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////
  //Keep track of expired/upcoming notifications to show on the "notification" screen

  refresh(user_id = null, context = null) {
    if(user_id) this.user_id = user_id;

    //Check if user id is the one saved to local storage. If not, clear notifications and save new user id.
    const local_user_id = parseInt(window.localStorage.getItem('@nod:notificationsUserId'));
    if(local_user_id !== this.user_id){
      window.localStorage.setItem('@nod:notificationsUserId', this.user_id);
      window.localStorage.setItem('@nod:notificationsScheduled', '[]');
      window.localStorage.setItem('@nod:notificationsExpired', '[]');
      this.cancelNotifications();
    }
    this.updateExpiredNotificationsInLocalStorage();
    this.updateScheduledNotificationsInLocalStorage();
  }
  refreshDelayed() { setTimeout(() => this.refresh(), this.wait_to_refresh_time); }

  updateExpiredNotificationsInLocalStorage() {
    let notificationsScheduled = window.localStorage.getItem('@nod:notificationsScheduled');
    let notificationsExpired = window.localStorage.getItem('@nod:notificationsExpired');
    if(notificationsScheduled !== null && notificationsScheduled !== ''){

      //Parse Storage
      notificationsScheduled = JSON.parse(notificationsScheduled);
      if(notificationsExpired !== null && notificationsExpired !== ''){
        notificationsExpired = JSON.parse(notificationsExpired);
      }else{
        notificationsExpired = [];
      }

      //Check scheduled localstorage for any expired events, move them to expired localstorage
      notificationsScheduled.forEach(scheduled_notification => {
        if(scheduled_notification.trigger.at < Date.now()) notificationsExpired.push(scheduled_notification);
      });

      //Remove any notifications from expired localstorage if they are X days expired and have been viewed
      const time = new Date(new Date().getTime() - this.expired_notification_removal_delay).getTime();
      notificationsExpired.forEach((expired_notification, index) => {
        if(expired_notification.trigger.at < time && expired_notification.viewed) notificationsExpired.splice(index, 1);
      });

      //Save expired localstorage
      window.localStorage.setItem('@nod:notificationsExpired', JSON.stringify(notificationsExpired));
    }
  }

  updateScheduledNotificationsInLocalStorage() {
    const notifications = window.localStorage.getItem('@nod:notifications');
    this.getAllScheduledNotifications().then(notificationList => {
      window.localStorage.setItem('@nod:notificationsScheduled', JSON.stringify(notificationList));

      //Automated Notifications
      let suggested_notifications_scheduled_array = new Array(this.suggested_notifications.length).fill(false);
      if(notifications === 'true'){

        //Find which suggested notifications are scheduled
        notificationList.forEach(notification => {
          this.suggested_notifications.forEach((suggested_notification, index) => {
            if(JSON.parse(notification.data).type === suggested_notification.type){
              suggested_notifications_scheduled_array[index] = true;
            }
          });
        });

        //Schedule them if they aren't already
        let new_notifications = false;
        suggested_notifications_scheduled_array.forEach((scheduled, index) => {
          if(!scheduled){
            let time = window.localStorage.getItem('@nod:notificationTime');
            time = time.split(':');
            time = [parseInt(time[0]), parseInt(time[1])];
            let date = new Date();
            date.setHours(time[0], time[1], 0);
            date = date.getTime() + this.suggested_notifications[index].recurringInterval;
            this.createSuggestedNotification(this.suggested_notifications[index].type, date);
            new_notifications  = true;
          }
        });

        //Resave notificationsScheduled storage if new ones were added
        if(new_notifications){
          setTimeout(() => {
            this.getAllScheduledNotifications().then(notificationList => {
              window.localStorage.setItem('@nod:notificationsScheduled', JSON.stringify(notificationList));
            });
          }, 100)
        }

      }

    });
  }


  //////////////////////////////////////////////////////////////////////////////
  //MISC////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////

  // Get all expired notifications
  getAllExpiredNotifications() {
    return new Promise((resolve, reject) => {
      let notificationsExpired = window.localStorage.getItem('@nod:notificationsExpired');
      if(notificationsExpired !== null && notificationsExpired !== ''){
        notificationsExpired = JSON.parse(notificationsExpired);
      }else{
        notificationsExpired = [];
      }
      resolve(notificationsExpired);
    });
  }

  // Get the number of expired notifications that haven't been viewed yet
  getNewNotificationCount() {
    const notificationsExpired = JSON.parse(window.localStorage.getItem('@nod:notificationsExpired'));
    let count = 0;
    if(notificationsExpired !== null) notificationsExpired.forEach(notification => { if(notification.viewed === undefined) count++; });
    return count;
  }

  // Updates the localstorage notifications to viewed
  expiredNotificationsViewed(){
    let notificationsExpired = JSON.parse(window.localStorage.getItem('@nod:notificationsExpired'));
    if(notificationsExpired !== null){
      notificationsExpired.forEach(notification => { notification.viewed = true; });
      window.localStorage.setItem('@nod:notificationsExpired', JSON.stringify(notificationsExpired));
    }
  }

  getExpiredChallengeNotifactionFromId(challengeId) {
    return new Promise((resolve, reject) => {
      this.getAllExpiredNotifications().then(notificationList => {
        const notification = notificationList.find(function(notification) {
          const data = JSON.parse(notification.data);
          return data.challengeId === challengeId;
        });
        resolve(notification);
      });
    });
  }

  //////////////////////////////////////////////////////////////////////////////
  //Notification Settings///////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////

  //Get notification setting by name
  getNotificationSetting(which){
    return window.localStorage.getItem('@nod:'+which);
  }

  //Set a notification setting value
  setNotificationSetting(which, value){

    //notifications
    if(which === 'notifications'){
      if(value){
        //bring back in challenge notifications somehow?
        this.refreshDelayed();
      }else{
        this.cancelNotifications()
      }
    }

    return window.localStorage.setItem('@nod:'+which, value);
  }

  //////////////////////////////////////////////////////////////////////////////
  // OS API/////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////

  // GET ///////////////////////////////////////////////////////////////////////

  // Responds true if user has given permission for notifications, else false
  areOsNotificationsEnabled() {
    return new Promise((resolve, reject) => {
      if(!window.cordova) return resolve(true); //return reject('Cordova not found');
      window.cordova.plugins.notification.local.hasPermission(granted => {
        resolve(granted);
      });
    });
  }

  // Request os notification enablement
  requestEnableOsNotifications() {
    return new Promise((resolve, reject) => {
      if(!window.cordova) return reject('Cordova not found');
      window.cordova.plugins.notification.local.requestPermission(function (granted) {
        resolve(granted);
      });
    });
  }

  // Get all scheduled notifications
  getAllScheduledNotifications() {
    return new Promise((resolve, reject) => {
      if(!window.cordova) return reject('Cordova not found');
      window.cordova.plugins.notification.local.getScheduled(function (notificationList) {
        resolve(notificationList);
      });
    });
  }

  // CREATE ////////////////////////////////////////////////////////////////////

  // Creates a new one-off notification
  createNotification(trigger, title, text, category, actionText, redirectRoute) {
    var mythis = this;
    return new Promise((resolve, reject) => {
      if(!window.cordova) return reject('Cordova not found');

      this.areOsNotificationsEnabled().then(granted => {
        if(granted){

          let time = window.localStorage.getItem('@nod:notificationTime');
          time = time.split(':');
          time = [parseInt(time[0]), parseInt(time[1])];
          let date = new Date();
          date.setHours(time[0], time[1], 0);
          date = date.getTime() + trigger;

          window.cordova.plugins.notification.local.schedule({
            id: Date.now() + Math.round(Math.random()*100000),
            title: title,
            text: text,
            foreground: true,
            data: {
              redirectRoute: redirectRoute,
              category: category,
              actionText: actionText
            },
            trigger: {at:date},
            group: "nod",
          });

          mythis.refreshDelayed();
        }
      });

      resolve(true);
    });
  }

  // Creates a new challenge notification with given id and options
  createChallengeNotification(challengeId, notificationOptions) {
    var mythis = this;
    return new Promise((resolve, reject) => {
      if(!window.cordova) return reject('Cordova not found');

      notificationOptions.id = Date.now() + Math.round(Math.random()*100000);

      this.areOsNotificationsEnabled().then(granted => {
        if(granted){
          notificationOptions.data = {
            challengeId: challengeId,
            redirectRoute: "/challenges/" + challengeId.toString(),
            category: 'challenge_notification',
            actionText: 'Mark it Finished'
          };
          window.cordova.plugins.notification.local.schedule({
            id: notificationOptions.id,
            title: notificationOptions.title,
            text: notificationOptions.text,
            foreground: true,
            data: notificationOptions.data,
            trigger: notificationOptions.trigger,
            group: "nod",
            actions: 'yes-no',
          });
          window.localStorage.setItem('@nod:notifications', true);
          mythis.refreshDelayed();
        }
      });

      resolve(notificationOptions.id);
    });
  }

  // Creates a new suggested notification with given category and options
  createSuggestedNotification(type, time) {
    return new Promise((resolve, reject) => {
      if(!window.cordova) return reject('Cordova not found');

      const id = Date.now() + Math.round(Math.random()*100000);
      this.areOsNotificationsEnabled().then(granted => {
        if(granted){
          const suggested_notification = this.suggested_notifications.find(suggested_notification => {return suggested_notification.type === type});
          const variation = Math.floor(Math.random() * suggested_notification.variations.length);

          const data = {
            type: type,
            redirectRoute: suggested_notification.redirectRoute,
            category: 'suggested_notification',
            actionText: suggested_notification.variations[variation].actionText,
          };
          window.cordova.plugins.notification.local.schedule({
            id: id,
            title: suggested_notification.variations[variation].title,
            text: suggested_notification.variations[variation].text,
            foreground: true,
            data: data,
            trigger: {at:time},
            group: "nod"
          });
        }
      });

      resolve(id);
    });
  }

  // DELETE ////////////////////////////////////////////////////////////////////

  // Cancels all notifications for given challenge with given id
  // Once run, this function is irreversible
  cancelChallengeNotification(challengeId) {
    var mythis = this;
    return new Promise((resolve, reject) => {
      if(!window.cordova) return reject('Cordova not found');

      window.cordova.plugins.notification.local.getScheduled(
        notificationList => {
          // filter full notification list for notifications with challengeId
          const challengeIdNotifications = notificationList.filter(
            notification =>
              JSON.parse(notification.data).challengeId === challengeId
          );

          challengeIdNotifications.forEach(notification => {
            window.cordova.plugins.notification.local.cancel(notification.id);
          });
        }
      );

      mythis.refreshDelayed();

    });
  }

  // Cancels all notifications
  // Once run, this function is irreversible
  cancelNotifications(category){
    return new Promise((resolve, reject) => {
      if(!window.cordova) return reject('Cordova not found');
      window.cordova.plugins.notification.local.getScheduled(function (notificationList) {
        notificationList.forEach(notification => {
          window.cordova.plugins.notification.local.cancel(notification.id);
        });
      });
    });
  }

  // UPDATE ////////////////////////////////////////////////////////////////////
  updateChallengeNotificationTime(challenge_id, newTime){
    var mythis = this;
    return new Promise((resolve, reject) => {
      if(!window.cordova) return reject('Cordova not found');

      mythis.getAllScheduledNotifications().then(notificationList => {
        const notification = notificationList.find(function(notification) {
          const data = JSON.parse(notification.data);
          return data.challengeId === challenge_id;
        });

        const newTrigger = { at: newTime };
        notification.trigger = newTrigger;
        window.cordova.plugins.notification.local.update(
          {id: notification.id, trigger: newTrigger}
        );
        resolve(true);
        mythis.refreshDelayed();
      });

    });
  }

  // Update trigger time for all notifications, changing only hour and minute
  updateAllNotificationTimes(newTime) {
    var mythis = this;
    return new Promise((resolve, reject) => {
      if(!window.cordova) return reject('Cordova not found');

      if(typeof newTime === 'string'){
        const newTimeSplit = newTime.split(':');
        newTime = {hour:parseInt(newTimeSplit[0]), minute:parseInt(newTimeSplit[1]) };
      }

      // Fault check for no minutes passed (assume on the hour)
      newTime.minute = newTime.minute ? newTime.minute : 0;
      window.cordova.plugins.notification.local.getScheduled(
        notificationList => {
          notificationList.forEach(notification => {
            // Fault check for no existing trigger time
            notification.trigger = notification.trigger
              ? notification.trigger
              : {};
            notification.trigger.at = notification.trigger.at
              ? notification.trigger.at
              : Date.now();
            const newTriggerTime = new Date(notification.trigger.at);
            newTriggerTime.setHours(newTime.hour, newTime.minute);

            const newTrigger = { at: newTriggerTime.getTime() };
            notification.trigger = newTrigger;

            // Update this notification
            window.cordova.plugins.notification.local.update(
              {id: notification.id, trigger: newTrigger}
            );
          });
        }
      );

      resolve(true);
      mythis.refreshDelayed();

    });
  }

}

export default Notifications;
