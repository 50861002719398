import * as PIXI from 'pixi.js';
import * as Matter from 'matter-js';
import { Howl } from 'howler';

import TurtleFlipped from './TurtleFlipped';
import TurtleNormal from './TurtleNormal';

import BaseReflection from '../../common/BaseReflection';
import Calc from '../../common/Calc';
import SvgSprite from '../../common/SvgSprite';

import groundSrc from '../images/ground.png';
import turtleFlippedSrc from '../images/turtle-flipped.png';
import turtleFlippedArmRightSrc from '../images/turtle-flipped-arm-right.png';
import turtleFlippedArmLeftSrc from '../images/turtle-flipped-arm-left.png';
import turtleFlippedLegRightSrc from '../images/turtle-flipped-leg-right.png';
import turtleFlippedLegLeftSrc from '../images/turtle-flipped-leg-left.png';
import turtleNormalSrc from '../images/turtle-normal.png';
import turtleNormalArmRightSrc from '../images/turtle-normal-arm-right.png';
import turtleNormalArmLeftSrc from '../images/turtle-normal-arm-left.png';
import turtleNormalLegRightSrc from '../images/turtle-normal-leg-right.png';
import turtleNormalLegLeftSrc from '../images/turtle-normal-leg-left.png';

import boingWebmSrc from '../sounds/boing.webm';
import boingOggSrc from '../sounds/boing.ogg';
import boingMp3Src from '../sounds/boing.mp3';
import thud0WebmSrc from '../sounds/thud-0.webm';
import thud0OggSrc from '../sounds/thud-0.ogg';
import thud0Mp3Src from '../sounds/thud-0.mp3';
import thud1WebmSrc from '../sounds/thud-1.webm';
import thud1OggSrc from '../sounds/thud-1.ogg';
import thud1Mp3Src from '../sounds/thud-1.mp3';
import thud2WebmSrc from '../sounds/thud-2.webm';
import thud2OggSrc from '../sounds/thud-2.ogg';
import thud2Mp3Src from '../sounds/thud-2.mp3';
import yayWebmSrc from '../sounds/yay.webm';
import yayOggSrc from '../sounds/yay.ogg';
import yayMp3Src from '../sounds/yay.mp3';

class FlipTheTurtleReflection extends BaseReflection {
  init(onComplete) {
    super.init(onComplete);

    this.assetPaths = [
      groundSrc,
      turtleFlippedSrc,
      turtleFlippedArmRightSrc,
      turtleFlippedArmLeftSrc,
      turtleFlippedLegRightSrc,
      turtleFlippedLegLeftSrc,
      turtleNormalSrc,
      turtleNormalArmRightSrc,
      turtleNormalArmLeftSrc,
      turtleNormalLegRightSrc,
      turtleNormalLegLeftSrc,
      boingWebmSrc,
      boingOggSrc,
      boingMp3Src,
      thud0WebmSrc,
      thud0OggSrc,
      thud0Mp3Src,
      thud1WebmSrc,
      thud1OggSrc,
      thud1Mp3Src,
      thud2WebmSrc,
      thud2OggSrc,
      thud2Mp3Src,
      yayWebmSrc,
      yayOggSrc,
      yayMp3Src
    ];

    this.groundSize = 40;
    this.settleCount = 0;
    this.settleTotal = 20;
    this.flipped = true;
    this.completeTimeout = null;

    this.addEventListeners();
    this.loadAssets();
  }

  reset(width = this.width, height = this.height) {
    super.reset(width, height);

    window.clearTimeout(this.completeTimeout);

    this.turtleFlipped = null;
    this.turtleNormal = null;
    this.settleCount = 0;
    this.flipped = true;

    this.create();
  }

  addEventListeners() {
    super.addEventListeners();
  }

  removeEventListeners() {
    super.removeEventListeners();
  }

  initPhysics() {
    super.initPhysics();

    this.engine = Matter.Engine.create();
    this.engine.world.gravity.y = 2;
    Matter.Engine.run(this.engine);

    Matter.Events.on(this.engine, 'collisionStart', e => {
      let pair = e.pairs[0];

      if (pair.collision.depth < 0.25) {
        return;
      }

      let sound = Calc.randArr(this.sounds.thud);
      let minVol = 0.1;
      let maxVol = 0.5;
      let volume = Calc.map(pair.collision.depth, 0, 10, minVol, maxVol);
      volume = Calc.clamp(volume, minVol, maxVol);
      sound.volume(volume);
      sound.play();
    });
  }

  initSounds() {
    super.initSounds();

    this.sounds.boing = new Howl({
      src: [boingWebmSrc, boingOggSrc, boingMp3Src],
      volume: 0.5
    });

    this.sounds.yay = new Howl({
      src: [yayWebmSrc, yayOggSrc, yayMp3Src],
      volume: 0.5
    });

    this.sounds.thud = [];
    for (let i = 0; i < 5; i++) {
      this.sounds.thud.push(
        new Howl({
          src: [thud0WebmSrc, thud0OggSrc, thud0Mp3Src],
          rate: Calc.map(i, 0, 4, 0.75, 1.25),
          volume: 0.5
        })
      );
    }
    for (let i = 0; i < 5; i++) {
      this.sounds.thud.push(
        new Howl({
          src: [thud1WebmSrc, thud1OggSrc, thud1Mp3Src],
          rate: Calc.map(i, 0, 4, 0.75, 1.25),
          volume: 0.5
        })
      );
    }
    for (let i = 0; i < 5; i++) {
      this.sounds.thud.push(
        new Howl({
          src: [thud2WebmSrc, thud2OggSrc, thud2Mp3Src],
          rate: Calc.map(i, 0, 4, 0.75, 1.25),
          volume: 0.5
        })
      );
    }
  }

  create() {
    super.create();

    this.createWalls();
    this.createGround();
    this.createTurtleFlipped();
    this.createTurtleNormal();
  }

  createWalls() {
    const buffer = 1000;
    const wallOptions = {
      isStatic: true,
      restitution: 0.5,
      friction: 0.5
    };

    this.wallTop = Matter.Bodies.rectangle(
      this.width / 2,
      -buffer / 2,
      this.width + buffer,
      buffer,
      wallOptions
    );
    Matter.World.add(this.engine.world, this.wallTop);

    this.wallBottom = Matter.Bodies.rectangle(
      this.width / 2,
      this.height + buffer / 2 - (this.groundSize - 1),
      this.width + buffer,
      buffer,
      wallOptions
    );
    Matter.World.add(this.engine.world, this.wallBottom);

    this.wallLeft = Matter.Bodies.rectangle(
      -buffer / 2,
      this.height / 2,
      buffer,
      this.height + buffer,
      wallOptions
    );
    Matter.World.add(this.engine.world, this.wallLeft);

    this.wallRight = Matter.Bodies.rectangle(
      this.width + buffer / 2,
      this.height / 2,
      buffer,
      this.height + buffer,
      wallOptions
    );
    Matter.World.add(this.engine.world, this.wallRight);
  }

  createGround() {
    this.groundContainer = new PIXI.Container();
    this.pixiApp.stage.addChild(this.groundContainer);
    this.groundWidth = 614;
    this.groundContainer.x = 0;
    this.groundContainer.y = this.height - this.groundSize - 20;
    let groundSpritesNeeded = Math.ceil(this.width / this.groundWidth) + 1;

    for (let i = 0; i < groundSpritesNeeded; i++) {
      let ground = new SvgSprite(
        i * this.groundWidth - i * 16 - 16,
        0,
        this.groundWidth,
        this.assets[groundSrc],
        0,
        0.5
      );
      this.groundContainer.addChild(ground.sprite);
    }
  }

  createTurtleFlipped() {
    let x = this.width / 2;
    let y = this.height * 0.6;
    let width = 150;

    this.turtleFlipped = new TurtleFlipped(
      x,
      y,
      width,
      this.assets[turtleFlippedSrc],
      this.sounds.boing,
      Calc.generateVerticesFromPolygonString(
        '662.94,152.41 661.68,134.74 616.74,106.99 562.44,107.35 565.8,94.46 509.09,30.44 451.62,7.98 357.19,0.2 244.45,16.24 174.01,31.65 125.85,53.78 101.81,78.93 67.59,82.04 58.61,56.29 41.38,35.2 22.32,31.73 5.07,39.88 0.95,53.59 22.71,51.62 32.47,67.31 39.7,97.82 54.1,126.13 75.06,138.15 81.79,173.42 115.47,265.73 138.35,308.57 173.33,325.83 212.23,339.73 255.08,344.74 359.27,345.72 432.74,326.99 460.16,307.35 467.11,280.81 484.16,263.77 501.69,255.2 519.63,261.86 539.51,249.4 570.84,245.82 584.64,218.44 628.79,201.82',
        width / this.assets[turtleFlippedSrc].texture.width
      ),
      {
        armRight: this.assets[turtleFlippedArmRightSrc],
        armLeft: this.assets[turtleFlippedArmLeftSrc],
        legRight: this.assets[turtleFlippedLegRightSrc],
        legLeft: this.assets[turtleFlippedLegLeftSrc]
      }
    );
    Matter.World.add(this.engine.world, this.turtleFlipped.body);
    this.pixiApp.stage.addChild(this.turtleFlipped.container);
  }

  createTurtleNormal() {
    let x = this.width / 2;
    let y = this.height - this.groundSize - 40;
    let width = 150;

    this.turtleNormal = new TurtleNormal(
      x,
      y,
      width,
      this.assets[turtleNormalSrc],
      {
        armRight: this.assets[turtleNormalArmRightSrc],
        armLeft: this.assets[turtleNormalArmLeftSrc],
        legRight: this.assets[turtleNormalLegRightSrc],
        legLeft: this.assets[turtleNormalLegLeftSrc]
      }
    );
    this.pixiApp.stage.addChild(this.turtleNormal.container);
  }

  onTick() {
    super.onTick();

    let time = Date.now();
    this.turtleFlipped.update(time);
    this.turtleNormal.update(time);

    let rotationCheck = Math.abs(this.turtleFlipped.body.angle % (Math.PI * 2));
    let rotationGoal = Math.PI;
    let rotationTolerance = 0.3;

    if (
      rotationCheck <= rotationGoal + rotationTolerance &&
      rotationCheck >= rotationGoal - rotationTolerance &&
      this.turtleFlipped.body.position.y >
        this.height - this.groundSize - this.turtleFlipped.sprite.height - 10
    ) {
      this.settleCount++;
      if (this.flipped && this.settleCount >= this.settleTotal) {
        this.sounds.yay.play();
        this.flipped = false;
        this.turtleFlipped.deactivate();
        this.turtleNormal.activate(this.turtleFlipped.body.position.x);
        this.progressTarget = 1;
        this.completeTimeout = window.setTimeout(() => {
          this.onComplete();
        }, 3000);
      }
    } else {
      this.settleCount = 0;
    }
  }

  destroy() {
    super.destroy();

    window.clearTimeout(this.completeTimeout);

    this.turtleFlipped = null;
    this.turtleNormal = null;
  }
}

export default FlipTheTurtleReflection;
