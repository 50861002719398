import React from 'react';

const GenderOptions = () => (
    <>
        <option value='Male'>Male</option>
        <option value='Female'>Female</option>
        <option value='Non-Binary'>Non-Binary</option>
        <option value='Other'>Other</option>
        <option value='I prefer not to respond'>I prefer not to respond</option>
    </>
);

export default GenderOptions