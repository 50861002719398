import * as PIXI from 'pixi.js';
import * as Matter from 'matter-js';

class Rope {
  constructor(parentWidth, parentHeight, collisionCategories, pinata) {
    this.parentWidth = parentWidth;
    this.parentHeight = parentHeight;
    this.collisionCategories = collisionCategories;
    this.pinata = pinata;

    this.createRope();
    this.createRenderer();
  }

  createRope() {
    this.parts = Matter.Composites.stack(
      this.parentWidth / 2,
      0,
      1,
      40,
      0,
      0,
      (x, y) => {
        return Matter.Bodies.circle(x, y, 2, {
          density: 0.1,
          collisionFilter: {
            category: this.collisionCategories.pinata,
            mask: this.collisionCategories.wall
          }
        });
      }
    );

    Matter.Composites.chain(this.parts, 0.5, 0, -0.5, 0, {
      stiffness: 1.1,
      length: 0
    });

    Matter.Composite.add(
      this.parts,
      Matter.Constraint.create({
        bodyB: this.parts.bodies[0],
        pointB: { x: 0, y: 0 },
        pointA: {
          x: this.parentWidth / 2,
          y: -10
        },
        stiffness: 1.1,
        length: 0
      })
    );

    this.constraint = Matter.Constraint.create({
      bodyA: this.parts.bodies[this.parts.bodies.length - 1],
      bodyB: this.pinata.body,
      pointA: { x: 0, y: 0 },
      pointB: { x: 0, y: -18 },
      stiffness: 1.1,
      length: 0
    });
  }

  createRenderer() {
    this.dpr = window.devicePixelRatio;
    this.canvas = document.createElement('canvas');
    this.ctx = this.canvas.getContext('2d');
    this.canvas.width = this.parentWidth * this.dpr;
    this.canvas.height = this.parentHeight * this.dpr;
    this.ctx.scale(this.dpr, this.dpr);

    this.sprite = new PIXI.Sprite.from(this.canvas);
    this.sprite.x = 0;
    this.sprite.y = 0;
    this.sprite.width = this.parentWidth;
    this.sprite.height = this.parentHeight;
    this.sprite.anchor.set(0);
  }

  render() {
    this.ctx.clearRect(0, 0, this.parentWidth, this.parentHeight);

    this.ctx.beginPath();
    let x = this.parts.bodies[this.parts.bodies.length - 1].position.x;
    let y = this.parts.bodies[this.parts.bodies.length - 1].position.y;
    this.ctx.moveTo(x, y);
    this.ctx.arc(x, y, 2, 0, Math.PI * 2);
    this.ctx.lineWidth = 2;
    this.ctx.strokeStyle = 'hsla(0, 0%, 0%, 1)';
    this.ctx.stroke();
    this.ctx.fillStyle = 'hsla(0, 0%, 100%, 1)';
    this.ctx.fill();

    this.ctx.beginPath();
    this.parts.bodies.forEach((body, i) => {
      this.ctx[i === 0 ? 'moveTo' : 'lineTo'](body.position.x, body.position.y);
    });
    this.ctx.lineWidth = 1;
    this.ctx.strokeStyle = 'hsla(0, 0%, 0%, 1)';
    this.ctx.stroke();

    this.ctx.beginPath();
    let x2 = this.parentWidth / 2;
    let y2 = 0;
    this.ctx.moveTo(x2, y2);
    this.ctx.arc(x2, y2, 16, 0, Math.PI * 2);
    this.ctx.fillStyle = 'hsla(0, 0%, 0%, 1)';
    this.ctx.fill();

    this.sprite.texture.update();
  }

  destroy() {
    this.canvas = null;
    this.ctx = null;
    this.parts = null;
    this.constraint = null;
    this.sprite = null;
  }
}

export default Rope;
