import BaseComponent from '../common/BaseComponent';
import Reflection from './scripts/AnimalJumpReflection';

class AnimalJumpComponent extends BaseComponent {
  constructor(props) {
    super(props);
    this.Reflection = Reflection;
  }
}

export default AnimalJumpComponent;
