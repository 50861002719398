import ReactGA from 'react-ga';

class GAService {

  constructor(debug) {
    this.debug = debug;
    if(process.env.REACT_APP_GOOGLE_ANALYTICS_ID) ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
  }

  sendGAPageLoad(path) {
    if(this.debug) console.log('GA Path', path);
    if(process.env.REACT_APP_GOOGLE_ANALYTICS_ID) ReactGA.pageview(path);
  }

  sendGAEvent(params) {
    if(this.debug) console.log('GA Event', params);
    if(process.env.REACT_APP_GOOGLE_ANALYTICS_ID) ReactGA.event(params);
  }

}

export default GAService;
