import React, { Component } from 'react';
import MyContext from '../../state/MyContext';
import {Helmet} from "react-helmet";
import { withRouter } from "react-router";
import FooterUpdator from '../FooterUpdator';
import PageHeader from '../PageHeader';
import SocialLoginButtons from '../SocialLoginButtons';
import login_button_email from '../../assets/images/login-buttons/email.png';
import loading_gif from '../../assets/images/loading.gif';
require('./style.scss');

class SignUpSocial extends Component {

  constructor (props) {
    super();
    this.state = {
      authenticating: false,
      terms_selected: false,
      terms_warning: false,
    }

    //Bindings & References
    this._clickTerms = this._clickTerms.bind(this);
    this._clickedWhileInactive = this._clickedWhileInactive.bind(this);
    this._clickSignUpWithEmail = this._clickSignUpWithEmail.bind(this);
  }

  componentDidMount() {
    if(this.context.user_logged_in) this.props.history.push('/');
  }

  _clickTerms(e) {
    this.setState({terms_selected: e.target.checked, terms_warning:!e.target.checked})
  }

  _clickedWhileInactive() {
    this.setState({terms_warning:true});
  }

  _clickSignUpWithEmail() {
    if(this.state.terms_selected){
      this.props.history.push('/signup');
    }else{
      this.setState({terms_warning:true});
    }
  }

  _handlePrivacyPolicyRedirect(e) {
    e.preventDefault();
    const link = 'https://heynod.com/privacy-policy';
    if(window.cordova){
      window.cordova.InAppBrowser.open(link, '_system');
    }else{
      window.open(link, "_system");
    }
  }

  _handleTermsRedirect(e) {
    e.preventDefault();
    const link = 'https://heynod.com/terms-and-conditions';
    if(window.cordova){
      window.cordova.InAppBrowser.open(link, '_system');
    }else{
      window.open(link, "_system");
    }
  }

  render(){
    document.querySelector('html').classList.remove('noscroll'); //Undo the welcome override
    return(
      <MyContext.Consumer>
        {(context) => (
          <div id="sign-up-social">
            <Helmet>
              <title>Nod - Sign Up</title>
            </Helmet>
            <div className={this.state.authenticating ? 'loading show' : 'loading'}><img src={loading_gif} alt="loading" /></div>
            <PageHeader bgColor="tan-accent" bgHeaderColor="tan">
              <h1 className="adieu">Choose a way to create your account.</h1>
            </PageHeader>
            <SocialLoginButtons
              active={this.state.terms_selected}
              clickedWhileInactive={this._clickedWhileInactive}
              onLoading={(loading) => { this.setState({authenticating:loading}); }} />
            <button className="login-email" onClick={this._clickSignUpWithEmail}><img src={login_button_email} alt="Continue with Email" /></button>
            {/*<div className="textLink">
              <a href="/" onClick={(e) => {e.preventDefault(); this._clickSignUpWithEmail(); }}>Or sign up with email.</a>
            </div>*/}
            <div className="terms-consent">
              <input type="checkbox" checked={this.state.terms_selected} name="terms-consent" id="terms-consent" onChange={(e) => this._clickTerms(e)}/>
              <label className={this.state.terms_selected ? 'active' : ''} htmlFor="terms-consent">
                I agree to <a href="/" onClick={this._handleTermsRedirect}>Terms of Use</a> and <a href="/" onClick={this._handlePrivacyPolicyRedirect}>Privacy Policy</a>.
              </label>
              { this.state.terms_warning ? <p className="terms-warning">You must agree to get started.</p>:null}
            </div>
            <p className="legal">Nod is 100% confidential. No personal data will ever be shared with any third parties for commercial purposes.</p>
            <FooterUpdator
              showHome={false}
              backHandler={() => {this.props.history.goBack()}} />
          </div>
        )}
      </MyContext.Consumer>
    );
  }
}

SignUpSocial.contextType = MyContext;
export default withRouter(SignUpSocial);
