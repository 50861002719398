import React, { Component } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { withRouter } from "react-router";
import MyContext from '../../state/MyContext';
require('./style.scss');

class Header extends Component {

  constructor(props) {
    super();
    this.state = {
      show_subnav: false
    }
  }

  componentDidUpdate() {

    if( //Show header on these pages
      this.props.location.pathname === '/myself'
      || this.props.location.pathname === '/myself/motivations'
      //|| this.props.location.pathname === '/myself/loneliness'
      || this.props.location.pathname === '/myself/mystatus'
      || this.props.location.pathname === '/myself/settings'
      || this.props.location.pathname === '/myself/notifications'
    ){
      if(!this.context.header_show) this.context.setState({header_show: true});

      if( //Show subnav on these pages
        this.props.location.pathname === '/myself'
        || this.props.location.pathname === '/myself/motivations'
        //|| this.props.location.pathname === '/myself/loneliness'
        || this.props.location.pathname === '/myself/mystatus'
      ){
        if(!this.state.show_subnav) this.setState({show_subnav:true});
      }else{
        if(this.state.show_subnav) this.setState({show_subnav:false});
      }

    }else{ //Otherwise, hide header if not hidden already
      if(this.context.header_show) this.context.setState({header_show: false, show_subnav: false});
    }
  }

  render(){
    let header_classes = '';
    let tabIndex = '-1';
    let ariaHidden = true;
    if(this.context.header_show){
      header_classes = 'visible';
      tabIndex = "0";
      ariaHidden = false;
    }

    let subnav_classes = '';
    let subnavTabIndex = '-1';
    let subnavAriaHidden = true;
    if(this.state.show_subnav && this.context.header_show){
      subnav_classes = 'visible';
      subnavTabIndex = "0";
      subnavAriaHidden = false;
    }

    const is_notifications = (this.props.location.pathname === '/myself/notifications');
    const is_settings = (this.props.location.pathname === '/myself/settings');

    return(
      <MyContext.Consumer>
        {(context) => (
          <React.Fragment>
            <header id="main-header" className={header_classes}>
              { is_notifications || is_settings ?
                  <React.Fragment>
                    <Link aria-hidden={ariaHidden} tabIndex={tabIndex} aria-label="go back" className="back" to="/myself" />
                    <ul className="tabLabel">
                      { is_notifications ? <div><h2>Viewing Notifications</h2><li><div className="notifications" />{this.context.new_notifications > 0 ? <span className="number">{this.context.new_notifications}</span> : null}</li></div> : null }
                      { is_settings ? <div><h2>Change Your Settings</h2><li><div className="profile" /></li></div> : null }
                    </ul>
                  </React.Fragment>
                :
                  <React.Fragment>
                    <h1 className="headerLabel">My Nod</h1>
                    <ul>
                      <li><NavLink aria-hidden={ariaHidden} tabIndex={tabIndex} aria-label="notifications" className="notifications" to='/myself/notifications'>{this.context.new_notifications > 0 ? <span className="number">{this.context.new_notifications}</span> : null}</NavLink></li>
                      <li><NavLink aria-hidden={ariaHidden} tabIndex={tabIndex} aria-label="profile" className="profile" to='/myself/settings'/></li>
                    </ul>
                  </React.Fragment>
              }
            </header>
            <div id="sub-nav" className={subnav_classes}>
              <ul>
                <li><NavLink aria-hidden={subnavAriaHidden} tabIndex={subnavTabIndex} role="tab" exact to='/myself'>Progress</NavLink></li>
                <li><NavLink aria-hidden={subnavAriaHidden} tabIndex={subnavTabIndex} role="tab" exact to='/myself/motivations'>Update Goals</NavLink></li>
                <li><NavLink aria-hidden={subnavAriaHidden} tabIndex={subnavTabIndex} role="tab" exact to='/myself/mystatus'>Feedback</NavLink></li>
              </ul>
            </div>
          </React.Fragment>
        )}
      </MyContext.Consumer>
    );
  }
}

Header.contextType = MyContext;
export default withRouter(Header);
