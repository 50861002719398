import BaseComponent from '../common/BaseComponent';
import Reflection from './scripts/StormySkyReflection';

class StormySkyComponent extends BaseComponent {
  constructor(props) {
    super(props);
    this.Reflection = Reflection;
  }
}

export default StormySkyComponent;
