import BaseComponent from '../common/BaseComponent';
import Reflection from './scripts/PinataReflection';

class PinataComponent extends BaseComponent {
  constructor(props) {
    super(props);
    this.Reflection = Reflection;
  }
}

export default PinataComponent;
