import * as PIXI from 'pixi.js';
import { Howl } from 'howler';
import { TweenMax, Back } from 'gsap';

import BaseReflection from '../../common/BaseReflection';
import Calc from '../../common/Calc';
import RectSprite from '../../common/RectSprite';
import SvgSprite from '../../common/SvgSprite';

import cloud0Src from '../images/cloud-0.png';
import cloud1Src from '../images/cloud-1.png';
import cloud2Src from '../images/cloud-2.png';
import cloud3Src from '../images/cloud-3.png';
import cloud4Src from '../images/cloud-4.png';
import cloud5Src from '../images/cloud-5.png';
import cloud6Src from '../images/cloud-6.png';
import grass0Src from '../images/grass-0.png';
import grass1Src from '../images/grass-1.png';
import grass2Src from '../images/grass-2.png';
import hillsSrc from '../images/hills.png';
import kangaroo0Src from '../images/kangaroo-0.png';
import kangaroo1Src from '../images/kangaroo-1.png';
import kangaroo2Src from '../images/kangaroo-2.png';
import kangarooGlassesSrc from '../images/kangaroo-glasses.png';

import cameraWebmSrc from '../sounds/camera.webm';
import cameraOggSrc from '../sounds/camera.ogg';
import cameraMp3Src from '../sounds/camera.mp3';
import jumpWebmSrc from '../sounds/jump.webm';
import jumpOggSrc from '../sounds/jump.ogg';
import jumpMp3Src from '../sounds/jump.mp3';
import launchWebmSrc from '../sounds/launch.webm';
import launchOggSrc from '../sounds/launch.ogg';
import launchMp3Src from '../sounds/launch.mp3';

class AnimalJumpReflection extends BaseReflection {
  init(onComplete) {
    super.init(onComplete);

    this.assetPaths = [
      cameraWebmSrc,
      cameraOggSrc,
      cameraMp3Src,
      jumpWebmSrc,
      jumpOggSrc,
      jumpMp3Src,
      launchWebmSrc,
      launchOggSrc,
      launchMp3Src,
      cloud0Src,
      cloud1Src,
      cloud2Src,
      cloud3Src,
      cloud4Src,
      cloud5Src,
      cloud6Src,
      grass0Src,
      grass1Src,
      grass2Src,
      hillsSrc,
      kangaroo0Src,
      kangaroo1Src,
      kangaroo2Src,
      kangarooGlassesSrc
    ];

    this.ascending = false;
    this.verticalHold = false;
    this.cameraHold = false;
    this.clouds = [];
    this.grasses = [];
    this.itemScale = 0.25;
    this.threshold = this.height - 180;
    this.canCapture = true;
    this.glassesInTimeout = null;
    this.completeTimeout = null;
    this.captureCount = 0;
    this.captureTotal = 3;

    this.addEventListeners();
    this.loadAssets();
  }

  reset(width = this.width, height = this.height) {
    super.reset(width, height);

    window.clearTimeout(this.glassesInTimeout);
    window.clearTimeout(this.completeTimeout);

    this.ascending = false;
    this.verticalHold = false;
    this.cameraHold = false;
    this.clouds.length = 0;
    this.grasses.length = 0;
    this.kangarooContainer = null;
    this.kangaroo0 = null;
    this.kangaroo1 = null;
    this.kangaroo2 = null;
    this.kangarooGlasses = null;
    this.thresholdLineContainer = null;
    this.thresholdLine = null;
    this.flashContainer = null;
    this.flash = null;
    this.threshold = this.height - 180;
    this.canCapture = true;
    this.captureCount = 0;

    this.create();
  }

  addEventListeners() {
    super.addEventListeners();

    this.onPointerDown = this.onPointerDown.bind(this);

    this.canvasWrap.addEventListener('mousedown', this.onPointerDown);
    this.canvasWrap.addEventListener('touchstart', this.onPointerDown);
  }

  removeEventListeners() {
    super.removeEventListeners();

    this.canvasWrap.removeEventListener('mousedown', this.onPointerDown);
    this.canvasWrap.removeEventListener('touchstart', this.onPointerDown);
  }

  onPointerDown() {
    if (this.kangarooContainer.getBounds().bottom - 10 < this.threshold) {
      if (this.canCapture) {
        this.captureCount++;
        this.sounds.camera.play();
        this.canCapture = false;

        this.cameraHold = true;
        this.kangarooGlasses.sprite.alpha = 1;
        this.glassesInTimeout = window.setTimeout(() => {
          if (this.captureCount < this.captureTotal) {
            let obj1 = { value: 1 };
            TweenMax.to(obj1, 0.3, {
              value: 0,
              onUpdate: () => {
                this.kangarooGlasses.sprite.y =
                  this.kangarooGlasses.y + (1 - obj1.value) * -60;
                this.kangarooGlasses.sprite.alpha = obj1.value;
              },
              onComplete: () => {
                this.kangarooGlasses.sprite.y = this.kangarooGlasses.y;
              }
            });
            this.cameraHold = false;
          } else {
            let obj2 = { value: 0 };
            let yStart = this.kangarooContainer.y;
            this.ascending = true;
            this.sounds.launch.fade(0.5, 0, 2000);
            this.sounds.launch.play();
            TweenMax.to(obj2, 1, {
              value: 1,
              ease: Back.easeIn.config(4),
              onUpdate: () => {
                this.kangarooContainer.y =
                  yStart -
                  obj2.value * (yStart + this.kangaroo2.sprite.height / 2);
              }
            });
          }
          this.cameraHold = false;
        }, 500);

        let obj3 = { value: 1 };
        TweenMax.to(obj3, 0.6, {
          value: 0,
          onUpdate: () => {
            this.flash.sprite.alpha = obj3.value;
          }
        });

        this.progressTarget = this.captureCount / this.captureTotal;

        if (this.captureCount >= this.captureTotal) {
          this.completeTimeout = window.setTimeout(() => {
            this.onComplete();
          }, 3000);
        }
      }
    }
  }

  initPhysics() {
    super.initPhysics();
  }

  initSounds() {
    super.initSounds();

    this.sounds.camera = new Howl({
      src: [cameraWebmSrc, cameraOggSrc, cameraMp3Src],
      volume: 0.5
    });

    this.sounds.jump = new Howl({
      src: [jumpWebmSrc, jumpOggSrc, jumpMp3Src],
      volume: 0.5
    });

    this.sounds.launch = new Howl({
      src: [launchWebmSrc, launchOggSrc, launchMp3Src],
      volume: 0.5
    });
  }

  create() {
    super.create();

    this.cloudContainer = new PIXI.Container();
    this.pixiApp.stage.addChild(this.cloudContainer);
    for (let i = 0; i < 6; i++) {
      this.createCloud(true);
    }

    this.grassContainer = new PIXI.Container();
    this.pixiApp.stage.addChild(this.grassContainer);
    for (let i = 0; i < 6; i++) {
      this.createGrass(true);
    }

    this.hillContainer = new PIXI.Container();
    this.pixiApp.stage.addChild(this.hillContainer);
    this.hillWidth = 500;
    this.hillContainer.x = 0;
    this.hillContainer.y = this.height - 130;
    let hillSpritesNeeded = Math.ceil(this.width / this.hillWidth) + 1;
    for (let i = 0; i < hillSpritesNeeded; i++) {
      let hill = new SvgSprite(
        i * this.hillWidth - i,
        0,
        this.hillWidth,
        this.assets[hillsSrc],
        0,
        0.5
      );
      this.hillContainer.addChild(hill.sprite);
    }

    this.thresholdLineContainer = new PIXI.Container();
    this.thresholdLineContainer.x = 0;
    this.thresholdLineContainer.y = this.threshold;
    this.thresholdLineContainer.pivot.set(0, 0.5);
    this.pixiApp.stage.addChild(this.thresholdLineContainer);

    this.dashWidth = 10;
    this.dashGap = 5;
    this.dashHeight = 1;
    this.dashCount =
      Math.ceil(this.width / (this.dashWidth + this.dashGap)) + 3;
    for (let i = 0; i < this.dashCount; i++) {
      let x =
        this.width / 2 +
        i * (this.dashWidth + this.dashGap) -
        ((this.dashWidth + this.dashGap) * this.dashCount) / 2;
      let dash = new RectSprite(
        x,
        0,
        this.dashWidth,
        this.dashHeight,
        'hsla(0, 0%, 0%, 0.5)'
      );
      this.thresholdLineContainer.addChild(dash.sprite);
    }

    this.kangarooContainer = new PIXI.Container();
    this.kangarooContainer.x = this.width / 2 - 200 * this.itemScale;
    this.kangarooContainer.y = this.height - 210 * this.itemScale;
    this.kangarooContainer.vy = 0;
    this.pixiApp.stage.addChild(this.kangarooContainer);

    this.kangaroo0 = new SvgSprite(
      0,
      0,
      this.assets[kangaroo0Src].data.width * this.itemScale,
      this.assets[kangaroo0Src]
    );
    this.kangarooContainer.addChild(this.kangaroo0.sprite);

    this.kangaroo1 = new SvgSprite(
      200 * this.itemScale,
      -40 * this.itemScale,
      this.assets[kangaroo1Src].data.width * this.itemScale,
      this.assets[kangaroo1Src]
    );
    this.kangaroo1.sprite.rotation = -0.2;
    this.kangaroo1.sprite.alpha = 0;
    this.kangarooContainer.addChild(this.kangaroo1.sprite);

    this.kangaroo2 = new SvgSprite(
      190 * this.itemScale,
      -90 * this.itemScale,
      this.assets[kangaroo2Src].data.width * this.itemScale,
      this.assets[kangaroo2Src]
    );
    this.kangaroo2.sprite.alpha = 0;
    this.kangarooContainer.addChild(this.kangaroo2.sprite);

    this.kangarooGlasses = new SvgSprite(
      430 * this.itemScale,
      -290 * this.itemScale,
      this.assets[kangarooGlassesSrc].data.width * this.itemScale,
      this.assets[kangarooGlassesSrc]
    );
    this.kangarooGlasses.sprite.alpha = 0;
    this.kangarooContainer.addChild(this.kangarooGlasses.sprite);

    this.flashContainer = new PIXI.Container();
    this.flashContainer.x = this.width / 2;
    this.flashContainer.y = this.height / 2;
    this.pixiApp.stage.addChild(this.flashContainer);

    this.flash = new RectSprite(
      0,
      0,
      this.width,
      this.height,
      'hsla(0, 0%, 100%, 1)'
    );
    this.flash.sprite.alpha = 0;
    this.flashContainer.addChild(this.flash.sprite);
  }

  createCloud(initial = false) {
    let asset = Calc.randArr([
      this.assets[cloud0Src],
      this.assets[cloud1Src],
      this.assets[cloud2Src],
      this.assets[cloud3Src],
      this.assets[cloud4Src],
      this.assets[cloud5Src],
      this.assets[cloud6Src]
    ]);

    let x = this.width + (asset.data.width * this.itemScale) / 2;
    let y = Calc.rand(this.threshold - 50);
    if (initial) {
      x = Calc.rand(this.width);
    }

    let cloud = new SvgSprite(x, y, asset.data.width * this.itemScale, asset);
    cloud.speed = Calc.rand(0.1, 0.3);
    this.clouds.push(cloud);
    this.cloudContainer.addChild(cloud.sprite);
  }

  createGrass(initial = false) {
    let asset = Calc.randArr([
      this.assets[grass0Src],
      this.assets[grass1Src],
      this.assets[grass2Src]
    ]);

    let y = Calc.rand(this.height - 100, this.height);
    let scale = Calc.map(y, this.height - 100, this.height, 1, 3);
    let width = asset.data.width * this.itemScale * scale;
    let x = this.width + width / 2;
    if (initial) {
      x = Calc.rand(this.width);
    }

    let grass = new SvgSprite(x, y, width, asset);
    grass.speed = 3 * scale;
    this.grasses.push(grass);
    this.grassContainer.addChild(grass.sprite);
  }

  onTick() {
    super.onTick();

    if (this.cameraHold) {
      this.tick--;
      return;
    }

    if (this.hillContainer.x <= -this.hillWidth) {
      this.hillContainer.x = 0;
    }
    this.hillContainer.x -= 1;

    this.thresholdLineContainer.x =
      -((this.tick % 12) * (this.dashGap + this.dashWidth)) / 12;

    if (this.tick % 360 === 0 || this.tick === 0) {
      this.createCloud();
    }

    if (this.tick % 30 === 0 || this.tick === 0) {
      this.createGrass();
    }

    let i = this.clouds.length;
    while (i--) {
      let cloud = this.clouds[i];
      cloud.sprite.x -= cloud.speed;
      if (cloud.sprite.x <= -cloud.sprite.width / 2) {
        this.cloudContainer.removeChild(cloud.sprite);
        this.clouds.splice(i, 1);
        cloud = null;
      }
    }

    let j = this.grasses.length;
    while (j--) {
      let grass = this.grasses[j];
      grass.sprite.x -= grass.speed;
      if (grass.sprite.x <= -grass.sprite.width / 2) {
        this.grassContainer.removeChild(grass.sprite);
        this.grasses.splice(j, 1);
        grass = null;
      }
    }

    if (!this.verticalHold && !this.ascending) {
      this.kangarooContainer.vy += 0.4;
      this.kangarooContainer.y += this.kangarooContainer.vy;

      if (
        this.kangarooContainer.y >=
        this.height - this.kangaroo0.sprite.height / 2
      ) {
        this.sounds.jump.play();
        this.kangarooContainer.vy = -13;
        if (this.captureCount < this.captureTotal) {
          this.canCapture = true;
        }
      }

      this.kangarooContainer.y = Calc.clamp(
        this.kangarooContainer.y,
        0,
        this.height - this.kangaroo0.sprite.height / 2
      );
    }

    if (this.kangarooContainer.vy > 8 || this.kangarooContainer.vy < -12) {
      this.kangaroo0.sprite.alpha = 1;
      this.kangaroo1.sprite.alpha = 0;
      this.kangaroo2.sprite.alpha = 0;
    } else if (this.kangarooContainer.vy > 5) {
      this.kangaroo0.sprite.alpha = 0;
      this.kangaroo1.sprite.alpha = 1;
      this.kangaroo2.sprite.alpha = 0;
    } else {
      this.kangaroo0.sprite.alpha = 0;
      this.kangaroo1.sprite.alpha = 0;
      this.kangaroo2.sprite.alpha = 1;
    }

    this.kangarooContainer.rotation = -0.01 + this.kangarooContainer.vy * 0.005;

    if (!this.verticalHold && Math.abs(this.kangarooContainer.vy) < 0.1) {
      this.verticalHold = true;
      setTimeout(() => {
        this.kangarooContainer.vy = 0.1;
        this.verticalHold = false;
      }, 75);
    }
  }

  destroy() {
    super.destroy();

    window.clearTimeout(this.glassesInTimeout);
    window.clearTimeout(this.completeTimeout);

    this.clouds.length = 0;
    this.grasses.length = 0;
    this.kangarooContainer = null;
    this.kangaroo0 = null;
    this.kangaroo1 = null;
    this.kangaroo2 = null;
    this.kangarooGlasses = null;
    this.thresholdLineContainer = null;
    this.thresholdLine = null;
    this.flashContainer = null;
    this.flash = null;
  }
}

export default AnimalJumpReflection;
