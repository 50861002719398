import KeenTracking from 'keen-tracking';
import { osName, osVersion } from 'react-device-detect';
import moment from 'moment';
import { sha256 } from 'js-sha256';
var pjson = require('../../package.json');

const uniqueness_events = ['app_flow']; //Added events here that should have the keenio uniqueness_token

class KeenIOService {

  constructor() {
    this.keen_client = new KeenTracking({
      projectId: process.env.REACT_APP_KEEN_PROJECT_ID,
      writeKey: process.env.REACT_APP_KEEN_WRITE_KEY
    });
  }

  sendKeenEvent(event, params, location_pathname, state) {

    const device_time = moment().format("YYYY-MM-DD HH:mm:ss");

    //Global Events
    let global_params = {
      device: osName+' '+osVersion,
      path: params.path ? params.path : location_pathname,
      environment: process.env.REACT_APP_KEEN_ENV,
      research_id: state.research_id,
      session_id: state.session_id,
      app_version: pjson.version,
      device_time: device_time,
      marketing_channel: state.marketing_channel
    };
    if(state.user_logged_in){
      global_params.school_id = state.user.school_id;
      global_params.user_id = state.user.id;
      global_params.research_id = state.user.pilot_hash; //This is from the user object
      global_params.grade_level = state.user.public_user.grade_level;
      global_params.gender = state.user.public_user.gender;
      global_params.ethnicity = state.user.public_user.ethnicity;
      global_params.age = state.user.public_user.age;

      const registration_date = moment(state.user.genesis);
      const seconds_from_utc = moment().utcOffset() * 60;
      const now_date = moment().utc().subtract(seconds_from_utc, 's');
      global_params.seconds_since_registration = Math.abs(now_date.diff(registration_date, 'seconds'));
      global_params.days_since_registration = Math.abs(now_date.diff(registration_date, 'days'));
      global_params.weeks_since_registration = Math.abs(now_date.diff(registration_date, 'weeks'));
      global_params.months_since_registration = Math.abs(now_date.diff(registration_date, 'months'));

      const school = state.instances.find(instance => instance.id === state.user.school_id);
      if(school) global_params.school_name = school.title;
    }
    params = Object.assign(params, global_params);

    if(uniqueness_events.includes(event)){
      params.uniqueness_token = sha256(event + params.path + state.session_id + device_time);
    }


    if(state.debug) console.log('KeenIO', event, params);

    //Send event
    try {
      this.keen_client.recordEvent(event, params).then((response) => {
        //Do nothing
      }).catch(error => {
        if(state.debug) console.log('KeenIO Error:', event, params, error);
      });
    }catch(err){}
  }

}

export default KeenIOService;
