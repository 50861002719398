import * as PIXI from 'pixi.js';
import * as Matter from 'matter-js';
import { TweenMax, Back } from 'gsap';

import Calc from '../../common/Calc';
import SvgSprite from '../../common/SvgSprite';

class Bubble extends SvgSprite {
  constructor(x, y, width, asset, xAnchor = 0.5, yAnchor = 0.5) {
    super(x, y, width, asset, xAnchor, yAnchor);

    this.sprite.alpha = 0;

    this.container = new PIXI.Container();
    this.container.scale.set(0);
    this.container.addChild(this.sprite);

    this.body = Matter.Bodies.circle(this.x, this.y, this.width * 0.5, {
      restitution: 0.5,
      friction: 0.5,
      frictionAir: Calc.rand(0.05, 0.15)
    });
  }

  activate(world, x, y) {
    Matter.Body.setPosition(this.body, { x: x, y: y });
    Matter.World.add(world, this.body);
    Matter.Body.applyForce(
      this.body,
      { x: this.body.position.x, y: this.body.position.y },
      {
        x: Calc.rand(-0.075, 0.075),
        y: -0.05
      }
    );

    this.sprite.alpha = 1;

    let obj = { value: 0 };
    TweenMax.to(obj, 0.3, {
      value: 1,
      ease: Back.easeOut.config(4),
      onUpdate: () => {
        this.sprite.alpha = obj.value;
        this.container.scale.set(obj.value);
      }
    });
  }

  burst(world) {
    let obj = { value: 1 };
    TweenMax.to(obj, 0.1, {
      value: 0,
      ease: Back.easeIn.config(2),
      onUpdate: () => {
        this.sprite.alpha = obj.value;
        this.container.scale.set(obj.value);
      },
      onComplete: () => {
        Matter.World.remove(world, this.body);
      }
    });
  }

  update() {
    this.container.x = this.body.position.x;
    this.container.y = this.body.position.y;
  }
}

export default Bubble;
