import * as Matter from 'matter-js';
import { Howl } from 'howler';

import BaseReflection from '../../common/BaseReflection';
import Calc from '../../common/Calc';

import Duck from './Duck';
import Ripple from './Ripple';
import RippleRenderer from './RippleRenderer';

import duckSrc from '../images/duck.png';
import mapSrc from '../images/map.jpg';

import completeWebmSrc from '../sounds/complete.webm';
import completeOggSrc from '../sounds/complete.ogg';
import completeMp3Src from '../sounds/complete.mp3';
import splash0WebmSrc from '../sounds/splash-0.webm';
import splash0OggSrc from '../sounds/splash-0.ogg';
import splash0Mp3Src from '../sounds/splash-0.mp3';
import splash1WebmSrc from '../sounds/splash-1.webm';
import splash1OggSrc from '../sounds/splash-1.ogg';
import splash1Mp3Src from '../sounds/splash-1.mp3';
import splash2WebmSrc from '../sounds/splash-2.webm';
import splash2OggSrc from '../sounds/splash-2.ogg';
import splash2Mp3Src from '../sounds/splash-2.mp3';

class WaterRipplesReflection extends BaseReflection {
  init(onComplete) {
    super.init(onComplete);

    this.assetPaths = [
      duckSrc,
      mapSrc,
      completeWebmSrc,
      completeOggSrc,
      completeMp3Src,
      splash0WebmSrc,
      splash0OggSrc,
      splash0Mp3Src,
      splash1WebmSrc,
      splash1OggSrc,
      splash1Mp3Src,
      splash2WebmSrc,
      splash2OggSrc,
      splash2Mp3Src
    ];

    this.ripples = [];
    this.tapCount = 0;
    this.tapTotal = 20;
    this.completeTimeout = null;
    this.rippleTimeout1 = null;
    this.rippleTimeout2 = null;

    this.addEventListeners();
    this.loadAssets();
  }

  reset(width = this.width, height = this.height) {
    super.reset(width, height);

    window.clearTimeout(this.completeTimeout);
    window.clearTimeout(this.rippleTimeout1);
    window.clearTimeout(this.rippleTimeout2);

    this.ripples.length = 0;
    this.rippleRenderer.destroy();
    this.duck = null;
    this.tapCount = 0;

    this.create();
  }

  addEventListeners() {
    super.addEventListeners();

    this.onPointerDown = this.onPointerDown.bind(this);

    this.canvasWrap.addEventListener('mousedown', this.onPointerDown);
    this.canvasWrap.addEventListener('touchstart', this.onPointerDown);
  }

  removeEventListeners() {
    super.removeEventListeners();

    this.canvasWrap.removeEventListener('mousedown', this.onPointerDown);
    this.canvasWrap.removeEventListener('touchstart', this.onPointerDown);
  }

  setDimensions(width, height) {
    super.setDimensions(width, height);
    this.diagonal = Math.sqrt(
      this.width * this.width + this.height * this.height
    );
  }

  onPointerDown(e) {
    this.tapCount++;

    if (this.tapCount <= this.tapTotal) {
      let sound = Calc.randArr(this.sounds.splash);
      sound.rate(Calc.rand(0.9, 1.1));
      sound.play();

      if (e.type === 'mousedown') {
        let x = e.clientX - this.xOffset;
        let y = e.clientY - this.yOffset;
        this.createRipple(x, y);
      } else if (e.type === 'touchstart') {
        for (let i = 0; i < e.changedTouches.length; i++) {
          let x = e.changedTouches[i].clientX - this.xOffset;
          let y = e.changedTouches[i].clientY - this.yOffset;
          this.createRipple(x, y);
        }
      }

      if (this.tapCount === this.tapTotal) {
        this.sounds.complete.play();
        this.completeTimeout = window.setTimeout(() => {
          this.onComplete();
        }, 2000);
      }
    }
  }

  initPhysics() {
    super.initPhysics();

    this.engine = Matter.Engine.create();
    this.engine.world.gravity.y = 0;
    Matter.Engine.run(this.engine);
  }

  initSounds() {
    super.initSounds();

    this.sounds.complete = new Howl({
      src: [completeWebmSrc, completeOggSrc, completeMp3Src],
      volume: 0.5
    });

    this.sounds.splash = [];
    [
      [splash0WebmSrc, splash0OggSrc, splash0Mp3Src],
      [splash1WebmSrc, splash1OggSrc, splash1Mp3Src],
      [splash2WebmSrc, splash2OggSrc, splash2Mp3Src]
    ].forEach(src => {
      this.sounds.splash.push(
        new Howl({
          src: src,
          volume: 0.5
        })
      );
    });
  }

  create() {
    super.create();

    this.createWalls();
    this.createRippleRenderer();
    this.createDuck();
  }

  createWalls() {
    const buffer = 1000;
    const wallOptions = {
      isStatic: true,
      restitution: 1,
      friction: 0,
      frictionAir: 0,
      frictionStatic: 0
    };

    this.wallTop = Matter.Bodies.rectangle(
      this.width / 2,
      -buffer / 2,
      this.width + buffer,
      buffer,
      wallOptions
    );
    Matter.World.add(this.engine.world, this.wallTop);

    this.wallBottom = Matter.Bodies.rectangle(
      this.width / 2,
      this.height + buffer / 2,
      this.width + buffer,
      buffer,
      wallOptions
    );
    Matter.World.add(this.engine.world, this.wallBottom);

    this.wallLeft = Matter.Bodies.rectangle(
      -buffer / 2,
      this.height / 2,
      buffer,
      this.height + buffer,
      wallOptions
    );
    Matter.World.add(this.engine.world, this.wallLeft);

    this.wallRight = Matter.Bodies.rectangle(
      this.width + buffer / 2,
      this.height / 2,
      buffer,
      this.height + buffer,
      wallOptions
    );
    Matter.World.add(this.engine.world, this.wallRight);
  }

  createRipple(x, y) {
    let radius = this.diagonal / 2;
    this.ripples.push(new Ripple(x, y, radius, 1));
    this.rippleTimeout1 = window.setTimeout(() => {
      this.ripples.push(new Ripple(x, y, radius / 2, 0.4));
    }, 100);
    this.rippleTimeout2 = window.setTimeout(() => {
      this.ripples.push(new Ripple(x, y, radius / 3, 0.2));
    }, 400);
  }

  createRippleRenderer() {
    this.rippleRenderer = new RippleRenderer(this.width, this.height, mapSrc);
    this.pixiApp.stage.addChild(this.rippleRenderer.sprite);
    this.pixiApp.stage.addChild(this.rippleRenderer.mapSprite);
  }

  createDuck() {
    this.duck = new Duck(
      this.width / 2,
      this.height / 2,
      100,
      this.assets[duckSrc]
    );
    this.pixiApp.stage.addChild(this.duck.container);
    Matter.World.add(this.engine.world, this.duck.body);
  }

  onTick() {
    super.onTick();

    let i = this.ripples.length;
    while (i--) {
      let ripple = this.ripples[i];
      ripple.update();
      if (ripple.done) {
        this.ripples.splice(i, 1);
        ripple = null;
      }
    }

    this.rippleRenderer.render(this.ripples);

    this.duck.update(this.width, this.height, this.ripples);

    this.progressTarget = this.tapCount / this.tapTotal;
  }

  destroy() {
    super.destroy();

    window.clearTimeout(this.completeTimeout);
    window.clearTimeout(this.rippleTimeout1);
    window.clearTimeout(this.rippleTimeout2);

    this.ripples.length = 0;
    this.ripples = null;

    this.rippleRenderer.destroy();
    this.rippleRenderer = null;

    this.duck = null;
  }
}

export default WaterRipplesReflection;
