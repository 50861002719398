import * as PIXI from 'pixi.js';
import * as Matter from 'matter-js';
import { Howl } from 'howler';
import { TweenMax } from 'gsap';

import BaseReflection from '../../common/BaseReflection';
import Calc from '../../common/Calc';
import SvgSprite from '../../common/SvgSprite';

import Monkey from './Monkey';
import Particle from './Particle';

import barrelSrc from '../images/barrel.png';
import monkeyEvenBalancedSrc from '../images/monkey-even-balanced.png';
import monkeyOddBalancedSrc from '../images/monkey-odd-balanced.png';

import cannonWebmSrc from '../sounds/cannon.webm';
import cannonOggSrc from '../sounds/cannon.ogg';
import cannonMp3Src from '../sounds/cannon.mp3';
import connectWebmSrc from '../sounds/connect.webm';
import connectOggSrc from '../sounds/connect.ogg';
import connectMp3Src from '../sounds/connect.mp3';
import winWebmSrc from '../sounds/win.webm';
import winOggSrc from '../sounds/win.ogg';
import winMp3Src from '../sounds/win.mp3';

class MonkeyInABarrelReflection extends BaseReflection {
  init(onComplete) {
    super.init(onComplete);

    this.assetPaths = [
      barrelSrc,
      monkeyEvenBalancedSrc,
      monkeyOddBalancedSrc,
      cannonWebmSrc,
      cannonOggSrc,
      cannonMp3Src,
      connectWebmSrc,
      connectOggSrc,
      connectMp3Src,
      winWebmSrc,
      winOggSrc,
      winMp3Src
    ];

    this.monkeys = [];
    this.monkeyPinnedCount = 0;
    this.monkeyPinnedTotal = 6;
    this.monkeyPinnedLast = null;
    this.monkeyScale = 0.2;

    this.particles = [];
    this.particleCount = 150;
    this.particleCurrent = 0;

    this.yHookLast = 0;
    this.yHookMove = 0;

    this.completeTimeout = null;
    this.done = false;

    this.addEventListeners();
    this.loadAssets();
  }

  reset(width = this.width, height = this.height) {
    super.reset(width, height);

    window.clearTimeout(this.completeTimeout);

    this.monkeys.length = 0;
    this.monkeyPinnedCount = 0;
    this.monkeyPinnedLast = null;
    this.particles.length = 0;
    this.particleCurrent = 0;
    this.done = false;

    this.create();
  }

  addEventListeners() {
    super.addEventListeners();

    this.onPointerDown = this.onPointerDown.bind(this);

    this.canvasWrap.addEventListener('mousedown', this.onPointerDown);
    this.canvasWrap.addEventListener('touchstart', this.onPointerDown);
  }

  removeEventListeners() {
    super.removeEventListeners();

    this.canvasWrap.removeEventListener('mousedown', this.onPointerDown);
    this.canvasWrap.removeEventListener('touchstart', this.onPointerDown);
  }

  onPointerDown() {
    if (this.monkeyPinnedCount < this.monkeyPinnedTotal + 1) {
      this.sounds.cannon.rate(Calc.rand(0.8, 1));
      this.sounds.cannon.play();
      this.createMonkey();
    }
  }

  initPhysics() {
    super.initPhysics();

    this.engine = Matter.Engine.create({
      positionIterations: 6,
      velocityIterations: 6,
      constraintIterations: 10
    });
    this.engine.world.gravity.y = 2;
    Matter.Engine.run(this.engine);
    this.noCollideGroup = Matter.Body.nextGroup(true);

    Matter.Events.on(this.engine, 'collisionStart', e => {
      let pair = e.pairs[0];
      let bodyA = pair.bodyA;
      let bodyB = pair.bodyB;
      if (bodyA.id === this.monkeyPinnedLast.body.id) {
        if (bodyA.label === 'monkey') {
          let monkey = this.monkeys.find(monkey => {
            return monkey.body.id === bodyB.id;
          });
          this.pinMonkey(monkey);
        }
        if (bodyB.label === 'monkey') {
          let monkey = this.monkeys.find(monkey => {
            return monkey.body.id === bodyB.id;
          });
          this.pinMonkey(monkey);
        }
      }
    });
  }

  initSounds() {
    super.initSounds();

    this.sounds.cannon = new Howl({
      src: [cannonWebmSrc, cannonOggSrc, cannonMp3Src],
      volume: 0.6
    });

    this.sounds.connect = new Howl({
      src: [connectWebmSrc, connectOggSrc, connectMp3Src],
      volume: 0.2
    });

    this.sounds.win = new Howl({
      src: [winWebmSrc, winOggSrc, winMp3Src],
      volume: 0.5
    });
  }

  create() {
    super.create();

    this.createMonkeyContainer();
    this.createBarrel();
    this.createParticles();
    this.createMonkey();
    this.createHook();
  }

  createMonkeyContainer() {
    this.monkeyContainer = new PIXI.Container();
    this.pixiApp.stage.addChild(this.monkeyContainer);
  }

  createBarrel() {
    let width = 60;
    let x = this.width / 2;
    let y = this.height;
    this.barrel = new SvgSprite(x, y, width, this.assets[barrelSrc], 0.5, 1);
    this.pixiApp.stage.addChild(this.barrel.sprite);
  }

  createParticles() {
    for (let i = 0; i < this.particleCount; i++) {
      let width = Calc.rand(3, 12);
      let height = Calc.rand(3, 12);
      let particle = new Particle(
        -100,
        -100,
        width,
        height,
        `hsla(${Calc.rand(0, 360)}, 95%, 60%, 1)`,
        0.5,
        0.5,
        Calc.rand(-Math.PI + 0.5, -0.5),
        Calc.rand(0, 20)
      );
      this.pixiApp.stage.addChild(particle.container);
      this.particles.push(particle);
    }
  }

  createMonkey() {
    let asset =
      this.monkeyPinnedCount % 2 === 0
        ? this.assets[monkeyEvenBalancedSrc]
        : this.assets[monkeyOddBalancedSrc];
    let width = asset.data.width * this.monkeyScale;
    let x = this.barrel.sprite.x;
    let y = this.barrel.sprite.y - 100;
    let monkey = new Monkey(x, y, width, asset, 0.5, 0.5, this.noCollideGroup);
    this.monkeys.push(monkey);
    Matter.World.add(this.engine.world, monkey.body);
    this.monkeyContainer.addChild(monkey.sprite);

    if (this.monkeyPinnedCount === 0) {
      Matter.Body.setVelocity(monkey.body, { x: 0, y: 0 });
      Matter.Body.setAngularVelocity(monkey.body, 0);
    } else {
      Matter.Body.applyForce(
        monkey.body,
        { x: monkey.body.position.x, y: monkey.body.position.y },
        {
          x: 0,
          y: -0.00015 * this.height * monkey.body.mass
        }
      );
    }
  }

  createHook() {
    this.monkeyPinnedCount++;
    this.hook = Matter.Constraint.create({
      pointA: { x: this.width / 2, y: 0 },
      bodyB: this.monkeys[0].body,
      pointB: {
        x: 0,
        y: -this.monkeys[0].sprite.height / 2
      },
      stiffness: 1.1,
      length: 0
    });
    Matter.World.add(this.engine.world, this.hook);
    this.monkeyPinnedLast = this.monkeys[0];
    this.yHookLast = this.hook.pointA.y;
  }

  pinMonkey(monkey) {
    this.monkeyPinnedCount++;

    if (this.monkeyPinnedCount > 1) {
      this.sounds.connect.rate(
        Calc.map(
          (this.monkeyPinnedCount - 1) / this.monkeyPinnedTotal,
          0,
          1,
          0.75,
          1.25
        )
      );
      this.sounds.connect.play();
    }

    monkey.body.collisionFilter.group = this.noCollideGroup;
    monkey.body.label = 'monkeyPinned';

    let constraint = Matter.Constraint.create({
      bodyA: this.monkeyPinnedLast.body,
      pointA: {
        x: 0,
        y: this.monkeyPinnedLast.sprite.height / 2 - 20 * this.monkeyScale
      },
      bodyB: monkey.body,
      pointB: {
        x: 0,
        y: -monkey.sprite.height / 2 + 20 * this.monkeyScale
      },
      stiffness: 1.1,
      length: 0
    });
    Matter.World.add(this.engine.world, constraint);
    this.monkeyPinnedLast = monkey;

    if (this.monkeyPinnedCount > 2) {
      if (this.monkeyPinnedCount % 2 === 0) {
        this.yHookMove =
          this.assets[monkeyOddBalancedSrc].data.height * this.monkeyScale -
          20 * this.monkeyScale;
      } else {
        this.yHookMove =
          this.assets[monkeyEvenBalancedSrc].data.height * this.monkeyScale -
          20 * this.monkeyScale;
      }

      if (this.monkeyPinnedCount === this.monkeyPinnedTotal + 1) {
        this.yHookMove =
          this.assets[monkeyOddBalancedSrc].data.height * this.monkeyScale +
          this.assets[monkeyOddBalancedSrc].data.height * this.monkeyScale +
          this.assets[monkeyEvenBalancedSrc].data.height * this.monkeyScale;
      }

      let obj = { value: 0 };
      if (this.hookTween && this.hookTween.progress() < 1) {
        this.hookTween.totalProgress(1);
        this.hookTween.vars.onUpdate();
      }
      this.hookTween = TweenMax.to(obj, 0.3, {
        value: 1,
        onUpdate: () => {
          this.hook.pointA.y = this.yHookLast - this.yHookMove * obj.value;
          this.monkeys.forEach(monkey => {
            if (monkey.body.label === 'monkeyPinned') {
              Matter.Body.setVelocity(monkey.body, { x: 0, y: 0 });
              Matter.Body.setAngularVelocity(monkey.body, 0);
            }
          });
        },
        onComplete: () => {
          this.yHookLast = this.hook.pointA.y;
        }
      });
    }

    this.progressTarget = (this.monkeyPinnedCount - 1) / this.monkeyPinnedTotal;

    if ((this.monkeyPinnedCount - 1) / this.monkeyPinnedTotal >= 1) {
      this.done = true;
      this.sounds.win.play();
      this.completeTimeout = window.setTimeout(() => {
        this.onComplete();
      }, 3000);
    }
  }

  onTick() {
    super.onTick();

    if (this.done && this.particleCurrent < this.particleCount) {
      for (let i = 0; i < 5; i++) {
        this.particles[this.particleCurrent].activate(
          this.barrel.sprite.x +
            Calc.rand(
              -this.barrel.sprite.width / 2,
              this.barrel.sprite.width / 2
            ),
          this.barrel.sprite.y - this.barrel.sprite.height
        );
      }
      this.particleCurrent++;
    }

    let time = Date.now();
    for (let i = 0, len = this.particles.length; i < len; i++) {
      this.particles[i].update(time);
    }

    this.barrel.sprite.x =
      this.width / 2 +
      Math.sin(Date.now() * 0.003) * (this.width / 2 - this.barrel.width / 2);

    let i = this.monkeys.length;
    while (i--) {
      let monkey = this.monkeys[i];
      monkey.update();
      if (
        monkey.body.velocity.y > 0 &&
        monkey.body.position.y > this.height + monkey.sprite.height / 2
      ) {
        Matter.World.remove(this.engine.world, monkey.body);
        this.monkeyContainer.removeChild(monkey.sprite);
        this.monkeys.splice(i, 1);
        monkey = null;
      }
    }
  }

  destroy() {
    super.destroy();

    window.clearTimeout(this.completeTimeout);

    this.monkeys.length = 0;
    this.monkeys = null;

    this.particles.length = 0;
    this.particles = null;
  }
}

export default MonkeyInABarrelReflection;
