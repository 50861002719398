class Session {

  constructor(sessionKey = 'sessionId', refreshKey = 'sessionRefreshId', expiration = 'sessionExpiration') {
    this.key = sessionKey;
    this.refreshKey = refreshKey;
    this.expiration = expiration;
  }

  save(v){
    const disk = localStorage;
    if (disk) {
      disk.setItem(`@nod:${this.key}`, JSON.stringify(v));
    }
  }
  saveRefresh(v){
    const disk = localStorage;
    if (disk) {
      disk.setItem(`@nod:${this.refreshKey}`, JSON.stringify(v));
    }
  }
  saveExpiration(v){
    const disk = localStorage;
    if (disk) {
      disk.setItem(`@nod:${this.expiration}`, JSON.stringify(v));
    }
  }

  clear() {
    this.save(null);
    this.saveRefresh(null);
    this.saveExpiration(null);
  }

  get(){
    return this.sessionId || this.load();
  }
  getRefresh(){
    return this.sessionRefreshId || this.loadRefresh();
  }
  getExpiration(){
    return this.sessionExpiration || this.loadExpiration();
  }

  load(){
    let data = null;
    const disk = localStorage;
    if (disk) {
      try {
        this.sessionId = data = JSON.parse(disk.getItem(`@nod:${this.key}`));
      } catch (e) {

      }
    }
    return data;
  }
  loadRefresh(){
    let data = null;
    const disk = localStorage;
    if (disk) {
      try {
        this.sessionRefreshId = data = JSON.parse(disk.getItem(`@nod:${this.refreshKey}`));
      } catch (e) {

      }
    }
    return data;
  }
  loadExpiration(){
    let data = null;
    const disk = localStorage;
    if (disk) {
      try {
        this.sessionExpiration = data = JSON.parse(disk.getItem(`@nod:${this.expiration}`));
      } catch (e) {

      }
    }
    return data;
  }
}

export default Session;
