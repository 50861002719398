import * as PIXI from 'pixi.js';
import * as Matter from 'matter-js';
import PolyDecomp from 'poly-decomp';

import Calc from '../../common/Calc';

window.decomp = PolyDecomp;

class Pinata {
  constructor(
    x,
    y,
    width,
    asset0,
    asset1,
    asset2,
    sounds,
    collisionVertices,
    xAnchor,
    yAnchor,
    collisionCategories,
    hitCallback,
    hitCount
  ) {
    this.x = x;
    this.y = y;
    this.width = width;
    this.asset0 = asset0;
    this.asset1 = asset1;
    this.asset2 = asset2;
    this.sounds = sounds;
    this.collisionVertices = collisionVertices;
    this.xAnchor = xAnchor;
    this.yAnchor = yAnchor;
    this.collisionCategories = collisionCategories;
    this.hitCallback = hitCallback;
    this.hitCount = hitCount;

    this.dpr = window.devicePixelRatio;
    this.srcWidth = this.asset0.texture.width;
    this.srcHeight = this.asset0.texture.height;
    this.srcRatio = this.srcWidth / this.srcHeight;
    this.destWidth = this.width;
    this.destHeight = this.width / this.srcRatio;

    this.body = Matter.Bodies.fromVertices(
      this.x,
      this.y,
      this.collisionVertices,
      {
        restitution: 0.5,
        friction: 0.5,
        density: 0.001,
        collisionFilter: {
          category: this.collisionCategories.pinata,
          mask: this.collisionCategories.wall
        }
      }
    );

    this.canvases = [];
    this.textures = [];
    [this.asset0, this.asset1, this.asset2].forEach(asset => {
      let canvas = document.createElement('canvas');
      let ctx = canvas.getContext('2d');
      canvas.width = this.destWidth * this.dpr;
      canvas.height = this.destHeight * this.dpr;
      ctx.scale(this.dpr, this.dpr);
      ctx.drawImage(asset.data, 0, 0, this.destWidth, this.destHeight);
      this.canvases.push({
        canvas: canvas,
        ctx: ctx
      });
      this.textures.push(new PIXI.Texture.from(canvas));
    });

    this.sprite = new PIXI.Sprite(this.textures[0]);
    this.sprite.x = this.x;
    this.sprite.y = this.y;
    this.sprite.width = this.destWidth;
    this.sprite.height = this.destHeight;
    this.sprite.anchor.set(this.xAnchor, this.yAnchor);
    this.sprite.interactive = true;
    this.sprite.hitArea = new PIXI.Rectangle(
      -this.xAnchor * this.sprite.width * 2 - 50,
      -this.yAnchor * this.sprite.height * 2 - 50,
      this.sprite.width * 2 + 100,
      this.sprite.height * 2 + 100
    );

    this.sprite.on('pointerdown', this.onPointerDown.bind(this));

    this.hits = 0;
    this.done = false;

    this.canvases.forEach(obj => {
      obj.canvas = null;
      obj.ctx = null;
    });
  }

  onPointerDown(e) {
    if (this.done) {
      return;
    }

    this.hits++;

    if (this.hits > 6) {
      this.sprite.texture = this.textures[2];
    } else if (this.hits > 2) {
      this.sprite.texture = this.textures[1];
    }

    let sound = Calc.randArr(this.sounds);
    sound.rate(Calc.rand(0.9, 1.1));
    sound.play();

    let angle = Calc.rand(0, Math.PI * 2);
    let force = 0.2 * this.body.mass;
    let xForce = Math.cos(angle) * force;
    let yForce = Math.sin(angle) * force;

    Matter.Body.applyForce(
      this.body,
      { x: e.data.global.x, y: e.data.global.y },
      {
        x: xForce,
        y: yForce
      }
    );

    if (this.hits === this.hitCount) {
      this.done = true;
    }

    this.hitCallback(this.done, this.hits);
  }

  destroy() {
    this.body = null;
    this.sprite = null;
  }
}

export default Pinata;
