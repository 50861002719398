import * as PIXI from 'pixi.js';

import Calc from '../../common/Calc';
import Ease from '../../common/Ease';
import SvgSprite from '../../common/SvgSprite';

class Star extends SvgSprite {
  constructor(x, y, width, asset, xAnchor = 0.5, yAnchor = 0.5, angle) {
    super(x, y, width, asset, xAnchor, yAnchor);

    this.angle = angle;

    this.container = new PIXI.Container();
    this.container.x = x;
    this.container.y = y;
    this.container.rotation = this.angle + Math.PI / 2;
    this.container.addChild(this.sprite);

    this.sprite.x = 0;
    this.sprite.y = 0;
    this.sprite.alpha = 0;

    this.active = false;
    this.progress = 0;
    this.progressRate = 0.025;
    this.magnitude = this.sprite.width * 2;
  }

  activate() {
    this.container.x = this.x;
    this.container.y = this.y;
    this.active = true;
    this.sprite.alpha = 1;
    this.progress = 0;
  }

  update() {
    if (!this.active) {
      return;
    }

    this.progress += this.progressRate;
    this.progressEased = Ease.outExpo(this.progress, 0, 1, 1);

    this.container.x =
      this.x + Math.cos(this.angle) * (this.magnitude * this.progressEased);
    this.container.y =
      this.y + Math.sin(this.angle) * (this.magnitude * this.progressEased);
    this.sprite.alpha = Calc.clamp(2 - this.progressEased * 2, 0, 1);
    this.container.scale.set(Calc.clamp(2 - this.progressEased * 2, 0, 1));

    if (this.progress >= 1) {
      this.active = false;
      this.sprite.alpha = 0;
    }
  }
}

export default Star;
