import React, { Component } from 'react';
require('./style.scss');

export const STATE_UNTRIED = 'untried';
export const STATE_ACTIVE = 'active';
export const STATE_CLOSE = 'close';
export const STATE_COMPLETED = 'completed';

class ChallengeDots extends Component {
  render(){
    return (
      <div className={'challenge-dots '+this.props.theme}>
        <ul aria-hidden="true">
        {
          this.props.dotValues.map((state, index) => {
            return <li key={index} className={state} />;
          })
        }
        </ul>
      </div>
    );
  }
}

export default ChallengeDots;
