import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import MyContext from '../../state/MyContext';
import SlickSlider from "react-slick";
import PageHeader from '../PageHeader';
import FooterUpdator from '../FooterUpdator';
import FooterModal from '../FooterModal';
import ReflectionCard from '../ReflectionCard';
import MoodMeter, {getRegion} from '../MoodMeter';
import ReflectionSkillCard from '../ReflectionSkillCard';
import A11yVideoAudioPlayer from '../A11yVideoAudioPlayer'

import AudioPlayerComponent from '../../components_reflections/audio_player/AudioPlayerComponent';
import AnimalBreathingComponent from '../../components_reflections/animal_breathing/AnimalBreathingComponent';
import AnimalJumpComponent from '../../components_reflections/animal_jump/AnimalJumpComponent';
import BlowBubblesComponent from '../../components_reflections/blow_bubbles/BlowBubblesComponent';
import FlipTheTurtleComponent from '../../components_reflections/flip_the_turtle/FlipTheTurtleComponent';
import HeartFillingComponent from '../../components_reflections/heart_filling/HeartFillingComponent';
import MonkeyInABarrelComponent from '../../components_reflections/monkey_in_a_barrel/MonkeyInABarrelComponent';
import PinataComponent from '../../components_reflections/pinata/PinataComponent';
import SquirrelZoomComponent from '../../components_reflections/squirrel_zoom/SquirrelZoomComponent';
import StormySkyComponent from '../../components_reflections/stormy_sky/StormySkyComponent';
import TurtleFistBumpComponent from '../../components_reflections/turtle_fist_bump/TurtleFistBumpComponent';
import WaterRipplesComponent from '../../components_reflections/water_ripples/WaterRipplesComponent';
import WhackAMoleComponent from '../../components_reflections/whack_a_mole/WhackAMoleComponent';

import wild_thing_mole_img from '../../assets/images/illustrations/wild-thing-mole.png';

//import captions_src from '../../assets/captions-en.vtt';
//import video_src from '../../assets/video.mp4';

require('./style.scss');

class Reflection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      reflection_index: 0,
      skip_footermodal_visible: false,

      mood_changed: false,
      mood_balance: 'neutral',
      mood_coords: [5,5],
      mood_position: [0.5, 0.5],
      mood_coord_label: '',

      reflections_slider_classes: '',
      reflections_slide: 0,
      go_back_slide: false,
      back_forward_transition_occuring: false,

      audio_interaction_played: false,

      //reflect_start_time: 0,
      reflect_duration: 0,

      interaction_alive: false,
      interaction_can_render: false,
      interaction_width: 0,
      interaction_height: 0,
      interaction_captions_on: false,
      interaction_transcripts_on: false,

      interaction_footer_modal_instructions_visible: true,
      interactive_footer_modal_instructions_slide: 0,

      notify_button_disabled:false,

    }
    this.came_from_challenge_id = null;
    this.reflect_filtered_group_slice = [];

    //Bindings & References
    this.footerModal = React.createRef();
    this.footerModalInstructions = React.createRef();
    this.interactiveWrap = React.createRef();
    this.interactiveHeader = React.createRef();
    this.transcriptsContainer = React.createRef();
  }

  componentDidMount() {

    //Redirects if properties aren't set
    if(
      (this.props.match.url === '/reflect/choices' && this.context.reflect_mood_balance === null)
      || (this.props.match.url === '/reflect/interact' && this.context.reflect_current === null)
      || (this.props.match.url === '/reflect/interact-response' && this.context.reflect_option === null)
    ){
      this.props.history.push('/reflect/mood');
    }else{

      let subtitles = window.localStorage.getItem('@nod:subtitles');
      if(subtitles === 'false') subtitles = false;
      if(subtitles === null) subtitles = true;

      this.setState({
        ready:true,
        mood_balance: 'neutral', //this.context.reflect_mood_balance,
        mood_coords: [5,5], //this.context.reflect_mood_coords,
        mood_position: [0.5, 0.5], //this.context.reflect_mood_position
        interaction_captions_on: subtitles
      });
    }
  }

  componentDidUpdate() {
    if(this.props.match.params.screen === 'interact'){
      if(this._canRenderInteraction() && !this.state.interaction_alive){
        setTimeout( () => {
          this.setState({
            interaction_alive: true,
            interaction_can_render: true,
            interaction_width: this.interactiveWrap.current.clientWidth,
            interaction_height: window.innerHeight - this.interactiveHeader.current.offsetHeight - 115
          });
        }, 100);
      }
    }
  }

  _canRenderInteraction() {
    if(this.interactiveWrap.current){
      return (this.context.background_header_height > 0 && this.interactiveWrap.current.clientWidth > 0) ? true : false;
    }else{
      return false;
    }
  }

  _getMoodSlide() {

    const handleSubmit = () => {
      this.context.reflectSetMood(
        this.state.mood_changed,
        this.state.mood_balance,
        this.state.mood_coords,
        this.state.mood_position,
        this.state.mood_coord_label
      ).then(() => {

        let guide_title = null;
        let challenge_id = null;
        let challenge_title = null;
        let last_challenge_rating = null;
        if(this.context.user_challenge_just_completed){
          const challenge = this.context.challenges.find((thechallenge)  => { return thechallenge.id === this.context.reflect_came_from_challenge_id; });
          challenge_id = challenge.id;
          challenge_title = challenge.title;
          const guide = this.context.guides_all.find((theguide) => { return theguide.id === challenge.guide_id; });
          guide_title = guide.title;
          last_challenge_rating = this.context.relfect_last_challenge_rating;
        }
        //KeenIO
        this.context.sendKeenEvent('mood', {
          skip: false,
          guide_title: guide_title,
          challenge_id: challenge_id,
          challenge_title: challenge_title,
          mood_coords_x: this.state.mood_coords[0],
          mood_coords_y: this.state.mood_coords[1],
          mood_balance: this.state.mood_balance,
          mood_label: this.state.mood_coord_label,
          mood_changed: this.state.mood_changed,
          rating: last_challenge_rating,
          mood_meter_on_homepage: false
        });
        //MixPanel
        this.context.sendMixPanelEvent('mood', {
          skip: false,
          guide_title: guide_title,
          challenge_id: challenge_id,
          challenge_title: challenge_title,
          mood_coords_x: this.state.mood_coords[0],
          mood_coords_y: this.state.mood_coords[1],
          mood_balance: this.state.mood_balance,
          mood_label: this.state.mood_coord_label,
          mood_changed: this.state.mood_changed,
          rating: last_challenge_rating,
          mood_meter_on_homepage: false
        });

        setTimeout(() => this.props.history.push('/reflect/skills'), 100);
      });
    };

    const handleSkip = () => {
      this.context.reflectSetMood(
        false, //changed
        'neutral', //balance
        [5,5], //coords
        [0.5, 0.5], //position
        '' //label
      ).then(() => {

        let guide_title = null;
        let challenge_id = null;
        let challenge_title = null;
        let last_challenge_rating = null;
        if(this.context.user_challenge_just_completed){
          const challenge = this.context.challenges.find((thechallenge)  => { return thechallenge.id === this.context.reflect_came_from_challenge_id; });
          challenge_id = challenge.id;
          challenge_title = challenge.title;
          const guide = this.context.guides_all.find((theguide) => { return theguide.id === challenge.guide_id; });
          guide_title = guide.title;
          last_challenge_rating = this.context.relfect_last_challenge_rating;
        }

        //KeenIO
        this.context.sendKeenEvent('mood', {
          skip: true,
          guide_title: guide_title,
          challenge_id: challenge_id,
          challenge_title: challenge_title,
          mood_coords_x: 5,
          mood_coords_y: 5,
          mood_balance: 'neutral',
          mood_label: '',
          mood_changed: this.state.mood_changed,
          rating: last_challenge_rating,
          mood_meter_on_homepage: false,
        });

        //MixPanel
        this.context.sendMixPanelEvent('mood', {
          skip: true,
          guide_title: guide_title,
          challenge_id: challenge_id,
          challenge_title: challenge_title,
          mood_coords_x: 5,
          mood_coords_y: 5,
          mood_balance: 'neutral',
          mood_label: '',
          mood_changed: this.state.mood_changed,
          rating: last_challenge_rating,
          mood_meter_on_homepage: false,
        });

        setTimeout(() => this.props.history.push('/reflect/skills'), 100);
      });
    }

    const handleChange = (mood_balance, mood_coords, mood_position, mood_coord_label) => {
      this.setState({
        mood_changed:true,
        mood_balance:mood_balance,
        mood_coords:mood_coords,
        mood_position:mood_position,
        mood_coord_label: mood_coord_label
      });
    }

    const hiddenButtonClick = (e) => {
      e.preventDefault();
      this.props.history.push('/reflect/choices/all');
    }

    return(
      <div id="slide-inner" className="slide-inner">
        <Helmet>
          <title>Nod - Reflect - Mood</title>
        </Helmet>
        <PageHeader bgColor="blue" bgHeaderColor="tan">
          {this.context.user_challenge_just_completed ?
            <p className="instructions">Time to check in</p>
          :
            <p className="instructions">Check in with your social self</p>
          }
          {this.context.user_challenge_just_completed ?
            <h1>How did you feel about the interaction afterward?</h1>
          :
            <h1>Think about a social encounter, past or future.</h1>
          }
        </PageHeader>
        <a aria-hidden="true" tabIndex="0" href="/" className="hidden-button" onClick={hiddenButtonClick}>&nbsp;</a>
        {/*<span className="instructions">How do you feel about it?</span>*/}
        <MoodMeter
          initialPosition={this.state.mood_position}
          a11yLabel={this.context.user_challenge_just_completed ? 'How do you feel about it?' : 'What is your current vibe?'}
          onChange={(mood_balance, mood_coords, mood_position, mood_coord_label) => {
            handleChange(mood_balance, mood_coords, mood_position, mood_coord_label);
          }} />
        <FooterModal visible={false} parentId="slide-inner" ignoreFooterInert={true}>
          <h1>Tell us how you're feeling</h1>
          <p>Use the mood tracker on the next screen to tell us how you feel.</p>
        </FooterModal>
        <FooterUpdator
          showHome={true}
          backHandler={() => this.props.history.goBack() }
          skipHandler={handleSkip}
          nextText="Set Mood"
          nextHandler={() => handleSubmit()}/>
      </div>
    );
  }

  _getSkillsSlide() {

    // const handleSkip = () => {
    //   this.props.history.push('/reflect');
    // }

    const region = getRegion(this.context.reflect_mood_position[0], this.context.reflect_mood_position[1]);

    let skills = [];
    this.context.reflect_filtered_group.forEach(reflection => {
      if(reflection.reflection_skill){
        if(!skills.some(skill => skill.id === reflection.reflection_skill.id)) skills.push(reflection.reflection_skill);
      }
    });

    return(
      <div>
        <Helmet>
          <title>Nod - Reflect - Skills</title>
        </Helmet>
        <PageHeader bgColor="blue">
          <p>Take time to check in</p>
          <h1>What would you like to focus on?</h1>
        </PageHeader>

        {this.context.reflect_mood_changed ?
          <div className="mood-status">
            <span>For when you're feeling</span>
            <div className={'circle region-'+region}></div>
            <span className="label">{this.context.reflect_mood_coord_label}</span>
            <button aria-label="go back" className="back" onClick={() => this.props.history.goBack()} />
          </div>
        :null
        }

        {
          skills.map((skill, index) => {
            return <ReflectionSkillCard
                      key={index}
                      imageUrl={skill.image_url}
                      title={skill.title}
                      linkUrl={'/reflect/choices/'+skill.slug}
                      onClick={() => this.context.setState({reflect_skill_choice:skill.slug}) } />
          })
        }

        {/*<button onClick={() => this.props.history.push('/reflect/choices')}>Next</button>*/}

        <FooterUpdator
          showHome={true}
          backHandler={() => this.props.history.goBack() }
          nextText={null}
          nextHandler={null}/>
      </div>
    );

  }

  _getChoicesSlide() {

    //Get the saved history index
    // const mood_balance = this.context.reflect_mood_balance;
    // let storage_name = '@nod:reflectionsHistoryIndexNeutral';
    // if(mood_balance === 'positive') storage_name = '@nod:reflectionsHistoryIndexPositive';
    // if(mood_balance === 'negative') storage_name = '@nod:reflectionsHistoryIndexNegative';
    // let history_index = window.localStorage.getItem(storage_name);
    // if(history_index === null || parseInt(history_index) >= this.context.reflect_filtered_group.length){
    //   window.localStorage.setItem(storage_name, 0);
    //   history_index = 0;
    // }else{
    //   history_index = parseInt(history_index);
    //   if(history_index > 0 && !this.state.go_back_slide) this.setState({go_back_slide:true});
    // }

    //Get reflection group slice and disable shuffle back
    //We want to now filter out by the previously selected skill: reflect_skill_choice
      //Get all the cards for that mood and filter them by skill
    //this.reflect_filtered_group_slice = this.context.reflect_filtered_group.slice(history_index, history_index+3);

    const skill_slug = this.props.match.params.skill;
    let skill = null;
    if(skill_slug){
      skill = this.context.reflection_skills.find(reflection_skill => reflection_skill.slug ===  skill_slug);
      this.reflect_filtered_group_slice = [];
      this.context.reflect_filtered_group.forEach(reflection => {
        if(reflection.reflection_skill){
          if(reflection.reflection_skill.slug === skill_slug) this.reflect_filtered_group_slice.push(reflection);
        }
      });
    }else{
      this.reflect_filtered_group_slice = this.context.reflect_filtered_group;
    }

    if(skill_slug === 'all'){
      this.reflect_filtered_group_slice = this.context.reflections.sort((a, b) => {
        if(a.type < b.type){
          return 1;
        }else if(a.type > b.type){
          return -1;
        }else{
          return 0;
        }
      });
    }

    //const shuffle_back_disabled = history_index <= 0 ? true : false;

    //Slider settings
    const slider_settings = {
      dots: true,
      infinite: false,
      speed: 500,
      //initialSlide: this.context.reflections_slide, //history_index > 0 ? 1 : 0, //so you don't land on "back" slide
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      centerMode: true,
      focusOnSelect:true,
      centerPadding: '50px',
      className: 'choose-slick-slider ' + this.state.reflections_slider_classes,
      appendDots: dots => {
        return (
          <ul>
            {dots.map((dot, index) => {
              return (
                <li className={dot.props.className} key={index}>
                  <button aria-label={'reflection ' + (index+1)} onClick={dot.props.children.props.onClick}>{index+1}</button>
                </li>
              );
            })}
          </ul>
        );
      },
      onInit: () => {
        this.context.setState({reflections_slide: 0});
        setTimeout(() => {
          if(this.reflect_filtered_group_slice.length > 0){

            //Keen
            this.context.sendKeenEvent('featured_reflections', {
              position: 0,
              reflection_id: this.reflect_filtered_group_slice[0].id,
              reflection_title: this.reflect_filtered_group_slice[0].title,
              swipe: null,
              reflection_type: this.reflect_filtered_group_slice[0].type,
              skill: this.context.reflect_skill_choice,
              mood_changed: this.context.reflect_mood_changed,
              mood_balance: this.context.reflect_mood_balance,
              mood_label: this.context.reflect_mood_coord_label,
            });

            //MixPanel
            this.context.sendMixPanelEvent('featured_reflections', {
              position: 0,
              reflection_id: this.reflect_filtered_group_slice[0].id,
              reflection_title: this.reflect_filtered_group_slice[0].title,
              swipe: null,
              reflection_type: this.reflect_filtered_group_slice[0].type,
              skill: this.context.reflect_skill_choice,
              mood_changed: this.context.reflect_mood_changed,
              mood_balance: this.context.reflect_mood_balance,
              mood_label: this.context.reflect_mood_coord_label,
            });
          }
        }, 100);
      },
      beforeChange: (oldIndex, newIndex) => {
        if( oldIndex !== newIndex){
          //let pagination_slide = true;
          let challenge_index = newIndex;

          //If paginated AND if not the first "back" slide AND if not last "see more" slide
          // if(history_index > 0 && newIndex !== 4 && newIndex !== 0){
          //   pagination_slide = false;
          //   challenge_index = newIndex-1;
          // }

          //If not paginated AND if not last "see more" slide
          // if(history_index === 0 && newIndex !== 3){
          //   pagination_slide = false;
          // }

          //if(!pagination_slide && !this.state.back_forward_transition_occuring){

            //Keen
            this.context.sendKeenEvent('featured_reflections', {
              position: challenge_index,
              reflection_id: this.reflect_filtered_group_slice[challenge_index].id,
              reflection_title: this.reflect_filtered_group_slice[challenge_index].title,
              swipe: newIndex > oldIndex ? 'swipe_left' : 'swipe_right',
              reflection_type: this.reflect_filtered_group_slice[challenge_index].type,
              skill: this.context.reflect_skill_choice,
              mood_changed: this.context.reflect_mood_changed,
              mood_balance: this.context.reflect_mood_balance,
              mood_label: this.context.reflect_mood_coord_label,
            });

            //MixPanel
            this.context.sendMixPanelEvent('featured_reflections', {
              position: challenge_index,
              reflection_id: this.reflect_filtered_group_slice[challenge_index].id,
              reflection_title: this.reflect_filtered_group_slice[challenge_index].title,
              swipe: newIndex > oldIndex ? 'swipe_left' : 'swipe_right',
              reflection_type: this.reflect_filtered_group_slice[challenge_index].type,
              skill: this.context.reflect_skill_choice,
              mood_changed: this.context.reflect_mood_changed,
              mood_balance: this.context.reflect_mood_balance,
              mood_label: this.context.reflect_mood_coord_label,
            });
          //}

        }
      },
      afterChange: (newIndex) => {
        //this.setState({reflections_slide: newIndex});
        this.context.setState({reflections_slide: newIndex});
      }
    };

    // const handleBack = (e) => {
    //   e.preventDefault();
    //   if(!shuffle_back_disabled){
    //     this.setState({reflections_slider_classes:'shuffle', back_forward_transition_occuring: true});
    //
    //     //History
    //     history_index = history_index - 3;
    //     if(history_index < 0) history_index = this.context.reflect_filtered_group.length-3;
    //     window.localStorage.setItem(storage_name, history_index);
    //
    //     //Navigate
    //     if(history_index > 0){
    //       this.reflectionsSlider.slickGoTo(1);
    //     }else{
    //       this.reflectionsSlider.slickGoTo(0);
    //     }
    //
    //     setTimeout(() => {
    //       this.setState({reflections_slider_classes:'', go_back_slide: history_index > 0});
    //       setTimeout(() => {
    //         const next_featured_reflections = this.context.reflect_filtered_group.slice(history_index, history_index+3);
    //
    //         //Keen
    //         this.context.sendKeenEvent('featured_reflections', {
    //           //back: true,
    //           //forward: false,
    //           position: 0, //history_index
    //           reflection_id: next_featured_reflections[0].id,
    //           reflection_title: next_featured_reflections[0].title,
    //           interaction_pattern: 'go back'
    //         });
    //
    //         this.setState({back_forward_transition_occuring:false});
    //       }, 10);
    //     }, 550);
    //   }
    // };

    // const handleShuffle = (e) => {
    //   e.preventDefault();
    //   this.setState({reflections_slider_classes:'shuffle', back_forward_transition_occuring: true});
    //
    //   //History
    //   history_index = history_index + 3;
    //   if(history_index >= this.context.reflect_filtered_group.length) history_index = 0;
    //   window.localStorage.setItem(storage_name, history_index);
    //
    //   //Navigate
    //   if(history_index > 0){
    //     this.reflectionsSlider.slickGoTo(1);
    //   }else{
    //     this.reflectionsSlider.slickGoTo(0);
    //   }
    //
    //   setTimeout(() => {
    //     this.setState({reflections_slider_classes:'', go_back_slide:true});
    //     setTimeout(() => {
    //       const next_featured_reflections = this.context.reflect_filtered_group.slice(history_index, history_index+3);
    //
    //       //Keen
    //       this.context.sendKeenEvent('featured_reflections', {
    //         //back: false,
    //         //forward: true,
    //         position: 0, //history_index
    //         reflection_id: next_featured_reflections[0].id,
    //         reflection_title: next_featured_reflections[0].title,
    //         interaction_pattern: 'see more'
    //       });
    //
    //       this.setState({back_forward_transition_occuring:false});
    //     }, 10);
    //   }, 550);
    // };

    const handelSkip = () => {

      let guide_title = null;
      let challenge_id = null;
      let challenge_title = null;
      let last_challenge_rating = null;
      if(this.context.user_challenge_just_completed){
        const challenge = this.context.challenges.find((thechallenge)  => { return thechallenge.id === this.context.reflect_came_from_challenge_id; });
        challenge_id = challenge.id;
        challenge_title = challenge.title;
        const guide = this.context.guides_all.find((theguide) => { return theguide.id === challenge.guide_id; });
        guide_title = guide.title;
        last_challenge_rating = this.context.relfect_last_challenge_rating;
      }
      //KeenIO
      this.context.sendKeenEvent('reflection_select', {
        skip: true,
        guide_title: guide_title,
        challenge_id: challenge_id,
        challenge_title: challenge_title,
        reflection_id: null,
        reflection_title: null,
        rating: last_challenge_rating,
        mood_changed: this.context.reflect_mood_changed,
        mood_balance: this.context.reflect_mood_balance,
        mood_answer: this.context.reflect_mood_coord_label,
        skill: this.context.reflect_skill_choice,
        reflection_type: null,
      });

      //MixPanel
      this.context.sendMixPanelEvent('reflection_select', {
        skip: true,
        guide_title: guide_title,
        challenge_id: challenge_id,
        challenge_title: challenge_title,
        reflection_id: null,
        reflection_title: null,
        rating: last_challenge_rating,
        mood_changed: this.context.reflect_mood_changed,
        mood_balance: this.context.reflect_mood_balance,
        mood_answer: this.context.reflect_mood_coord_label,
        skill: this.context.reflect_skill_choice,
        reflection_type: null,
      });

      setTimeout(() => this.props.history.push('/reflect'), 100);
    }

    const handleSubmit = (index) => {

      //let the_index = index;
      //if(this.state.go_back_slide) the_index = index-1;
      //if(the_index < 0) the_index = 0;
      //if(the_index > 2) the_index = 2;
      //this.context.reflectSetReflectionChoice(this.reflect_filtered_group_slice[the_index]).then(() => {
      this.context.reflectSetReflectionChoice(this.reflect_filtered_group_slice[index]).then(() => {

        //Set history back so it doesn't try and go to this card that's gone now.
        // setTimeout(() => {
        //   let history_index = window.localStorage.getItem(storage_name);
        //   history_index = parseInt(history_index) + 1;
        //   if(history_index >= this.context.reflect_filtered_group.length) history_index = 0;
        //   window.localStorage.setItem(storage_name, history_index);
        // }, 1000);

        let guide_title = null;
        let challenge_id = null;
        let challenge_title = null;
        let last_challenge_rating = null;
        if(this.context.user_challenge_just_completed){
          const challenge = this.context.challenges.find((thechallenge)  => { return thechallenge.id === this.context.reflect_came_from_challenge_id; });
          challenge_id = challenge.id;
          challenge_title = challenge.title;
          const guide = this.context.guides_all.find((theguide) => { return theguide.id === challenge.guide_id; });
          guide_title = guide.title;
          last_challenge_rating = this.context.relfect_last_challenge_rating;
        }
        //KeenIO
        this.context.sendKeenEvent('reflection_select', {
          skip: false,
          guide_title: guide_title,
          challenge_id: challenge_id,
          challenge_title: challenge_title,
          reflection_id: this.reflect_filtered_group_slice[index].id,
          reflection_title: this.reflect_filtered_group_slice[index].title,
          rating: last_challenge_rating,
          mood_changed: this.context.reflect_mood_changed,
          mood_balance: this.context.reflect_mood_balance,
          mood_answer: this.context.reflect_mood_coord_label,
          skill: this.context.reflect_skill_choice,
          reflection_type: this.reflect_filtered_group_slice[index].type,
        });

        //MixPanel
        this.context.sendMixPanelEvent('reflection_select', {
          skip: false,
          guide_title: guide_title,
          challenge_id: challenge_id,
          challenge_title: challenge_title,
          reflection_id: this.reflect_filtered_group_slice[index].id,
          reflection_title: this.reflect_filtered_group_slice[index].title,
          rating: last_challenge_rating,
          mood_changed: this.context.reflect_mood_changed,
          mood_balance: this.context.reflect_mood_balance,
          mood_answer: this.context.reflect_mood_coord_label,
          skill: this.context.reflect_skill_choice,
          reflection_type: this.reflect_filtered_group_slice[index].type,
        });

        setTimeout(() => this.props.history.push('/reflect/interact'), 100);

      });
    };


    return(
      <div className="slide-inner">
        <Helmet>
          <title>Nod - Reflect - Choose</title>
          <link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
          <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
        </Helmet>
        <PageHeader bgColor="blue">
          <p>A few suggestions to...</p>
          { this.context.reflect_mood_coord_label ?
            <h1><span>{skill ? skill.title : ''}</span> when you're feeling <span>{this.context.reflect_mood_coord_label.toLowerCase()}.</span></h1>
          :
            <h1><span>{skill ? skill.title : ''}</span></h1>
          }
        </PageHeader>
        <p className="sliderLabel">Choose a Reflection</p>
        <SlickSlider ref={slider => (this.reflectionsSlider = slider)} {...slider_settings}>
          {/* this.state.go_back_slide ?
            <CircleCard
              onClick={(e) => { handleBack(e) }}
              text="Go back to previous reflections"
              classes="blue small back" />
          : null*/
          }
          {
            this.reflect_filtered_group_slice.map((reflection, index) => {
              return(
                <div key={index} className="touch-slide">
                  <ReflectionCard
                    image_url={reflection.image_url}
                    type={reflection.type}
                    title={reflection.title}
                    onClick={(e) => { e.preventDefault(); handleSubmit(this.context.reflections_slide);}} />
                  {/*<div dangerouslySetInnerHTML={{__html: reflection.text}}></div>*/}
                </div>
              )
            })
          }
          {/* history_index < this.context.reflect_filtered_group.length - 3 ?
            <CircleCard
              onClick={(e) => { handleShuffle(e) }}
              text="Show me 3 more reflections"
              classes="blue small forward" />
          : null*/
          }
        </SlickSlider>
        {/*<div className="reflection-controls">
          <a href="/" className="back-shuffle" onClick={handleBack} disabled={shuffle_back_disabled}>&nbsp;</a>
          <a href="/" className="shuffle" onClick={handleShuffle}>&nbsp;</a>
        </div>*/}

        <FooterUpdator
          showHome={true}
          backHandler={() => this.props.history.goBack() }
          skipHandler={handelSkip}
          nextText="Begin"
          nextHandler={() => handleSubmit(this.context.reflections_slide)} />
      </div>
    );
  }

  _getInteractionSlide() {

    let touch_gestures = window.localStorage.getItem('@nod:touch_gestures');
    if(touch_gestures === 'false') touch_gestures = false;
    if(touch_gestures === null) touch_gestures = true;

    const windowLock = () => {
      document.querySelector('html').classList.add('locked');
    }

    const windowUnlock = () => {
      document.querySelector('html').classList.remove('locked');
    }

    const onStart = () => {
      this.setState({
        reflect_duration: 0,
        audio_interaction_played: this.context.reflect_current.type === 'B'
      });

      this.context.updateFooterNextText('Finish');
      windowLock();
    }

    const onComplete = (duration) => { //Not really used anymore since we allowed finishing any time.
      this.setState({reflect_duration: duration});
      //this.context.updateFooterNextText('Finish');
    }

    const handleOptionChoice = (option) => {
      this.context.reflectOptionChoice(option).then(() => {

        let guide_title = null;
        let challenge_id = null;
        let challenge_title = null;
        if(this.context.user_challenge_just_completed){
          const challenge = this.context.challenges.find((thechallenge)  => { return thechallenge.id === this.context.reflect_came_from_challenge_id; });
          challenge_id = challenge.id;
          challenge_title = challenge.title;
          const guide = this.context.guides_all.find((theguide) => { return theguide.id === challenge.guide_id; });
          guide_title = guide.title;
        }
        //KeenIO
        this.context.sendKeenEvent('reflection', {
          skip: false,
          guide_title: guide_title,
          challenge_id: challenge_id,
          challenge_title: challenge_title,
          reflection_id: this.context.reflect_current.id,
          reflection_title: this.context.reflect_current.title,
          reflection_type: this.context.reflect_current.type,
          back_navigation: false,
          home_navigation: false,
          finish: false,
          modal_page: 'finished', //0,
          option_selected_now: true,
          option_selected: this.context.reflect_option.button_text,
          mood_changed: this.context.reflect_mood_changed,
          mood_balance: this.context.reflect_mood_balance,
          mood_label: this.context.reflect_mood_coord_label,
          skill: this.context.reflect_skill_choice,
          audio_played: this.state.audio_interaction_played,
          exit_app:false,
        });

        //MixPanel
        this.context.sendMixPanelEvent('reflection', {
          skip: false,
          guide_title: guide_title,
          challenge_id: challenge_id,
          challenge_title: challenge_title,
          reflection_id: this.context.reflect_current.id,
          reflection_title: this.context.reflect_current.title,
          reflection_type: this.context.reflect_current.type,
          back_navigation: false,
          home_navigation: false,
          finish: false,
          modal_page: 'finished', //0,
          option_selected_now: true,
          option_selected: this.context.reflect_option.button_text,
          mood_changed: this.context.reflect_mood_changed,
          mood_balance: this.context.reflect_mood_balance,
          mood_label: this.context.reflect_mood_coord_label,
          skill: this.context.reflect_skill_choice,
          audio_played: this.state.audio_interaction_played,
          exit_app:false,
        });

        setTimeout(() => this.props.history.push('/reflect/interact-response'), 100);

      });
    }

    const handleHome = () => {
      const duration = new Date().getTime() - this.context.reflect_start_time;

      let guide_title = null;
      let challenge_id = null;
      let challenge_title = null;
      if(this.context.user_challenge_just_completed){
        const challenge = this.context.challenges.find((thechallenge)  => { return thechallenge.id === this.context.reflect_came_from_challenge_id; });
        challenge_id = challenge.id;
        challenge_title = challenge.title;
        const guide = this.context.guides_all.find((theguide) => { return theguide.id === challenge.guide_id; });
        guide_title = guide.title;
      }
      //KeenIO
      this.context.sendKeenEvent('reflection', {
        skip: false,
        guide_title: guide_title,
        challenge_id: challenge_id,
        challenge_title: challenge_title,
        reflection_id: this.context.reflect_current.id,
        reflection_title: this.context.reflect_current.title,
        reflection_type: this.context.reflect_current.type,
        duration: duration, //this.state.reflect_duration,
        back_navigation: false,
        home_navigation: true,
        finish: false,
        option_selected:null,
        modal_page: this.state.interactive_footer_modal_instructions_slide,
        mood_changed: this.context.reflect_mood_changed,
        mood_balance: this.context.reflect_mood_balance,
        mood_label: this.context.reflect_mood_coord_label,
        skill: this.context.reflect_skill_choice,
        audio_played: this.state.audio_interaction_played,
        exit_app:false,
      });

      //MixPanel
      this.context.sendMixPanelEvent('reflection', {
        skip: false,
        guide_title: guide_title,
        challenge_id: challenge_id,
        challenge_title: challenge_title,
        reflection_id: this.context.reflect_current.id,
        reflection_title: this.context.reflect_current.title,
        reflection_type: this.context.reflect_current.type,
        duration: duration, //this.state.reflect_duration,
        back_navigation: false,
        home_navigation: true,
        finish: false,
        option_selected:null,
        modal_page: this.state.interactive_footer_modal_instructions_slide,
        mood_changed: this.context.reflect_mood_changed,
        mood_balance: this.context.reflect_mood_balance,
        mood_label: this.context.reflect_mood_coord_label,
        skill: this.context.reflect_skill_choice,
        audio_played: this.state.audio_interaction_played,
        exit_app:false,
      });

    }

    const handleGoBack = () => {
      windowUnlock();

      const duration = new Date().getTime() - this.context.reflect_start_time;

      let guide_title = null;
      let challenge_id = null;
      let challenge_title = null;
      if(this.context.user_challenge_just_completed){
        const challenge = this.context.challenges.find((thechallenge)  => { return thechallenge.id === this.context.reflect_came_from_challenge_id; });
        challenge_id = challenge.id;
        challenge_title = challenge.title;
        const guide = this.context.guides_all.find((theguide) => { return theguide.id === challenge.guide_id; });
        guide_title = guide.title;
      }

      //KeenIO
      this.context.sendKeenEvent('reflection', {
        skip: false,
        guide_title: guide_title,
        challenge_id: challenge_id,
        challenge_title: challenge_title,
        reflection_id: this.context.reflect_current.id,
        reflection_title: this.context.reflect_current.title,
        reflection_type: this.context.reflect_current.type,
        duration: duration, //this.state.reflect_duration,
        back_navigation: true,
        home_navigation: false,
        finish: false,
        option_selected:null,
        modal_page: this.state.interactive_footer_modal_instructions_slide,
        mood_changed: this.context.reflect_mood_changed,
        mood_balance: this.context.reflect_mood_balance,
        mood_label: this.context.reflect_mood_coord_label,
        skill: this.context.reflect_skill_choice,
        audio_played: this.state.audio_interaction_played,
        exit_app:false,
      });

      //MixPanel
      this.context.sendMixPanelEvent('reflection', {
        skip: false,
        guide_title: guide_title,
        challenge_id: challenge_id,
        challenge_title: challenge_title,
        reflection_id: this.context.reflect_current.id,
        reflection_title: this.context.reflect_current.title,
        reflection_type: this.context.reflect_current.type,
        duration: duration, //this.state.reflect_duration,
        back_navigation: true,
        home_navigation: false,
        finish: false,
        option_selected:null,
        modal_page: this.state.interactive_footer_modal_instructions_slide,
        mood_changed: this.context.reflect_mood_changed,
        mood_balance: this.context.reflect_mood_balance,
        mood_label: this.context.reflect_mood_coord_label,
        skill: this.context.reflect_skill_choice,
        audio_played: this.state.audio_interaction_played,
        exit_app:false,
      });

      setTimeout(() => this.props.history.goBack(), 100);
    }

    const handleSubmit = () => {
      const duration = new Date().getTime() - this.context.reflect_start_time;

      if(this.context.user_challenge_just_completed){
        this.context.reflectFinish(this.context.reflect_current.id).then(() => {
          this._checkIn();
        });
      }else{
        this.context.standaloneReflectFinish(this.context.reflect_current.id, this.context.reflect_mood_position).then(() => {
          this._checkIn();
        });
      }

      let guide_title = null;
      let challenge_id = null;
      let challenge_title = null;
      if(this.context.user_challenge_just_completed){
        const challenge = this.context.challenges.find((thechallenge)  => { return thechallenge.id === this.context.reflect_came_from_challenge_id; });
        challenge_id = challenge.id;
        challenge_title = challenge.title;
        const guide = this.context.guides_all.find((theguide) => { return theguide.id === challenge.guide_id; });
        guide_title = guide.title;
      }

      //KeenIO
      this.context.sendKeenEvent('reflection', {
        skip: false,
        guide_title: guide_title,
        challenge_id: challenge_id,
        challenge_title: challenge_title,
        reflection_id: this.context.reflect_current.id,
        reflection_title: this.context.reflect_current.title,
        reflection_type: this.context.reflect_current.type,
        duration: duration, //this.state.reflect_duration,
        back_navigation: false,
        home_navigation: false,
        finish: true,
        option_selected:null,
        modal_page: this.state.interactive_footer_modal_instructions_slide,
        mood_changed: this.context.reflect_mood_changed,
        mood_balance: this.context.reflect_mood_balance,
        mood_label: this.context.reflect_mood_coord_label,
        skill: this.context.reflect_skill_choice,
        audio_played: this.state.audio_interaction_played,
        exit_app:false,
      });

      //MixPanel
      this.context.sendMixPanelEvent('reflection', {
        skip: false,
        guide_title: guide_title,
        challenge_id: challenge_id,
        challenge_title: challenge_title,
        reflection_id: this.context.reflect_current.id,
        reflection_title: this.context.reflect_current.title,
        reflection_type: this.context.reflect_current.type,
        duration: duration, //this.state.reflect_duration,
        back_navigation: false,
        home_navigation: false,
        finish: true,
        option_selected:null,
        modal_page: this.state.interactive_footer_modal_instructions_slide,
        mood_changed: this.context.reflect_mood_changed,
        mood_balance: this.context.reflect_mood_balance,
        mood_label: this.context.reflect_mood_coord_label,
        skill: this.context.reflect_skill_choice,
        audio_played: this.state.audio_interaction_played,
        exit_app:false,
      });
    };

    if(this.context.reflect_current !== null){

      //////////////////////////////////////////////////////////////////////////
      //TYPE A//////////////////////////////////////////////////////////////////
      //////////////////////////////////////////////////////////////////////////
      if(this.context.reflect_current.type === 'A'){

        const handleNext = () => {
          if(this.state.interaction_footer_modal_instructions_visible){
            this.footerModalInstructions.current.hide();
            this.setState({
              interaction_footer_modal_instructions_visible: false,
              interaction_ready_to_render: true,
              interactive_footer_modal_instructions_slide: 'finished'
            });
            this.context.updateFooterNextText('');
          }else{
            windowUnlock();
            handleSubmit();
          }
        }

        const handleInstructionsOpen = () => {
          this.setState({interaction_footer_modal_instructions_visible: true});
          this.context.updateFooterNextText('Continue');
        }

        return(
          <div id="slide-inner" className="slide-inner">
            <Helmet>
              <title>Nod - {this.context.reflect_current.title}</title>
            </Helmet>
            <PageHeader>
              <h1 dangerouslySetInnerHTML={{__html: this.context.reflect_current.title}}></h1>
            </PageHeader>
            <ul className="multipleChoice-Wrap">
              {
                this.context.reflect_current.options.map((option, index) => {
                  return <li key={index}><button className="multipleChoice" onClick={() => handleOptionChoice(option)}>{option.button_text}</button></li>;
                })
              }
            </ul>
            <FooterModal ref={this.footerModalInstructions} descriptor={true} ignoreInit={true} onOpen={handleInstructionsOpen} pushBottom={true} disableBackgroundClick={true} parentId="slide-inner" ignoreFooterInert={true}>
              <div dangerouslySetInnerHTML={{__html: this.context.reflect_current.text}}></div>
            </FooterModal>
            <FooterUpdator
              showHome={true}
              homeHandler={handleHome}
              backHandler={handleGoBack}
              nextText='Continue'
              nextHandler={handleNext} />
          </div>
        );

      //////////////////////////////////////////////////////////////////////////
      //TYPE B and C////////////////////////////////////////////////////////////
      //////////////////////////////////////////////////////////////////////////
      }else{

        const interaction_a11y_video_src = this.context.reflect_current.video_file_url;
        const interaction_a11y_captions_src = this.context.reflect_current.captions_file_url;
        const interaction_a11y_transcript = this.context.reflect_current.transcript;
        let interaction_component = null;
        let audio_file = null;

        if(this.context.reflect_current.type === 'B'){

          audio_file = this.context.reflect_current.audio_file_url; //'https://grit-nod-pro.s3.amazonaws.com/test.mp3';
          if(this.state.interaction_can_render && this.state.interaction_ready_to_render && audio_file !== null){
            interaction_component = <AudioPlayerComponent
              src={audio_file}
              width={this.state.interaction_width}
              height={this.state.interaction_height}
              onStart={() => { onStart(); }}
              onComplete={(duration) => { onComplete(duration); }}
            />;
          }

        }else{

          if(this.state.interaction_can_render && this.state.interaction_ready_to_render){
            switch(this.context.reflect_current.interaction_id){
              default:
              case 'animal_breathing':
                interaction_component = <AnimalBreathingComponent
                  width={this.state.interaction_width}
                  height={this.state.interaction_height}
                  onStart={() => { onStart(); }}
                  onComplete={(duration) => { onComplete(duration); }}
                />;
                break;
              case 'animal_jump':
                interaction_component = <AnimalJumpComponent
                  width={this.state.interaction_width}
                  height={this.state.interaction_height}
                  onStart={() => { onStart(); }}
                  onComplete={(duration) => { onComplete(duration); }}
                />;
                break;
              case 'blow_bubbles':
                interaction_component = <BlowBubblesComponent
                  width={this.state.interaction_width}
                  height={this.state.interaction_height}
                  onStart={() => { onStart(); }}
                  onComplete={(duration) => { onComplete(duration); }}
                />;
                break;
              case 'flip_the_turtle':
                interaction_component = <FlipTheTurtleComponent
                  width={this.state.interaction_width}
                  height={this.state.interaction_height}
                  onStart={() => { onStart(); }}
                  onComplete={(duration) => { onComplete(duration); }}
                />;
                break;
              case 'heart_filling':
                interaction_component = <HeartFillingComponent
                  width={this.state.interaction_width}
                  height={this.state.interaction_height}
                  onStart={() => { onStart(); }}
                  onComplete={(duration) => { onComplete(duration); }}
                />;
                break;
              case 'monkey_in_a_barrel':
                interaction_component = <MonkeyInABarrelComponent
                  width={this.state.interaction_width}
                  height={this.state.interaction_height}
                  onStart={() => { onStart(); }}
                  onComplete={(duration) => { onComplete(duration); }}
                />;
                break;
              case 'pinata':
                interaction_component = <PinataComponent
                  width={this.state.interaction_width}
                  height={this.state.interaction_height}
                  onStart={() => { onStart(); }}
                  onComplete={(duration) => { onComplete(duration); }}
                />;
                break;
              case 'squirrel_zoom':
                interaction_component = <SquirrelZoomComponent
                  width={this.state.interaction_width}
                  height={this.state.interaction_height}
                  onStart={() => { onStart(); }}
                  onComplete={(duration) => { onComplete(duration); }}
                />;
                break;
              case 'stormy_sky':
                interaction_component = <StormySkyComponent
                  width={this.state.interaction_width}
                  height={this.state.interaction_height}
                  onStart={() => { onStart(); }}
                  onComplete={(duration) => { onComplete(duration); }}
                />;
                break;
              case 'turtle_fist_bump':
                interaction_component = <TurtleFistBumpComponent
                  width={this.state.interaction_width}
                  height={this.state.interaction_height}
                  onStart={() => { onStart(); }}
                  onComplete={(duration) => { onComplete(duration); }}
                />;
                break;
              case 'water_ripples':
                interaction_component = <WaterRipplesComponent
                  width={this.state.interaction_width}
                  height={this.state.interaction_height}
                  onStart={() => { onStart(); }}
                  onComplete={(duration) => { onComplete(duration); }}
                />;
                break;
              case 'whack_a_mole':
                interaction_component = <WhackAMoleComponent
                  width={this.state.interaction_width}
                  height={this.state.interaction_height}
                  onStart={() => { onStart(); }}
                  onComplete={(duration) => { onComplete(duration); }}
                />;
                break;
            }
          }

        }

        const slider_settings = {
          dots: true,
          infinite: false,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          focusOnSelect: true,
          className: 'interactive-slick-slider',
          appendDots: dots => {
            return (
              <ul>
                {dots.map((dot, index) => {
                  return (
                    <li className={dot.props.className} key={index}>
                      <button aria-label={'instruction slide ' + (index+1)} onClick={dot.props.children.props.onClick}>{index+1}</button>
                    </li>
                  );
                })}
              </ul>
            );
          },
          afterChange: (newIndex) => {
            this.setState({interactive_footer_modal_instructions_slide: newIndex});
          }
        };

        const handleNext = () => {
          if(this.state.interaction_footer_modal_instructions_visible){
            if(this.state.interactive_footer_modal_instructions_slide === 0){
              this.reflectionsSlider.slickGoTo(1);
            }else{
              this.footerModalInstructions.current.hide();
              this.setState({
                interaction_footer_modal_instructions_visible: false,
                interaction_ready_to_render: true,
                interactive_footer_modal_instructions_slide: 'finished'
              });
              this.context.updateFooterNextText('');
              if(!touch_gestures) onStart();
            }
          }else{
            windowUnlock();
            handleSubmit();
          }
        }

        const handleInstructionsOpen = () => {
          this.setState({interaction_footer_modal_instructions_visible: true, interactive_footer_modal_instructions_slide: 0});
          this.context.updateFooterNextText('Continue');
          setTimeout(() => this.reflectionsSlider.slickGoTo(0), 250);
        }

        const handleCaptionsToggle = () => {
          this.setState({interaction_captions_on: !this.state.interaction_captions_on});
        }

        const handleTranscriptsToggle = () => {
          this.setState({interaction_transcripts_on: !this.state.interaction_transcripts_on});
          if(!this.state.interaction_transcripts_on) setTimeout(() => this.transcriptsContainer.current.focus(), 100);
        }

        return(
          <div id="slide-inner" className="slide-inner">
            <Helmet>
              <title>Nod - {this.context.reflect_current.title}</title>
            </Helmet>
            <PageHeader bgColor="tan"></PageHeader>
            <div ref={this.interactiveHeader} id="interactive-header">
              <h1>{this.context.reflect_current.title}</h1>
            </div>
            <div ref={this.interactiveWrap} className='interactive-wrap' style={{height:this.state.interaction_height}}>
              {(touch_gestures && this.context.reflect_current.type !== 'B') && interaction_component}
              {((!touch_gestures || this.context.reflect_current.type === 'B') && this.state.interaction_can_render && this.state.interaction_ready_to_render) &&
                <A11yVideoAudioPlayer
                  isAudioPlayer={this.context.reflect_current.type === 'B'}
                  height={this.state.interaction_height - 60}
                  src={audio_file ? audio_file : interaction_a11y_video_src}
                  captionsSrc={interaction_a11y_captions_src}
                  captionsEnabled={this.state.interaction_captions_on} />
              }
            </div>
            <div tabIndex="-1" ref={this.transcriptsContainer} className={this.state.interaction_transcripts_on ? 'interactive-transcripts visible' : 'interactive-transcripts'}>
              <div className="interactive-transcripts_text" dangerouslySetInnerHTML={{__html: interaction_a11y_transcript}} />
              <div className="interactive-transcripts_corner-arrow" />
            </div>
            {!this.state.interaction_footer_modal_instructions_visible && (!touch_gestures || this.context.reflect_current.type === 'B') ?
              <div className="interactive-footer">
                <button aria-label="show captions" className={this.state.interaction_captions_on ? 'interactive-footer_closed-caption active' : 'interactive-footer_closed-caption'} onClick={handleCaptionsToggle} />
                <button aria-label="show transcripts" className={this.state.interaction_transcripts_on ? 'interactive-footer_transcripts active' : 'interactive-footer_transcripts'} onClick={handleTranscriptsToggle} />
              </div>
            :null}
            <FooterModal ref={this.footerModalInstructions} descriptor={true} ignoreInit={true} onOpen={handleInstructionsOpen} pushBottom={true} disableBackgroundClick={true} parentId="slide-inner" ignoreFooterInert={true}>
              <SlickSlider ref={slider => (this.reflectionsSlider = slider)} {...slider_settings}>
                <div className="touch-slide">
                  <div dangerouslySetInnerHTML={{__html: this.context.reflect_current.text}}></div>
                </div>
                <div className="touch-slide">
                  <div dangerouslySetInnerHTML={{__html: this.context.reflect_current.instructional_text}}></div>
                </div>
              </SlickSlider>
            </FooterModal>
            <FooterUpdator
              showHome={true}
              homeHandler={handleHome}
              backHandler={handleGoBack}
              nextText="Continue"
              nextHandler={handleNext} />
          </div>
        );
      }
    }else{
      return null;
    }
  }

  _getInteractResponseSlide() {

    const handleHome = () => {
      const duration = new Date().getTime() - this.context.reflect_start_time;

      let guide_title = null;
      let challenge_id = null;
      let challenge_title = null;
      if(this.context.user_challenge_just_completed){
        const challenge = this.context.challenges.find((thechallenge)  => { return thechallenge.id === this.context.reflect_came_from_challenge_id; });
        challenge_id = challenge.id;
        challenge_title = challenge.title;
        const guide = this.context.guides_all.find((theguide) => { return theguide.id === challenge.guide_id; });
        guide_title = guide.title;
      }
      //KeenIO
      this.context.sendKeenEvent('reflection', {
        skip: false,
        guide_title: guide_title,
        challenge_id: challenge_id,
        challenge_title: challenge_title,
        reflection_id: this.context.reflect_current.id,
        reflection_title: this.context.reflect_current.title,
        reflection_type: this.context.reflect_current.type,
        duration: duration, //this.state.reflect_duration,
        back_navigation: false,
        home_navigation: true,
        finish: false,
        modal_page: 'finished', //0,
        option_selected: this.context.reflect_option.button_text,
        mood_changed: this.context.reflect_mood_changed,
        mood_balance: this.context.reflect_mood_balance,
        mood_label: this.context.reflect_mood_coord_label,
        skill: this.context.reflect_skill_choice,
        audio_played: this.state.audio_interaction_played,
        exit_app:false,
      });

      //MixPanel
      this.context.sendMixPanelEvent('reflection', {
        skip: false,
        guide_title: guide_title,
        challenge_id: challenge_id,
        challenge_title: challenge_title,
        reflection_id: this.context.reflect_current.id,
        reflection_title: this.context.reflect_current.title,
        reflection_type: this.context.reflect_current.type,
        duration: duration, //this.state.reflect_duration,
        back_navigation: false,
        home_navigation: true,
        finish: false,
        modal_page: 'finished', //0,
        option_selected: this.context.reflect_option.button_text,
        mood_changed: this.context.reflect_mood_changed,
        mood_balance: this.context.reflect_mood_balance,
        mood_label: this.context.reflect_mood_coord_label,
        skill: this.context.reflect_skill_choice,
        audio_played: this.state.audio_interaction_played,
        exit_app:false,
      });
    }

    const handleGoBack = () => {

      const duration = new Date().getTime() - this.context.reflect_start_time;

      let guide_title = null;
      let challenge_id = null;
      let challenge_title = null;
      if(this.context.user_challenge_just_completed){
        const challenge = this.context.challenges.find((thechallenge)  => { return thechallenge.id === this.context.reflect_came_from_challenge_id; });
        challenge_id = challenge.id;
        challenge_title = challenge.title;
        const guide = this.context.guides_all.find((theguide) => { return theguide.id === challenge.guide_id; });
        guide_title = guide.title;
      }
      //KeenIO
      this.context.sendKeenEvent('reflection', {
        skip: false,
        guide_title: guide_title,
        challenge_id: challenge_id,
        challenge_title: challenge_title,
        reflection_id: this.context.reflect_current.id,
        reflection_title: this.context.reflect_current.title,
        reflection_type: this.context.reflect_current.type,
        duration: duration, //this.state.reflect_duration,
        back_navigation: true,
        home_navigation: false,
        finish: false,
        modal_page: 'finished', //0,
        option_selected: this.context.reflect_option.button_text,
        mood_changed: this.context.reflect_mood_changed,
        mood_balance: this.context.reflect_mood_balance,
        mood_label: this.context.reflect_mood_coord_label,
        skill: this.context.reflect_skill_choice,
        audio_played: this.state.audio_interaction_played,
        exit_app:false,
      });

      //MixPanel
      this.context.sendMixPanelEvent('reflection', {
        skip: false,
        guide_title: guide_title,
        challenge_id: challenge_id,
        challenge_title: challenge_title,
        reflection_id: this.context.reflect_current.id,
        reflection_title: this.context.reflect_current.title,
        reflection_type: this.context.reflect_current.type,
        duration: duration, //this.state.reflect_duration,
        back_navigation: true,
        home_navigation: false,
        finish: false,
        modal_page: 'finished', //0,
        option_selected: this.context.reflect_option.button_text,
        mood_changed: this.context.reflect_mood_changed,
        mood_balance: this.context.reflect_mood_balance,
        mood_label: this.context.reflect_mood_coord_label,
        skill: this.context.reflect_skill_choice,
        audio_played: this.state.audio_interaction_played,
        exit_app:false,
      });

      setTimeout(() => this.props.history.goBack(), 100);
    }

    const handleSubmit = () => {
      const duration = new Date().getTime() - this.context.reflect_start_time;

      if(this.context.user_challenge_just_completed){
        this.context.reflectFinish().then(() => {
          this._checkIn();
        });
      }else{
        // this.context.setState({
        //   reflect_finished: true,
        //   user_challenge_just_completed: false,
        // }).then(() => {
        //   this._checkIn();
        // });
        this.context.standaloneReflectFinish(this.context.reflect_current.id, this.context.reflect_mood_position).then(() => {
          this._checkIn();
        });
      }

      let guide_title = null;
      let challenge_id = null;
      let challenge_title = null;
      if(this.context.user_challenge_just_completed){
        const challenge = this.context.challenges.find((thechallenge)  => { return thechallenge.id === this.context.reflect_came_from_challenge_id; });
        challenge_id = challenge.id;
        challenge_title = challenge.title;
        const guide = this.context.guides_all.find((theguide) => { return theguide.id === challenge.guide_id; });
        guide_title = guide.title;
      }
      //KeenIO
      this.context.sendKeenEvent('reflection', {
        skip: false,
        guide_title: guide_title,
        challenge_id: challenge_id,
        challenge_title: challenge_title,
        reflection_id: this.context.reflect_current.id,
        reflection_title: this.context.reflect_current.title,
        reflection_type: this.context.reflect_current.type,
        duration: duration, //this.state.reflect_duration,
        back_navigation: false,
        home_navigation: false,
        finish: true,
        modal_page: 'finished', //0,
        option_selected: this.context.reflect_option.button_text,
        mood_changed: this.context.reflect_mood_changed,
        mood_balance: this.context.reflect_mood_balance,
        mood_label: this.context.reflect_mood_coord_label,
        skill: this.context.reflect_skill_choice,
        audio_played: this.state.audio_interaction_played,
        exit_app:false,
      });

      //MixPanel
      this.context.sendMixPanelEvent('reflection', {
        skip: false,
        guide_title: guide_title,
        challenge_id: challenge_id,
        challenge_title: challenge_title,
        reflection_id: this.context.reflect_current.id,
        reflection_title: this.context.reflect_current.title,
        reflection_type: this.context.reflect_current.type,
        duration: duration, //this.state.reflect_duration,
        back_navigation: false,
        home_navigation: false,
        finish: true,
        modal_page: 'finished', //0,
        option_selected: this.context.reflect_option.button_text,
        mood_changed: this.context.reflect_mood_changed,
        mood_balance: this.context.reflect_mood_balance,
        mood_label: this.context.reflect_mood_coord_label,
        skill: this.context.reflect_skill_choice,
        audio_played: this.state.audio_interaction_played,
        exit_app:false,
      });
    };

    return(
      <div className="slide-inner">
        <Helmet>
          <title>Nod - Reflect - Interaction - Response</title>
        </Helmet>
        <PageHeader>
          <h1>{this.context.reflect_option.page_title}</h1>
          <p dangerouslySetInnerHTML={{__html: this.context.reflect_option.page_content}}></p>
        </PageHeader>
        {this.context.reflect_option.image_url !== null ? <img className="reflection-option-image" src={this.context.reflect_option.image_url} alt="" /> : null}
        <FooterUpdator
          showHome={true}
          homeHandler={handleHome}
          backHandler={() => handleGoBack() }
          skipHandler={null}
          nextText="Finish"
          nextHandler={() => handleSubmit()} />
      </div>
    );
  }

  _checkIn() {

    //If notifications are not enabled
    this.context.areOsNotificationsEnabled().then(granted => {

      if(this.context.getNotificationSetting('notifications') === 'false' || !granted){

        //Check if it's been 21 days since last checkin. If so, send to checkin page. Otherwise send to homepage.
        // const lastcheckin = this.context.getNotificationSetting('notificationLastAsked');
        // const timeago = 21 * 24 * 60 * 60 * 1000; //21 days
        // const now = new Date().getTime();
        // if(parseInt(lastcheckin) < now-timeago){
        const notificationAskSkips = parseInt(this.context.getNotificationSetting('notificationAskSkips'));
        if(notificationAskSkips < 3){
          this.props.history.push('/reflect/checkin');
        }else{
          this.props.history.push('/')
        }

      }else{
        this.props.history.push('/');
      }

    });

  }

  _getCheckInSlide() {

    const handleSubmit = () => {
      this.context.areOsNotificationsEnabled().then(granted => {
        if(granted){
          this.context.setNotificationSetting('notifications', true);
          //this.context.setNotificationSetting('notificationLastAsked', new Date().getTime());

          //KeenIO Event
          this.context.sendKeenEvent('notification', {
            page_title: 'reflection',
            skipped:false,
            os_declined: false,
            snoozed:false,
            reminder_selection: null,
            notification_title: null,
            marked_complete: false,
            push_notification: false,
            notification_action_button_clicked: null,
            code_location: 'Reflection.jsx',
            push_notification_title: null,
          });

          //MixPanel Event
          this.context.sendMixPanelEvent('notification', {
            page_title: 'reflection',
            skipped:false,
            os_declined: false,
            snoozed:false,
            reminder_selection: null,
            notification_title: null,
            marked_complete: false,
            push_notification: false,
            notification_action_button_clicked: null,
            code_location: 'Reflection.jsx',
            push_notification_title: null,
          });

          setTimeout(() => this.props.history.push('/'), 100);

        }else{
          this.context.requestEnableOsNotifications().then(granted => {
            if(granted){
              this.context.setNotificationSetting('notifications', true);
              //this.context.setNotificationSetting('notificationLastAsked', new Date().getTime());

              //KeenIO Event
              this.context.sendKeenEvent('notification', {
                page_title: 'reflection',
                skipped:false,
                os_declined: false,
                snoozed:false,
                reminder_selection: null,
                notification_title: null,
                marked_complete: false,
                push_notification: false,
                notification_action_button_clicked: null,
                code_location: 'Reflection.jsx',
                push_notification_title: null,
              });

              //MixPanel Event
              this.context.sendMixPanelEvent('notification', {
                page_title: 'reflection',
                skipped:false,
                os_declined: false,
                snoozed:false,
                reminder_selection: null,
                notification_title: null,
                marked_complete: false,
                push_notification: false,
                notification_action_button_clicked: null,
                code_location: 'Reflection.jsx',
                push_notification_title: null,
              });

              setTimeout(() => this.props.history.push('/'), 100);

            }else{
              this.footerModal.current.show();

              //KeenIO Event
              this.context.sendKeenEvent('notification', {
                page_title: 'reflection',
                skipped:false,
                os_declined: true,
                snoozed:false,
                reminder_selection: null,
                notification_title: null,
                marked_complete: false,
                push_notification: false,
                notification_action_button_clicked: null,
                code_location: 'Reflection.jsx',
                push_notification_title: null,
              });

              //MixPanel Event
              this.context.sendMixPanelEvent('notification', {
                page_title: 'reflection',
                skipped:false,
                os_declined: true,
                snoozed:false,
                reminder_selection: null,
                notification_title: null,
                marked_complete: false,
                push_notification: false,
                notification_action_button_clicked: null,
                code_location: 'Reflection.jsx',
                push_notification_title: null,
              });
            }
          });
        }
      });
    }

    const handleSkip = () => {
      //this.context.setNotificationSetting('notificationLastAsked', new Date().getTime());
      const notificationAskSkips = parseInt(this.context.getNotificationSetting('notificationAskSkips'));
      this.context.setNotificationSetting('notificationAskSkips', notificationAskSkips+1);

      //KeenIO Event
      this.context.sendKeenEvent('notification', {
        page_title: 'reflection',
        skipped: true,
        os_declined: false,
        snoozed:false,
        reminder_selection: null,
        notification_title: null,
        marked_complete: false,
        push_notification: false,
        notification_action_button_clicked: null,
        code_location: 'Reflection.jsx',
        push_notification_title: null,
      });

      //MixPanel Event
      this.context.sendMixPanelEvent('notification', {
        page_title: 'reflection',
        skipped: true,
        os_declined: false,
        snoozed:false,
        reminder_selection: null,
        notification_title: null,
        marked_complete: false,
        push_notification: false,
        notification_action_button_clicked: null,
        code_location: 'Reflection.jsx',
        push_notification_title: null,
      });

      setTimeout(() => this.props.history.push('/'), 100);
    }

    return(
      <div id="slide-inner" className="slide-inner">
        <Helmet>
          <title>Nod - Reflect - Check-in</title>
        </Helmet>
        <PageHeader>
          <p>Plan ahead for reminders</p>
          <h1>Is it OK to check in with you sometimes?</h1>
        </PageHeader>
        <img className="notification-image" alt="" src={wild_thing_mole_img} />
        <p>Get the occasional reminder to reflect, plus suggestions for new challenges. No spam, just useful stuff.</p>
        {/*<button className="button" onClick={handleSubmit} disabled={ this.state.notify_button_disabled ? 'disabled' : false}>Allow check-ins</button>*/}
        <FooterModal ref={this.footerModal} visible={false} parentId="slide-inner" ignoreFooterInert={true}>
          <h3>First, allow Nod to send reminders</h3>
          <p>Open Settings on your phone to allow Nod to send you notifications.</p>
        </FooterModal>
        <FooterUpdator
          showHome={true}
          backHandler={() => this.props.history.goBack() }
          skipHandler={handleSkip}
          nextText="Allow check-ins"
          nextHandler={handleSubmit}/>
      </div>
    );
  }

  _getSlide() {
    let the_content = null;
    switch(this.props.match.params.screen) {
      default:
      case 'mood':
        the_content = this._getMoodSlide();
        break;
      case 'skills':
        the_content = this._getSkillsSlide();
        break;
      case 'choices':
        the_content = this._getChoicesSlide();
        break;
      case 'interact':
        the_content = this._getInteractionSlide();
        break;
      case 'interact-response':
        the_content = this._getInteractResponseSlide();
        break;
      case 'checkin':
        the_content = this._getCheckInSlide();
        break;
    }
    return the_content;
  }

  render(){
    return (
      <div className="reflection">
        {
          this.state.ready ?
            <React.Fragment>{this._getSlide()}</React.Fragment>
          :
            <div></div>
        }
      </div>
    );
  }
}

Reflection.contextType = MyContext;
export default Reflection;
