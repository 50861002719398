import BaseComponent from '../common/BaseComponent';
import Reflection from './scripts/TurtleFistBumpReflection';

class TurtleFistBumpComponent extends BaseComponent {
  constructor(props) {
    super(props);
    this.Reflection = Reflection;
  }
}

export default TurtleFistBumpComponent;
