import { Component } from 'react';
import MyContext from '../../state/MyContext';
import PropTypes from 'prop-types';

class FooterUpdator extends Component {

  componentDidMount() {

    if(this.props.normal !== undefined){
      this.context.updateFooter('normal');
    }else{
      this.context.updateFooter(
        'subnav',
        true,
        this.props.showHome,
        this.props.showSignIn,
        this.props.homeHandler,
        this.props.backHandler,
        this.props.skipHandler,
        this.props.skipText,
        this.props.nextText,
        this.props.nextHandler
      );
    }

  }

  render(){
    return null;
  }
}

FooterUpdator.propTypes = {
  showHome: PropTypes.bool,
  showSignIn: PropTypes.bool,
  homeHandler: PropTypes.func,
  backHandler: PropTypes.func,
  skipHandler: PropTypes.func,
  skipText: PropTypes.string,
  nextText: PropTypes.string,
  nextHandler: PropTypes.func,
};

FooterUpdator.contextType = MyContext;
export default FooterUpdator;
