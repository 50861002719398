import * as PIXI from 'pixi.js';
import { Howl } from 'howler';
import { TweenMax, Expo } from 'gsap';

import BaseReflection from '../../common/BaseReflection';
import Calc from '../../common/Calc';
import SvgSprite from '../../common/SvgSprite';

import Particle from './Particle';

import armLeftSrc from '../images/arm-left.png';
import armRightSrc from '../images/arm-right.png';
import burstSrc from '../images/burst.png';
import groundSrc from '../images/ground.png';
import turtleLeftSrc from '../images/turtle-left.png';
import turtleRightSrc from '../images/turtle-right.png';

import snapWebmSrc from '../sounds/snap.webm';
import snapOggSrc from '../sounds/snap.ogg';
import snapMp3Src from '../sounds/snap.mp3';
import successWebmSrc from '../sounds/success.webm';
import successOggSrc from '../sounds/success.ogg';
import successMp3Src from '../sounds/success.mp3';
import whooshWebmSrc from '../sounds/whoosh.webm';
import whooshOggSrc from '../sounds/whoosh.ogg';
import whooshMp3Src from '../sounds/whoosh.mp3';

class TurtleFistBumpReflection extends BaseReflection {
  init(onComplete) {
    super.init(onComplete);

    this.assetPaths = [
      armLeftSrc,
      armRightSrc,
      burstSrc,
      groundSrc,
      turtleLeftSrc,
      turtleRightSrc,
      snapWebmSrc,
      snapOggSrc,
      snapMp3Src,
      successWebmSrc,
      successOggSrc,
      successMp3Src,
      whooshWebmSrc,
      whooshOggSrc,
      whooshMp3Src
    ];

    this.itemScale = 0.35;

    this.leftBase = this.width / 2 - 110 * this.itemScale;
    this.leftMove = 16;

    this.rightBase = this.width / 2 + 100 * this.itemScale;
    this.rightMove = 12;

    this.hitCurrent = 0;
    this.hitCount = 8;

    this.particles = [];
    this.particleCount = 150;

    this.completeTimeout = null;

    this.addEventListeners();
    this.loadAssets();
  }

  reset(width = this.width, height = this.height) {
    super.reset(width, height);

    window.clearTimeout(this.completeTimeout);

    this.turtleLeft = null;
    this.turtleRight = null;
    this.armLeft = null;
    this.armRight = null;
    this.burstContainer = null;
    this.burst = null;
    this.particles.length = 0;

    this.hitCurrent = 0;

    this.leftBase = this.width / 2 - 110 * this.itemScale;
    this.leftMove = 16;

    this.rightBase = this.width / 2 + 100 * this.itemScale;
    this.rightMove = 12;

    this.create();
  }

  addEventListeners() {
    super.addEventListeners();

    this.onPointerDown = this.onPointerDown.bind(this);
    this.canvasWrap.addEventListener('mousedown', this.onPointerDown);
    this.canvasWrap.addEventListener('touchstart', this.onPointerDown);
  }

  removeEventListeners() {
    super.removeEventListeners();

    this.canvasWrap.removeEventListener('mousedown', this.onPointerDown);
    this.canvasWrap.removeEventListener('touchstart', this.onPointerDown);
  }

  onPointerDown(e) {
    if (this.hitCurrent < this.hitCount) {
      this.hitCurrent++;
      this.bumpIn();
    }

    this.progressTarget = this.hitCurrent / this.hitCount;
  }

  initPhysics() {
    super.initPhysics();
  }

  initSounds() {
    super.initSounds();

    this.sounds.snap = new Howl({
      src: [snapWebmSrc, snapOggSrc, snapMp3Src],
      volume: 0.5
    });

    this.sounds.success = new Howl({
      src: [successWebmSrc, successOggSrc, successMp3Src],
      volume: 0.75
    });

    this.sounds.whoosh = new Howl({
      src: [whooshWebmSrc, whooshOggSrc, whooshMp3Src],
      volume: 0.5
    });
  }

  create() {
    super.create();

    this.createGround();
    this.createTurtles();
    this.createParticles();
  }

  createGround() {
    this.groundContainer = new PIXI.Container();
    this.pixiApp.stage.addChild(this.groundContainer);
    this.groundWidth = 614;
    this.groundContainer.x = 0;
    this.groundContainer.y = this.height / 2 + 220 * this.itemScale;
    let groundSpritesNeeded = Math.ceil(this.width / this.groundWidth) + 1;

    for (let i = 0; i < groundSpritesNeeded; i++) {
      let ground = new SvgSprite(
        i * this.groundWidth - i * 16 - 16,
        0,
        this.groundWidth,
        this.assets[groundSrc],
        0,
        0.5
      );
      this.groundContainer.addChild(ground.sprite);
    }
  }

  createTurtles() {
    let armLeftSrcWidth = this.assets[armLeftSrc].data.width;
    this.armLeft = new SvgSprite(
      this.leftBase,
      this.height / 2 - 100 * this.itemScale,
      armLeftSrcWidth * this.itemScale,
      this.assets[armLeftSrc]
    );
    this.pixiApp.stage.addChild(this.armLeft.sprite);

    let armRightSrcWidth = this.assets[armRightSrc].data.width;
    this.armRight = new SvgSprite(
      this.rightBase,
      this.height / 2 - 115 * this.itemScale,
      armRightSrcWidth * this.itemScale,
      this.assets[armRightSrc]
    );
    this.pixiApp.stage.addChild(this.armRight.sprite);

    let turtleLeftSrcWidth = this.assets[turtleLeftSrc].data.width;
    this.turtleLeft = new SvgSprite(
      this.width / 2 - 240 * this.itemScale,
      this.height / 2,
      turtleLeftSrcWidth * this.itemScale,
      this.assets[turtleLeftSrc]
    );
    this.pixiApp.stage.addChild(this.turtleLeft.sprite);

    let turtleRightSrcWidth = this.assets[turtleRightSrc].data.width;
    this.turtleRight = new SvgSprite(
      this.width / 2 + 240 * this.itemScale,
      this.height / 2 - 15 * this.itemScale,
      turtleRightSrcWidth * this.itemScale,
      this.assets[turtleRightSrc]
    );
    this.pixiApp.stage.addChild(this.turtleRight.sprite);

    let burstSrcWidth = this.assets[burstSrc].data.width;
    this.burstContainer = new PIXI.Container();
    this.burstContainer.x = this.width / 2 + 0 * this.itemScale;
    this.burstContainer.y = this.height / 2 - 100 * this.itemScale;
    this.pixiApp.stage.addChild(this.burstContainer);
    this.burst = new SvgSprite(
      0,
      0,
      burstSrcWidth * this.itemScale,
      this.assets[burstSrc]
    );
    this.burst.sprite.alpha = 0;
    this.burst.sprite.rotation = -0.1;
    this.burstContainer.addChild(this.burst.sprite);
  }

  createParticles() {
    for (let i = 0; i < this.particleCount; i++) {
      let width = Calc.rand(3, 12);
      let height = Calc.rand(3, 12);
      let particle = new Particle(
        -100,
        -100,
        width,
        height,
        `hsla(${Calc.rand(0, 360)}, 95%, 60%, 1)`,
        0.5,
        0.5,
        Calc.rand(-Math.PI + 0.5, -0.5),
        Calc.rand(0, 20)
      );
      this.pixiApp.stage.addChild(particle.container);
      this.particles.push(particle);
    }
  }

  bumpIn() {
    this.sounds.whoosh.rate(
      Calc.map(this.hitCurrent / this.hitCount, 0, 1, 1, 2)
    );
    this.sounds.whoosh.play();
    if (this.hitCurrent >= this.hitCount) {
      this.sounds.success.play();
      this.completeTimeout = window.setTimeout(() => {
        this.onComplete();
      }, 3000);
    }
    let obj = { value: 0 };
    this.tweenIn = TweenMax.to(obj, 0.05, {
      value: 1,
      ease: Expo.easeIn,
      onUpdate: () => {
        this.armLeft.sprite.x = this.leftBase + obj.value * this.leftMove;
        this.armRight.sprite.x = this.rightBase + obj.value * -this.rightMove;
      },
      onComplete: () => {
        this.sounds.snap.rate(
          Calc.map(this.hitCurrent / this.hitCount, 0, 1, 0.5, 1.5)
        );
        this.sounds.snap.play();
        this.burstIn();
        this.bumpOut();
        if (this.hitCurrent >= this.hitCount) {
          for (let i = 0, len = this.particles.length; i < len; i++) {
            this.particles[i].activate(
              this.burstContainer.x,
              this.burstContainer.y
            );
          }
        }
      }
    });
  }

  bumpOut() {
    let obj = { value: 1 };
    this.tweenOut = TweenMax.to(obj, 0.8, {
      value: 0,
      ease: Expo.easeOut,
      onUpdate: () => {
        this.armLeft.sprite.x = this.leftBase + obj.value * this.leftMove;
        this.armRight.sprite.x = this.rightBase + obj.value * -this.rightMove;
      },
      onComplete: () => {}
    });
  }

  burstIn() {
    let obj = { value: 0 };
    this.tweenOut = TweenMax.to(obj, 0.6, {
      value: 1,
      ease: Expo.easeOut,
      onUpdate: () => {
        this.burst.sprite.alpha = Calc.clamp((1 - obj.value) * 4, 0, 1);
        this.burstContainer.scale.set(0.5 + obj.value * 1);
      }
    });
  }

  onTick() {
    super.onTick();

    let time = Date.now();
    for (let i = 0, len = this.particles.length; i < len; i++) {
      this.particles[i].update(time);
    }
  }

  destroy() {
    super.destroy();

    window.clearTimeout(this.completeTimeout);

    this.turtleLeft = null;
    this.turtleRight = null;
    this.armLeft = null;
    this.armRight = null;
    this.burstContainer = null;
    this.burst = null;
    this.particles.length = 0;
    this.particles = null;
  }
}

export default TurtleFistBumpReflection;
